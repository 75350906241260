
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import MediaUploadAndPreview from '@/createandpublish/components/socialStories/MediaUploadAndPreview.vue';
import TagInput from '@/createandpublish/components/common/TagsInput.vue';

import { eventBus, busEvents } from '@/createandpublish/core/eventBus/socialPostEventBus';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { capitalize } from '@/utils/string';
import {
  YouTubeErrorMessage,
  YouTubeVisibilityValues,
  YouTubeVisibility,
  youTubeInvalidCharacters,
  youTubeTagsMaxTotalCharLen,
  youTubeThumbnailFilesize,
  youTubeShortMaxDuration,
  YOUTUBE_TITLE_MAX_LENGTH,
  YOUTUBE_DESCRIPTION_MAX_LENGTH,
  YOUTUBE_MAX_TAGS,
  YOUTUBE_TAG_CHARACTER_MIN_LENGTH,
  YOUTUBE_VIDEO_ACCEPT_FILE_TYPES,
  YOUTUBE_IMAGE_ACCEPT_FILE_TYPES,
} from '@/createandpublish/core/utils/socialStory';
import { SocialPlatform, SocialPlatforms } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { MediaElProperties } from '@/types/createandpublish';
import type { YouTubePostOptions } from 'content-cloud-types/dist/types/requests/createandpublish/PlatformPostOptions';
import type { PlatformPostStatus } from 'content-cloud-types/dist/types/createandpublish/SocialPosting';
import type { DraftSocialStory, SocialPostBody } from '@/types/createandpublish/socialStory';
import EmojiButton from '@/components/common/buttons/EmojiButton.vue';

const SocialStoryStore = namespace('CreateAndPublishStore/socialStories');

Component.registerHooks(['beforeDestroy', 'validations']);

@Component({
  name: 'YouTubePost',
  components: {
    MediaUploadAndPreview,
    TagInput,
    EmojiButton,
  },
})
export default class YouTubePost extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly isReadonly!: boolean;
  @Prop({ type: String, required: true }) readonly currentSelectedTab!: SocialPlatform;

  @SocialStoryStore.Getter readonly draftSocialStory?: DraftSocialStory;
  @SocialStoryStore.Getter readonly editingStoryId!: number;
  @SocialStoryStore.Mutation('UPDATE_DRAFT_SOCIAL_STORY_POST') updatePost!: ({
    platformType,
    postUpdates,
  }: {
    platformType: SocialPlatform;
    postUpdates: SocialPostBody;
  }) => void;

  title = '';
  post = '';
  mediaUrl = '';
  visibility: YouTubeVisibility = 'public';
  thumbnail = '';
  videoThumbnailUrl = '';
  tags: string[] = [];
  short: string[] = [];

  editingPostStatus: PlatformPostStatus | '' = '';

  get postContent(): SocialPostBody {
    const { title, post, mediaUrl, visibility, thumbnail, tags, short: unparsedShort, videoThumbnailUrl } = this;
    const mediaUrls = mediaUrl ? [mediaUrl] : [];
    const shorts = unparsedShort.includes('true');
    const thumbNail = thumbnail || undefined;

    const videoThumbnailUrlOption = videoThumbnailUrl ? { videoThumbnailUrl: videoThumbnailUrl } : {};
    const platforms: SocialPlatform[] = [SocialPlatforms.youtube];
    const mainRequest: SocialPostBody['mainRequest'] = {
      post,
      platforms,
      mediaUrls,
      isVideo: true,
      ...videoThumbnailUrlOption,
    };
    const youTubeOptions: YouTubePostOptions = {
      title,
      visibility,
      thumbNail,
      tags,
      shorts,
    };
    return {
      mainRequest,
      platformOptions: {
        youTubeOptions,
      },
    };
  }

  onInputChanged(input) {
    this.post = input;
    this.$v.post.$model = input;
  }

  @Watch('postContent')
  onPostContentUpdate(newVal: YouTubePost['postContent']) {
    this.updatePost({
      platformType: SocialPlatforms.youtube,
      postUpdates: newVal,
    });
    eventBus.$emit(busEvents.UPDATE_DRAFT_POST_DETAILS, newVal);
  }

  validations() {
    const requireShortDurationValidator = this.short.includes('true');
    return {
      title: {
        required,
        maxLength: maxLength(YOUTUBE_TITLE_MAX_LENGTH),
        youTubeInvalidCharacters,
      },
      post: {
        required,
        maxLength: maxLength(YOUTUBE_DESCRIPTION_MAX_LENGTH),
        youTubeInvalidCharacters,
      },
      mediaUrl: {
        required,
      },
      tags: {
        maxLength: maxLength(YOUTUBE_MAX_TAGS),
        youTubeTagsMaxTotalCharLen,
        $each: {
          minLength: minLength(YOUTUBE_TAG_CHARACTER_MIN_LENGTH),
        },
      },
      thumbnailProperties: {
        filesize: {
          youTubeThumbnailFilesize,
        },
      },
      mediaUrlProperties: {
        duration: requireShortDurationValidator ? { youTubeShortMaxDuration } : {},
      },
    };
  }

  get isErrorFree() {
    return !this.$v.$invalid;
  }

  get errorCount() {
    const { titleError, postError, mediaUrlError, tagsError, thumbnailError, shortError } = this;

    let count = 0;
    titleError && count++;
    postError && count++;
    mediaUrlError && count++;
    tagsError && count++;
    thumbnailError && count++;
    shortError && count++;

    return count;
  }

  @Watch('errorCount')
  onErrorCountChange() {
    this.$emit('errorCount:update', { platform: SocialPlatforms.youtube, count: this.errorCount });
  }

  get titleError() {
    const field = this.$v.title;
    const { required, maxLength, youTubeInvalidCharacters } = YouTubeErrorMessage.title;

    if (field.$error) {
      if (!field.required) {
        return required;
      }
      if (!field.maxLength) {
        return maxLength;
      }
      if (!field.youTubeInvalidCharacters) {
        return youTubeInvalidCharacters;
      }
    }
    return '';
  }

  get postError() {
    const field = this.$v.post;
    const { required, maxLength, youTubeInvalidCharacters } = YouTubeErrorMessage.post;

    if (field.$error) {
      if (!field.required) {
        return required;
      }
      if (!field.maxLength) {
        return maxLength;
      }
      if (!field.youTubeInvalidCharacters) {
        return youTubeInvalidCharacters;
      }
    }
    return '';
  }

  get mediaUrlError() {
    const field = this.$v.mediaUrl;
    const { required } = YouTubeErrorMessage.mediaUrl;

    if (field.$error) {
      if (!field.required) return required;
    }

    return '';
  }

  get thumbnailError() {
    const field = this.$v.thumbnailProperties?.filesize;
    const { filesizeExceeded } = YouTubeErrorMessage.thumbnail;

    if (field?.$invalid) {
      return filesizeExceeded;
    }
    return '';
  }

  get tagsError() {
    const field = this.$v.tags;
    const { tagMinLCharacterLength, maxTagsLength, youTubeTagsMaxTotalCharLen } = YouTubeErrorMessage.tags;

    if (field.$invalid) {
      if (!field.maxLength) {
        return maxTagsLength;
      }
      if (!field.youTubeTagsMaxTotalCharLen) {
        return youTubeTagsMaxTotalCharLen;
      }
      if (field.$each?.$invalid) {
        return tagMinLCharacterLength;
      }
    }
    return '';
  }

  get shortError() {
    const field = this.$v.mediaUrlProperties?.duration;
    const { maxLength } = YouTubeErrorMessage.shorts;

    if (field?.$invalid) {
      return maxLength;
    }
    return '';
  }

  get visibilityOptions() {
    return Object.values(YouTubeVisibilityValues).map((val) => ({ label: capitalize(val), value: val }));
  }

  get shortOptions() {
    return [{ label: 'Post as YouTube Short', value: 'true' }];
  }

  get descriptionLengthString() {
    return `${this.post.length}/${YOUTUBE_DESCRIPTION_MAX_LENGTH}`;
  }

  mediaUrlProperties: MediaElProperties | null = null;
  onMediaUrlPropertiesChanged(properties: MediaElProperties) {
    this.mediaUrlProperties = properties;
  }

  thumbnailProperties: MediaElProperties | null = null;
  onThumbnailPropertiesChanged(properties: MediaElProperties) {
    this.thumbnailProperties = properties;
  }

  updateVideoThumbnailUrl(value: string) {
    this.videoThumbnailUrl = value;
  }

  get videoFileInputAcceptAttr() {
    return YOUTUBE_VIDEO_ACCEPT_FILE_TYPES;
  }
  get imageFileInputAcceptAttr() {
    return YOUTUBE_IMAGE_ACCEPT_FILE_TYPES;
  }

  toggleUploadingMediaStatus() {
    this.$emit('toggleUploadingMediaStatus');
  }

  validateForm() {
    this.$v.$touch();
  }

  get isPostEditable() {
    const { editingPostStatus: status, editingStoryId: storyId } = this;
    if (isNaN(storyId)) return true;

    const allowedStatuses: PlatformPostStatus[] = ['draft', 'scheduled', 'error'];

    return !this.isReadonly && !isNaN(storyId) && allowedStatuses.includes(status as PlatformPostStatus);
  }

  setDataFromExistingEpisode() {
    const platformPost = this.draftSocialStory?.socialPlatformPosts.find(
      (socialPosts) => socialPosts.mainRequest.platforms[0] === SocialPlatforms.youtube
    );
    if (!platformPost) return;

    this.editingPostStatus = platformPost.status ?? '';

    platformPost.mainRequest;
    const { post, mediaUrls, videoThumbnailUrl } = platformPost.mainRequest;
    const { title, visibility, thumbNail, tags, shorts } = platformPost.platformOptions.youTubeOptions ?? {};

    this.title = title ?? '';
    this.post = post;
    this.mediaUrl = mediaUrls?.[0] ?? '';
    this.thumbnail = thumbNail ?? '';
    this.videoThumbnailUrl = videoThumbnailUrl ?? '';
    this.visibility = visibility ?? 'public';
    this.short = shorts ? ['true'] : [];
    this.tags = tags ?? [];
  }

  setupBusEvents() {
    eventBus.$on(busEvents.VALIDATE_POST, this.validateForm);
    eventBus.$on(busEvents.ADD_PREFILL_CONTENT, this.addPrefillContent);
    eventBus.$on(busEvents.ADD_MEDIA_FROM_LIBRARY, this.addMediaFromLibrary);
  }

  addMediaFromLibrary(content: { value: string; prefix: 'video' | 'image' | 'gif' }) {
    const { prefix, value } = content;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.youtube) {
      switch (prefix) {
        case 'video':
          this.mediaUrl = value;
          break;
        case 'gif':
        case 'image':
        default:
          break;
      }
    }
  }

  addPrefillContent(content: { value: string; prefix: string }) {
    const { prefix, value } = content;

    const currentPost = this.post;
    const currentTags = this.tags;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.youtube) {
      switch (prefix) {
        case 'to post':
        case 'link':
          this.post = currentPost + value + ' ';
          break;
        case 'title':
          this.title = value;
          break;
        case 'hashtag':
          this.tags = [...currentTags, value.split(' ').join('')];
          break;
        default:
          break;
      }
    }
  }

  destroyBusEvents() {
    eventBus.$off(busEvents.VALIDATE_POST, this.validateForm);
  }

  mounted() {
    this.setupBusEvents();
    if (!isNaN(this.editingStoryId)) {
      this.setDataFromExistingEpisode();
    }
  }

  beforeDestroy() {
    this.destroyBusEvents();
  }
}
