
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import Drawer from '@/components/common/drawer/Drawer.vue';
import CampaignInput from '@/components/common/campaigns/CampaignInput.vue';
import SplitForm from '@/components/common/form/SplitForm.vue';
import PlatformSelectorTabs from '@/createandpublish/components/socialStories/PlatformSelectorTabs.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import PrefillContent from '@/createandpublish/components/socialStories/PrefillContent.vue';

import { eventBus, busEvents } from '@/createandpublish/core/eventBus/socialPostEventBus';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment-timezone';
import { maybePluralize } from '@/utils';

import type { Campaign } from '@/types/Campaign';
import type { Brand } from '@/types/Brand';
import type { AlertDisplay as AlertDisplayData } from '@/types/AlertMessage';
import type { ActiveSocialAccount, AyrshareProfile } from '@/types/audience';
import type {
  SocialStoryRequest,
  CreateSocialStoryInput as DraftSocialStory,
} from 'content-cloud-types/dist/types/createandpublish/PostingControllerInterfaces';
import type { SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { PlatformPostStatus } from 'content-cloud-types/dist/types/createandpublish/SocialPosting';
import type { ISO8601DateTime } from '@/types/Common';
import { ButtonSecondary as FmButtonSecondary, Link as FmLink } from '@futuri/futuri-ui-kit';
import SocialPostPreview from './SocialPostPreview.vue';
import { getDateTimeTwoMinutesFromNow } from '@/createandpublish/core/utils/helpers';
import { RSSDrawerStoryData } from '@/types/discover/DiscoverRSSFeed';
import { Story } from '@/types/discover/Story';
import { SharableForSocialStory } from '@/createandpublish/modules/socialStories';
import { parseCommentary } from '@/utils/commentaryParser';
import DiscoverIdeaStarterById from '@/models/DiscoverIdeaStarterByIdModel';
import { ImageAsset, VideoAsset } from '@/types/createandpublish';

type PublishMethod = 'publish' | 'schedule';

type DrawerForm = {
  campaignIds: Campaign['id'][];
  title: string;
  plannedDate: string;
  plannedTime: string;
  publishMethod: PublishMethod;
  scheduleDate: ISO8601DateTime;
};

type PrefillObject = {
  prefix: string;
  value: string;
};

type AddMediaObject = {
  prefix: 'video' | 'image' | 'gif';
  value: ImageAsset | VideoAsset;
};

const DATE_TIME_FORMAT = 'YYYY-MM-DD h:mm a' as const;

const AudienceStore = namespace('AudienceAnalyticsStore');
const SocialStoryStore = namespace('CreateAndPublishStore/socialStories');

Component.registerHooks(['validations']);

@Component({
  name: 'SocialStoryDrawer',
  components: {
    Drawer,
    CampaignInput,
    SplitForm,
    PlatformSelectorTabs,
    AlertDisplay,
    VueCtkDateTimePicker,
    SocialPostPreview,
    PrefillContent,
  },
})
export default class SocialStoryDrawer extends Vue {
  static components: {
    Drawer: typeof Drawer;
    CampaignInput: typeof CampaignInput;
    SplitForm: typeof SplitForm;
    PlatformSelectorTabs: typeof PlatformSelectorTabs;
    AlertDisplay: typeof AlertDisplay;
    VueCtkDateTimePicker: typeof VueCtkDateTimePicker;
  };

  @SocialStoryStore.Getter readonly socialStoryDrawerStatus!: boolean;
  @SocialStoryStore.Mutation('SET_SOCIAL_STORY_DRAWER_READ_ONLY_STATUS') setSocialStoryDrawerReadOnlyStatus!: (
    isReadonly?: boolean
  ) => void;

  @Getter readonly selectedBrand!: Brand;

  @AudienceStore.Getter readonly timeZone!: string;
  @AudienceStore.Getter readonly profile!: AyrshareProfile | null;
  @AudienceStore.Getter readonly activeSocialAccounts!: ActiveSocialAccount;
  @AudienceStore.Action getAyrShareConnectUrl!: () => Promise<string>;

  @SocialStoryStore.Action ensureDraftSocialStory!: () => Promise<void>;
  @SocialStoryStore.Mutation('CLEAR_DRAFT_SOCIAL_STORY') clearDraftSocialStory!: () => void;
  @SocialStoryStore.Mutation('CLEAR_EDITING_STORY_ID') clearEditingStoryId!: () => void;
  @SocialStoryStore.Action saveSocialStory!: () => Promise<void>;
  @SocialStoryStore.Action updateSocialStory!: () => Promise<void>;
  @SocialStoryStore.Getter readonly draftSocialStory?: DraftSocialStory;
  @SocialStoryStore.Getter readonly editingStoryId!: number;
  @SocialStoryStore.Mutation('UPDATE_DRAFT_SOCIAL_STORY') updateDraftSocialStory!: (
    socialStoryUpdates: SocialStoryRequest
  ) => void;
  @SocialStoryStore.Mutation('REMOVE_DRAFT_SOCIAL_STORY_POST') removeDraftSocialStoryPost!: (
    platformType: SocialPlatform
  ) => void;
  @SocialStoryStore.Mutation('UPDATE_STORY_POST_STATUS') updateSocialPostStatus!: ({
    platformType,
    status,
  }: {
    platformType: SocialPlatform;
    status: PlatformPostStatus | undefined;
  }) => void;
  @SocialStoryStore.Mutation('UPDATE_STORY_POST_SCHEDULE_DATE') updatePostScheduleDate!: ({
    platformType,
    scheduleDate,
  }: {
    platformType: SocialPlatform;
    scheduleDate: ISO8601DateTime | undefined;
  }) => void;
  @SocialStoryStore.Action getStories!: () => Promise<void>;

  @SocialStoryStore.Mutation('SET_SHARABLE_FOR_SOCIAL_STORY') setSharableForSocialStory!: (
    sharable: SharableForSocialStory
  ) => void;
  @SocialStoryStore.Getter('sharableForSocialStory') readonly sharableForSocialStory!: SharableForSocialStory;

  form: DrawerForm = {
    campaignIds: [],
    title: '',
    plannedDate: '',
    plannedTime: '',

    publishMethod: 'publish',
    scheduleDate: '',
  };

  modalToggle = false;
  tempPostType = '';
  isUploadingMedia = false;

  readonly publishMethodOptions: {
    label: Capitalize<PublishMethod>;
    value: PublishMethod;
  }[] = [
    { label: 'Publish', value: 'publish' },
    { label: 'Schedule', value: 'schedule' },
  ];

  dateTimePicker = {
    minDateTime: '',
    format: DATE_TIME_FORMAT,
    formatted: 'llll',
  };

  selectedTab: SocialPlatform | '' = '';

  get currentSelectedTab() {
    return this.selectedTab;
  }

  setCurrentSelectedTab(platformType: SocialPlatform | '') {
    this.selectedTab = platformType;
  }
  prefilledContent: PrefillObject[] = [];
  mediaAssetsToAdd: AddMediaObject[] = [];

  generatePrefilledContent() {
    const currentSharable = this.sharableForSocialStory;
    if (!currentSharable) return;

    const { data, label } = currentSharable;

    const MAX_HASHTAGS = 5;
    const MAX_LINKS = 5;
    const MAX_CONTENT = 5;

    switch (label) {
      case 'IdeaStarter': {
        const ideaStarter = data as DiscoverIdeaStarterById;
        const ideaStarterComments = parseCommentary(ideaStarter?.commentary)?.slice(0, MAX_CONTENT);
        if (ideaStarterComments.length > 0) {
          ideaStarterComments.forEach((comment) => {
            if (comment) {
              this.prefilledContent.push({
                prefix: 'to post',
                value: comment,
              });
            }
          });
        }
        const ideaStartTitle = ideaStarter?.title;
        if (ideaStartTitle) {
          this.prefilledContent.push({
            prefix: 'title',
            value: ideaStartTitle,
          });
        }
        const ideaStarterUrls = ideaStarter?.articles?.map((article) => article.url).slice(0, MAX_LINKS);
        if (ideaStarterUrls?.length > 0) {
          ideaStarterUrls.forEach((url) => {
            if (url) {
              this.prefilledContent.push({
                prefix: 'link',
                value: url,
              });
            }
          });
        }
        break;
      }
      case 'TrendingStory': {
        const trendingStory = data as Story;
        const storyTitle = trendingStory?.title;
        if (storyTitle) {
          this.prefilledContent.push({
            prefix: 'title',
            value: storyTitle,
          });
        }
        const storyUrls = trendingStory?.articles?.map((article) => article.url).slice(0, MAX_LINKS);
        if (storyUrls?.length > 0) {
          storyUrls.forEach((url) => {
            if (url) {
              this.prefilledContent.push({
                prefix: 'link',
                value: url,
              });
            }
          });
        }
        const storyHashtags = trendingStory?.keywords?.map((keyword) => keyword.name).slice(0, MAX_HASHTAGS);
        if (storyHashtags?.length > 0) {
          storyHashtags.forEach((hashtag) => {
            if (hashtag) {
              this.prefilledContent.push({
                prefix: 'hashtag',
                value: hashtag,
              });
            }
          });
        }
        break;
      }
      case 'RSSStory': {
        const rssStory = data as RSSDrawerStoryData;
        const rssLink = rssStory?.link;
        if (rssLink) {
          this.prefilledContent.push({
            prefix: 'link',
            value: rssLink,
          });
        }
        const rssTitle = rssStory?.title;
        if (rssTitle) {
          this.prefilledContent.push({
            prefix: 'title',
            value: rssTitle,
          });
        }
        const rssContent = rssStory?.content;
        if (rssContent) {
          this.prefilledContent.push({
            prefix: 'to post',
            value: rssContent,
          });
        }
        const rssHashtags = rssStory?.tags?.slice(0, MAX_HASHTAGS);
        if (rssHashtags?.length > 0) {
          rssHashtags.forEach((hashtag) => {
            if (hashtag) {
              this.prefilledContent.push({
                prefix: 'hashtag',
                value: hashtag,
              });
            }
          });
        }
        break;
      }
      case 'ImageAsset': {
        const imageAsset = data as ImageAsset;
        if (imageAsset) {
          this.mediaAssetsToAdd.push({
            prefix: 'image',
            value: imageAsset,
          });
        }
        break;
      }
      case 'VideoAsset': {
        const videoAsset = data as VideoAsset;
        if (videoAsset) {
          this.mediaAssetsToAdd.push({
            prefix: 'video',
            value: videoAsset,
          });
        }
        break;
      }
      case 'GifAsset': {
        const gifAsset = data as ImageAsset;
        if (gifAsset) {
          this.mediaAssetsToAdd.push({
            prefix: 'gif',
            value: gifAsset,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  setNewSharable() {
    this.setSharableForSocialStory({
      data: null,
      label: 'none',
    });
    this.prefilledContent = [];
    this.mediaAssetsToAdd = [];
  }

  get selectedPrefilledContent() {
    return this.prefilledContent;
  }

  get selectedMediaAssets() {
    return this.mediaAssetsToAdd;
  }

  get isReadonly() {
    return this.socialStoryDrawerStatus;
  }

  get isDateTimePickerVisible() {
    return this.form.publishMethod === 'schedule';
  }

  updateMinDateTime() {
    this.dateTimePicker.minDateTime = moment().tz(this.timeZone).subtract(1, 'minutes').format(DATE_TIME_FORMAT);
  }

  toggleUploadingMediaStatus() {
    const current = this.isUploadingMedia;
    this.isUploadingMedia = !current;
  }

  get scheduleInputAssistiveText() {
    const offset = moment.tz(this.timeZone).format('Z');
    return `The selected date/time is in the timezone: ${this.timeZone} (GMT ${offset})`;
  }

  onPublishMethodUpdate(publishMethod: DrawerForm['publishMethod']) {
    if (this.form.publishMethod === 'schedule' && publishMethod === 'publish') {
      this.form.scheduleDate = '';
    }
    this.form.publishMethod = publishMethod;
  }
  onScheduleDateUpdate(scheduleDate: ISO8601DateTime) {
    this.form.scheduleDate = scheduleDate;
  }

  get socialStoryPosts() {
    return this.draftSocialStory?.socialPlatformPosts ?? [];
  }

  get publishButtonText() {
    const postsString = maybePluralize(this.socialStoryPosts?.length, 'post');
    return this.form.publishMethod === 'publish' ? `Publish ${postsString}` : `Schedule ${postsString}`;
  }

  get plannedDateTime() {
    const { plannedDate, plannedTime } = this.form;
    if (!plannedDate || !plannedTime) return undefined;

    return moment(`${plannedDate} ${plannedTime}`, 'YYYY-MM-DD HH:mm').tz(this.timeZone).toISOString();
  }

  get storyData(): SocialStoryRequest {
    const { title: storyTitle, campaignIds } = this.form;
    const plannedDate = this.plannedDateTime;
    return {
      storyTitle,
      campaignIds,
      plannedDate,
    };
  }

  @Watch('storyData')
  onStoryDataChange(newVal: SocialStoryDrawer['storyData']) {
    this.updateDraftSocialStory(newVal);
  }

  platformTabs = {
    show: false,
  };

  isSaveButtonLoading = false;
  isDraftButtonLoading = false;
  get isDraftButtonDisabled() {
    return this.isSaveButtonLoading || this.isUploadingMedia;
  }
  get isSaveButtonDisabled() {
    return this.isDraftButtonLoading || this.isUploadingMedia;
  }

  apiErrors: string[] = [];

  get alertDisplayData(): AlertDisplayData | undefined {
    if (!this.apiErrors.length) return undefined;

    return {
      header: 'Failed to save Social Post',
      message: this.apiErrors.join('<br />'),
      type: 'critical',
    };
  }

  validations() {
    return {
      form: {
        title: { required },
      },
      draftSocialStory: {
        socialPlatformPosts: {
          minLength: (v) => !Array.isArray(v) || v.length > 0,
        },
      },
    };
  }

  get minLengthPostError() {
    const field = this.$v.draftSocialStory?.socialPlatformPosts;
    if (field?.$error) {
      if (!field.minLength) {
        return 'At least one post is required';
      }
    }
    return '';
  }

  postErrors = 0;

  onErrorCountUpdate(count: number) {
    this.postErrors = count;
  }

  get isStepOneErrorFree() {
    return !this.$v.$invalid && this.postErrors === 0 && this.socialStoryPosts.length > 0;
  }

  get mayPublish() {
    return this.isStepOneErrorFree;
  }

  onSaveAsDraft() {
    const platforms = this.socialStoryPosts.map(({ mainRequest }) => mainRequest.platforms[0]);
    for (const platformType of platforms) {
      this.updateSocialPostStatus({ platformType, status: 'draft' });
    }
    this.isDraftButtonLoading = true;
    this.postStory();
  }

  emitValidationRequest() {
    this.$v.$touch();
    eventBus.$emit(busEvents.VALIDATE_POST);
  }

  async onPublishOrScheduleStory() {
    this.emitValidationRequest();
    await this.$nextTick();
    if (!this.mayPublish) return;

    const platforms = this.socialStoryPosts.map(({ mainRequest }) => mainRequest.platforms[0]);

    if (platforms.includes('twitter') && this.form.publishMethod === 'publish') {
      const twitterPostData = this.socialStoryPosts.find(({ mainRequest }) => mainRequest.platforms[0] === 'twitter');
      if (twitterPostData?.mainRequest?.isVideo) {
        this.onPublishMethodUpdate('schedule');
        this.form.scheduleDate = getDateTimeTwoMinutesFromNow(this.timeZone);
      }
    }

    const postStatus: PlatformPostStatus = this.form.publishMethod === 'publish' ? 'sent' : 'scheduled';

    for (const platformType of platforms) {
      this.updateSocialPostStatus({ platformType, status: postStatus });
      if (postStatus === 'scheduled') {
        const dateTime = moment.tz(this.form.scheduleDate, DATE_TIME_FORMAT, this.timeZone).toISOString();
        this.updatePostScheduleDate({ platformType, scheduleDate: dateTime });
      }
    }
    this.isSaveButtonLoading = true;
    this.postStory();
  }

  get isEditMode() {
    return !isNaN(this.editingStoryId);
  }

  async fetchData() {
    await this.getStories();
  }

  async postStory() {
    this.apiErrors = [];
    try {
      if (this.isEditMode) {
        await this.updateSocialStory();
      } else {
        await this.saveSocialStory();
      }
      this.closeDrawer();
      this.fetchData();
    } catch (e) {
      this.apiErrors = e as string[];
      await this.$nextTick();
      this.$refs.alertDisplay?.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } finally {
      this.isSaveButtonLoading = false;
      this.isDraftButtonLoading = false;
    }
  }

  onRemoveDraftSocialStoryPost(platformType: SocialPlatform) {
    this.removeDraftSocialStoryPost(platformType);
  }

  resetPanel() {
    this.form = {
      campaignIds: [],
      title: '',
      plannedDate: '',
      plannedTime: '',
      publishMethod: 'publish',
      scheduleDate: '',
    };
    this.isSaveButtonLoading = false;
    this.isDraftButtonLoading = false;
    this.apiErrors = [];
    this.postErrors = 0;
    this.$v.$reset();
    // Bootstrap directive adds tabindex to host element, making it tabbable twice. Removing.
    this.$refs.cancelLink.removeAttribute('tabindex');
    this.$refs.cancelButton?.removeAttribute('tabindex');
  }

  async onOpen() {
    await this.ensureDraftSocialStory();
    this.updateMinDateTime();
    this.generatePrefilledContent();
    if (!isNaN(this.editingStoryId)) {
      this.setDataFromExistingStory();
    }
    this.platformTabs.show = true;
  }

  closeDrawer(hide?: () => void) {
    if (!hide) {
      this.$root.$emit('bv::toggle::collapse', this.$refs.drawer.id);
    } else {
      hide();
    }

    setTimeout(() => {
      this.platformTabs.show = false;
      this.setCurrentSelectedTab('');
      this.setNewSharable();
      this.clearStoreData();
      this.resetPanel();
    }, 300);
  }

  clearStoreData() {
    this.clearDraftSocialStory();
    this.clearEditingStoryId();
    this.setSocialStoryDrawerReadOnlyStatus();
  }

  setDataFromExistingStory() {
    if (!this.draftSocialStory) return;

    const { campaignIds, storyTitle, plannedDate: plannedDateTime } = this.draftSocialStory.socialStoryRequest;
    let plannedDate = '';
    let plannedTime = '';
    if (plannedDateTime) {
      const date = moment.tz(plannedDateTime, this.timeZone);
      plannedDate = date.format('YYYY-MM-DD');
      plannedTime = date.format('HH:mm');
    }

    const publishMethod: PublishMethod = this.draftSocialStory.socialPlatformPosts.some(
      ({ status }) => status === 'scheduled'
    )
      ? 'schedule'
      : 'publish';

    let scheduleDate = '';
    if (publishMethod === 'schedule') {
      const scheduledPost =
        this.draftSocialStory.socialPlatformPosts.find((post) => !!post.mainRequest.scheduleDate)?.mainRequest
          .scheduleDate ?? '';
      scheduleDate = moment.utc(scheduledPost).tz(this.timeZone).format(DATE_TIME_FORMAT);
    }

    this.form = {
      campaignIds,
      title: storyTitle,
      plannedDate,
      plannedTime,
      publishMethod,
      scheduleDate,
    };
  }

  onAddNewCampaign() {
    this.$emit('on-add-new-campaign');
  }

  readonly manageSocialChannelsLink = {
    label: 'Manage social channels',
    onClick: () => {
      this.openAyrshareConnect();
    },
  };

  async openAyrshareConnect() {
    try {
      const url = await this.getAyrShareConnectUrl();
      const anchorEl = document.createElement('A') as HTMLAnchorElement;
      anchorEl.href = url;
      anchorEl.target = '_blank';
      const body = document.getElementsByTagName('BODY')[0];
      body.appendChild(anchorEl);
      anchorEl.click();
      body.removeChild(anchorEl);
    } catch (e) {
      console.error(e);
    }
  }

  openDeleteModal(value) {
    this.modalToggle = true;
    this.tempPostType = value;
  }

  closeModal() {
    this.modalToggle = false;
  }

  // i would like to use this function instead of holding the code in the "confirm" button of the modal
  // but that would require more research and I want to get this done for now

  // deletePost() {
  //   this.$refs.tabs.onRemoveNewSocialPost(this.tempPostType)
  //   this.closeModal()
  // }

  $refs!: {
    cancelLink: FmLink;
    cancelButton?: FmButtonSecondary;
    drawer: Drawer;
    alertDisplay?: AlertDisplay;
  };
}
