
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import MediaUploadAndPreview from '@/createandpublish/components/socialStories/MediaUploadAndPreview.vue';
import TagInput from '@/createandpublish/components/common/TagsInput.vue';

import { eventBus, busEvents } from '@/createandpublish/core/eventBus/socialPostEventBus';
import { required, maxLength, minValue, maxValue } from 'vuelidate/lib/validators';

import {
  INSTAGRAM_MAX_IMAGE_COUNT,
  INSTAGRAM_IMAGE_ACCEPT_FILE_TYPES,
  INSTAGRAM_MAX_VIDEO_DURATION,
  INSTAGRAM_MIN_VIDEO_DURATION,
  INSTAGRAM_MAX_VIDEO_FILESIZE,
  INSTAGRAM_VIDEO_ACCEPT_FILE_TYPES,
  InstagramErrorMessage,
  INSTAGRAM_POST_MAX_LENGTH,
  INSTAGRAM_MAX_IMAGE_FILESIZE,
  INSTAGRAM_IMAGE_MIN_WIDTH,
  INSTAGRAM_IMAGE_MAX_WIDTH,
  INSTAGRAM_IMAGE_MIN_ASPECT_RATIO,
  INSTAGRAM_IMAGE_MAX_ASPECT_RATIO,
  INSTAGRAM_VIDEO_MIN_ASPECT_RATIO,
  INSTAGRAM_VIDEO_MAX_ASPECT_RATIO,
} from '@/createandpublish/core/utils/socialStory';
import { SocialPlatform, SocialPlatforms } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { MediaElProperties } from '@/types/createandpublish';
import type { InstagramPostOptions } from 'content-cloud-types/dist/types/requests/createandpublish/PlatformPostOptions';
import type { PlatformPostStatus } from 'content-cloud-types/dist/types/createandpublish/SocialPosting';
import type { DraftSocialStory, SocialPostBody } from '@/types/createandpublish/socialStory';
import EmojiButton from '@/components/common/buttons/EmojiButton.vue';

const SocialStoryStore = namespace('CreateAndPublishStore/socialStories');

Component.registerHooks(['beforeDestroy', 'validations']);

@Component({
  name: 'InstagramPost',
  components: {
    MediaUploadAndPreview,
    TagInput,
    EmojiButton,
  },
})
export default class InstagramPost extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly isReadonly!: boolean;
  @Prop({ type: String, required: true }) readonly currentSelectedTab!: SocialPlatform;
  @Prop({ type: Boolean, required: false, default: true }) readonly showsMediaSelector!: boolean;

  @SocialStoryStore.Getter readonly draftSocialStory?: DraftSocialStory;
  @SocialStoryStore.Getter readonly editingStoryId!: number;
  @SocialStoryStore.Mutation('UPDATE_DRAFT_SOCIAL_STORY_POST') updatePost!: ({
    platformType,
    postUpdates,
  }: {
    platformType: SocialPlatform;
    postUpdates: SocialPostBody;
  }) => void;

  post = '';
  imageUrls: string[] = [];
  newImage = '';
  videoUrl = '';
  videoThumbnailUrl = '';
  mediaType: InstagramPost['mediaTypeContextOptions'][number] = 'image';
  mediaTypeContextOptions = ['image', 'video'] as const;

  imageElsProperties: MediaElProperties[] = [];
  videoElProperties: MediaElProperties | null = null;

  editingPostStatus: PlatformPostStatus | '' = '';

  @Watch('newImage')
  async onNewImageChange(newVal) {
    if (newVal) {
      await this.$nextTick();
      this.imageUrls.push(newVal);
      this.newImage = '';
    }
  }

  onMediaTypeContextChange($event: Event) {
    if (!this.isPostEditable) return;
    const { value } = $event.target as HTMLInputElement;
    this.mediaType = value as InstagramPost['mediaType'];
    this.imageUrls = [];
    this.imageElsProperties = [];
    this.videoUrl = '';
    this.videoElProperties = null;
  }
  onImageUrlPropertiesChanged(properties: MediaElProperties, index: number) {
    if (properties) {
      this.$set(this.imageElsProperties, index, properties);
    }
  }
  onVideoUrlPropertiesChanged(properties: MediaElProperties) {
    this.videoElProperties = properties;
  }

  updateVideoThumbnailUrl(value: string) {
    this.videoThumbnailUrl = value;
  }

  onClearMedia(index: number) {
    this.imageUrls.splice(index, 1);
    this.imageElsProperties.splice(index, 1);
  }

  get isAddingAnotherImageAllowed() {
    return this.imageUrls.length < INSTAGRAM_MAX_IMAGE_COUNT;
  }

  get postContent(): SocialPostBody {
    const { post, imageUrls, videoUrl, mediaType, videoThumbnailUrl } = this;
    const mediaUrls: string[] = [];

    if (mediaType === 'image' && imageUrls.length) {
      mediaUrls.push(...imageUrls);
      //TODO add support for user tags
      // if (altText.length) {
      //   mediaAltText.push(...altText);
      // }
    }

    if (mediaType === 'video' && videoUrl) {
      mediaUrls.push(videoUrl);
    }

    const isVideo = mediaType === 'video' && !!mediaUrls.length ? { isVideo: true } : { isVideo: false };
    const videoThumbnailUrlOption = videoThumbnailUrl ? { videoThumbnailUrl: videoThumbnailUrl } : {};
    const isThumbNailOffset = mediaType === 'video' && !!mediaUrls.length ? { thumbNailOffset: 3000 } : {};
    const mediaUrlsOption = mediaUrls.length ? { mediaUrls } : { mediaUrls: undefined as unknown as string[] };
    const platforms: SocialPlatform[] = [SocialPlatforms.instagram];

    const mainRequest: SocialPostBody['mainRequest'] = {
      post,
      platforms,
      ...videoThumbnailUrlOption,
      ...mediaUrlsOption,
      ...isVideo,
    };
    const instagramOptions: InstagramPostOptions = {
      reels: mediaType === 'video' ? true : false,
      shareReelsFeed: mediaType === 'video' ? true : false,
      ...isThumbNailOffset,
    };
    return {
      mainRequest,
      platformOptions: {
        instagramOptions,
      },
    };
  }

  onInputChanged(input) {
    this.post = input;
    this.$v.post.$model = input;
  }

  @Watch('postContent')
  onPostContentUpdate(newVal: InstagramPost['postContent']) {
    this.updatePost({
      platformType: SocialPlatforms.instagram,
      postUpdates: newVal,
    });
    eventBus.$emit(busEvents.UPDATE_DRAFT_POST_DETAILS, newVal);
  }

  validations() {
    const requireImageValidation = this.mediaType === 'image' && !!this.imageUrls.length;
    const requireVideoValidation = this.mediaType === 'video' && !!this.videoUrl;

    return {
      post: {
        required,
        maxLength: maxLength(INSTAGRAM_POST_MAX_LENGTH),
      },
      videoElProperties: requireVideoValidation
        ? {
            duration: {
              minValue: minValue(INSTAGRAM_MIN_VIDEO_DURATION),
              maxValue: maxValue(INSTAGRAM_MAX_VIDEO_DURATION),
            },
            filesize: {
              maxValue: maxValue(INSTAGRAM_MAX_VIDEO_FILESIZE),
            },
            aspectRatio: {
              minValue: minValue(INSTAGRAM_VIDEO_MIN_ASPECT_RATIO),
              maxValue: maxValue(INSTAGRAM_VIDEO_MAX_ASPECT_RATIO),
            },
          }
        : {},
      imageElsProperties: requireImageValidation
        ? {
            $each: {
              filesize: {
                maxValue: maxValue(INSTAGRAM_MAX_IMAGE_FILESIZE),
              },
              width: {
                minValue: minValue(INSTAGRAM_IMAGE_MIN_WIDTH),
                maxValue: maxValue(INSTAGRAM_IMAGE_MAX_WIDTH),
              },
              aspectRatio: {
                minValue: minValue(INSTAGRAM_IMAGE_MIN_ASPECT_RATIO),
                maxValue: maxValue(INSTAGRAM_IMAGE_MAX_ASPECT_RATIO),
              },
            },
          }
        : {},
    };
  }

  get isErrorFree() {
    return !this.$v.$invalid;
  }

  get errorCount() {
    const { postError, videoElPropertiesError, imageElsPropertiesError } = this;

    let count = 0;
    postError && count++;
    videoElPropertiesError && count++;
    const numImageElPropertyErrors = imageElsPropertiesError.filter((s) => !!s).length;
    count += numImageElPropertyErrors;

    return count;
  }

  @Watch('errorCount')
  onErrorCountChange() {
    this.$emit('errorCount:update', { platform: SocialPlatforms.instagram, count: this.errorCount });
  }

  get postError() {
    const field = this.$v.post;
    const { required, maxLength } = InstagramErrorMessage.post;

    if (field.$error) {
      if (!field.required) {
        return required;
      }
      if (!field.maxLength) {
        return maxLength;
      }
    }
    return '';
  }

  get videoElPropertiesError() {
    const field = this.$v.videoElProperties;
    const durationField = field?.duration;
    const filesizeField = field?.filesize;
    const aspectRatioField = field?.aspectRatio;
    const { minLength, maxLength, maxFilesize, minAspectRatio, maxAspectRatio } = InstagramErrorMessage.videoUrl;

    if (field.$invalid) {
      if (!durationField?.minValue) return minLength;
      if (!durationField?.maxValue) return maxLength;
      if (!filesizeField?.maxValue) return maxFilesize;
      if (!aspectRatioField?.minValue) return minAspectRatio;
      if (!aspectRatioField?.maxValue) return maxAspectRatio;
    }

    return '';
  }

  get imageElsPropertiesError() {
    const field = this.$v.imageElsProperties.$each;
    const { maxFilesize, minImageWidth, maxImageWidth, minAspectRatio, maxAspectRatio } =
      InstagramErrorMessage.imageUrls;

    if (field?.$invalid) {
      const allValidators = Object.values(field.$iter).map((validator) => {
        const filesizeIsValid = validator?.filesize?.maxValue as boolean;
        const minWidthIsValid = validator?.width?.minValue as boolean;
        const maxWidthIsValid = validator?.width?.maxValue as boolean;
        const minAspectRatioIsValid = validator?.aspectRatio?.minValue as boolean;
        const maxAspectRatioIsValid = validator?.aspectRatio?.maxValue as boolean;
        return { filesizeIsValid, minWidthIsValid, maxWidthIsValid, minAspectRatioIsValid, maxAspectRatioIsValid };
      });
      return allValidators.map(
        ({ filesizeIsValid, minWidthIsValid, maxWidthIsValid, minAspectRatioIsValid, maxAspectRatioIsValid }) => {
          if (!filesizeIsValid) return maxFilesize;
          if (!minWidthIsValid) return minImageWidth;
          if (!maxWidthIsValid) return maxImageWidth;
          if (!minAspectRatioIsValid) return minAspectRatio;
          if (!maxAspectRatioIsValid) return maxAspectRatio;
          return '';
        }
      );
    }

    return new Array(this.imageElsProperties.length).fill('');
  }

  get postLengthString() {
    return `${this.post.length}/${INSTAGRAM_POST_MAX_LENGTH}`;
  }

  get videoFileInputAcceptAttr() {
    return INSTAGRAM_VIDEO_ACCEPT_FILE_TYPES;
  }
  get imageFileInputAcceptAttr() {
    return INSTAGRAM_IMAGE_ACCEPT_FILE_TYPES;
  }
  mediaUrlProperties: MediaElProperties | null = null;
  onMediaUrlPropertiesChanged(properties: MediaElProperties) {
    this.mediaUrlProperties = properties;
  }

  validateForm() {
    this.$v.$touch();
  }

  toggleUploadingMediaStatus() {
    this.$emit('toggleUploadingMediaStatus');
  }

  get isPostEditable() {
    const { editingPostStatus: status, editingStoryId: storyId } = this;
    if (isNaN(storyId)) return true;

    const allowedStatuses: PlatformPostStatus[] = ['draft', 'scheduled', 'error'];

    return !this.isReadonly && !isNaN(storyId) && allowedStatuses.includes(status as PlatformPostStatus);
  }

  setDataFromExistingEpisode() {
    const platformPost = this.draftSocialStory?.socialPlatformPosts.find(
      (socialPosts) => socialPosts.mainRequest.platforms[0] === SocialPlatforms.instagram
    );
    if (!platformPost) return;

    this.editingPostStatus = platformPost.status ?? '';

    platformPost.mainRequest;
    const { post, isVideo, mediaUrls, videoThumbnailUrl } = platformPost.mainRequest;

    this.post = post;
    if (isVideo) {
      this.videoUrl = mediaUrls?.[0] ?? '';
      this.mediaType = 'video';
      this.videoThumbnailUrl = videoThumbnailUrl ?? '';
    } else if (mediaUrls && mediaUrls.length > 0) {
      this.imageUrls = mediaUrls ?? [];
      this.mediaType = 'image';
    }
  }

  setupBusEvents() {
    eventBus.$on(busEvents.VALIDATE_POST, this.validateForm);
    eventBus.$on(busEvents.ADD_PREFILL_CONTENT, this.addPrefillContent);
    eventBus.$on(busEvents.ADD_MEDIA_FROM_LIBRARY, this.addMediaFromLibrary);
  }

  addMediaFromLibrary(content: { value: string; prefix: 'video' | 'image' | 'gif' }) {
    const { prefix, value } = content;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.instagram) {
      switch (prefix) {
        case 'image':
        case 'gif':
          this.newImage = value;
          this.mediaType = 'image';
          break;
        case 'video':
          this.videoUrl = value;
          this.mediaType = 'video';
          break;
        default:
          break;
      }
    }
  }

  addPrefillContent(content: { value: string; prefix: string }) {
    const { prefix, value } = content;

    const currentPost = this.post;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.instagram) {
      switch (prefix) {
        case 'to post':
        case 'title':
        case 'link':
          this.post = currentPost + value + ' ';
          break;
        case 'hashtag':
          this.post = currentPost + `#${value.split(' ').join('')}` + ' ';
          break;
        default:
          break;
      }
    }
  }

  destroyBusEvents() {
    eventBus.$off(busEvents.VALIDATE_POST, this.validateForm);
  }

  mounted() {
    this.setupBusEvents();
    if (!isNaN(this.editingStoryId)) {
      this.setDataFromExistingEpisode();
    }
  }

  beforeDestroy() {
    this.destroyBusEvents();
  }
}
