import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import { FacebookMetricsSnapshot, Snapshot } from '@/types/audience/per-post-metrics/Metrics';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import sum from 'lodash.sum';
import uniqBy from 'lodash.uniqby';
import moment from 'moment';
import HttpClient from '../common/HttpClient';

function mapMetricsByPlatform<T extends Snapshot>(metrics: T[], platform: Lowercase<PlatformLabels>): T[] {
  if (platform === 'facebook') {
    return metrics.map((metric) => ({
      ...metric,
      reactionsCount: sum(Object.values((metric as FacebookMetricsSnapshot).reactions)),
    }));
  }

  return metrics;
}

function filterMetrics<T extends Snapshot>(metrics: T[]): T[] {
  if (metrics.length < 12) {
    return metrics;
  }

  const relevantMetrics: T[] = [metrics[0]];
  metrics.slice(1).forEach((metric, index) => {
    if (index === metrics.length - 1) {
      relevantMetrics.push(metric);
    } else {
      const previousMetrics = relevantMetrics[relevantMetrics.length - 1];
      const hoursDiff = Math.abs(moment(metric.asOfDate).diff(moment(previousMetrics.asOfDate), 'hours') || 0);

      if (hoursDiff >= 6) {
        relevantMetrics.push(metric);
      }
    }
  });

  return relevantMetrics;
}

export async function getPostMetrics<T extends Snapshot>(
  httpClient: HttpClient,
  platform: Lowercase<PlatformLabels>,
  postId: AudiencePost['postId'],
  range: { startDate: string; endDate: string } | undefined
): Promise<T[]> {
  const params = new URLSearchParams({});

  if (range) {
    params.set('startDate', range.startDate);
    params.set('endDate', range.endDate);
  }

  let metrics: T[] = await httpClient.get(`${platform}/posts/${postId}?${params.toString()}`);

  // all time view - keep only one data point per day
  if (!range) {
    const getFormattedDate = (snapshot: T) => moment(snapshot.asOfDate).format('YYYY-DD-MM');

    if (metrics.length > 1) {
      const firstMetricDay = getFormattedDate(metrics[0]);
      const allMetricsOnTheSameDay = metrics.every((metric) => getFormattedDate(metric) === firstMetricDay);

      // data points are from different days - keep only one per day
      if (!allMetricsOnTheSameDay) {
        metrics = uniqBy(metrics, (metric) => getFormattedDate(metric));
      }
    }
  } else {
    // this week view + first 72 hours view -
    // show 6 hours min distance between data points
    metrics = filterMetrics(metrics);
  }

  return mapMetricsByPlatform(metrics, platform).reverse();
}
