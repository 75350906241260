import { requestAndRetry } from '../core/util';
import HttpClient from '@/services/common/HttpClient';

const SECS_PER_FRAME = 0.0464;

export default {
  namespaced: true,

  state: {
    events: [],
    eventEditing: null,
    playlistEditing: null,
    // following five properties used for TwistedWave rewind/fastForward functionality
    originalEvent: null,
    dropdownDurations: null,
    lastSegmentId: null,
    preCount: 0,
    postCount: 0,
    uploadUuid: null,
  },

  getters: {
    eventEditing: (state) => state.eventEditing,
    dropdownDurations: (state) => state.dropdownDurations,
    preCount: (state) => state.preCount,
    postCount: (state) => state.postCount,
    originalEvent: (state) => state.originalEvent,
    uploadUuid: (state) => state.uploadUuid,
    // endpoint: (state) => state.endpoint
  },

  mutations: {
    SET_EVENTS: (state, events) => {
      state.events = events.filter((event) => event.cart.type_name !== 'SINGLE_USE');
    },

    APPEND_EVENT: (state, event) => {
      state.events.push(event);
    },

    SET_EDIT_EVENT: (state, event) => {
      state.eventEditing = event;
    },
    SET_TW_DROPDOWN_VALUES: (state, data) => {
      const segments = data.segments;
      const isLast = data.last;

      if (isLast) {
        state.lastSegmentId = null;
      } else {
        for (let index = segments.length - 1; index >= 0; index--) {
          if (segments[index].type === 'event') {
            state.lastSegmentId = segments[index].id;
            break;
          }
        }
      }

      const durations = { rewind: [], ff: [] };
      if (segments && segments.length > 6 + state.postCount) {
        const segLen = segments.length;
        // use segment 5 through 0 to populate pre increment drop down
        let duration = 0;
        for (let index = 5; index >= 0; index--) {
          const currentDuration = segments[index].levels_left.split(',').length * SECS_PER_FRAME;
          duration += currentDuration;
          const value = 6 - index;
          durations.rewind.push({ value, duration });
        }
        if (state.lastSegmentId) {
          let startIndex = -1;
          for (let index = segLen - 1; index > 0; index--) {
            if (segments[index].id === state.lastSegmentId) {
              // only set startIndex if extra segments beyond start + post_count
              if (segLen - 1 > index + state.postCount) {
                startIndex = index + 1 + state.postCount;
              }
              break;
            }
          }
          if (startIndex > -1) {
            // add up to 6 entries to fast forward drop down starting at startIndex
            duration = 0;
            for (let index = startIndex; index < segLen; index++) {
              const currentDuration = segments[index].levels_left.split(',').length * SECS_PER_FRAME;
              duration += currentDuration;
              const value = 7 - (segLen - index);
              durations.ff.push({ value, duration });
            }
          }
        }
      }
      state.dropdownDurations = durations;
    },

    CLEAR_TW_DROPDOWN_VALUES: (state) => {
      state.dropdownDurations = null;
    },

    PREPEND_SEGMENT: (state, increment) => {
      state.preCount += increment;
    },

    APPEND_SEGMENT: (state, increment) => {
      state.postCount += increment;
    },

    RESET_SEG_COUNTS: (state) => {
      state.preCount = 0;
      state.postCount = 0;
    },

    SET_ORIGINAL_EVENT: (state, event) => {
      state.originalEvent = event;
    },

    /**
     * If file is uploaded via EditEpisodeAudio, we need to know the uuid of that file so we can
     *   later get the RMS data for it.
     */
    SET_UPLOAD_UUID(state, { uuid }) {
      state.uploadUuid = uuid;
    },

    ADD_EVENT_TO_PLAYLIST: (state, event) => {
      state.playlistEditing.events.push(event);
    },

    // SET_ENDPOINT: (state, endpoint) => {
    //   state.endpoint = endpoint;
    // },
  },

  actions: {
    /**
     * Gets CC endpoint and brand id
     */
    // endpoint({commit}) {
    //   const vuex = localStorage.getItem('vuex');
    //   const _store = vuex ? JSON.parse(JSON.stringify(vuex)) : null;
    //   const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
    //   commit('SET_ENDPOINT', `${process.env.VUE_APP_API_URL}/createandpublish/${brand?.id}`);
    // },
    /**
     * Get all events in library.
     * @param dispatch
     * @param commit
     * @param getters
     * @param rootGetters
     * @param params object
     * @returns {*}
     */
    async getEvents({ dispatch, commit, rootState }) {
      try {
        dispatch('endpoint');
        //http.get(`${rootGetters.stationUrl}api/v1/event/?cart__type_name=UPLOAD&frame_count__gt=0`)
        const http = HttpClient.getInstance();
        const res = await http.get(
          `${rootState.CreateAndPublishStore.endpoint}/event/?cart__type_name=UPLOAD&frame_count__gt=0`
        );
        commit('SET_EVENTS', res.body.objects || []);
      } catch (err) {
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not retrieve uploaded audio.',
          },
          { root: true }
        );
      }
    },

    /**
     * Upload event to library.
     * @param dispatch
     * @param commit
     * @param getters
     * @param rootGetters
     * @param rootState
     * @param formData
     * @return Promise<Object>
     */
    // eslint-disable-next-line
    async uploadEvent({ dispatch, commit, getters, rootGetters, rootState }, formData) {
      try {
        const http = HttpClient.getInstance();
        const response = await http.post(`${rootGetters.mediaUrl}/${rootGetters.station}/event/upload.html`, formData);
        const eventUri = response.body.location.replace(/\/$/, ''); // Remove last optional /
        const eventId = eventUri.substr(eventUri.lastIndexOf('/') + 1);
        response.body.event_id = eventId;
        return response.body;
      } catch (err) {
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not upload this audio file. Please try again.',
          },
          { root: true }
        );
      }
    },

    /**
     * Check progress of uploading file.
     * @param dispatch
     * @param commit
     * @param getters
     * @param rootGetters
     * @param uuid
     * @returns {Promise<*|*|undefined>}
     */
    // eslint-disable-next-line
    async checkUploadProgress({ dispatch, getters }, uuid) {
      try {
        const http = HttpClient.getInstance();
        const resp = await http.get(`createandpublish/1/event/upload-progress/${uuid}`);
        return resp.body;
      } catch (err) {
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not check upload/encode progress',
          },
          { root: true }
        );
      }
    },

    /**
     * Delete event by id.
     * @param dispatch
     * @param rootGetters
     * @param id
     * @returns {Promise<void>}
     */
    async deleteEvent({ dispatch, rootState }, id) {
      //http.patch(`${rootGetters.stationUrl}api/v1/cart/${id}`, payload)
      // dispatch('endpoint');
      const http = HttpClient.getInstance();
      try {
        const payload = {
          type_name: 'SINGLE_USE',
        };
        return http.patch(`${rootState.CreateAndPublishStore.endpoint}/cart/${id}`, payload);
      } catch (err) {
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not delete audio.',
          },
          { root: true }
        );
      }
    },

    /**
     * Retrieve resource by id.
     * @param dispatch
     * @param rootGetters
     * @param id
     * @returns {Promise<*>}
     */
    async getEventById({ dispatch, rootState }, id) {
      // dispatch('endpoint');
      const http = HttpClient.getInstance();
      try {
        const response = await http.get(`${rootState.CreateAndPublishStore.endpoint}/station/event/${id}/`);
        // return response.body;
        return response;
      } catch (err) {
        console.error('err', err);
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: `Could not retrieve audio ID ${id}.`,
          },
          { root: true }
        );
      }
    },

    /**
     * Set event to edit.
     * @param commit
     * @param event
     */
    editEvent({ commit }, event) {
      commit('SET_EDIT_EVENT', event);
    },

    setEditPlaylist({ commit }, playlist) {
      commit('SET_EDIT_PLAYLIST', playlist);
    },

    /**
     * Set event to edit back to null.
     */
    finishEditingEvent({ commit }) {
      commit('SET_EDIT_EVENT', null);
    },

    /**
     * Sets original event loaded into TW. Only used to track the originating event of a clone
     */
    setOriginalEvent({ commit }, event) {
      commit('SET_ORIGINAL_EVENT', event);
    },

    /**
     * Sets original event back to default value
     */
    clearOriginalEvent({ commit }) {
      commit('SET_ORIGINAL_EVENT', null);
    },

    /**
     * Sets preCount and postCount back to default values
     */
    resetSegCounts({ commit }) {
      commit('RESET_SEG_COUNTS');
    },

    /**
     * Used to tell the server to start caching event audio to improvide download speeds
     * @param dispatch
     * @param rootGetters
     * @param {Object} data station, bitrate, array of event ids
     */

    // eslint-disable-next-line
    cacheEvents({ dispatch, rootGetters }, data) {
      const http = HttpClient.getInstance();
      try {
        http.post(`${rootGetters.mediaUrl}/${rootGetters.station}/api/v1/eventcache/`, data);
      } catch (e) {
        // Eat it
        console.error(e);
      }
    },

    /**
     * Tells the backend to cache a playlist.
     * @param {Object} data bitrate, station, playlist id
     */

    // eslint-disable-next-line
    async cachePlaylist({ rootGetters }, data) {
      const http = HttpClient.getInstance();
      const brandId = rootGetters['selectedBrand']?.id;
      try {
        const resp = await http.post(`createandpublish/${brandId}/playlist/cache`, data);
        return resp;
      } catch (err) {
        console.error(err);
        console.warn(`Couldn't cache playlist, error: ${err.body.msg ? err.body.msg : err.status}`);
        return false;
      }
    },

    /**
     * Checks for whether or not an event or playlist has been cached.
     * @param {String} guid event or playlist to check cacheing status of
     */
    async checkCacheProgress({ rootGetters }, guid) {
      const http = HttpClient.getInstance();
      const brandId = rootGetters['selectedBrand']?.id;

      try {
        const resp = await http.get(`createandpublish/${brandId}/playlist/cache/${guid}`);
        return resp;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    /**
     * This is used to provide visualization data to WaveSurfer so it doesn't have to construct the data itself,
     *   thus speeding up load times
     * @param dispatch
     * @param rootGetters
     * @param {Number} id playlist id
     * @param {Number} numPoints the number of RMS points to return
     */

    // eslint-disable-next-line
    getRMSData({ getters, rootGetters }, { id, rmsPath, numPoints = 300 }) {
      const mediaUrl = rootGetters['CreateAndPublishStore/mediaUrl'];
      const station = rootGetters['CreateAndPublishStore/settings']?.station_name;
      const baseUrl = `${mediaUrl}/${station}/playlist/rms/`;
      let asset;
      if (getters.uploadUuid) {
        asset = `${getters.uploadUuid}.json`;
      } else if (rmsPath) {
        asset = rmsPath.split('/').pop();
      } else {
        // TODO: This should no longer happen. Due to time constraints, this should be looked over later
        //    to ensure nothing breaks.
        asset = `${id}.json?num_points=${numPoints}`;
      }
      const url = `${baseUrl}${asset}`;
      return requestAndRetry(url, 'get', 5, 2500);
    },

    /** Gets all individual segments that make up a single event and optionally pulls in a specified number of segments
     *   that occur directly before and/or after an event. This then commits a mutation that calculates the durations
     *   of the returned segments, which are used to populate the dropdown boxes for the TW rewind/fast-forward feature
     * @param pre Number of segments immediately prior to an event
     * @param post Number of segments immediately following an event
     * @param deletedRegions This is not currently used, but apparently is a thing.
     */
    async getAllSegmentsForEvent(
      { dispatch, commit, getters, rootGetters },
      { pre = null, post = null, deletedRegions = null }
    ) {
      const url = `${rootGetters.mediaUrl}/${rootGetters.station}/api/v1/segment/for-event-${getters.originalEvent.id}.json`;
      let query = '?';
      if (pre) {
        query += `pre_segments=${pre + getters.preCount}&`;
      }
      if (post) {
        query += `post_segments=${post + getters.postCount}&`;
      }
      if (deletedRegions) {
        query += `deleted_regions=${deletedRegions}`;
      }
      const http = HttpClient.getInstance();
      try {
        const resp = await http.get(`${url}${query}`);
        commit('SET_TW_DROPDOWN_VALUES', resp.body);
      } catch (e) {
        return dispatch(
          'handleServerError',
          {
            error: e,
            errmsg: `Could not get all segments for ${getters.eventEditing.id}.`,
          },
          { root: true }
        );
      }
    },
    /**
     * Resets dropdown values to default.
     */

    // eslint-disable-next-line
    clearDropdownValues({ dispatch, commit }) {
      commit('CLEAR_TW_DROPDOWN_VALUES');
    },

    /**
     * Clones an event, appending a specified number segments to the beginning and/or end of the original event.
     */
    async cloneEvent({ dispatch, rootGetters, getters }) {
      const url = `${rootGetters.stationUrl}event/${getters.originalEvent.id}/clone.html`;
      let query = '?start=0&end=999999&';
      if (getters.preCount > 0) {
        query += `pre_segments=${getters.preCount}&`;
      }
      if (getters.postCount > 0) {
        query += `post_segments=${getters.postCount}`;
      }
      const http = HttpClient.getInstance();
      try {
        const resp = await http.get(`${url}${query}`);
        const newEventId = resp.headers.map.location[0].match(/\d+\.\d+/)[0];
        return newEventId;
      } catch (e) {
        return dispatch(
          'handleServerError',
          {
            error: e,
            errmsg: `Could not clone event ${getters.originalEvent.id}.`,
          },
          { root: true }
        );
      }
    },

    /**
     * Checks the status of the creation of cloned event.
     * @param id the id of the clone event
     */
    async checkCloneReady({ dispatch, rootGetters }, id) {
      const http = HttpClient.getInstance();
      const url = `${rootGetters.stationUrl}event/test/${id}.json`;
      try {
        const resp = await http.get(url);
        return resp.body;
      } catch (e) {
        return dispatch(
          'handleServerError',
          {
            error: e,
            errmsg: `Could not verify cloned event is ready.`,
          },
          { root: true }
        );
      }
    },
  },
};
