import { render, staticRenderFns } from "./TikTokPreview.vue?vue&type=template&id=ae86aa72&scoped=true&"
import script from "./TikTokPreview.vue?vue&type=script&lang=ts&"
export * from "./TikTokPreview.vue?vue&type=script&lang=ts&"
import style0 from "./TikTokPreview.vue?vue&type=style&index=0&id=ae86aa72&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae86aa72",
  null
  
)

export default component.exports