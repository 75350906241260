import { setupBaseSSE } from './base';

import {
  socialAnalyticsRefreshed,
  socialChannelConnected,
  socialChannelDisconnected,
  socialChannelRefreshed,
  subscriptionUpdate,
} from '@/audience/eventsource/handlers';

export const setupSSE = async (url, cfg) => {
  const msgServer = await setupBaseSSE(url, cfg);

  socialAnalyticsRefreshed(msgServer);
  socialChannelConnected(msgServer);
  socialChannelDisconnected(msgServer);
  socialChannelRefreshed(msgServer);
  subscriptionUpdate(msgServer);

  return msgServer;
};

export default setupSSE;
