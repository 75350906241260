import { render, staticRenderFns } from "./SplitForm.vue?vue&type=template&id=71bd5fbf&scoped=true&functional=true&"
import script from "./SplitForm.vue?vue&type=script&lang=ts&"
export * from "./SplitForm.vue?vue&type=script&lang=ts&"
import style0 from "./SplitForm.vue?vue&type=style&index=0&id=71bd5fbf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "71bd5fbf",
  null
  
)

export default component.exports