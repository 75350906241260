
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EmojiPicker } from 'vue-emoji-picker';

Vue.use(EmojiPicker);

@Component({
  components: {
    EmojiPicker,
  },
})
export default class EmojiButton extends Vue {
  search = '';
  text = '';

  append(emoji) {
    this.text += emoji;
  }

  @Watch('text')
  onInputChanged() {
    this.$emit('valueChanged', this.text);
  }
}
