
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Loading from '@/components/common/Loading.vue';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'NoProfileConnected',
  components: {
    Loading,
  },
})
export default class NoProfileConnected extends Vue {
  @Prop({ type: String, required: true }) platformLabel!: string;

  @audienceModule.Action('getAyrShareConnectUrl') getAyrShareConnectUrl;

  isButtonLoading = false;
  connectURL = '';
  loading = false;

  async onConnectClick() {
    this.isButtonLoading = true;
    this.loading = true;
    try {
      this.$router.push('/connect-socials');
    } catch (e) {
      this.loading = false;
      console.error(e);
    } finally {
      this.isButtonLoading = false;
    }
  }
}
