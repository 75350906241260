import { requiresBrandAccess, requiresProductAccess } from '@/constants/router/permissions';

import type { RouteConfig } from 'vue-router';

const SocialStoriesRoute = () =>
  import(/* webpackChunkName: "cp-stories" */ '@/createandpublish/views/social-stories/SocialStoriesRoute.vue');
const Library = () =>
  import(/* webpackChunkName: "cp-stories" */ '@/createandpublish/views/social-stories/Library.vue');

export default {
  path: 'stories',
  component: SocialStoriesRoute,
  redirect: 'stories/library',
  meta: {
    ...requiresBrandAccess,
    ...requiresProductAccess,
  },
  children: [
    {
      path: 'library',
      name: 'SocialStories.Library',
      component: Library,
      meta: {
        sidebar_active_item: 'Social Posts',
        title: 'Social Posts | Create&Publish',
      },
    },
  ],
} as RouteConfig;
