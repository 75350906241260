import type { Plugin, DoughnutController } from 'chart.js';

const doughnutHolePlugin: Plugin = {
  id: 'doughnutHolePlugin',
  afterDatasetsDraw: (chart, _args, options) => {
    const datasetCount = chart.getVisibleDatasetCount();
    if (datasetCount !== 1) throw new Error(`doughnutHolePlugin expected 1 dataset, got ${datasetCount}`);

    const {
      ctx,
      chartArea: { top, bottom, left, right },
    } = chart;

    const { label, value } = options as unknown as DoughnutHolePluginOptions;

    const datasetMeta = chart.getDatasetMeta(0);
    const controller = datasetMeta.controller as DoughnutController;
    const { innerRadius } = controller;

    const cX = (left + right) / 2;
    const cY = (top + bottom) / 2;

    {
      // Draw header
      const deg: Degrees = 145;
      const { yOffset, chordLen } = ChartPluginHelpers.getRenderingContext(deg, innerRadius);

      const textValue = (label || '').toUpperCase();
      const textMaxLen = chordLen;
      const textY = cY - yOffset;

      ctx.save();
      ctx.font = '16px Roboto';
      ctx.fillStyle = '#222046';
      ctx.textAlign = 'center';

      ctx.fillText(textValue, cX, textY, textMaxLen);
      ctx.restore();
    }

    {
      // Draw value
      const deg: Degrees = 160;
      const { yOffset, chordLen } = ChartPluginHelpers.getRenderingContext(deg, innerRadius);

      const textMaxLen = chordLen;
      const textY = cY + yOffset + 10;

      ctx.save();
      ctx.font = '25px Roboto';
      ctx.fillStyle = '#222046';
      ctx.textAlign = 'center';

      ctx.fillText(ChartPluginHelpers.formatValue(value || 0), cX, textY, textMaxLen);
      ctx.restore();
    }

    {
      // Draw stat indicator icon and percent change
      //   const deg: Degrees = 130;
      //   const { yOffset, chordLen } = ChartPluginHelpers.getRenderingContext(deg, innerRadius);
      //   ctx.save();
      //   ctx.font = '400 20px/28px Roboto';
      //   const fillColor = Math.sign(percentChange) !== -1 ? '#30780C' : '#CB2610';
      //   ctx.fillStyle = fillColor;
      //   ctx.textAlign = 'left';
      //   ctx.textBaseline = 'top';
      //   const textValue = ChartPluginHelpers.formatPercentChange(percentChange || 0);
      //   const textWidth = ctx.measureText(textValue).width;
      //   const imagePosX = cX - textWidth / 2 - 12; // half text width - margin
      //   const imagePosY = cY + yOffset;
      //   const textX = imagePosX + 25 + 4; // 25 = image width, 4 = margin;
      //   const textY = imagePosY + 6; // 6 = help justify the image and text
      //   const maxTextSize = chordLen - 25 - 12;
      //   ctx.fillText(textValue, textX, textY, maxTextSize);
      //   ctx.restore();
      //   const getIcon = Math.sign(percentChange) !== -1 ? getStatIncreaseIcon : getStatDecreaseIcon;
      //   getIcon().then((image) => {
      //     ctx.drawImage(image, imagePosX, imagePosY);
      //   });
    }
  },
};

export default doughnutHolePlugin;

class ChartPluginHelpers {
  /**
   * Calculates the the x offset, y offset, and available length for an artifact
   * that will be rendered inside the center of a doughnut chart
   * @param {Number} deg angle created from the center of the chart and two
   *  points on the inner circle of the doughnut (forming an isosceles triangle)
   * @param {Number} radius radius of the inner circle of the doughnut
   * @returns {Object} rendering context
   */
  static getRenderingContext(
    deg: Degrees,
    radius: Pixels
  ): {
    xOffset: Pixels;
    yOffset: Pixels;
    chordLen: Pixels;
  } {
    const angle: Radians = this.convertDegToRad(deg);
    const chordLen = 2 * radius * Math.sin(angle / 2);
    const xOffset = chordLen / 2;
    const yOffset = Math.sqrt(Math.pow(radius, 2) - Math.pow(chordLen / 2, 2));
    return {
      xOffset,
      yOffset,
      chordLen,
    };
  }

  static convertDegToRad(deg: Degrees): Radians {
    return deg * (Math.PI / 180);
  }

  /**
   * Transforms number into readable string, truncating if over (+/-)100,000
   * @param {Number} val number to format
   * @returns {String} formatted value
   */
  static formatValue(val: number): string {
    const absVal = Math.abs(val);
    if (absVal > 999_999) {
      return (val / 1_000_000).toFixed(1) + 'M';
    } else if (absVal > 99_999) {
      return (val / 1_000).toFixed(1) + 'K';
    }
    return val.toLocaleString();
  }

  /**
   * Transforms number readable string, truncating if over (+/-)1,000
   * @param {Number} val percentage to format (e.g. .24 for 24%)
   * @returns {String} formatted value
   */
  static formatPercentChange(val: number): string {
    const absVal = Math.abs(val * 100);
    if (absVal > 999_999) {
      return (absVal / 1_000_000).toFixed(1) + 'M%';
    } else if (absVal > 99_999) {
      return (absVal / 1_000).toFixed(1) + 'K%';
    }
    return Number(absVal.toFixed(0)).toLocaleString() + '%';
  }
}

export interface DoughnutHolePluginOptions {
  label: string;
  value: number;
  percentChange: number;
}

type Degrees = number;
type Radians = number;
type Pixels = number;
