import HttpClient from '@/services/common/HttpClient';
import {
  MediaAsset,
  MediaUploadMetadata,
  MediaUploadType,
  GetMediaAssetsQueryParams,
} from '@/types/createandpublish/mediaLibraries/Common';
import { Playlist } from '@/types/createandpublish/podcasts/Playlist';
import { AudioEventId } from '@/types/createandpublish/mediaLibraries/AudioLibrary';
import {
  TwistedWaveSessionData,
  SegmentObserverData,
  SegmentObserverOutputResult,
} from '@/types/createandpublish/mediaLibraries/TwistedWave';
import store from '@/store';

export default class CreateAndPublishMediaService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store?.getters['selectedBrand']?.id;
    if (!brandId) {
      // Fallback to localStorage
      try {
        const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand.id;
      } catch {
        console.warn('[CreateAndPublishAudioLibraryService] Unable to get brandId from localStorage');
      }
    }
    return brandId;
  }

  // Endpoint prefix definitions.
  private get mediaLibraryPrefix() {
    return `/createandpublish/${this.brandId}/media`;
  }

  private get audioMixerPrefix() {
    return `/createandpublish/${this.brandId}/playlist`;
  }

  private get uploadProgressPrefix() {
    return `/createandpublish/${this.brandId}/event`;
  }

  public getMediaLibraryItems(type: MediaUploadType, params?: GetMediaAssetsQueryParams): Promise<MediaAsset[]> {
    return this.http.get(`${this.mediaLibraryPrefix}/${type}`, params);
  }

  public addMediaLibraryItem(type: MediaUploadType, payload: FormData): Promise<MediaAsset[]> {
    return this.http.post(`${this.mediaLibraryPrefix}/${type}`, payload);
  }

  public updateMediaLibraryItem(type: MediaUploadType, payload: MediaAsset): Promise<MediaAsset> {
    const { id } = payload;
    return this.http.patch(`${this.mediaLibraryPrefix}/${type}/${id}`, payload);
  }

  public deleteMediaLibraryItem(type: MediaUploadType, assetId: AudioEventId | number): Promise<void> {
    return this.http.delete(`${this.mediaLibraryPrefix}/${type}/${assetId}`);
  }

  // TODO: Consider moving the below to a more appropriate place in the future.

  public generateTemPlaylist(playlist: Playlist): Promise<Playlist> {
    return this.http.post(`${this.audioMixerPrefix}`, playlist);
  }

  public initTwistedWaveSession(playlistId: number): Promise<TwistedWaveSessionData> {
    return this.http.get(`${this.audioMixerPrefix}/edit/${playlistId}`);
  }

  public submitAudioMixerOutput(output: SegmentObserverData): Promise<SegmentObserverOutputResult> {
    return this.http.post(`${this.audioMixerPrefix}/submit_output/${output.id}`, output);
  }

  public checkEventUploadProgress(uuid: string): Promise<MediaUploadMetadata> {
    return this.http.get(`${this.uploadProgressPrefix}/upload-progress/${uuid}`);
  }

  public createLibraryAsset(payload) {
    return this.http.post(`/createandpublish/${this.brandId}/createLibraryAsset`, payload);
  }
}
