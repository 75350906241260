import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import HttpClient from '@/services/common/HttpClient';
import { ShareMetricsInput, Snapshot } from '@/types/audience/per-post-metrics/Metrics';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import { getPostMetrics } from './common';

export default class NativePostsService {
  private http: HttpClient = HttpClient.getInstance();

  public async getPosts(platform: Lowercase<PlatformLabels> | 'native' = 'native'): Promise<AudiencePost[]> {
    const posts: AudiencePost[] = await this.http.get(`${platform}/posts`);

    if (platform !== 'native') {
      return posts.map((post) => ({ ...post, platform }));
    }

    return posts;
  }

  public getPostMetrics<T extends Snapshot>(
    platform: Lowercase<PlatformLabels>,
    postId: AudiencePost['postId'],
    range: { startDate: string; endDate: string } | undefined
  ): Promise<T[]> {
    return getPostMetrics(this.http, platform, postId, range);
  }

  public sharePostMetrics(shareInput: ShareMetricsInput): Promise<void> {
    const formData = new FormData();
    formData.append('recipientEmail', shareInput.recipientEmail);
    formData.append('subject', shareInput.subject);
    formData.append('body', shareInput.content);
    formData.append('file', shareInput.file);

    return this.http.post(`/native/posts/share`, formData);
  }
}
