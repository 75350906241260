
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import FooterLinkColumn from './footer/linkColumn.vue';
import Button from '@/components/common/buttons/Button.vue';

import type { Feature } from '@/types/features/FEFeature';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '@/constants/externalLinks';

import { toggleFreshChat } from '@/utils/freshChat';
import ProductLogo from '@/assets/product-logos/logo_color_dark_txt_transparent.png';

Component.registerHooks(['mounted']);

@Component({
  components: {
    FooterLinkColumn,
    Button,
  },
})
export default class PortalFooter extends Vue {
  @Prop({ type: Number, required: true }) selectedBrandId!: number;
  @Getter features!: Feature[];

  get HelpSection() {
    return {
      header: 'HELP',
      links: [
        // { route: '/contact', name: 'Contact us' },
        { route: 'http://support.contentcloud.app/support/home', name: 'Knowledge Base', isExternal: true },
        // { route: '/terms-and-conditions', name: 'Terms & Conditions' },
        // privacy policy and terms of service links
        { route: TERMS_OF_SERVICE, name: 'Terms of Service', isExternal: true },
        { route: PRIVACY_POLICY, name: 'Privacy Policy', isExternal: true },
      ],
    };
  }
  get UserPortalLinks() {
    return [
      { route: '/profile', name: 'Profile' },
      ...(this.isEcommAvailable ? [{ route: '/subscriptions', name: 'Subscription & billing' }] : []),
      { route: '/users', name: 'Manage users' },
      // { route: '/brands', name: `Manage brands ${this.isCampaignsAvailable ? '& campaigns' : ''}` },
    ];
  }

  get mainFooterDetails() {
    return [
      {
        header: 'ACCOUNT',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  get audienceFooterDetails() {
    return [
      {
        header: 'QUICK LINKS',
        links: this.audienceFooterLinks,
      },
      {
        header: 'ACCOUNT',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  get audienceFooterLinks() {
    const audienceLinks = [
      { route: `/audience/${this.selectedBrandId}`, name: 'Dashboard' },
      { route: `/audience/${this.selectedBrandId}/leaderboard`, name: 'Leaderboard' },
      // { route: `/audience/${this.selectedBrandId}/community`, name: 'Creator Community' },
    ];
    const footerLinks = audienceLinks.concat(this.audiencePlatformLinks);
    return footerLinks;
  }

  get audiencePlatformLinks() {
    return this.nativePlatformFooterLinks;
  }

  get platformPageLinks() {
    return {
      youtube: { route: `/audience/${this.selectedBrandId}/youtube`, name: 'Youtube' },
      instagram: { route: `/audience/${this.selectedBrandId}/instagram`, name: 'Instagram' },
      facebook: { route: `/audience/${this.selectedBrandId}/facebook`, name: 'Facebook' },
      twitter: { route: `/audience/${this.selectedBrandId}/twitter`, name: 'Twitter' },
      tiktok: { route: `/audience/${this.selectedBrandId}/tiktok`, name: 'Tiktok' },
      linkedin: { route: `/audience/${this.selectedBrandId}/linkedin`, name: 'Linkedin' },
      pinterest: { route: `/audience/${this.selectedBrandId}/pinterest`, name: 'Pinterest' },
    };
  }

  get ayrsharePlatformFooterLinks() {
    return [
      this.platformPageLinks.youtube,
      this.platformPageLinks.instagram,
      this.platformPageLinks.facebook,
      this.platformPageLinks.twitter,
      this.platformPageLinks.tiktok,
      this.platformPageLinks.linkedin,
      this.platformPageLinks.pinterest,
    ];
  }

  get nativePlatformFooterLinks() {
    return [
      this.platformPageLinks.youtube,
      this.platformPageLinks.instagram,
      this.platformPageLinks.facebook,
      this.platformPageLinks.tiktok,
    ];
  }

  get discoverFooterDetails() {
    return [
      // {
      //   header: 'QUICK LINKS',
      //   links: [
      //     { route: `/discover/${this.selectedBrandId}`, name: 'Trending' },
      //     { route: `/discover/${this.selectedBrandId}/following`, name: 'Following' },
      //     { route: `/discover/${this.selectedBrandId}/settings`, name: 'Settings' },
      //   ],
      // },
      {
        header: 'ACCOUNT',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  get createFooterDetails() {
    return [
      {
        header: 'QUICK LINKS',
        links: [
          { route: `/create/${this.selectedBrandId}`, name: 'Dashboard' },
          { route: `/create/${this.selectedBrandId}/planner`, name: 'Planner' },
          // { route: `/create/${this.selectedBrandId}/podcasts`, name: 'Podcasts' },
          // { route: `/create/${this.selectedBrandId}/audio-library`, name: 'Audio' },
          { route: `/create/${this.selectedBrandId}/videos`, name: 'Video' },
          { route: `/create/${this.selectedBrandId}/images`, name: 'Images' },
          { route: `/create/${this.selectedBrandId}/analytics/dashboard`, name: 'Analytics' },
          // { route: `/tools`, name: 'Tools' },
          { route: `/create/${this.selectedBrandId}/settings`, name: 'Settings' },
        ],
      },
      {
        header: 'ACCOUNT',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  footerHiddenForRoutes = [''];

  get isEcommAvailable() {
    return this.features.includes('ecomm');
  }
  get isCampaignsAvailable() {
    return this.features.includes('campaigns');
  }

  get showFooter() {
    return !this.footerHiddenForRoutes.includes(this.$route.path);
  }
  get isDiscover() {
    return this.$route.path.search('discover') === 1;
  }
  get isCreate() {
    return this.$route.path.search('create') === 1;
  }
  get isAudience() {
    return this.$route.path.search('audience') === 1;
  }

  get logoUrl() {
    // return require('@/assets/FuturiCC.svg');
    return ProductLogo;
  }
  get footerLinks() {
    if (this.isDiscover) {
      return this.discoverFooterDetails;
    } else if (this.isCreate) {
      return this.createFooterDetails;
    } else if (this.isAudience) {
      return this.audienceFooterDetails;
    } else {
      return this.mainFooterDetails;
    }
  }

  freshChatMessageCount = 0;

  setupFreshChatListener() {
    const addListener = () => {
      window.fcWidget.on('unreadCount:notify', ({ count }) => {
        this.freshChatMessageCount = count;
      });
    };
    const checker = setInterval(() => {
      if (window.fcWidget && window.fcWidget.isLoaded()) {
        addListener();
        clearInterval(checker);
      }
    }, 1000);
  }

  toggleFreshChat() {
    toggleFreshChat();
  }

  mounted() {
    this.setupFreshChatListener();
  }
}
