import { isValidErrorFormat } from '@/utils/typeGuards';

const maskEmail = (emailAddress: string): string => {
  const maskPart = (part: string): string => part.substr(0, 1) + '*'.repeat(3);
  if (!emailAddress.includes('@')) {
    return maskPart(emailAddress);
  }
  const parts = emailAddress.split('@');
  const username = maskPart(parts[0]);
  const domainParts = parts[1].split('.');
  const tld = domainParts.slice(-1);
  const domain = domainParts.slice(0, -1).join('.');
  const maskedDomain = maskPart(domain) + '.' + tld;
  return username + '@' + maskedDomain;
};

// Prettify plural/singular forms
const maybePluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

/**
 *
 * @param amount - number or string
 * @param currency - 'USD' or 'CAD'
 * @returns a string with the currency symbol if currency is provided
 */
const formatCurrency = (amount: string, currency?: 'USD' | 'CAD') => {
  const parsedAmount = Number(amount.match(/^\d+(?:\.\d{0,2})?/)).toFixed(2);
  switch (currency) {
    case 'USD':
      return `$${parsedAmount}`;
    case 'CAD':
      return `$${parsedAmount}`;
    default:
      return parsedAmount;
  }
};

/**
 * Gets valid errors from response object
 * @param resp response
 * @returns string[] of errors if valid or undefined
 */
const getErrorsFromResponse = (resp: unknown): string[] | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const copy = resp as any;
  try {
    let errors;
    if ('_axiosError' in copy) {
      errors = copy._axiosError.response.data.errors;
    }
    if (!errors) {
      errors = copy.data.errors || copy.errors;
    }

    if (isValidErrorFormat(errors)) {
      return errors;
    }
  } catch (_) {
    return undefined;
  }
};

const maybeGetContentLength = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'HEAD',
    });
    if (resp.ok) {
      return resp.headers.get('Content-Length');
    }
    return null;
  } catch {
    return null;
  }
};

export { maybePluralize, maskEmail, capitalize, formatCurrency, getErrorsFromResponse, maybeGetContentLength };

export * from './sort';
export * from './handleDispatches';
export * from './userPermissions';
export * from './products/getUniqueProductIds';
export * from '@/utils/keyboardKeys';
