import AudienceAnalyticsRouter from '@/audience/core/router';
import type { RouteConfig } from 'vue-router';

const AudienceRoute = () => import('@/views/AudienceRoute.vue');

export default [
  {
    path: '/audience/:brandId?',
    component: AudienceRoute,
    children: AudienceAnalyticsRouter,
    // beforeEnter: () => {
    //   return '/login';
    // },
  },
] as RouteConfig[];
