import setupFullStory from '@/utils/fullStory';
import setupTwistedWave from '@/utils/twistedWave';
import setupBeamer from '@/utils/beamer';

/**
 * This lazy-loads Chameleon, which we try to call with requestIdleCallback if it's available (not supported in Safari).
 * We do this because Chameleon blocks to main thread for about a second, which is a significant impact to
 * time to interactive. If the browser is not idle within 4 seconds, it's loaded anyway.
 */
function setupChameleon() {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(
      () => {
        import('@/utils/chameleon').then(({ default: run }) => run());
      },
      { timeout: 4000 }
    );
  } else {
    import('@/utils/chameleon').then(({ default: run }) => run());
  }
}

export default () => {
  // Note: FreshChat is no longer loaded here. It is instead lazy-loaded when the user clicks the chat button
  try {
    if (process.env.NODE_ENV === 'development') return;
    setupFullStory();
    setupTwistedWave();
    setupBeamer();
    setupChameleon();
  } catch (e) {
    console.warn('Error during third party support setup:', e);
  }
};

// Making the following maps as helpers to make human readable values for CX and Support

export const roleIdMap = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Standard',
} as const;

export const statusIdMap = {
  1: 'Active',
  2: 'Disabled',
} as const;
