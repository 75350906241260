
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ContextTotalCard from '@/audience/components/ContextTotalCard.vue';
import MetricList from '@/audience/components/MetricList.vue';
import ViewHeader from '@/audience/components/ViewHeader.vue';
import DashboardDoughnutChart from '@/audience/components/dashboard/DashboardDoughnutChart.vue';
import DashboardLineChart from '@/audience/components/dashboard/DashboardLineChart.vue';
import GrowthTips from '@/audience/components/dashboard/GrowthTips.vue';
import PlatformViewLayout from '@/audience/layouts/PlatformViewLayout.vue';
import Loading from '@/components/common/Loading.vue';
import PerPostMetrics from '../components/per-post-metrics/PerPostsMetrics.vue';
import PlatformPostMetrics from '../components/per-post-metrics/metrics/PlatformPostMetrics.vue';

import { getChartDataByPlatform, getMetricTotalsByContextAndDataIndex } from '@/audience/core/utils/chart';
import { calculatePercentChange } from '@/audience/data/chart-plugins/util';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import AccountService from '@/services/AccountService';

import moment from 'moment-timezone';

import UserSettingsModule from '@/utils/userSettings';

import DoughnutChart from '@/components/common/charts/DoughnutChart.vue';
import NativePostsService from '@/services/per-post/NativePostsService';
import type {
  ActiveSocialAccount,
  ChartDataByPlatform,
  DateRange,
  PlatformAnalyticsContext,
  SocialAnalytics,
} from '@/types/audience';
import { TimeRangeMode, getDateRangeByRangeMode } from '@/types/audience/TimeRangeMode';
import { AudienceViewMode } from '@/types/audience/ViewMode';
import { Snapshot } from '@/types/audience/per-post-metrics/Metrics';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import { SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialRanking';

const audienceModule = namespace('AudienceAnalyticsStore');
const UserSettings = new UserSettingsModule();

@Component({
  name: 'Dashboard',
  components: {
    PlatformViewLayout,
    ViewHeader,
    DoughnutChart,
    DashboardDoughnutChart,
    DashboardLineChart,
    GrowthTips,
    ContextTotalCard,
    MetricList,
    Loading,
    PerPostMetrics,
    PlatformPostMetrics,
  },
})
export default class AudienceDashboard extends Vue {
  @audienceModule.Getter dateRange!: DateRange;
  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];
  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Getter('platformPosts') platformPosts!: AudiencePost[];
  @audienceModule.Action getAyrShareConnectUrl;
  @audienceModule.Action setPlatformPosts;

  loading = false;

  context: PlatformAnalyticsContext =
    (UserSettings.get('context') as PlatformAnalyticsContext | undefined) ?? 'audience';

  onContextChange(context: PlatformAnalyticsContext) {
    this.context = context;
    UserSettings.save('context', context);
  }

  viewMode: AudienceViewMode = AudienceViewMode.PerPostAnalytics;
  timeRangeMode: TimeRangeMode = TimeRangeMode.AllTime;

  currentPostMetricsSnapshots: Snapshot[] = [];
  selectedPostIndex = -1;

  get isPerPostAnalytics() {
    return this.viewMode === AudienceViewMode.PerPostAnalytics;
  }

  hoverState = SocialPlatform.all;
  clickState = SocialPlatform.all;

  get lineChartDataTypes() {
    return {
      daily: 'daily',
      overTime: 'overTime',
    };
  }

  get contextOptions() {
    let options = [
      { label: 'Impressions', value: 'impressions' },
      { label: 'Engagement', value: 'engagement' },
    ];

    if (this.viewMode === AudienceViewMode.PlatformAnalytics) {
      options = options.concat({
        label: 'Audience',
        value: 'audience',
      });
    }

    return options;
  }

  get metricsChartData() {
    let socialAnalyticsDates = Object.entries(this.socialAnalytics);
    if (socialAnalyticsDates.length < 2) return {};

    const startEndSocialAnalytics = {};
    socialAnalyticsDates = socialAnalyticsDates.map(([date, platformsData]) => {
      const platformsDataConverted = {};

      Object.entries(platformsData).forEach(([platform, data]) => {
        platformsDataConverted[platform] = data?.followers;
      });

      return [date, platformsDataConverted];
    });

    startEndSocialAnalytics[socialAnalyticsDates[0][0]] = socialAnalyticsDates[0][1];
    startEndSocialAnalytics[socialAnalyticsDates[socialAnalyticsDates.length - 1][0]] =
      socialAnalyticsDates[socialAnalyticsDates.length - 2][1];

    return startEndSocialAnalytics;
  }

  @Watch('context', { immediate: true })
  onContextValueChange() {
    const newPlatformLabel: PlatformLabels | undefined = PlatformLabels[this.platform.toUpperCase()];
    if (newPlatformLabel && this.excludedPlatformLabels.includes(newPlatformLabel)) {
      this.platform = SocialPlatform.all;
    }
  }

  @Watch('hoverState', { immediate: true })
  onHoverStateChange(newState: string) {
    this.hoverState = SocialPlatform[newState];
  }

  @Watch('clickState', { immediate: true })
  onClickStateChange(newState: string) {
    this.clickState = SocialPlatform[newState];
  }

  platform: SocialPlatform = (UserSettings.get('platform') as SocialPlatform | undefined) ?? SocialPlatform.all;

  onPlatformInput($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.platform = value as SocialPlatform;
    UserSettings.save('platform', value);
  }

  get excludedPlatformLabels() {
    return this.context === 'audience' ? [PlatformLabels.TIKTOK, PlatformLabels.PINTEREST] : [];
  }

  get platformOptions() {
    const activePlatformLabels = this.activeSocialAccounts.map(({ platform }) => platform);
    const allOption = [{ label: 'All', value: 'all' }];

    const activePlatformOptions = Object.values(PlatformLabels)
      .map((label) => ({ label, value: label.toLowerCase() }))
      .filter(({ value }) => activePlatformLabels.includes(value as SocialPlatform));

    const displayedPlatformOptions = activePlatformOptions.filter(
      (option) => !this.excludedPlatformLabels.includes(option.label)
    );

    return [...allOption, ...displayedPlatformOptions];
  }

  get layoutConfig() {
    return {
      isDashboard: true,
      userHasNoConnectedPlatforms: this.activeSocialAccounts.length === 0,
      leftContent: {
        heading: 'Snapshot',
        subHeading: this.displayedDateRange,
      },
      rightContent: {
        heading: 'Over time',
      },
      bottomContent: {
        heading: 'Daily metrics',
      },
    };
  }

  get headerConfig() {
    return {
      heading: 'Social impact by channel',
      showSubHeading: true,
      subHeading: '',
      platformLabel: '',
    };
  }

  get displayedDateRange() {
    const start = moment.tz(this.dateRange.start, this.timeZone).format('MMM DD[,] YYYY');
    const end = moment.tz(this.dateRange.end, this.timeZone).format('MMM DD[,] YYYY');
    return `${start} vs. ${end}`;
  }

  get platformsData(): Record<string, ChartDataByPlatform> {
    const activePlatforms = this.activeSocialAccounts.map(
      ({ platform }) => PlatformLabels[platform.toUpperCase()]
    ) as string[];
    const platformsData = {};
    activePlatforms.forEach((platformLabel) => {
      platformsData[platformLabel] = getChartDataByPlatform(platformLabel, this.socialAnalytics);
    });
    return platformsData;
  }

  get contextTotalCardConfig() {
    const title = `Total ${this.context}`;

    if (this.platform === 'all') {
      const initialAccumulatedTotal = this.metricTotals.reduce((acc, metricTotal) => {
        acc += metricTotal.initialValue || 0;
        return acc;
      }, 0);
      const finalAccumulatedTotal = this.metricTotals.reduce((acc, metricTotal) => {
        acc += metricTotal.value || 0;
        return acc;
      }, 0);

      const percentChange = calculatePercentChange(initialAccumulatedTotal, finalAccumulatedTotal);
      const numericChange = finalAccumulatedTotal - initialAccumulatedTotal;

      return {
        title,
        value: finalAccumulatedTotal,
        percentChange,
        numericChange,
      };
    }

    const platform = PlatformLabels[this.platform.toUpperCase()];
    const len = this.platformsData[platform]?.[this.context]?.[0]?.data.length;
    if (!len) {
      return {
        title,
        value: NaN,
        percentChange: NaN,
        numericChange: NaN,
      };
    }

    const initialValue = getMetricTotalsByContextAndDataIndex(this.platformsData[platform], this.context, 0);
    const finalValue = getMetricTotalsByContextAndDataIndex(this.platformsData[platform], this.context, len - 1);
    const percentChange = calculatePercentChange(initialValue, finalValue);
    const numericChange = finalValue - initialValue;
    return {
      title,
      value: finalValue,
      percentChange,
      numericChange,
    };
  }

  get metricTotalsTitle(): string {
    return this.platform === 'all' ? 'All channels' : PlatformLabels[this.platform.toUpperCase()];
  }

  get metricTotals(): {
    label: string;
    initialValue?: number;
    value: number;
    percentChange: number;
    numericChange: number;
  }[] {
    if (this.platform === 'all') {
      const filteredPlatformsDataEntries = Object.entries(this.platformsData).filter(
        ([key]) => !this.excludedPlatformLabels.includes(key as PlatformLabels)
      );

      return filteredPlatformsDataEntries.map(([platformLabel, platformMetrics]) => {
        const metricsByContext = platformMetrics?.[this.context] ?? [];
        const totals = {
          initial: 0,
          final: 0,
        };
        const finalAccumulatedTotalByContext = metricsByContext.reduce((acc, dataset) => {
          const initialValue = dataset.data[0] || 0;
          const finalValue = dataset.data[dataset.data.length - 1] || 0;
          acc.initial += initialValue;
          acc.final += finalValue;
          return acc;
        }, totals);

        const percentChange = calculatePercentChange(
          finalAccumulatedTotalByContext.initial,
          finalAccumulatedTotalByContext.final
        );

        const numericChange = finalAccumulatedTotalByContext.final - finalAccumulatedTotalByContext.initial;

        return {
          label: platformLabel,
          // initialValue only used when this.platform is set to 'all'
          initialValue: finalAccumulatedTotalByContext.initial,
          value: finalAccumulatedTotalByContext.final,
          percentChange,
          numericChange,
        };
      });
    }
    const platform = PlatformLabels[this.platform.toUpperCase()];
    return (
      this.platformsData[platform]?.[this.context]?.map((metric) => {
        const initialValue = metric.data[0];
        const finalValue = metric.data[metric.data.length - 1];
        const percentChange = calculatePercentChange(initialValue, finalValue);
        const numericChange = finalValue - initialValue;
        return {
          label: metric.label,
          value: finalValue,
          percentChange,
          numericChange,
        };
      }) ?? []
    );
  }

  get selectedPost() {
    return this.selectedPostIndex >= 0 ? this.platformPosts[this.selectedPostIndex] : undefined;
  }

  async onConnectClick() {
    this.loading = true;
    try {
      this.$router.push('/connect-socials');
    } catch (e) {
      this.loading = false;
      console.error(e);
    }
  }

  async getEnvVariables() {
    await new AccountService().getEnvVariables();
  }

  async linkToFacebook() {
    try {
      const { url } = await new AccountService().linkToFacebook();
      const anchorEl = document.createElement('A') as HTMLAnchorElement;
      anchorEl.href = url;
      anchorEl.target = '_blank';
      const body = document.getElementsByTagName('BODY')[0];
      body.appendChild(anchorEl);
      anchorEl.click();
      body.removeChild(anchorEl);
    } catch (e) {
      console.error(e);
    }
  }

  async fetchPostMetrics(index) {
    if (this.platformPosts && this.platformPosts.length > 0) {
      this.selectedPostIndex = index;

      const post = this.platformPosts[index];
      const postCreatedDate = moment.tz(post.dateCreated, this.timeZone);
      const postMetrics = await new NativePostsService().getPostMetrics(
        post.platform,
        post.postId,
        getDateRangeByRangeMode(postCreatedDate, this.timeRangeMode)
      );

      this.currentPostMetricsSnapshots = postMetrics;
    }
  }

  onResetClick() {
    this.hoverState = SocialPlatform.all;
    this.clickState = SocialPlatform.all;
  }

  get showFacebookDisclaimer() {
    // Facebook Analytics data is only available on Pages with 100 or more likes (aka followers).
    if (this.activeSocialAccounts.length === 0) return false;

    const isFacebookAccountLinked = !!this.activeSocialAccounts.find(
      ({ platform }) => platform === PlatformLabels.FACEBOOK.toLowerCase()
    );
    if (!isFacebookAccountLinked) return false;

    const facebookDataset = Object.values(this.socialAnalytics)
      .filter((analyticsByDate) => analyticsByDate?.[PlatformLabels.FACEBOOK]?.followers)
      .map((analyticsByDate) => analyticsByDate?.[PlatformLabels.FACEBOOK]?.followers ?? 0);

    const datasetLen = facebookDataset.length;
    if (!datasetLen) return false; // No data, can't make determination

    return facebookDataset.every((val) => val === 0);
  }

  get showTikTokDisclaimer() {
    // Twitter does not provide follower analytics at this time. - BDP 10/09/22
    if (this.activeSocialAccounts.length === 0) return false;

    const isTikTokAccountLinked = !!this.activeSocialAccounts.find(
      ({ platform }) => platform === PlatformLabels.TIKTOK.toLowerCase()
    );
    const isAudienceContext = this.context === 'audience';
    return isTikTokAccountLinked && isAudienceContext;
  }

  get showPinterestDisclaimer() {
    if (this.activeSocialAccounts.length === 0) return false;

    // Pinterest does not provide follower analytics at this time. - BDP 10/09/22
    const isTikTokAccountLinked = !!this.activeSocialAccounts.find(
      ({ platform }) => platform === PlatformLabels.PINTEREST.toLowerCase()
    );
    const isAudienceContext = this.context === 'audience';
    return isTikTokAccountLinked && isAudienceContext;
  }

  @Watch('timeRangeMode')
  async onTimeRangeModeChange() {
    this.fetchPostMetrics(this.selectedPostIndex);
  }

  async mounted() {
    const posts = await new NativePostsService().getPosts();
    this.setPlatformPosts(posts);
  }
}
