import HttpClient from '@/services/common/HttpClient';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import { setupSSE } from '@/createandpublish/eventsource';
import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
const MediaService = new CreateAndPublishMediaService();

import type { SSE } from '@/types/SSE';
import type { RootState } from '@/types/store';
import type { ActionTree } from 'vuex';
import type { CreateAndPublishStoreState } from './store';
import {
  CategoryGroup,
  ImportRSSFeedRequestPayload,
  Playlist,
  PlaylistCategory,
  POSTApiResponse,
  PostAudioEvent,
  PostVersionInfo,
  ResourceUri,
  Settings,
  Show,
  SocialMediaConnection,
  TrackingUrlPrefixRequestPayload,
  UpdatePlaylistStatePayload,
  VideoFromAudioPayload,
} from '@/types/createandpublish';
import { isValidErrorFormat } from '@/utils/typeGuards';

const http = HttpClient.getInstance();

const NON_RELATIVE_URL = /(^\/)|([^:]+:)/;

export default {
  async getSettings({ dispatch, commit, state, rootGetters }) {
    return handleAjax({
      request: http.get(`${state.endpoint}/settings`),
      dispatch,
      commit,
      mutation: 'SET_STATION_SETTINGS',
      errmsg: 'Unable to fetch CreateAndPublish settings from the server',
      modify: (data: POSTApiResponse<Settings>) => {
        return {
          ...data.objects[0],
          stationBrandId: rootGetters.selectedBrand?.id,
        };
      },
      callback: (err, _data, resp) => {
        if (err) {
          window.location.href = `${location.origin}/create`;
          return;
        }

        if (resp && resp.headers) {
          const appVersions = {} as PostVersionInfo;
          if (resp.headers.get('X-App-Version')) {
            appVersions.staticAppVersion = resp.headers.get('X-App-Version');
          }
          if (resp.headers.get('X-Color')) {
            appVersions.color = resp.headers.get('X-Color');
          }
          if (resp.headers.get('X-ChartTag')) {
            appVersions.chartTag = resp.headers.get('X-ChartTag');
          }
          if (resp.headers.get('X-ImageTag')) {
            appVersions.imageTag = resp.headers.get('X-ImageTag');
          }
          commit('SET_APP_VERSIONS', appVersions);
        }
      },
    });
  },

  async ensureStationSessionId({ getters, commit }) {
    const key = `${getters.station}`;
    const msgUint8 = new TextEncoder().encode(key);
    const arrayBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
    const base64 = window.btoa(
      new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    const urlSafeBase64 = base64.substring(0, 30).replace(/\//g, '_').replace(/\+|=/g, '-');
    commit('SET_STATION_SESSION_ID', urlSafeBase64);
  },

  setEndpoint({ commit, rootState }) {
    const brandId = rootState.BrandStore.selectedBrand?.id || NaN;
    commit('SET_ENDPOINT', `${process.env.VUE_APP_API_URL}/createandpublish/${brandId}`);
  },

  async fetchAllShowsData({ dispatch, commit, state }) {
    await handleAjax({
      request: http.get(`${state.endpoint}/shows`),
      dispatch,
      commit,
      mutation: 'SET_ALL_SHOWS',
      modify: (data) => data.shows,
      errmsg: 'Unable to talk to server to get all shows',
    });
  },

  async fetchShowData({ dispatch, commit, state }, showId: Show['id']) {
    await handleAjax({
      request: http.get(`${state.endpoint}/show/${showId}`),
      dispatch,
      commit,
      mutation: 'SET_CURRENT_SHOW',
      errmsg: 'Unable to talk to server to get requested show',
    });
  },

  setActiveShow({ commit }, showId) {
    commit('SET_ACTIVE_SHOW', showId);
  },

  clearActiveShow({ commit }) {
    commit('CLEAR_ACTIVE_SHOW');
  },

  clearActiveEpisodes({ commit }) {
    commit('CLEAR_ACTIVE_EPISODES');
  },

  deleteShow({ dispatch, commit, state }, showId: Show['id']) {
    return handleAjax({
      request: http.delete(`${state.endpoint}/show/${showId}/`),
      dispatch,
      commit,
      modify() {
        return showId;
      },
      errmsg: 'Unable to talk to server to delete show',
    });
  },

  deletePlaylist({ dispatch, commit, state }, episodeId: Playlist['id']) {
    return handleAjax({
      request: http.delete(`${state.endpoint}/playlist/${episodeId}/`),
      dispatch,
      commit,
      errmsg: 'Unable to talk to server to delete episode',
      callback(err) {
        if (!err) {
          dispatch('fetchAllShowsData');
          dispatch('getPlaylists/fetchPlaylists');
        }
      },
    });
  },

  downloadPlaylist({ state, getters, commit }, { playlist_id, urls }) {
    const map =
      state.playlistsAwaitingDownload instanceof Map
        ? state.playlistsAwaitingDownload
        : new Map(Object.entries(state.playlistsAwaitingDownload));

    if (map.has(playlist_id)) {
      const app = document.getElementById('app') as HTMLDivElement;
      map.get(playlist_id).forEach((type) => {
        const el = document.createElement('A') as HTMLAnchorElement;
        el.href = `${getters.mediaUrl}${urls[type]}`;
        app.appendChild(el);
        el.click();
        app.removeChild(el);
      });
      commit('REMOVE_PLAYLIST_ID_FOR_DOWNLOAD', playlist_id);
    }

    state.playlistsAwaitingDownload = map;
  },

  /**
   * Sets the following properties for a list of playlist: draft, unpublished, explicit
   */
  async setPlaylistType({ dispatch, commit, state }, { settings }: { settings: UpdatePlaylistStatePayload[] }) {
    await handleAjax({
      request: http.post(`${state.endpoint}/playlist/type/`, settings),
      dispatch,
      commit,
      errmsg: 'Unable to talk to server to update episode type',
      callback(err) {
        if (!err) {
          dispatch('fetchAllShowsData');
          dispatch('getPlaylists/fetchPlaylists');
        }
      },
    });
  },

  getSocialMediaConnections({ dispatch, commit, state }) {
    return handleAjax({
      request: http.get(`${state.endpoint}/social-media-connections/`),
      dispatch,
      commit,
      mutation: 'SET_SOCIAL_MEDIA_CONNECTIONS',
      modify: (data: POSTApiResponse<SocialMediaConnection>) => data.objects,
      errmsg: 'Unable to fetch social media connections from the server',
    });
  },

  addSocialMediaConnection({ dispatch, commit, state }, conn: SocialMediaConnection) {
    handleAjax({
      request: http.post(`${state.endpoint}/socialmediaconnection`, conn),
      dispatch,
      commit,
      errmsg: 'Unable to talk to server to add social media connection',
      callback(err) {
        if (!err) {
          dispatch('getSocialMediaConnections');
        }
      },
    });
  },

  editSocialMediaConnection({ dispatch, commit, state }, conn: SocialMediaConnection) {
    handleAjax({
      request: http.put(`${state.endpoint}/socialmediaconnection/${conn.id}/`, conn),
      dispatch,
      commit,
      mutation: 'UPDATE_SOCIAL_MEDIA_CONNECTION',
      modify() {
        return conn;
      },
      errmsg: 'Unable to talk to server to update a social media connection',
    });
  },

  async unpublishSocialMediaConnection({ dispatch, commit, state }, { playlistId }) {
    // get all social media connections related to episode
    const socialData = (await handleAjax({
      request: http.get(`${state.endpoint}/social-media-connections/${playlistId}`),
      dispatch,
      commit,
      errmsg: "Unable to talk to server to fetch this episode's social media connections",
    })) as SocialMediaConnection[];

    const connData = {
      social_media_connections: [] as string[],
    };

    socialData.forEach((conn) => {
      if (
        conn.type !== 'facebook' &&
        conn.type !== 'twitter' &&
        conn.type !== 'v_download' &&
        conn.type !== 'v_advanced' &&
        conn.type !== 'v_youtube' &&
        conn.type !== 'v_facebook'
      ) {
        connData.social_media_connections.push(`${conn.id}`);
      }
    });

    // removes/un-posts the social media connections from the episode
    handleAjax({
      request: http.post(`${state.endpoint}/playlist/unpost/${playlistId}.json`, connData),
      dispatch,
      commit,
      errmsg: 'Unable to talk to server to unpublish episode',
    });
  },

  async getPlaylistCategories({ dispatch, commit, state }) {
    await handleAjax({
      request: http.get(`${state.endpoint}/playlistcategory/`),
      dispatch,
      commit,
      mutation: 'SET_PLAYLIST_CATEGORIES',
      modify: (data: POSTApiResponse<PlaylistCategory>) => data.objects,
      errmsg: 'Unable to fetch playlist categories from the server',
    });
  },

  createPlaylistCategory({ dispatch, commit, state }, playlistCategory: PlaylistCategory) {
    return handleAjax({
      request: http.post(`${state.endpoint}/playlistcategory/`, playlistCategory),
      dispatch,
      commit,
      mutation: 'ADD_PLAYLIST_CATEGORY',
      errmsg: 'Unable to talk to server to create a tag',
      callback(err) {
        if (!err) {
          dispatch('getPlaylistCategories');
        }
      },
    });
  },

  updatePlaylistCategory({ dispatch, commit, state }, playlistCategory: PlaylistCategory) {
    return handleAjax({
      request: http.patch(`${state.endpoint}/playlistcategory/${playlistCategory.id}/`, playlistCategory),
      dispatch,
      commit,
      mutation: 'UPDATE_PLAYLIST_CATEGORY',
      errmsg: 'Unable to talk to server to update a tag',
    });
  },

  deletePlaylistCategory({ dispatch, commit, state }, id: PlaylistCategory['id']) {
    return handleAjax({
      request: http.delete(`${state.endpoint}/playlistcategory/${id}/`),
      dispatch,
      commit,
      mutation: 'DELETE_PLAYLIST_CATEGORY',
      modify: () => {
        return id;
      },
      errmsg: 'Unable to talk to server to delete a tag',
    });
  },

  /**
   * Fetch logger events based on start and end time.
   * @param dispatch
   * @param commit
   * @param params
   * @returns {Promise<void>}
   */
  async fetchLoggerEvents({ dispatch, commit, state }, params = {}) {
    const { startTime, endTime, cartType, searchTerm, cartCategory } = params;
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('frame_count__gt', '0');
    if (cartType) {
      urlSearchParams.set('cart__type_name', cartType);
    }
    if (startTime && endTime) {
      urlSearchParams.set('id__gte', startTime);
      urlSearchParams.set('id__lt', endTime);
    }

    if (searchTerm) {
      urlSearchParams.set('search', searchTerm);
    }

    if (cartCategory) {
      urlSearchParams.set('cart__category__name__in', cartCategory);
    }

    await handleAjax({
      request: http.get(`${state.endpoint}/station/?${urlSearchParams.toString()}`),
      dispatch,
      commit,
      mutation: 'SET_LOGGER_EVENTS',
      modify: (data: POSTApiResponse<PostAudioEvent>) => data.objects,
    });
  },

  bulkDeleteStacks({ dispatch, commit, state }, payload) {
    return handleAjax({
      request: http.post(`${state.endpoint}/bulk/delete/`, payload),
      dispatch,
      commit,
      mutation: 'BULK_DELETE_STACKS',
      errmsg: 'Unable to talk to server to bulk delete files',
    });
  },

  bulkDownloadStacks({ dispatch, commit, getters }, payload) {
    return handleAjax({
      request: http.post(`${getters.stationUrl}api/v2/pw/`, payload),
      dispatch,
      commit,
      mutation: 'BULK_DOWNLOAD_STACKS',
      modify: (data) => {
        return { ...data, mp3_list: payload.mp3_list };
      },
      errmsg: 'Unable to talk to server to bulk download files',
    });
  },

  deleteCompletedStack({ commit }, guid: string) {
    commit('DELETE_COMPLETED_DOWNLOAD_STACK', guid);
  },

  // @NOT-USED: This is not used in CC's Post -- leaving it in cause it may get used in the future
  async postToSocial({ dispatch, commit, state }, { id, data }: { id: Playlist['id']; data: ResourceUri[] }) {
    await handleAjax({
      request: http.post(`${state.endpoint}/playlist/t/post_social/${id}`, data),
      dispatch,
      commit,
    });
  },

  fetchGroupSettings({ dispatch, commit, state }) {
    const query = '?limit=0';
    return handleAjax({
      request: http.get(`${state.endpoint}/groupsettings${query}`),
      dispatch,
      commit,
      mutation: 'SET_CATEGORY_GROUPS',
      modify: (data: POSTApiResponse<CategoryGroup>) => data.objects,
      errmsg: 'Unable to talk to server to get category group settings',
    });
  },

  getExistingImages({ commit, dispatch, state }) {
    handleAjax({
      request: http.get(`${state.endpoint}/images`),
      dispatch,
      commit,
      mutation: 'GET_EXISTING_IMAGES',
      errmsg: 'Unable to talk to server to fetch existing images',
    });
  },

  async deleteImages({ dispatch, commit, state }, fileList: string[]) {
    const payload = {
      job_type: 'delete_s3_images',
      image_list: fileList,
    };

    await handleAjax({
      request: http.post(`${state.endpoint}/images`, payload),
      dispatch,
      commit,
      mutation: 'DELETE_IMAGES',
      modify() {
        return fileList;
      },
      callback(err) {
        if (err) {
          throw err;
        }
      },
      errmsg: 'Unable to talk to server to delete images',
    });
  },

  /**
   * Used to get the URL for an existing Headliner project. It is NOT the URL for the Headliner Video Creation Wizard
   * @param {Number} playlistId id of the playlist to get the Headliner URL for
   */
  getAdvancedVideoEditUrl({ dispatch, commit, state }, playlistId: Playlist['id']) {
    handleAjax({
      request: http.get(`${state.endpoint}/advanced-video-edit-url?playlist_id=${playlistId}`),
      dispatch,
      commit,
      mutation: 'SET_ADVANCED_VIDEO_EDIT_URL',
      modify: (data) => {
        return {
          playlist_id: playlistId,
          url: data.URL,
        };
      },
    });
  },

  async importExternalRSSFeed({ dispatch, commit, state }, payload: ImportRSSFeedRequestPayload) {
    await handleAjax({
      request: http.post(`${state.endpoint}/import-rss`, payload),
      dispatch,
      commit,
      callback(err) {
        if (err) {
          throw err;
        }
      },
    });
  },

  async setStationTrackingPrefixList({ dispatch, commit, state }, prefixObj: TrackingUrlPrefixRequestPayload) {
    await handleAjax({
      request: http.post(`${state.endpoint}/tracking-prefix/station/`, prefixObj),
      dispatch,
      commit,
      mutation: 'SET_STATION_TRACKING_PREFIX_LIST',
      modify() {
        return prefixObj;
      },
      callback(err) {
        if (err) {
          throw err;
        }
      },
    });
  },

  async setShowTrackingPrefixList(
    { dispatch, commit, state },
    { showId, prefixObj }: { showId: Show['id']; prefixObj: TrackingUrlPrefixRequestPayload }
  ) {
    await handleAjax({
      request: http.post(`${state.endpoint}/tracking-prefix/show/${showId}`, prefixObj),
      dispatch,
      commit,
      mutation: 'SET_SHOW_TRACKING_PREFIX_LIST',
      modify() {
        return { showId, prefixObj };
      },
      callback(err) {
        if (err) {
          throw err;
        }
      },
    });
  },

  openPreviewPlayer({ commit, getters }, { data, endpoint }) {
    const finalEndpoint = NON_RELATIVE_URL.exec(endpoint) ? endpoint : `${getters.stationUrl}${endpoint}`;

    commit('OPEN_PREVIEW_PLAYER', { data, endpoint: finalEndpoint });
  },

  verifyAndUpdateStoreForCurrentBrand: async ({ commit, getters, rootGetters, dispatch }) => {
    const stationSettings = Object.keys(getters.settings).length > 0;
    const stationBrandId = getters.stationBrandId as number;
    const currentBrandId: number | undefined = rootGetters.selectedBrand?.id;
    const isBrandIdChanged = currentBrandId !== stationBrandId;

    if (!stationSettings || isBrandIdChanged) {
      try {
        await dispatch('getSettings');
        commit('SET_PODCAST_ONLY');
        commit('SET_IS_ADMIN');
        commit('SET_LOGGER_ONLY');
      } catch (e) {
        console.error(e);
      }
    }

    const msgServer: SSE | null = getters.msgServer;

    if (msgServer && isBrandIdChanged) {
      commit('CLEAR_MESSAGE_SERVER');
    }

    if (!getters.msgServer) {
      await dispatch('ensureStationSessionId');
      const stationSessionId: string = getters.stationSessionId;
      const sseUrl = `${getters.eventsUrl}/sessions/${stationSessionId}`;
      const sseCfg = { format: 'json' };

      if (!getters.eventsUrl || !stationSessionId) {
        console.warn('Event server prefix or key not set. Unable to connect to SSE server.');
        return;
      }

      setupSSE(sseUrl, sseCfg)
        .then((msgServer) => {
          commit('SET_MESSAGE_SERVER', msgServer);
        })
        .catch((e) => {
          console.error('Unable to connect to SSE server; retrying every 20 seconds until established', e);
          const ms = 20000;
          const retry = () => {
            setupSSE(sseUrl, sseCfg)
              .then((msgServer) => commit('SET_MESSAGE_SERVER', msgServer))
              .catch(() => setTimeout(() => retry(), ms));
          };
          retry();
        });
    }
  },
  createVideoAssetWithPlaylistId({ dispatch, commit }, data) {
    const payload = {
      keep_audio: false, // this doesn't discard the original episode, it just prevents creating a new Audio Asset with the same name
      make_video: true,
      background_file: data.background_file,
      title: data.title,
      description: data.description,
      playlist_id: data.playlist_id,
    };
    return handleAjax({
      request: MediaService.createLibraryAsset(payload),
      dispatch,
      commit,
    });
  },

  createVideoAssetFromAudioAsset({ dispatch, commit }, payload: VideoFromAudioPayload) {
    return handleAjax({
      request: MediaService.createLibraryAsset(payload),
      dispatch,
      commit,
      callback(err) {
        if (err) {
          const errors = isValidErrorFormat(err);
          if (errors) {
            throw errors;
          }
        }
      },
    });
  },
} as ActionTree<CreateAndPublishStoreState, RootState>;
