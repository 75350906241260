/**
 * Record key used by the CC API to group Chargify product and price data
 */
export enum ProductKey {
  AUDIENCE = 'audience',
  DISCOVER = 'discover',
  CREATE_AND_PUBLISH = 'create',
  SUITE = 'suite',
  SUITE_FREE_TRIAL = 'suite_trial',
  TEST_SUITE = 'test_suite',
}

/**
 * Chargify product handles
 */
export enum ProductHandle {
  // DISCOVER_ANNUAL = 'product_annual_discover',
  DISCOVER_MONTHLY = 'product_monthly_discover',
  // CREATE_ANNUAL = 'product_annual_create',
  CREATE_MONTHLY = 'product_monthly_create',
  // SUITE_ANNUAL = 'product_annual_suite',
  SUITE_MONTHLY = 'product_monthly_suite',
  DAILY_TEST_PRODUCT = 'daily_test_product',
  AUDIENCE = 'product_audience',
  FULL_SUITE_FREE_TRIAL = 'product_suite_free_trial',
  CYBER_MONDAY_PRODUCT = 'cyber_monday_product',
}

/**
 * Chargify component handles
 */
export enum ComponentHandle {
  DISCOVER_BRANDS = 'component_brands_discover',
  CREATE_BRANDS = 'component_brands_create',
  AUDIENCE_BRANDS = 'component_brands_audience',
  DISCOVER_SEATS = 'component_seats_discover',
  CREATE_SEATS = 'component_seats_create',
  AUDIENCE_SEATS = 'component_seats_audience',
  SUITE_SEATS = 'component_seats_suite',
  SUITE_BRANDS = 'component_brands_suite',
  TEST_SUITE_SEATS = 'component_seats_test_suite',
}

/**
 * Chargify price point handles
 */
export enum PricePointHandle {
  // ANNUAL = 'annual',
  MONTHLY = 'monthly',
  FREE_TRIAL = 'free-trial',
  SUITE_MONTHLY = 'suite_monthly',
  DAILY_TEST_PRICE = 'daily_test_price',
  ANNUAL = 'annual',
  ANNUAL_PLUS = 'annual-plus',
  // SUITE_ANNUAL = 'suite_annual',
}

export enum BillingInterval {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
}
