
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import AssistiveText from './AssistiveText.vue';
import ErrorMessage from './ErrorMessage.vue';
import Label from './Label.vue';

@Component({
  components: {
    AssistiveText,
    ErrorMessage,
    Label,
  },
})
export default class Input extends Vue {
  // Props
  @Prop({ default: '', required: false }) ariaLabel?: string;
  @Prop({ default: '', required: false }) assistiveText?: string;
  @Prop({ default: '', required: false }) error?: string;
  @Prop({ default: '', required: false }) label?: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Prop({ required: true }) inputHandler!: (e: InputEvent) => void;
  @Prop({ default: '', required: false }) link?: {
    label: string;
    onClick: () => undefined;
  };
  @Prop({ default: '', required: false }) placeholder?: string;
  @Prop({ default: '', required: false }) value?: string;
  @Prop({ default: '', required: false }) name?: string;
  @Prop({ default: 'text', required: false }) type?: string;
  @Prop({ default: false, required: false }) disabled?: boolean;
  @Prop({ required: false }) maxLength?: number;
  @Prop({ default: false, required: false }) showWordCount?: boolean;
  @Prop({ default: '', required: false }) inputClass?: string;
  @Prop({ default: false, required: false }) autoFocus?: boolean;
  @Prop({ default: false, required: false }) hideAssistive?: boolean;

  get shouldBeFocused() {
    return this.autoFocus;
  }
  // Watch
  @Watch('shouldBeFocused', { immediate: true })
  onAutoFocusChange(newValue) {
    if (newValue) {
      this.$nextTick(() => {
        const input = this.$refs.input;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        input.focus();
      });
    }
  }

  // Computed
  get _ariaLabel(): string {
    // Maybe it shouldn't be undefined
    return this.ariaLabel || this.label || '';
  }

  // Methods
  hasError(x?: string) {
    typeof x === 'string' && x.length > 0;
  }

  wordCountText(): string {
    const { value } = this;
    if (value && this.maxLength) {
      return `${value.length} of ${this.maxLength} characters`;
    }
    return '';
  }

  wordCountWarning(): string {
    const { value } = this;
    if (value && this.maxLength && value.length >= this.maxLength) {
      return `${this.label} must be less than ${this.maxLength} characters`;
    } else {
      return '';
    }
  }
  isAtMaxLength(): boolean {
    const { value, maxLength } = this;
    if (value && maxLength) {
      return value.length > maxLength;
    } else {
      return false;
    }
  }

  shouldShowAssist(): boolean {
    if (this.value && this.maxLength) {
      return true;
    } else {
      return false;
    }
  }
}
