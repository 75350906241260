export enum PlatformLabels {
  YOUTUBE = 'YouTube',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  TIKTOK = 'TikTok',
  LINKEDIN = 'LinkedIn',
  PINTEREST = 'Pinterest',
}

export const activePlatforms = [
  PlatformLabels.YOUTUBE,
  PlatformLabels.INSTAGRAM,
  PlatformLabels.FACEBOOK,
  PlatformLabels.TIKTOK,
];

export enum PlatformColors {
  YOUTUBE = '#FF0000',
  INSTAGRAM = '#BB00AD',
  FACEBOOK = '#1877F2',
  TWITTER = '#1DA1F2',
  TIKTOK = '#25F3ED',
  LINKEDIN = '#0A66C2',
  PINTEREST = '#FF5A44',
}

export enum PlatformChannelUrlPrefixes {
  YOUTUBE = 'https://www.youtube.com/c',
  INSTAGRAM = 'https://www.instagram.com',
  FACEBOOK = 'https://www.facebook.com',
  TWITTER = 'https://twitter.com',
  TIKTOK = 'https://www.tiktok.com',
  LINKEDIN = 'https://www.linkedin.com/in',
  PINTEREST = 'https://www.instagram.com',
}

export const DatasetBackgroundColors = [
  '#0054a9',
  '#00adef',
  '#5b68de',
  '#532aa2',
  '#8e199d',
  '#ff81ba',
  '#ec4c8a',
  '#b5005d',
  '#ff7c52',
  '#d73909',
];

const PLATFORM_LABEL_CONVERTOR: Record<string, PlatformLabels> = {
  youtube: PlatformLabels.YOUTUBE,
  instagram: PlatformLabels.INSTAGRAM,
  facebook: PlatformLabels.FACEBOOK,
  twitter: PlatformLabels.TWITTER,
  tiktok: PlatformLabels.TIKTOK,
  linkedin: PlatformLabels.LINKEDIN,
  pinterest: PlatformLabels.PINTEREST,
};
export const convetToPlatformLabel = (string: string): PlatformLabels => {
  return PLATFORM_LABEL_CONVERTOR[string.toLowerCase()];
};
