
import moment, { MomentInput } from 'moment';
import { mapGetters } from 'vuex';

import '../styles.scss';
import Button from '@/components/common/buttons/Button.vue';

export default {
  name: 'trial-expired-admin',

  components: {
    Button,
  },

  computed: {
    ...mapGetters(['trialEndedAt']),
    daysRemaining(): number {
      const daysContentRemains = 30;
      const expirationDate = moment(this.trialEndedAt as MomentInput);
      const now = moment();
      return Math.max(daysContentRemains - now.diff(expirationDate, 'days'), 0);
    },
  },
};
