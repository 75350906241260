import { RouteConfig } from 'vue-router';

import EditEpisodeRoutes from '@/createandpublish/core/routes/episode.js';
import podcastAnalyticsRoutes from '@/createandpublish/core/routes/analytics';
import AudioWizardRoutes from '@/createandpublish/core/routes/audioWizard';
import EditShowRoutes from '@/createandpublish/core/routes/editShow';
import SocialStoryRoutes from '@/createandpublish/core/routes/socialStories';

import store from '@/store';

import { requiresBrandAccess, requiresProductAccess } from '@/constants/router/permissions';

const CreateAndPublish = () => import(/* webpackChunkName: "cp-entry" */ '@/views/CreateAndPublish.vue');
const CreateDashboard = () => import(/* webpackChunkName: "cp-entry" */ '@/createandpublish/Dashboard.vue');

const Podcasts = () => import('@/createandpublish/Podcasts.vue');

// Create & Publish
const Planner = () => import('@/createandpublish/views/planner/Planner.vue');
// Discover
const ShowsList = () => import(/* webpackChunkName: "cp-podcast-entry" */ '@/createandpublish/views/ShowsList.vue');
const ShowsRoute = () => import(/* webpackChunkName: "cp-podcast-entry" */ '@/createandpublish/views/ShowsRoute.vue');
const ShowsRouteDetails = () =>
  import(/* webpackChunkName: "cp-podcast-entry" */ '@/createandpublish/views/ShowsRouteDetails.vue');

const AnalyticsHomeRoute = () =>
  import(/* webpackChunkName: "cp-analytics-entry" */ '@/createandpublish/views/analytics/AnalyticsHomeRoute.vue');
const AnalyticsDashboard = () =>
  import(/* webpackChunkName: "cp-analytics-entry" */ '@/createandpublish/views/analytics/AnalyticsDashboard.vue');

const SocialPostsAnalytics = () =>
  import(/* webpackChunkName: "cp-analytics-social" */ '@/createandpublish/views/analytics/SocialPostAnalytics.vue');
const SocialPostAnalyticsDetail = () =>
  import(
    /* webpackChunkName: "cp-analytics-social" */ '@/createandpublish/views/analytics/SocialPostAnalyticsDetail.vue'
  );

const ImageLibrary = () =>
  import(/* webpackChunkName: "cp-libraries" */ '@/createandpublish/views/image-library/ImageLibrary.vue');
const VideoLibrary = () =>
  import(/* webpackChunkName: "cp-libraries" */ '@/createandpublish/views/video-library/VideoLibrary.vue');
const AudioLibrary = () =>
  import(/* webpackChunkName: "cp-audio-library" */ '@/createandpublish/views/audio-library/AudioLibrary.vue');

const CNPSettings = () => import('@/createandpublish/views/CNPSettings.vue');

const TrackingURLs = () => import('@/createandpublish/views/trackingURLs/TrackingURLs.vue');
const ImportRSSFeed = () => import('@/createandpublish/views/import-content/ImportRSSFeed.vue');

const CreatorCommunity = () => import('@/audience/views/CreatorCommunity.vue');

export default [
  {
    path: '/create/:brandId?',
    component: CreateAndPublish,
    meta: { ...requiresBrandAccess, ...requiresProductAccess },
    beforeEnter: async (to, from, next) => {
      try {
        const promises: Promise<unknown>[] = [
          store.dispatch('CreateAndPublishStore/setEndpoint'),
          store.dispatch('CreateAndPublishStore/verifyAndUpdateStoreForCurrentBrand'),
        ];
        await Promise.all(promises);
        next();
      } catch (e) {
        console.error(e);
        next({
          path: '',
        });
      }
    },
    children: [
      {
        path: '',
        name: 'Create.Home',
        component: CreateDashboard,
        meta: {
          ...requiresBrandAccess,
          sidebar_active_item: 'Dashboard',
          title: 'Dashboard | Create & Publish',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'podcasts',
        name: 'Create.Podcasts',
        component: Podcasts,
        meta: {
          ...requiresBrandAccess,
          sidebar_active_item: 'Podcasts',
          title: 'Podcasts | Create & Publish',
          feature: 'create-and-publish',
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('CreateAndPublishStore/fetchAllShowsData');
          next();
        },
      },
      SocialStoryRoutes,
      {
        path: 'planner',
        name: 'Planner',
        component: Planner,
        meta: {
          ...requiresBrandAccess,
          sidebar_active_item: 'Content Calendar',
          title: 'Planner | Create & Publish',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'podcasts/',
        redirect: 'podcasts/shows',
        meta: {
          title: 'Podcasts | Create & Publish',
          feature: 'create-and-publish',
          sidebar_active_item: 'Podcasts',
        },
      },
      {
        path: 'audio-library',
        name: 'Create.Audio',
        component: AudioLibrary,
        props: () => ({
          libraryMode: store.getters['CreateAndPublishStore/audioWizard/wizardModes'].LIBRARY,
        }),
        meta: {
          sidebar_active_item: 'Audio',
          title: 'Audio Library | Create & Publish',
          feature: 'create-and-publish',
        },
        beforeEnter(_to, _from, next) {
          store.commit('CreateAndPublishStore/audioWizard/CLEAR_AUDIO_EVENTS');
          next();
        },
      },
      AudioWizardRoutes,
      {
        path: 'podcasts/shows',
        component: ShowsList,
        name: 'Shows',
        meta: {
          title: 'Shows | Podcasts | Create & Publish',
          sidebar_active_item: 'Shows',
          feature: 'create-and-publish',
        },
        beforeEnter(to, from, next) {
          store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
          next();
        },
      },
      {
        path: 'podcasts/shows/:showId',
        component: ShowsRoute,
        meta: {
          sidebar_active_item: 'Shows',
          feature: 'create-and-publish',
        },
        children: [
          {
            path: '/',
            redirect: 'details',
          },
          {
            name: 'show-detail',
            path: 'details',
            component: ShowsRouteDetails,
            meta: {
              title: 'New Show | Podcasts | Create & Publish',
              sidebar_active_item: 'Shows',
              feature: 'create-and-publish',
            },
            beforeEnter(to, from, next) {
              store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
              next();
            },
          },
          {
            path: 'new-episode',
            redirect: 'episodes/0/edit/audio',
          },
          EditEpisodeRoutes,
        ],
      },
      {
        path: 'analytics',
        name: 'Create.Analytics',
        component: AnalyticsHomeRoute,
        // redirect: 'analytics/dashboard',
        redirect: 'analytics/social',
        children: [
          {
            path: 'dashboard',
            name: 'Create.Analytics.Dashboard',
            component: AnalyticsDashboard,
            meta: {
              title: 'Analytics Dashboard | Create & Publish',
              sidebar_active_item: 'Analytics',
              feature: 'create-and-publish',
            },
          },
          {
            path: 'social',
            name: 'Create.Analytics.Social',
            component: SocialPostsAnalytics,
            meta: {
              title: 'Analytics | Create & Publish',
              sidebar_active_item: 'Analytics',
            },
          },
          {
            path: 'social/:storyId',
            name: 'Create.Analytics.Social.Detail',
            component: SocialPostAnalyticsDetail,
            meta: {
              title: 'Analytics | Create & Publish',
              sidebar_active_item: 'Analytics',
            },
          },
          podcastAnalyticsRoutes,
        ],
      },
      {
        path: 'images',
        name: 'Create.Images',
        component: ImageLibrary,
        meta: {
          title: 'Image Library | Create & Publish',
          sidebar_active_item: 'Images',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'videos',
        name: 'Create.Videos',
        component: VideoLibrary,
        meta: {
          title: 'Video Library | Create & Publish',
          sidebar_active_item: 'Video',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'settings',
        component: CNPSettings,
        name: 'Create.Settings',
        meta: {
          title: 'Settings | Create & Publish',
          sidebar_active_item: 'Settings',
          feature: 'create-and-publish',
        },
        beforeEnter(to, from, next) {
          store.dispatch('CreateAndPublishStore/fetchAllShowsData');
          store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
          store.dispatch('CreateAndPublishStore/getPlaylistCategories');
          next();
        },
      },
      {
        path: 'tracking-urls',
        name: 'tracking-urls',
        component: TrackingURLs,
        meta: {
          title: '3rd party Tracking URLs',
          sidebar_active_item: 'Tracking URLs',
          feature: 'create-and-publish',
        },
        async beforeEnter(to, from, next) {
          await store.dispatch('CreateAndPublishStore/fetchAllShowsData');
          next();
        },
      },
      {
        path: 'import-rss-feed',
        name: 'import-rss-feed',
        component: ImportRSSFeed,
        meta: {
          title: 'Import RSS feed',
          sidebar_active_item: 'Import Content',
          feature: 'create-and-publish',
        },
        async beforeEnter(_to, _from, next) {
          const promises: Promise<unknown>[] = [
            store.dispatch('CreateAndPublishStore/fetchAllShowsData'),
            store.dispatch('CreateAndPublishStore/getSocialMediaConnections'),
            store.dispatch('CreateAndPublishStore/clearActiveShow'),
          ];
          await Promise.all(promises);
          next();
        },
      },
      {
        path: 'community',
        name: 'Create.CreatorCommunity',
        component: CreatorCommunity,
        props: {
          addPadding: true,
        },
        meta: {
          sidebar_active_item: 'Creator Community',
          title: 'Creator Community | Create & Publish',
        },
      },
      EditShowRoutes,
    ],
  },
] as RouteConfig[];
