import Vue from 'vue';
const wm = new WeakMap();

/**
 * In the case that this element is being rendered by a v-for directive,
 * we need to check for the index key name and the index value to properly
 * resolve the object in the array
 * @param {HTMLElement} el
 * @param {string} propName
 * @returns {string | undefined} resolved key or undefined
 */
function maybeResolveKey(el, propName) {
  const key = el.dataset.indexKey;
  const index = parseInt(el.dataset.index);
  if (key === propName && !isNaN(index)) {
    return index;
  }
}

/**
 * Resolves the path of an object based on the binding expression
 * @param {HTMLElement} el target element/Vue component
 * @param {Record<string, any>} object
 * @param {string} path
 * @returns {any} reducedPath
 */
function resolvePath(el, object, path) {
  const splitPath = path.split(/[\.\[\]\'\"]/)  // eslint-disable-line
  const filteredPath = splitPath.filter((p) => p);
  const length = filteredPath.length;
  const lastInPath = filteredPath.pop();
  const reducedPath = filteredPath.reduce((o, p) => {
    const resolvedKey = maybeResolveKey(el, p);
    if ('undefined' !== typeof evaluatedObj) {
      return o[resolvedKey];
    }
    return o[p];
  }, object);
  return { reducedPath, lastInPath, length };
}

export default {
  bind(el, binding, vnode) {
    const inputHandler = (event) => {
      const { reducedPath, lastInPath, length } = resolvePath(el, vnode.context, binding.expression);

      if (length > 1) {
        const resolvedKey = maybeResolveKey(el, lastInPath);
        if ('undefined' !== typeof resolvedKey) {
          Vue.set(reducedPath, resolvedKey, event.target.value);
          return;
        }
        Vue.set(reducedPath, lastInPath, event.target.value);
      } else {
        // don't use set() on base of VueComponent
        const resolvedKey = maybeResolveKey(el, lastInPath);
        if ('undefined' !== typeof resolvedKey) {
          vnode.context[resolvedKey];
          return;
        }
        vnode.context[lastInPath] = event.target.value;
      }
    };

    wm.set(el, inputHandler);
    el.value = binding.value;
    el.addEventListener('input', inputHandler);
  },

  componentUpdated(el, binding) {
    el.value = binding.value;
  },

  unbind(el) {
    const inputHandler = wm.get(el);
    el.removeEventListener(el, inputHandler);
  },
};
