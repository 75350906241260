import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
import { handleAjax } from '@/createandpublish/core/util';
import { Module } from 'vuex';
import type { RootState } from '@/types/store';
import type {
  GetMediaAssetsQueryParams,
  VideoAsset,
  PaginatedAssetsResponse,
  PaginationMetadata,
} from '@/types/createandpublish/mediaLibraries';

const MediaService = new CreateAndPublishMediaService();
const defaultPaginationValue: PaginationMetadata = {
  current_page: 1,
  limit: 10,
  total_pages: 0,
  total_count: 0,
};

export default {
  namespaced: true,

  state: {
    videoQuery: '',
    videoAssets: [],
    videoPagination: defaultPaginationValue,
  },

  getters: {
    videoAssets(state) {
      return state.videoAssets;
    },

    videoPagination(state) {
      return state.videoPagination;
    },
  },

  mutations: {
    SET_VIDEO_ASSETS(state, paginatedVideoAssets: PaginatedAssetsResponse<VideoAsset>) {
      state.videoPagination = paginatedVideoAssets.pagination;
      state.videoAssets = paginatedVideoAssets.data;
    },

    CLEAR_VIDEO_ASSETS(state) {
      state.videoAssets = [];
      state.videoPagination = defaultPaginationValue;
    },

    UPDATE_VIDEO_ASSET(state, updatedAssetData: VideoAsset) {
      const index = state.videoAssets.findIndex((asset) => asset.id === updatedAssetData.id);
      if (index !== -1) {
        const updatedAsset = {
          ...state.videoAssets[index],
          ...updatedAssetData,
        };
        state.videoAssets.splice(index, 1, updatedAsset);
      }
    },

    DELETE_VIDEO_ASSET(state, assetId: number) {
      const index = state.videoAssets.findIndex((asset) => asset.id === assetId);
      if (index !== -1) {
        state.videoAssets.splice(index, 1);
      }
    },
  },

  actions: {
    async getVideoAssets({ dispatch, commit }, params: GetMediaAssetsQueryParams) {
      const mediaType = 'video';
      await handleAjax({
        request: MediaService.getMediaLibraryItems(mediaType, params),
        dispatch,
        commit,
        mutation: 'SET_VIDEO_ASSETS',
      });
    },

    async updateVideoAsset({ dispatch, commit }, videoAsset: VideoAsset) {
      const mediaType = 'video';
      await handleAjax({
        request: MediaService.updateMediaLibraryItem(mediaType, videoAsset),
        dispatch,
        commit,
        mutation: 'UPDATE_VIDEO_ASSET',
      });
    },

    async deleteVideoAsset({ dispatch, commit }, assetId: number) {
      const mediaType = 'video';
      await handleAjax({
        request: MediaService.deleteMediaLibraryItem(mediaType, assetId),
        dispatch,
        commit,
        mutation: 'DELETE_VIDEO_ASSET',
        modify() {
          return assetId;
        },
      });
    },
  },
} as Module<VideoLibraryState, RootState>;

/**
 * Module state
 */
interface VideoLibraryState {
  videoAssets: VideoAsset[];
  videoPagination: PaginationMetadata;
  videoQuery: string;
}
