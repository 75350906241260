import SocialPostService from '@/services/CreateAndPublishSocialPostService';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import { getErrorsFromResponse } from '@/utils';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';
import type { SocialPostResponse as SocialPost } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';
import type { ISO8601DateTime } from '@/types/Common';

const PostService = new SocialPostService();

const socialPostModule: Module<SocialPostsState, RootState> = {
  namespaced: true,

  state: {
    posts: [],
    start: '',
    end: '',
  },

  getters: {
    posts(state) {
      return state.posts;
    },
  },

  mutations: {
    SET_POSTS(state, posts: SocialPost[]) {
      state.posts = posts;
    },
    SET_START(state, start: ISO8601DateTime) {
      state.start = start;
    },
    SET_END(state, end: ISO8601DateTime) {
      state.end = end;
    },
    CLEAR_POSTS(state) {
      state.posts = [];
    },
  },

  actions: {
    getPosts({ dispatch, commit, state }) {
      return handleAjax({
        request: PostService.getPosts(state.start, state.end),
        dispatch,
        commit,
        mutation: 'SET_POSTS',
        callback: (err) => {
          if (err) {
            const errors = getErrorsFromResponse(err);
            if (errors) {
              throw errors;
            }
            throw ['Unknown server error'];
          }
        },
      });
    },
    deletePost({ dispatch, commit }, postId) {
      return handleAjax({
        request: PostService.deletePost(postId),
        dispatch,
        commit,
        callback: (err) => {
          if (err) {
            const errors = getErrorsFromResponse(err);
            if (errors) {
              throw errors;
            }
            throw ['Unknown server error'];
          }
        },
      });
    },
  },
};

export default socialPostModule;
export interface SocialPostsState {
  posts: SocialPost[];
  start: ISO8601DateTime;
  end: ISO8601DateTime;
}
