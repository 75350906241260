
import { busEvents, eventBus } from '@/createandpublish/core/eventBus/socialPostEventBus';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ImageAsset, VideoAsset } from '@/types/createandpublish';

type PrefillObject = {
  prefix: string;
  value: string;
};

type AddMediaObject = {
  prefix: 'video' | 'image';
  value: ImageAsset | VideoAsset;
};

@Component({
  name: 'PrefillContent',
})
export default class PrefillContent extends Vue {
  @Prop({ type: Array, required: true }) selectedPrefilledContent!: PrefillObject[];
  @Prop({ type: Array, required: true }) mediaAssetsToAdd!: AddMediaObject[];

  async removePrefillContentOption(prefillOption: PrefillObject) {
    const arr = this.selectedPrefilledContent ?? [];
    this.selectedPrefilledContent = arr.filter((s) => s.value != prefillOption.value);
  }

  async removeMediaAssetToAdd(mediaAsset: AddMediaObject) {
    const arr = this.mediaAssetsToAdd ?? [];
    this.mediaAssetsToAdd = arr.filter((s) => s.value.id != mediaAsset.value.id);
  }

  emitAddPrefillContent(event) {
    const prefix = event.target.dataset.prefix;
    const value = event.target.dataset.value;
    eventBus.$emit(busEvents.ADD_PREFILL_CONTENT, { prefix, value });
  }

  emitAddMediaAsset(event) {
    let prefix = event.target.dataset.prefix;
    let value = event.target.dataset.value;
    if (!prefix || !value) {
      prefix = event.target.parentElement.dataset.prefix;
      value = event.target.parentElement.dataset.value;
    }
    eventBus.$emit(busEvents.ADD_MEDIA_FROM_LIBRARY, { prefix, value });
  }

  ensureReasonableContentLength(content: string) {
    return content.length > 44 ? content.slice(0, 44) + '...' : content;
  }
}
