
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import { Snapshot } from '@/types/audience/per-post-metrics/Metrics';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Posts from './Posts.vue';

@Component({
  components: { Posts },
})
export default class PerPostsMetrics extends Vue {
  @Prop({ type: String }) platform!: PlatformLabels;
  @Prop({ type: Array }) posts!: AudiencePost[];
  @Prop({ type: Number }) selectedPostIndex!: number;
  @Prop({ type: Array, required: true }) snapshots!: Snapshot[];
  @Prop({ type: String, required: false }) title?: string;

  onPostSelect(postIndex: number) {
    this.$emit('post-select', postIndex);
  }

  @Watch('selectedPostIndex')
  onSelectedPostChange() {
    if (this.selectedPostIndex !== -1) {
      if (this.$refs.wrapper) {
        (this.$refs.wrapper as HTMLElement).scrollTo({
          left: 1500,
          behavior: 'smooth',
        });
      }
    }
  }
}
