import { ChatbotMessage } from '@/types/discover/ChatbotMessage';

export const emptyStateConversation: ChatbotMessage[] = [
  // {
  //   sender: {
  //     type: 'USER',
  //   },
  //   content: 'Post ideas about trending recipes',
  // },
  {
    sender: {
      type: 'CHATBOT',
    },
    content: `As of now, these quick vegan recipes are trending on social media:

1. One - Pot Vegan Pasta: A simple and delicious pasta dish made with a variety of vegetables, tomato sauce, and your choice of pasta.This recipe is popular for its minimal cleanup and customizable ingredients.

2. Chickpea Salad Sandwiches: A healthy alternative to traditional tuna or chicken salad sandwiches, using mashed chickpeas mixed with vegan mayo, mustard, and various seasonings.Add - ins like celery, pickles, or red onion can be included for extra flavor and crunch.

3. Vegan Buddha Bowls: These colorful bowls are filled with a mix of grains(like quinoa or brown rice), roasted vegetables, protein sources(such as tofu or tempeh), and topped with a flavorful dressing.They're highly customizable and visually appealing.

4. 15-Minute Vegan Tacos: Quick tacos made with seasoned lentils or black beans as the base filling, topped with avocado slices, salsa, shredded lettuce or cabbage, and any other desired toppings.

5. Smoothie Bowls: Nutrient - packed smoothie bowls featuring fruits like bananas, berries, mangoes blended with plant - based milk or yogurt.Toppings include granola, nuts/ seeds, coconut flakes, and more fruit for added texture.

To make your content stand out:

- Share step - by - step instructions in an easy - to - follow format.
- Use high - quality images to showcase the final product.
- Offer ingredient substitutions for those with dietary restrictions.
- Share tips on how to meal prep these recipes for busy weeks.
- Engage your audience by asking them to share their favorite quick vegan recipes in the comments section.`,
  },
];
