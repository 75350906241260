
// See /documentation/MediaUploads.md for documentation
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { v4 as uuidv4 } from 'uuid';
import type {
  MediaUploadProgressState,
  MediaUploadType,
  MediaUploadMetadata,
  ResourceUri,
  MediaFileUploadMetadata,
} from '@/types/createandpublish';
import type { TimerId } from '@/types/Common';

const MAX_MILLIS_SINCE_LAST_UPDATE = 1000 * 60 * 2; // two minutes
const MODULE_NAMESPACE = 'CreateAndPublishStore/mediaUploads';
const mediaUploadsModule = namespace(MODULE_NAMESPACE);

function prepareAudioUploadPayload(file, metaData: MediaFileUploadMetadata<'audio'>) {
  const uuid = uuidv4();
  const single_use = !!metaData.single_use;
  const title = metaData.title || file.name;
  const description = metaData.description || file.name;
  const audio_type = metaData.audio_type || 'UPLOAD-Talk';
  const campaigns = metaData.campaignIds || [];

  return {
    uuid,
    title,
    file,
    description,
    audio_type,
    single_use,
    campaigns,
  };
}

function prepareImageUploadPayload(file, metaData: MediaFileUploadMetadata<'image'>) {
  const uuid = uuidv4();
  const title = metaData.title || file.name;
  const description = metaData.description || file.name;
  const campaigns = metaData.campaignIds || [];

  return {
    uuid,
    title,
    file,
    description,
    campaigns,
  };
}

function prepareVideoUploadPayload(file: File, metaData: MediaFileUploadMetadata<'video'>) {
  const uuid = uuidv4();
  const title = metaData.title || file.name;
  const description = metaData.description || file.name;
  const campaigns = metaData.campaignIds || [];

  return {
    uuid,
    title,
    file,
    description,
    campaigns,
  };
}

type FileUploadMetadata =
  | MediaFileUploadMetadata<'audio'>
  | MediaFileUploadMetadata<'video'>
  | MediaFileUploadMetadata<'image'>;

@Component({})
export default class UploadMediaMixin extends Vue {
  unwatch: ReturnType<Vue['$watch']> | null = null;
  lastUpdatedTimer: TimerId | null = null;

  @mediaUploadsModule.Getter progress!: MediaUploadProgressState;
  @mediaUploadsModule.Getter isUploading!: boolean;
  @mediaUploadsModule.Getter lastUpdatedMediaUploads!: Pick<MediaUploadMetadata, 'uuid' | 'lastUpdated'>[];
  @mediaUploadsModule.Getter areAllUploadsComplete!: boolean;
  @mediaUploadsModule.Getter singleUseEventUris!: ResourceUri[];
  @mediaUploadsModule.Getter mediaLibraryEventUris!: ResourceUri[];
  @mediaUploadsModule.Getter successfulVideoUploads!: MediaUploadMetadata[];
  @mediaUploadsModule.Getter successfulImageUploads!: MediaUploadMetadata[];
  @mediaUploadsModule.Getter allSuccessfulUploads!: MediaUploadMetadata[];
  @mediaUploadsModule.Getter failedMediaUploads!: Pick<MediaUploadMetadata, 'uuid' | 'title' | 'err_msg'>[];

  @mediaUploadsModule.State uploadType!: Exclude<MediaUploadType, undefined>;

  @mediaUploadsModule.Action('uploadMedia') uploadFileWithModule!: (uploadObj: MediaUploadMetadata) => Promise<void>; // renamed to avoid potential conflicts

  startUploadWithFile(file: File, metaData: FileUploadMetadata = {}) {
    if (!file || !(file instanceof File)) {
      throw new TypeError(`[Upload Service] Expected to receive file, received ${file}.`);
    }

    if (typeof this.unwatch !== 'function') {
      throw new Error('[Upload Service] startUploadWithFile called before initMediaUploadService');
    }

    let payload;
    switch (this.uploadType) {
      case 'audio':
        payload = prepareAudioUploadPayload(file, metaData);
        break;
      case 'image':
        payload = prepareImageUploadPayload(file, metaData);
        break;
      case 'video':
        payload = prepareVideoUploadPayload(file, metaData);
        break;
      default:
        // This shouldn't happen, since we verify type in the init call,
        // but putting this here in case something really janky occurs.
        throw new Error(
          "[Upload Service] Couldn't identify upload type when startUploadWithFile was called. Likely that mixin state was mutated improperly."
        );
    }

    this.uploadFileWithModule(payload);
  }

  initMediaUploadService(uploadType: Exclude<MediaUploadType, undefined>, onCompleteCb) {
    if (typeof onCompleteCb !== 'function') {
      throw new TypeError(`[Upload Service] Expected function, received ${typeof onCompleteCb}`);
    }

    if (!uploadType || !['audio', 'image', 'video'].includes(uploadType)) {
      throw new TypeError(
        `[Upload Service] Expected to receive a valid upload type, got ${uploadType || typeof uploadType}`
      );
    }

    this.$store.commit(`${MODULE_NAMESPACE}/SET_UPLOAD_TYPE`, uploadType);

    this.unwatch = this.$watch('areAllUploadsComplete', async () => {
      if (this.areAllUploadsComplete) {
        if (this.failedMediaUploads.length) {
          // if we have errors, inform user
          let errDesc = '';
          this.failedMediaUploads.forEach(({ title, err_msg }, i) => {
            const addSpace = i > 0 ? ' ' : '';
            errDesc += `${addSpace}"${title}" failed to upload: ${err_msg?.reason}.`;
          });
          this.$store.commit('CreateAndPublishStore/SET_MESSAGE', {
            name: 'Upload',
            details: errDesc,
            type: 'error',
          });
        }

        try {
          await onCompleteCb();
          // Provide a slight buffer before resetting state
          await new Promise((resolve) => setTimeout(resolve, 50));
        } catch (e) {
          console.error(
            '[Upload Service] Callback provided to initUploadService failed. Throwing to consuming component.'
          );
          throw e;
        } finally {
          this.resetMediaUploadService();
        }
      }
    });

    this.lastUpdatedTimer = setInterval(() => {
      const now = new Date().getTime();
      this.lastUpdatedMediaUploads.forEach(({ uuid, lastUpdated }) => {
        const allotedTime = (lastUpdated as number) + MAX_MILLIS_SINCE_LAST_UPDATE;
        if (now > allotedTime) {
          const err_msg = {
            service: 'frontend',
            reason: 'stopped receiving updates from server',
          };
          const progressEvent = {
            uuid,
            failed: 1,
            err_msg,
          };
          this.$store.commit(`${MODULE_NAMESPACE}/UPDATE_MEDIA_UPLOAD`, progressEvent);
        }
      });
    }, 1000);
  }

  resetMediaUploadService() {
    try {
      this.unwatch && this.unwatch();
    } catch {
      // Would only fail if unwatch was already reset. Ignore.
    }
    this.unwatch = null;
    this.lastUpdatedTimer && clearInterval(this.lastUpdatedTimer);
    this.$store.commit(`${MODULE_NAMESPACE}/CLEAR_UPLOAD_TYPE`);
    this.$store.commit(`${MODULE_NAMESPACE}/CLEAR_ALL_MEDIA_UPLOADS`);
  }
}
