import type { PaymentProfile } from '@/types/ecomm';
import moment from 'moment-timezone';

export * from '@/utils/ecomm/chargify';

/**
 * Determines whether or not a credit card is expired
 *
 * Note: This function assumes that the credit card expiry is in the timezone of the user's browser,
 * which is not necessarily the case, but is the most likely case. We ultimately rely on
 * the payment processor to determine the actual validity of the credit card.
 *
 * @param {PaymentProfile} paymentProfile a Chargify payment profile
 * @returns {boolean} whether or not the credit card associated with the payment profile is expired
 */
export function isCreditCardExpired(paymentProfile: PaymentProfile) {
  const now = moment().unix();
  const cardExpiration = moment(`${paymentProfile.expiration_year}-${paymentProfile.expiration_month}`, 'YYYY-MM')
    .endOf('month')
    .unix();

  return cardExpiration < now;
}
