
import Button from '@/components/common/buttons/Button.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Input from '@/components/common/form/Input.vue';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import { formatDateTime } from '@/createandpublish/core/filters';
import NativePostsService from '@/services/per-post/NativePostsService';
import Loading from '@/components/common/Loading.vue';
import { Getter } from 'vuex-class';

@Component({
  components: { Button, Input, Loading },
})
export default class ShareMetricsModal extends Vue {
  @Getter authenticatedUser;

  @Prop({ type: Boolean, required: true }) show!: boolean;
  @Prop({ type: Object }) post!: AudiencePost;
  @Prop({ type: File, required: false }) file?: File | undefined;

  form = {
    recipientEmail: '',
    subject: '',
    content: ``,
  };
  loading = false;

  get canSend() {
    return this.form.recipientEmail !== '' && this.form.subject !== '' && !this.loading;
  }

  handleInputChange(e) {
    this.form[e.target.name] = e.target.value;
  }

  close() {
    this.$emit('close');
  }

  async share() {
    this.loading = true;
    try {
      if (this.file) {
        await new NativePostsService().sharePostMetrics({
          content: this.form.content,
          subject: this.form.subject,
          recipientEmail: this.form.recipientEmail,
          file: this.file,
        });
      }
      this.$toast.success('Metrics shared successfully');

      this.loading = false;
      this.close();
    } catch (err) {
      this.$toast.error('Something went wrong, please try again');
      this.loading = false;
    }
  }

  download() {
    if (this.file) {
      const link = document.createElement('a');
      const url = URL.createObjectURL(this.file);

      link.href = url;
      link.download = this.file.name;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }

  mounted() {
    if (this.post) {
      const userFullName = this.authenticatedUser
        ? `${this.authenticatedUser.firstName} ${this.authenticatedUser.lastName} - `
        : '';
      this.form.subject = userFullName + 'Campaign Post Performance';

      const postedOn = formatDateTime(new Date(this.post.dateCreated).getTime() / 1000);

      this.form.content = `Posted on ${postedOn}.
Link - ${this.post?.postUrl}`;
    }
  }
}
