
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'TagsInput',
})
export default class TagsInput extends Vue {
  @Prop({ type: Array, required: true }) value!: string[];
  @Prop({ type: String, required: false, default: 'Add tags' }) label!: string;
  @Prop({ type: String, required: false, default: 'Add tags, one at a time' }) assistiveText!: string;

  tagInput = '';

  get modelledValue() {
    return this.value;
  }
  set modelledValue(value) {
    this.$emit('input', value);
  }

  onAddTag() {
    const tag = this.tagInput.trim().replace('#', '');
    if (!tag) return;

    this.modelledValue.push(tag);
    this.tagInput = '';
  }

  removeTagByIndex(index: number) {
    this.modelledValue.splice(index, 1);
  }
}
