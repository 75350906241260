import store from '@/store';
import {
  adminOnly,
  allowAnonymousMeta,
  preventAuthenticatedMeta,
  preventDisabled,
  requiresMultiBrandAccount,
  requiresSingleBrandAccount,
  requiresValidSubscription,
} from '@/constants/router/permissions';
import { hideLayoutMeta } from '@/constants/router/layout';
import type { RouteConfig } from 'vue-router';

const Login = () => import('@/views/Login.vue');

const PasswordHelp = () => import('@/views/PasswordHelp.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPassword.vue');
const UserConfirmationNewPassword = () => import('@/views/auth/UserConfirmationNewPassword.vue');

const Checkout = () => import('@/views/ecomm/CheckoutNew.vue');

const DiscoverSetup = () => import('@/views/DiscoverSetup.vue');

const ManageBrands = () => import(/* webpackChunkName: "account" */ '@/views/ManageBrands.vue');
const ManageCampaigns = () => import(/* webpackChunkName: "account" */ '@/views/ManageCampaigns.vue');
const MyProfile = () => import(/* webpackChunkName: "account" */ '@/views/MyProfile.vue');
const Users = () => import(/* webpackChunkName: "account" */ '@/views/Users.vue');

const Subscriptions = () => import(/* webpackChunkName: "subscriptions" */ '@/views/Subscriptions.vue');
const ManageSubscriptions = () => import(/* webpackChunkName: "subscriptions" */ '@/views/ManageSubscriptions.vue');
const AddPaymentMethod = () => import(/* webpackChunkName: "subscriptions" */ '@/views/ecomm/AddPaymentMethod.vue');

const ConnectSocials = () => import('@/views/connect-socials/ConnectSocials.vue');

const JobsControl = () => import('@/views/JobsControl.vue');

const NotFound = () => import('@/views/NotFound.vue');

export default [
  {
    path: '/',
    name: 'LandingPage',
    beforeEnter: async (to, from, next) => {
      next('/discover');
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta, title: 'Log In' },
  },
  {
    path: '/checkout/login',
    name: 'Subscription Login',
    component: Login,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta, title: 'Log In', flow: 'checkout' },
  },
  {
    path: '/audience/login',
    name: 'Audience Login',
    component: Login,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta, title: 'Log In', flow: 'audience' },
  },
  // Forgot password
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
  },
  // User confirm new password (user activation)
  {
    path: '/confirmation',
    name: 'Confirm New Password',
    component: UserConfirmationNewPassword,
    meta: { ...allowAnonymousMeta, ...hideLayoutMeta },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('logOut'); // Log user out if resetting password
      next();
    },
  },
  // Checkout process
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta },
  },
  // Setup proccess
  {
    path: '/setup',
    name: 'Setup',
    component: DiscoverSetup,
    meta: { ...hideLayoutMeta },
  },
  // Checkout process free trial
  {
    path: '/checkout/free-trial',
    name: 'Checkout Free Trial',
    redirect: '/checkout',
  },
  // Checkout process audience
  {
    path: '/signupforaudience',
    name: 'Checkout Audience',
    redirect: '/checkout',
  },
  {
    path: '/profile',
    name: 'Profile',
    component: MyProfile,
    meta: { title: 'Profile' },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchAccount');
      await store.dispatch('currentUser/requestUser').then(() => {
        next();
      });
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: async (_to, _from, next) => {
      const promises: Promise<void>[] = [
        store.dispatch('fetchAccount'),
        store.dispatch('fetchBrands'),
        store.dispatch('fetchUsers'),
      ];
      try {
        await Promise.all(promises);
      } catch (err) {
        console.error('Some data for users route may not be available.', err);
      }
      next();
    },
    meta: { ...requiresValidSubscription, ...adminOnly, ...preventDisabled, title: 'Manage Users' },
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: { ...adminOnly, ...preventDisabled, title: 'Manage Subscriptions', feature: 'ecomm' },
  },
  {
    path: '/subscriptions/add-payment',
    name: 'Subscription.AddPaymentMethod',
    component: AddPaymentMethod,
    meta: { ...adminOnly, ...preventDisabled, title: 'Add Payment Method' },
  },
  {
    path: '/subscriptions/management',
    name: 'Manage Subscription',
    component: ManageSubscriptions,
    meta: { ...adminOnly, ...preventDisabled, title: 'Manage Subscriptions', feature: 'ecomm' },
  },
  {
    path: '/subscriptions/billing',
    name: 'Billing',
    component: ManageSubscriptions,
    meta: { ...adminOnly, ...preventDisabled, title: 'Manage Billing', feature: 'ecomm' },
  },
  {
    path: '/subscriptions/billing-history',
    name: 'Billing History',
    component: ManageSubscriptions,
    meta: { ...adminOnly, ...preventDisabled, title: 'Billing History', feature: 'ecomm' },
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: ManageCampaigns,
    meta: {
      ...requiresValidSubscription,
      ...requiresSingleBrandAccount,
      ...adminOnly,
      ...preventDisabled,
      title: 'Manage Campaigns',
      feature: 'campaigns',
    },
  },
  {
    path: '/brands',
    name: 'Brands',
    component: ManageBrands,
    meta: {
      ...requiresValidSubscription,
      ...adminOnly,
      ...requiresMultiBrandAccount,
      ...preventDisabled,
      // might need to make this title dynamic if campaign feature is turned off
      title: 'Manage Brands & Campaigns',
    },
  },
  {
    path: '/account-overview',
    name: 'Account Overview',
    component: NotFound,
    meta: { title: 'Account Overview' },
  },

  {
    path: '/reset-password',
    name: 'Password Help',
    component: PasswordHelp,
    meta: { ...allowAnonymousMeta, ...hideLayoutMeta, title: 'Password Help' },
    beforeEnter: (to, from, next) => {
      store.dispatch('logOut'); // Log user out if resetting password
      next();
    },
  },
  {
    path: '/jobs-control',
    name: 'JobsControl',
    component: JobsControl,
    meta: {
      ...requiresValidSubscription,
      ...adminOnly,
      title: 'Jobs Control | Hidden route',
    },
  },
  {
    path: '/connect-socials',
    name: 'ConnectSocials',
    component: ConnectSocials,
    meta: {
      ...requiresValidSubscription,
      title: 'Connect Socials',
    },
  },
] as RouteConfig[];
