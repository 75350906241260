
import { ActiveSocialAccount, AyrshareProfile } from '@/types/audience';
import { Component, Vue, Prop } from 'vue-property-decorator';
import NoImagePlaceholder from '@/assets/no-image.png';
import { SocialPostBody } from '@/types/createandpublish/socialStory';
import { busEvents, eventBus } from '@/createandpublish/core/eventBus/socialPostEventBus';

@Component({
  name: 'PinterestPreview',
  components: {},
})
export default class PinterestPreview extends Vue {
  @Prop({ type: Object, required: false }) platformProfileData!: ActiveSocialAccount | null;
  @Prop({ type: Object, required: false }) audienceProfileData!: AyrshareProfile | null;
  @Prop({ type: Object, required: true }) currentPlatformPostDetails!: SocialPostBody;

  currentPostDetails: SocialPostBody | null = null;

  get pinterestPostDetails() {
    return this.currentPostDetails ? (this.currentPostDetails as SocialPostBody) : null;
  }

  updateDraftPostDetails(postContent: SocialPostBody | undefined) {
    if (postContent?.mainRequest?.platforms?.includes('pinterest')) {
      this.currentPostDetails = postContent;
    }
  }

  get profileImage() {
    return this.audienceProfileData?.image;
  }

  get profileName() {
    return this.platformProfileData?.platformDisplayName;
  }

  get postLink() {
    const link = this.currentPostDetails?.platformOptions?.pinterestOptions?.link;
    if (link) {
      const linkContent = link.split('://')[1];
      const linkArr = linkContent.split('.');
      let finalLink;
      if (linkArr.length < 3) {
        finalLink = linkArr.join('.');
      } else {
        finalLink = linkArr.slice(1).join('.');
      }
      return finalLink.length > 17 ? `${finalLink.slice(0, 17)}...` : finalLink;
    }
    return null;
  }

  get pinTitle() {
    return this.currentPostDetails?.platformOptions?.pinterestOptions?.title;
  }

  get postImage() {
    if (!this.currentPostDetails?.mainRequest?.mediaUrls?.length) {
      return null;
    }
    if (this.currentPostDetails?.mainRequest?.isVideo) {
      return this.currentPostDetails?.mainRequest?.videoThumbnailUrl;
    }
    return this.currentPostDetails?.mainRequest?.mediaUrls?.length
      ? this.currentPostDetails?.mainRequest.mediaUrls[0]
      : NoImagePlaceholder;
  }

  // TODO - this is not currently in use, but will be important
  get platformPostOptions() {
    return this.currentPostDetails?.platformOptions?.pinterestOptions;
  }

  mounted() {
    this.updateDraftPostDetails(this.currentPlatformPostDetails);
    eventBus.$on(busEvents.UPDATE_DRAFT_POST_DETAILS, this.updateDraftPostDetails);
  }
}
