
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ProfileDropdown from './header/profileDropdown.vue';
import MobileProfileMenu from '@/components/MobileProfileMenu.vue';
import TrialExpirationAlert from '@/components/common/alerts/trialExpirationAlert/index.vue';
import ChatbotSearch from './chatbot/ChatbotSearch.vue';
import { openHireCreatorPlatform } from '@/utils/jobs/jobs';

@Component({
  components: {
    ProfileDropdown,
    MobileProfileMenu,
    TrialExpirationAlert,
    ChatbotSearch,
  },
  data() {
    return {
      notificationCount: 999,
    };
  },
})
export default class PortalHeader extends Vue {
  @Prop({ type: Boolean, required: true }) isAudienceRoute!: boolean;
  @Getter authenticatedUser;
  @Getter showSidebar!: boolean;
  @Getter selectedBrand;
  @Getter isTrial;

  isCreateAndPublishEnabled = false;

  async created() {
    this.isCreateAndPublishEnabled = await this.$featureFlags.getCreateAndPublishFlag();
  }

  get audienceLink() {
    return this.$route.path.search('audience') === 1;
  }

  get discoverLink() {
    return this.$route.path.search('discover') === 1 && this.$route.path.search('community') === 0;
  }

  get createLink() {
    return this.$route.path.search('create') === 1;
  }

  get communityLink() {
    return this.$route.path.search('community') === 1;
  }

  get currentUserInitials() {
    const currentUser = this.authenticatedUser;
    return currentUser ? currentUser.firstName[0] + currentUser.lastName[0] : '';
  }

  get selectedBrandId() {
    return this.selectedBrand?.id;
  }

  toggleProfileMenu() {
    this.$root.$emit('bv::toggle::collapse', 'profile-menu');
  }

  goToJobsPlatform() {
    openHireCreatorPlatform();
  }
}
