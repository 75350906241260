
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Loading from '@/components/common/Loading.vue';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'Connect',
  components: { Loading },
})
export default class ConnectSocial extends Vue {
  @audienceModule.Action getAyrShareConnectUrl;

  loading = false;

  async onConnect() {
    this.loading = true;
    try {
      this.$router.push('/connect-socials');
    } catch (e) {
      this.loading = false;
      console.error(e);
    }
  }
}
