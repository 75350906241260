
import BaseDoughnutChart from '@/audience/components/base-charts/BaseDoughnutChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { annulusSectorImagePlugin, doughnutHolePlugin } from '@/audience/data/chart-plugins';
import { PlatformColors, PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import moment from 'moment-timezone';

import FacebookIcon from '@/assets/audience/leaderboard-bar-chart/logo-facebook.svg';
import InstagramIcon from '@/assets/audience/leaderboard-bar-chart/logo-instagram.svg';
import LinkedInIcon from '@/assets/audience/leaderboard-bar-chart/logo-linkedin.svg';
import PinterestIcon from '@/assets/audience/leaderboard-bar-chart/logo-pinterest.svg';
import TikTokIcon from '@/assets/audience/leaderboard-bar-chart/logo-tiktok.svg';
import TwitterIcon from '@/assets/audience/leaderboard-bar-chart/logo-twitter.svg';
import YouTubeIcon from '@/assets/audience/leaderboard-bar-chart/logo-youtube.svg';

import type { DoughnutHolePluginOptions } from '@/audience/data/chart-plugins/doughnutHolePlugin';
import type { ActiveSocialAccount, DateRange, TooltipConfig } from '@/types/audience';
import type { ChartData, ChartOptions } from 'chart.js';
import { DateAnalytics } from './types/Impact';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'DoughnutChart',
  components: {
    BaseDoughnutChart,
    ChartTooltip,
    NoChartDataBanner,
  },
})
export default class DoughnutChart extends Vue {
  @Prop({ required: true }) context!: 'audience' | 'engagement' | 'impressions';
  @Prop({ required: true }) dateRange!: DateRange;
  @Prop({ required: true }) socialAnalytics!: DateAnalytics;
  @Prop({ required: false, default: 400 }) width?: number;
  @Prop({ required: false, default: 450 }) height?: number;
  @Prop({ required: false, default: false }) empty?: boolean;

  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];

  chartKey = 0;

  @Watch('canDisplayChart')
  async onChartDisplayChange() {
    await this.$nextTick();
    // Chart doesn't always render plugins when they're conditionally added based on data.
    // Keying the component fixes that by forcing it to remount.
    this.chartKey += 1;
  }

  get chartConfig() {
    const plugins = this.canDisplayChart ? [doughnutHolePlugin, annulusSectorImagePlugin] : [];
    return {
      chartId: 'doughnut-chart',
      datasetIdKey: 'doughnut-chart',
      plugins: [...plugins],
      cssClasses: '',
      styles: {
        width: `${this.width}px`,
        height: `${this.height}px`,
      },
    };
  }

  get chartOptions(): ChartOptions {
    const doughnutHolePlugin = this.canDisplayChart ? { doughnutHolePlugin: this.doughnutHolePluginOptions } : {};
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {},
      onHover: (event, activeElements) => {
        if (activeElements.length > 0) {
          // this.hoveredPlatform = this.chartData.labels[activeElements[0].index];
          this.$emit('hoverStateChange', this.chartData.labels[activeElements[0].index].toLowerCase());
        } else {
          // this.hoveredPlatform = null;
          this.$emit('hoverStateChange', 'all');
        }
      },
      onClick: (event, activeElements) => {
        if (activeElements.length > 0) {
          // this.clickedPlatform = this.chartData.labels[activeElements[0].index];
          this.$emit('clickStateChange', this.chartData.labels[activeElements[0].index].toLowerCase());
        } else {
          // this.clickedPlatform = null;
          this.$emit('clickStateChange', 'all');
        }
      },
      plugins: {
        ...doughnutHolePlugin,
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'line'>;
            const {
              dataIndex,
              datasetIndex,
              label: platformLabel,
              dataset: { backgroundColor, borderColor, label: metricLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = moment.tz(this.dateRange.end, this.timeZone).format('MMM DD[,] YYYY').toUpperCase();

            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;

            const value = chartData.datasets[datasetIndex].data[dataIndex] as number;
            const total = this.chartDatasets.end.reduce((acc, val) => {
              if (isNaN(val)) return acc;
              acc += val;
              return acc;
            }, 0);

            const percentOfTotalText = ((value / total) * 100).toFixed(0);

            const primaryBackgroundColor = Array.isArray(backgroundColor) ? backgroundColor[dataIndex] : '#fff';

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            style.left = window.pageXOffset + tooltipModel.caretX - width / 2 + 'px';
            style.top = window.pageYOffset + tooltipModel.caretY - height - 8 + 'px';
            style.pointerEvents = 'none';

            const tooltipConfig: TooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: !!platformLabel,
                iconSrc: this.getIconSrcByPlatform(platformLabel ?? ''),
              },
              topRow: {
                primary: {
                  label: metricLabel || '',
                  value: primaryValueText,
                  backgroundColor: primaryBackgroundColor as string,
                  borderColor: borderColor as string,
                },
                secondary: {
                  label: '% of total',
                  value: percentOfTotalText,
                },
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;
          },
        },
      },
    };
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  get canDisplayChart(): boolean {
    const endTotal = this.chartDatasets.end.reduce((acc, value) => {
      acc += value;
      return acc;
    }, 0);
    return endTotal !== 0;
  }

  get chartLabels(): string[] {
    const activePlatforms = this.activeSocialAccounts.map(({ platform }) => platform) as string[];
    if (activePlatforms.length === 0 && this.empty) return ['Twitter', 'YouTube'];
    return Object.values(PlatformLabels).filter((label) => activePlatforms.includes(label.toLowerCase()));
  }

  get chartBackgroundColors(): string[] {
    return this.chartLabels.map((label) => PlatformColors[label.toUpperCase()]);
  }

  get startAndEndTotals(): StartAndEndTotals[] {
    const startDate = moment.tz(this.dateRange.start, this.timeZone).format('YYYY-MM-DD');
    const endDate = moment.tz(this.dateRange.end, this.timeZone).format('YYYY-MM-DD');

    return this.chartLabels.map((label) => {
      return {
        start: {
          audience: this.socialAnalytics[startDate]?.[label] || 0,
          impressions: this.socialAnalytics[startDate]?.[label] || 0,
          engagement: this.socialAnalytics[startDate]?.[label] || 0,
        },
        end: {
          audience: this.socialAnalytics[endDate]?.[label] || 0,
          impressions: this.socialAnalytics[endDate]?.[label] || 0,
          engagement: this.socialAnalytics[endDate]?.[label] || 0,
        },
      };
    });
  }

  get chartDatasets(): Record<string, number[]> {
    switch (this.context) {
      case 'audience':
        return {
          start: this.startAndEndTotals.map(({ start }) => start.audience),
          end: this.startAndEndTotals.map(({ end }) => end.audience),
        };
      case 'impressions':
        return {
          start: this.startAndEndTotals.map(({ start }) => start.impressions),
          end: this.startAndEndTotals.map(({ end }) => end.impressions),
        };
      case 'engagement':
        return {
          start: this.startAndEndTotals.map(({ start }) => start.engagement),
          end: this.startAndEndTotals.map(({ end }) => end.engagement),
        };
      default:
        return {
          start: [],
          end: [],
        };
    }
  }

  get doughnutHolePluginOptions(): DoughnutHolePluginOptions {
    const startTotal = this.chartDatasets.start.reduce((acc, value) => {
      acc += value;
      return acc;
    }, 0);
    const endTotal = this.chartDatasets.end.reduce((acc, value) => {
      acc += value;
      return acc;
    }, 0);
    const getPercentChange = () => {
      if (startTotal === 0) return 0;
      return (endTotal - startTotal) / parseFloat(Math.abs(startTotal).toString());
    };
    const getNumericChange = () => {
      return endTotal - startTotal;
    };

    return {
      label: `Total ${this.context}`,
      value: endTotal,
      percentChange: getPercentChange(),
      numericChange: getNumericChange(),
    };
  }

  get chartData() {
    return {
      labels: this.chartLabels,
      datasets: [
        {
          label: `Total ${this.context}`,
          backgroundColor: this.chartBackgroundColors,
          data: this.chartDatasets.end,
        },
      ],
    };
  }

  getIconSrcByPlatform(platformLabel: string) {
    switch (platformLabel) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      default:
        return '';
    }
  }

  hexToRgbA(hex: string) {
    let c: string[];
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      const d = parseInt('0x' + c.join(''));
      return 'rgba(' + [(d >> 16) & 255, (d >> 8) & 255, d & 255].join(',') + ',0.4)';
    }
    throw new Error('Bad Hex');
  }
}

interface StartAndEndTotals {
  start: TotalGroup;
  end: TotalGroup;
}
interface TotalGroup {
  audience: number;
  impressions: number;
  engagement: number;
}
