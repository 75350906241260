import type {
  ComponentData,
  ComponentRequest,
  PaymentProfile,
  PlanData,
  PlansData,
  PriceData,
  PricePointsData,
  ProductData,
} from '@/types/ecomm';
// Enums
import { ProductKey, ProductHandle, ComponentHandle, PricePointHandle } from '@/types/ecomm';
import store from '@/store';

import moment from 'moment-timezone';

export const FREE_UNIT_PRICE = '0.0';
export const CURRENCY = 'USD';

export const currencyFormatOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: CURRENCY,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const numberFormatOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
  useGrouping: false,
};

/**
 * Gets a Chargify Price Point via Component and PricePoint handles
 * @param plans Chargify product and price data
 * @param productKey Record key used by the CC API to group Chargify product and price data
 * @param componentHandle Chargify component handle
 * @param pricePointHandle Chargify price point handle
 * @returns Chargify price point
 */
export const getPricePointByHandles = (
  plans: PlansData,
  productKey: ProductKey,
  componentHandle: ComponentHandle,
  pricePointHandle: PricePointHandle
): PricePointsData | null => {
  return (
    plans?.[productKey]?.components
      ?.find((component) => component.handle === componentHandle)
      ?.pricePoints?.find((pricePoint) => pricePoint.handle === pricePointHandle) ?? null
  );
};

/**
 * Gets a Chargify Price Point via Component and PricePoint IDs
 * @param plans Chargify product and price data
 * @param productKey Record key used by the CC API to group Chargify product and price data
 * @param componentId Chargify component id
 * @param pricePointId Chargify price point id
 * @returns Chargify price point
 */
export const getPricePointByIds = (
  plans: PlansData,
  productKey: ProductKey,
  componentId: ComponentData['id'],
  pricePointId: PricePointsData['id']
): PricePointsData | null => {
  return (
    plans?.[productKey]?.components
      ?.find((component) => component.id === componentId)
      ?.pricePoints?.find((pricePoint) => pricePoint.id === pricePointId) ?? null
  );
};

/**
 * Gets a Chargify Price
 * @param pricePoint Chargify price point
 * @param quantity quantity of item represented by the price point
 * @returns Chargify price
 */
export const getPriceByQuantity = (pricePoint: PricePointsData, quantity: number): PriceData | null => {
  return (
    pricePoint.prices.find((price) => {
      const { startingQuantity, endingQuantity } = price;
      if (!endingQuantity) {
        // If endingQuantity is null, there is only one price option
        // i.e. it is a stepped (flat rate), as opposed to tiered, price
        return true;
      }

      return quantity >= startingQuantity && quantity <= endingQuantity;
    }) ?? null
  );
};

/**
 * Gets a Chargify Price by its ID
 * @param pricePoint Chargify price point
 * @param id Chargify price ID
 * @returns Chargify price
 */
export const getPriceById = (pricePoint: PricePointsData, id: PriceData['id']): PriceData | undefined => {
  return pricePoint.prices.find((pricePoint) => pricePoint.id === id);
};

/**
 * Determine if a Chargify component's price point is tiered or stepped (flat price per unit)
 * @param pricePoint Chargify price point
 * @returns true if component's price is tiered (priced based on quantity)
 */
export const isPricePointTiered = (pricePoint: PricePointsData): boolean => {
  const { prices } = pricePoint;
  const isFlatPrice = prices.length === 1 && prices[0].endingQuantity === null;
  return !isFlatPrice;
};

/**
 * Gets a Chargify Price Point handle by Chargify product handle
 * @param productHandle Chargify product handle
 * @returns Chargify price point handle
 */
export const getPricePointHandle = (productHandle: ProductHandle): PricePointHandle | undefined => {
  const {
    DISCOVER_MONTHLY,
    CREATE_MONTHLY,
    SUITE_MONTHLY,
    AUDIENCE,
    FULL_SUITE_FREE_TRIAL,
    DAILY_TEST_PRODUCT,
    CYBER_MONDAY_PRODUCT,
  } = ProductHandle;
  switch (productHandle) {
    case DISCOVER_MONTHLY:
    case CREATE_MONTHLY:
    case AUDIENCE:
      return PricePointHandle.MONTHLY;
    case SUITE_MONTHLY:
      //SUITE_MONTHLY used the old price points, Monthly has correct price points
      return PricePointHandle.MONTHLY;
    //return PricePointHandle.SUITE_MONTHLY;
    case FULL_SUITE_FREE_TRIAL:
      return PricePointHandle.FREE_TRIAL;
    case DAILY_TEST_PRODUCT:
      return PricePointHandle.DAILY_TEST_PRICE;
    case CYBER_MONDAY_PRODUCT:
      return PricePointHandle.ANNUAL;
    default:
      return undefined;
  }
};

/**
 * Gets a Chargify Product
 * @param plans Chargify product and price data
 * @param productHandle Chargify product handle
 * @returns Chargify product
 */
export const getProduct = (plans: PlansData, productHandle: ProductHandle): Partial<ProductData> | undefined => {
  let key: ProductKey | undefined;

  switch (productHandle) {
    case ProductHandle.DISCOVER_MONTHLY:
      key = ProductKey.DISCOVER;
      break;
    case ProductHandle.CREATE_MONTHLY:
      key = ProductKey.CREATE_AND_PUBLISH;
      break;
    case ProductHandle.SUITE_MONTHLY:
      key = ProductKey.SUITE;
      break;
    case ProductHandle.AUDIENCE:
      key = ProductKey.AUDIENCE;
      break;
    default:
      break;
  }

  if (!key) return undefined;

  return plans?.[key]?.products.find((product) => product.handle === productHandle);
};

export const getAllComponentIds = (plans: PlansData): ComponentData['id'][] => {
  const componentIds: Set<ComponentData['id']> = new Set();
  Object.values(plans).map((planData: PlanData) => {
    planData.components.forEach(({ id }) => {
      componentIds.add(id);
    });
  });
  return Array.from(componentIds);
};

/**
 * Get the total cost of a specific component
 * @param pricePoint Chargify price point to use
 * @param quantity component quantity
 * @returns price
 * @throws {Error} if no such price/quantity combination exists
 */
const getComponentTotalCost = (pricePoint: PricePointsData, quantity: number): string => {
  const unitPrice = getPriceByQuantity(pricePoint, quantity)?.unitPrice;
  if (!unitPrice) {
    throw new Error(`[getComponentTotalCost] Price Point and quantity did not match any known price`);
  }
  if (isPricePointTiered(pricePoint)) {
    return unitPrice;
  }

  const totalCost = new Intl.NumberFormat(undefined, numberFormatOptions).format(Number(unitPrice) * quantity);
  return totalCost;
};

export const formatCurrency = (value: number | bigint) => {
  return new Intl.NumberFormat(undefined, currencyFormatOptions).format(value);
};

/**
 *
 * @param {PlansData} plans Chargify plans
 * @param {SubscriptionData} subscriptionData
 * @param {Boolean} useCurrencyFormat format as currency, or just as a number
 * @returns total price based on subscriptionData
 * @throws {Error} if plans doesn't include data specified in the subscriptionData
 */
export const getTotalPrice = (
  plans: PlansData,
  subscriptionData: SubscriptionData,
  useCurrencyFormat = true
): string => {
  const { productKey } = subscriptionData;

  const components = getComponentsPayload(plans, subscriptionData);

  const costs = components.map(({ componentId, qty, pricePointId }) => {
    const pricePoint = getPricePointByIds(plans, productKey, componentId, pricePointId) as PricePointsData;
    return getComponentTotalCost(pricePoint, qty);
  });

  const costReducer: (acc: number, cost: string) => number = (acc, cost) => {
    acc += Number(cost);
    return acc;
  };

  const formatOptions = useCurrencyFormat ? currencyFormatOptions : numberFormatOptions;
  const total = new Intl.NumberFormat(undefined, formatOptions).format(costs.reduce(costReducer, 0));
  return total;
};

export const getCurrentPrice = (useCurrencyFormat = true): string => {
  const pricePoint = store.getters['EcommStore/currentPrice'];
  const currentPrice = pricePoint?.prices?.[0]?.unit_price;
  const formatOptions = useCurrencyFormat ? currencyFormatOptions : numberFormatOptions;
  const finalPrice = new Intl.NumberFormat(undefined, formatOptions).format(currentPrice);
  return finalPrice;
};

/**
 * Generates subscription payload to send to API
 * @param plans Chargify plans
 * @param subscriptionData
 * @returns payload for submitting a subscription
 * @throws {Error} if plans doesn't include data specified in the subscriptionData
 */
export const getSubscriptionPayload = (
  plans: PlansData,
  subscriptionData: SubscriptionData
): { productId: number; components: ComponentRequest[] } => {
  const { productHandle } = subscriptionData;

  const productId = getProduct(plans, productHandle)?.id;
  if (!productId) {
    throw new Error(`[getSubscriptionPayload] Product key and billing interval did not match any known product`);
  }
  const components = getComponentsPayload(plans, subscriptionData);

  return {
    productId,
    components,
  };
};

/**
 * Gets components part of the payload for submitting a request to create/modify a subscription
 * @param plans Chargify plans
 * @param subscriptionData
 * @returns component data payload
 * @throws {Error} if plans doesn't include data specified in the subscriptionData
 */
export const getComponentsPayload = (plans: PlansData, subscriptionData: SubscriptionData): ComponentRequest[] => {
  const {
    productKey,
    productHandle,
    audienceSeatQuantity,
    suiteSeatQuantity,
    audienceBrandQuantity,
    suiteBrandQuantity,
  } = subscriptionData;

  const pricePointFactory = (componentHandle: ComponentHandle): PricePointsData | null => {
    const pricePointHandle = getPricePointHandle(productHandle);
    if (!pricePointHandle) return null;

    const exceptions = [ComponentHandle.AUDIENCE_SEATS, ComponentHandle.AUDIENCE_BRANDS];
    if (exceptions.includes(componentHandle)) {
      // Exception to the rule. There is no suite_monthly price point for Audience components.
      return getPricePointByHandles(plans, productKey, componentHandle, PricePointHandle.MONTHLY);
    }

    return getPricePointByHandles(plans, productKey, componentHandle, pricePointHandle);
  };

  const res = plans[productKey]?.components.map(({ id: componentId, handle }) => {
    const pricePointId = pricePointFactory(handle as ComponentHandle)?.id;
    if (!pricePointId) {
      throw new Error(
        `[getComponentsPayload] Received component handle '${handle}' that didn't match any known PricePoint`
      );
    }
    let qty = 0;

    switch (handle) {
      case ComponentHandle.AUDIENCE_SEATS:
        qty = audienceSeatQuantity;
        break;
      case ComponentHandle.AUDIENCE_BRANDS:
        qty = audienceBrandQuantity;
        break;
      case ComponentHandle.SUITE_SEATS:
        qty = suiteSeatQuantity;
        break;
      case ComponentHandle.SUITE_BRANDS:
        qty = suiteBrandQuantity;
        break;
      case ComponentHandle.TEST_SUITE_SEATS:
        qty = suiteSeatQuantity;
        break;
      default:
        break;
    }
    return {
      componentId,
      qty,
      pricePointId,
    };
  });

  return res;
};

export const isCreditCardExpired = (paymentProfile: PaymentProfile) => {
  const { expiration_month, expiration_year } = paymentProfile;

  const thisMonth = moment(new Date()).endOf('month').unix;
  const cardExpiration = moment(new Date(expiration_year, expiration_month - 1, 1)).endOf('month').unix;

  return cardExpiration > thisMonth;
};

export interface SubscriptionData {
  productKey: ProductKey;
  productHandle: ProductHandle;
  audienceSeatQuantity: number;
  audienceBrandQuantity: number;
  suiteSeatQuantity: number;
  suiteBrandQuantity: number;
}
