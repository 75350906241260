
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PostItem from './PostItem.vue';

@Component({
  components: { PostItem },
})
export default class Posts extends Vue {
  @Prop({ type: Array }) posts!: AudiencePost[];

  selectedPostIndex = -1;

  getPostDate(post: AudiencePost) {
    return new Date(post.dateCreated);
  }

  onPostClick(postIndex: number) {
    this.selectedPostIndex = postIndex;
    this.$emit('post-select', postIndex);
  }
}
