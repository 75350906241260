import type { Plugin, BarProps, Chart } from 'chart.js';
import { getMemoizedImage } from '@/audience/data/chart-plugins/util';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';

import YouTubeIcon from '@/assets/audience/leaderboard-bar-chart/logo-youtube.svg';
import FacebookIcon from '@/assets/audience/leaderboard-bar-chart/logo-facebook.svg';
import InstagramIcon from '@/assets/audience/leaderboard-bar-chart/logo-instagram.svg';
import LinkedInIcon from '@/assets/audience/leaderboard-bar-chart/logo-linkedin.svg';
import PinterestIcon from '@/assets/audience/leaderboard-bar-chart/logo-pinterest.svg';
import TikTokIcon from '@/assets/audience/leaderboard-bar-chart/logo-tiktok.svg';
import TwitterIcon from '@/assets/audience/leaderboard-bar-chart/logo-twitter.svg';

import StatIncreaseIcon from '@/assets/audience/arrow-up-icon.svg';
import StatDecreaseIcon from '@/assets/audience/arrow-down-icon.svg';

import type { OnlyOneOf } from '@/types/helpers';

const getYouTubeIcon = getMemoizedImage(YouTubeIcon);
const getFacebookIcon = getMemoizedImage(FacebookIcon);
const getInstagramIcon = getMemoizedImage(InstagramIcon);
const getLinkedInIcon = getMemoizedImage(LinkedInIcon);
const getPinterestIcon = getMemoizedImage(PinterestIcon);
const getTikTokIcon = getMemoizedImage(TikTokIcon);
const getTwitterIcon = getMemoizedImage(TwitterIcon);

const getStatIncreaseIcon = getMemoizedImage(StatIncreaseIcon);
const getStatDecreaseIcon = getMemoizedImage(StatDecreaseIcon);
const getStatIncreaseIconSmall = getMemoizedImage(StatIncreaseIcon, 16, 16);
const getStatDecreaseIconSmall = getMemoizedImage(StatDecreaseIcon, 16, 16);

const barGroupLabelPlugin: Plugin = {
  id: 'barGroupLabelPlugin',
  afterDraw: (chart, _args, options) => {
    const { ctx } = chart;

    const startDatasetMeta = chart.getDatasetMeta(0);
    const barProps = startDatasetMeta.data as unknown as BarProps[];

    if ('statLabels' in options) {
      const { stats } = (options as unknown as BarGroupLabelPluginOptions).statLabels as StatLabelContext;
      if (barProps.length !== stats.length) return;

      const shrinkFont = stats.length > 3;
      const longestLabel = stats.reduce((acc, stat) => (stat.label.length > acc.length ? stat.label : acc), '');
      const labelFontSize = shrinkFont ? 12 : 14;
      const { maxLabelHeight, maxLabelWidth, labelAngleOfRotation } = ChartPluginHelpers.getLabelRotationContext(
        longestLabel,
        labelFontSize,
        barProps.length,
        chart
      );
      const shrinkElements = labelAngleOfRotation > 0;

      stats.forEach(({ label, value, percentChange, numericChange }, i) => {
        const { x, y, height } = barProps[i];
        // Draw value
        ctx.save();
        const valueFontSize = shrinkElements ? 16 : 20;
        ctx.font = `700 ${valueFontSize}px/28px Roboto`;
        ctx.fillStyle = '#222046';
        ctx.textAlign = 'center';

        const valueX = x + 12;
        const valueY = y + height + 16 + valueFontSize; // 16 = margin
        const valueText = ChartPluginHelpers.formatValue(value);
        ctx.fillText(valueText, valueX, valueY);
        ctx.restore();

        // Draw percentChange and icon
        const drawIconAndPercentChange = (xOffset: number, yOffset: number) => {
          const percentChangeText = ChartPluginHelpers.formatPercentChange(percentChange);
          const numericChangeText = ChartPluginHelpers.formatValue(numericChange);
          const changeText = label.toLowerCase() === 'followers' ? percentChangeText : numericChangeText;

          ctx.save();
          const percentTextFontSize = shrinkElements ? 12 : 20;
          const percentTextLineHeight = 20;
          ctx.font = `400 ${percentTextFontSize}px/${percentTextLineHeight}px Roboto`;
          const percentChangeTextWidth = ctx.measureText(changeText).width;
          const fillColor = Math.sign(percentChange) !== -1 ? '#30780C' : '#CB2610';
          ctx.fillStyle = fillColor;
          ctx.textAlign = 'left';
          ctx.textBaseline = 'top';

          // If shrinkElements is true, stack the percentChange value and icon instead of placing them side-by-side
          const imagePosX = shrinkElements ? xOffset : xOffset - percentChangeTextWidth / 2;
          const imagePosY = shrinkElements ? yOffset + percentTextLineHeight : yOffset + 8;
          const imageWidth = shrinkElements ? 16 : 25;

          const percentChangeX = shrinkElements ? xOffset : imagePosX + imageWidth + 4; // 4 = margin
          const percentChangeYOffset = shrinkElements ? 5 : 6;
          const percentChangeY = shrinkElements ? yOffset + 8 : imagePosY + percentChangeYOffset; // offset to help align icon and text

          ctx.fillText(changeText, percentChangeX, percentChangeY);
          ctx.restore();

          let getIcon;
          if (Math.sign(percentChange) !== -1) {
            getIcon = shrinkElements ? getStatIncreaseIconSmall : getStatIncreaseIcon;
          } else {
            getIcon = shrinkElements ? getStatDecreaseIconSmall : getStatDecreaseIcon;
          }

          getIcon().then((image) => {
            ctx.drawImage(image, imagePosX, imagePosY, image.width, image.height);
          });
        };

        if (labelAngleOfRotation > 0) {
          drawIconAndPercentChange(valueX - 10, valueY);
        }

        // Draw label
        ctx.save();
        const labelFontSize = shrinkElements ? 12 : 14;
        const labelLineHeight = 21;
        ctx.font = `700 ${labelFontSize}px/${labelLineHeight}px Roboto`;
        ctx.fillStyle = '#222046';
        ctx.textAlign = 'center';

        const labelText = label;
        let labelX = x + 12;
        let labelY = valueY + 20;

        if (labelAngleOfRotation > 0) {
          labelX = x + 10 + Math.min(maxLabelWidth, 48);
          labelY = valueY + 30 + Math.max(maxLabelHeight / 2, 45);
          ctx.textAlign = 'right';
          const translateX = labelX - 48;
          const translateY = labelY + labelLineHeight;
          ctx.translate(translateX, translateY);
          ctx.rotate(labelAngleOfRotation * -1);
          ctx.translate(translateX * -1, translateY * -1);
        }
        ctx.fillText(labelText, labelX, labelY);
        ctx.restore();

        if (labelAngleOfRotation === 0) {
          drawIconAndPercentChange(labelX - 14, labelY);
        }
      });

      const layout = chart.config.options?.layout;
      if (layout) {
        const bottomPadding = shrinkElements ? 175 : 90;
        layout.padding = {
          bottom: bottomPadding,
        };
        chart.update('resize');
      }
    }
    if ('briefStatLabels' in options) {
      const { stats } = (options as unknown as BarGroupLabelPluginOptions).briefStatLabels as BriefStatLabelContext;
      if (barProps.length !== stats.length) return;

      stats.forEach(({ label, percentChange }, i) => {
        const { x, y, height } = barProps[i];

        // Draw label
        ctx.save();
        ctx.font = '400 12px/12px Roboto';
        ctx.fillStyle = '#6E6E7A';
        ctx.textAlign = 'center';

        const labelX = x + 12;
        const labelY = y + height + 16 + 12; // 16 = margin, 12 = font-size of valueText
        const labelText = label;

        ctx.fillText(labelText, labelX, labelY);
        ctx.restore();

        // Draw percentChange and icon
        const percentChangeText = ChartPluginHelpers.formatPercentChange(percentChange);

        ctx.save();
        ctx.font = '400 12px/21px Roboto';
        const percentChangeTextWidth = ctx.measureText(percentChangeText).width;
        const fillColor = Math.sign(percentChange) !== -1 ? '#30780C' : '#CB2610';
        ctx.fillStyle = fillColor;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';

        const imagePosX = x - percentChangeTextWidth / 2 + 2; // half text width
        const imagePosY = labelY + 8; // 8 = margin

        const percentChangeX = imagePosX + 16 + 4; // 25 = image width, 4 = margin
        const percentChangeY = imagePosY + 4; // 4 = help justify the image and text

        ctx.fillText(percentChangeText, percentChangeX, percentChangeY);
        ctx.restore();

        const getStatIcon = Math.sign(percentChange) !== -1 ? getStatIncreaseIconSmall : getStatDecreaseIconSmall;
        getStatIcon().then((image) => {
          ctx.drawImage(image, imagePosX, imagePosY, image.width, image.height);
        });
      });
    }
    if ('platformLabels' in options) {
      const { platforms } = (options as unknown as BarGroupLabelPluginOptions).platformLabels as PlatformLabelContext;
      if (barProps.length !== platforms.length) return;
      const {
        chartArea: { bottom: chartBottomPosition },
      } = chart;

      platforms.forEach(({ label, percentChange }, i) => {
        const { x } = barProps[i];

        let getIcon: undefined | (() => Promise<HTMLImageElement>);

        switch (label) {
          case PlatformLabels.YOUTUBE:
            getIcon = getYouTubeIcon;
            break;
          case PlatformLabels.FACEBOOK:
            getIcon = getFacebookIcon;
            break;
          case PlatformLabels.INSTAGRAM:
            getIcon = getInstagramIcon;
            break;
          case PlatformLabels.TWITTER:
            getIcon = getTwitterIcon;
            break;
          case PlatformLabels.LINKEDIN:
            getIcon = getLinkedInIcon;
            break;
          case PlatformLabels.PINTEREST:
            getIcon = getPinterestIcon;
            break;
          case PlatformLabels.TIKTOK:
            getIcon = getTikTokIcon;
            break;
          default:
            getIcon = undefined;
            break;
        }

        const platformIconX = x;
        const platformIconY = chartBottomPosition + 8; // 8 = margin
        if (getIcon) {
          getIcon().then((image) => {
            // Because icons all have different heights, we find the difference
            // and offset them by half that
            const { height } = image;
            const maxImageHeight: Pixels = 30;
            const yOffset = (maxImageHeight - height) / 2.0;
            ctx.drawImage(image, platformIconX, platformIconY + yOffset);
          });
        }

        // Draw percentChange and icon
        const percentChangeText = ChartPluginHelpers.formatPercentChange(percentChange);

        ctx.save();
        ctx.font = '400 12px/21px Roboto';
        const percentChangeTextWidth = ctx.measureText(percentChangeText).width;
        const fillColor = Math.sign(percentChange) !== -1 ? '#30780C' : '#CB2610';
        ctx.fillStyle = fillColor;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';

        const imagePosX = x - percentChangeTextWidth / 2 + 2; // half text width
        const imagePosY = platformIconY + 16 + 20; // 16 = image height, 20 = margin

        const percentChangeX = imagePosX + 16 + 4; // 25 = image width, 4 = margin
        const percentChangeY = imagePosY + 4; // 4 = help justify the image and text

        ctx.fillText(percentChangeText, percentChangeX, percentChangeY);
        ctx.restore();

        const getStatIcon = Math.sign(percentChange) !== -1 ? getStatIncreaseIconSmall : getStatDecreaseIconSmall;
        getStatIcon().then((image) => {
          ctx.drawImage(image, imagePosX, imagePosY, image.width, image.height);
        });
      });
    }
  },
};

export default barGroupLabelPlugin;

class ChartPluginHelpers {
  static getLabelRotationContext(
    label: string,
    fontSize: number,
    dataIndexLength: number,
    chart: Chart
  ): {
    maxLabelWidth: Pixels;
    maxLabelHeight: Pixels;
    labelAngleOfRotation: Radians;
  } {
    const { ctx } = chart;

    const { width: canvasWidth } = ctx.canvas;
    const { width: yLabelWidth } = chart.scales.y;
    const actualChartWidth = canvasWidth - yLabelWidth;
    const chartElementWidth = (actualChartWidth - 48) / dataIndexLength;
    const maxLabelWidth = Math.max(chartElementWidth, 48);

    ctx.save();
    const lineHeight = 21;
    ctx.font = `700 ${fontSize}px/${lineHeight}px Roboto`;
    const { width: labelWidth } = ctx.measureText(label);
    const labelAngleOfRotation: Radians = this.findAngleOfRotation(labelWidth / 2, maxLabelWidth);
    const topRightOffset = Math.sin(labelAngleOfRotation) * labelWidth;
    const bottomLeftOffset = Math.cos(labelAngleOfRotation) * lineHeight;
    // height of rotated rectangle
    const maxLabelHeight = topRightOffset + bottomLeftOffset;
    ctx.restore();

    return {
      maxLabelWidth,
      maxLabelHeight,
      labelAngleOfRotation,
    };
  }

  static findAngleOfRotation(radius: Pixels, chordLen: Pixels): Radians {
    if (radius * 2 <= chordLen) return 0; // width is smaller than chord len, so no need to rotate
    const maxAngle: Radians = 60 * (Math.PI / 180);
    const angle: Radians = Math.asin(chordLen / (2 * radius)) * 2;
    if (angle > maxAngle) {
      return maxAngle;
    }
    return angle;
  }

  /**
   * Transforms number into readable string, truncating if over (+/-)100,000
   * @param {Number} val number to format
   * @returns {String} formatted value
   */
  static formatValue(val: number): string {
    if (isNaN(val)) return (0).toLocaleString();

    const absVal = Math.abs(val);
    if (absVal > 999_999) {
      return (val / 1_000_000).toFixed(1) + 'M';
    } else if (absVal > 99_999) {
      return (val / 1_000).toFixed(1) + 'K';
    }
    return val.toLocaleString();
  }

  /**
   * Transforms number readable string, truncating if over (+/-)1,000
   * @param {Number} val percentage to format (e.g. .24 for 24%)
   * @returns {String} formatted value
   */
  static formatPercentChange(val: number): string {
    if (isNaN(val)) return (0).toLocaleString() + '%';

    const absVal = Math.abs(val * 100);
    if (absVal > 999_999) {
      return (absVal / 1_000_000).toFixed(1) + 'M%';
    } else if (absVal > 99_999) {
      return (absVal / 1_000).toFixed(1) + 'K%';
    }
    return Number(absVal.toFixed(2)).toLocaleString() + '%';
  }
}

interface BarGroupLabelPluginPossibleOptions {
  statLabels?: StatLabelContext;
  briefStatLabels?: BriefStatLabelContext;
  platformLabels?: PlatformLabelContext;
}

/**
 * This type restricts usage to only one of three possible contexts for this plugin.
 * Its context can be one of 'statLabels', 'briefStatLabels', or 'platformLabels', but not more than one.
 */
export type BarGroupLabelPluginOptions = OnlyOneOf<
  BarGroupLabelPluginPossibleOptions,
  'statLabels' | 'briefStatLabels' | 'platformLabels'
>;

interface StatLabelContext {
  stats: StatLabel[];
}

interface StatLabel {
  label: string;
  value: number;
  percentChange: number;
  numericChange: number;
}

interface BriefStatLabelContext {
  stats: BriefStatLabel[];
}

interface BriefStatLabel {
  label: string;
  percentChange: number;
}

interface PlatformLabelContext {
  platforms: PlatformLabel[];
}

interface PlatformLabel {
  label: string;
  percentChange: number;
}

type Pixels = number;
type Radians = number;
