
import { PropType } from 'vue';
import { defineComponent } from 'vue';
import Loading from '../Loading.vue';
const Button = defineComponent({
  data() {
    return {
      buttonTypeClass: this.buttonType,
      buttonSizeClass: this.buttonSize,
      iconPositionClass: this.iconPosition,
    };
  },
  components: {
    Loading,
  },
  props: {
    active: Boolean,
    disabled: Boolean,
    onClickEventHandler: {
      type: String,
      default: 'click',
    },
    onKeydownEventHandler: {
      type: String,
      default: 'keydown',
    },
    loading: Boolean,
    // primary, secondary, tertiary, icon, primary icon, secondary icon, tertiary icon, brand
    buttonType: {
      type: String,
      default: '',
    },
    //left or right
    iconPosition: {
      type: String,
      default: 'left',
    },
    // small or default ''
    buttonSize: {
      type: String,
      default: '',
    },
    // button type to allow switching between submit, button, etc
    type: {
      type: String as PropType<'button' | 'submit' | 'reset' | undefined>,
      default: 'button',
    },
  },
});

export default Button;
