import moment from 'moment-timezone';

export function getDateTimeTwoMinutesFromNow(timeZone: string) {
  const today = moment.tz(timeZone).toDate();
  const twoMinsAway = moment.tz(timeZone).add(2, 'minutes').toDate();
  const year = today.getFullYear().toString();
  const tempMonth = (today.getMonth() + 1).toString();
  const month = tempMonth.length === 1 ? `0${tempMonth}` : tempMonth;
  const tempDay = today.getDate().toString();
  const day = tempDay.length === 1 ? `0${tempDay}` : tempDay;
  const time = twoMinsAway.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });
  return `${year}-${month}-${day} ${time}`;
}
