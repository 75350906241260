import HttpClient from '@/services/common/HttpClient';
import store from '@/store';
import {
  FinalStoryLeaderboardObject,
  TimeChunk,
} from 'content-cloud-types/dist/types/createandpublish/AnalyticsControllerInterfaces';

export default class CreateAndPublishSocialStoriesService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store.getters.selectedBrand?.id as number | undefined;
    if (!brandId) {
      // Fallback to localStorage
      try {
        const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand.id;
      } catch {
        console.warn('[CreateAndPublishSocialStoriesService] Unable to get brandId from localStorage');
      }
    }
    return brandId;
  }

  private get storiesAnalyticsPrefix() {
    return `/createandpublish/${this.brandId}/analytics/stories`;
  }

  public getTopStories(timeChunk: TimeChunk): Promise<FinalStoryLeaderboardObject> {
    const params = new URLSearchParams({
      timeChunk,
    });
    return this.http.get(`${this.storiesAnalyticsPrefix}?${params.toString()}`);
  }

  public getStoryById(storyId: number, timeChunk: TimeChunk) {
    const params = new URLSearchParams({
      timeChunk,
    });
    return this.http.get(`${this.storiesAnalyticsPrefix}/${storyId}?${params.toString()}`);
  }
}
