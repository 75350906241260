
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BaseLineChart from '@/audience/components/base-charts/BaseLineChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';

import {
  getChartDataByPlatform,
  lineChartHasDisplayableData,
  chartHasNonZeroData,
  makeDate,
  getMaxValueFromChartDatasets,
} from '@/audience/core/utils/chart';
import { PlatformLabels, PlatformColors } from '@/audience/data/socialPlatformChartConfig';

import moment from 'moment-timezone';

import YouTubeIcon from '@/assets/audience/leaderboard-bar-chart/logo-youtube.svg';
import InstagramIcon from '@/assets/audience/leaderboard-bar-chart/logo-instagram.svg';
import FacebookIcon from '@/assets/audience/leaderboard-bar-chart/logo-facebook.svg';
import TwitterIcon from '@/assets/audience/leaderboard-bar-chart/logo-twitter.svg';
import TikTokIcon from '@/assets/audience/leaderboard-bar-chart/logo-tiktok.svg';
import LinkedInIcon from '@/assets/audience/leaderboard-bar-chart/logo-linkedin.svg';
import PinterestIcon from '@/assets/audience/leaderboard-bar-chart/logo-pinterest.svg';

import type { ChartData, ChartOptions, ChartDataset } from 'chart.js';
import type {
  SocialAnalytics,
  ActiveSocialAccount,
  DateRange,
  TooltipConfig,
  ChartDataByPlatform,
} from '@/types/audience';
import { SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialRanking';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'DashboardLineChart',
  components: {
    BaseLineChart,
    ChartTooltip,
    NoChartDataBanner,
  },
})
export default class DashboardLineChart extends Vue {
  @Prop({ type: String, required: true }) context!: 'audience' | 'impressions' | 'engagement';
  @Prop({ required: true }) hoverState!: SocialPlatform;
  @Prop({ required: true }) clickState!: SocialPlatform;
  @Prop({ required: true }) lineChartType!: 'daily' | 'overTime';

  // TODO : use the passed down lineChartType prop to determine what data is displayed on the line chart

  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];
  @audienceModule.Getter dateRange!: DateRange;
  @audienceModule.Getter timeZone!: string;

  get chartConfig() {
    return {
      chartId: 'dashboard-line-chart',
      datasetIdKey: 'dashboard-line-chart',
      plugins: [],
      cssClasses: '',
      styles: {},
    };
  }

  get chartOptions(): ChartOptions {
    // get y-axis maximum value for chart
    const maxCeil = getMaxValueFromChartDatasets(this.chartDatasets);

    return {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          min: 0,
          max: maxCeil,
          stacked: true,
          ticks: {
            maxTicksLimit: 6,
            // for whatever reason, we sometimes see non-number typed values for some ticks, this callback ensures that it is a Number
            callback: function (val) {
              return `${this.getLabelForValue(Number(val))}`;
            },
          },
        },
      },
      plugins: {
        filler: {
          propagate: false,
        },
        legend: {
          display: false,
        },
        // legend: {
        //   labels: {
        //     boxWidth: 3,
        //     usePointStyle: true,
        //     pointStyle: '',
        //   },
        //   title: {
        //     font: {
        //       family: 'Roboto',
        //       size: 12,
        //       weight: '400',
        //     },
        //     color: '#222046',
        //   },
        //   onClick: (evt) => {
        //     evt.native?.stopPropagation();
        //   },
        // },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'line'>;
            const {
              dataIndex,
              datasetIndex,
              label: xAxisLabel,
              dataset: { backgroundColor, borderColor, label: platformLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = this.chartDates[dataIndex].format('MMM DD[,] YYYY').toUpperCase();

            const primaryValueLabel = this.context.charAt(0).toUpperCase() + this.context.substring(1);
            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;
            const percentOfTotalText = (
              ((chartData.datasets[datasetIndex].data[dataIndex] as number) / this.chartTotalsByDate[xAxisLabel]) *
              100
            ).toFixed(0);

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            const leftPos = window.pageXOffset + tooltipModel.caretX - width / 2;
            const topPos = window.pageYOffset + tooltipModel.caretY - height;
            style.left = leftPos + 'px';
            style.top = topPos + 'px';
            style.pointerEvents = 'none';

            const tooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: !!platformLabel,
                iconSrc: this.getIconSrcByPlatform(platformLabel ?? ''),
              },
              topRow: {
                primary: {
                  label: primaryValueLabel,
                  value: primaryValueText,
                  backgroundColor: backgroundColor as string,
                  borderColor: borderColor as string,
                },
                secondary: {
                  label: '% of total',
                  value: percentOfTotalText === 'NaN' ? '0' : percentOfTotalText,
                },
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;

            // Figure out if tooltip is overflowing to right of viewport
            // and push left if so.
            await this.$nextTick();
            const maxRightOffset = document.body.clientWidth - 16; // 16px of padding
            const { right: newRight } = tooltipEl.getBoundingClientRect();
            const diff = maxRightOffset - newRight;
            const shiftLeftPx = diff < 0 ? Math.abs(diff) : 0;
            style.left = leftPos - shiftLeftPx + 'px';
          },
        },
      },
      elements: {
        line: {
          tension: 0.000001,
        },
        point: {
          hitRadius: 10,
          pointStyle: 'line',
        },
      },
    };
  }

  get platformsData(): Record<string, ChartDataByPlatform> {
    const activePlatforms = this.activeSocialAccounts.map(
      ({ platform }) => PlatformLabels[platform.toUpperCase()]
    ) as string[];
    const platformsData = {};
    activePlatforms.forEach((platformLabel) => {
      platformsData[platformLabel] = getChartDataByPlatform(platformLabel, this.socialAnalytics);
    });
    return platformsData;
  }

  get chartDates() {
    return Object.keys(this.socialAnalytics).map((date) => moment.tz(makeDate(date), this.timeZone));
  }

  get chartLabels() {
    return this.chartDates.map((date) => [date.format('M[/]DD'), date.format('ddd')]);
  }

  get metricsByChartType() {
    return {
      followers: this.lineChartType === 'daily' ? 'dailyFollowers' : 'followers',
      totalImpressions: this.lineChartType === 'daily' ? 'dailyImpressions' : 'totalImpressions',
      totalEngagement: this.lineChartType === 'daily' ? 'dailyEngagement' : 'totalEngagement',
    };
  }

  get chartDatasets() {
    switch (this.context) {
      case 'audience':
        return Object.keys(this.platformsData).map((platformLabel) => {
          return {
            label: platformLabel,
            backgroundColor: PlatformColors[platformLabel.toUpperCase()],
            borderColor: '#fff',
            data: Object.values(this.socialAnalytics).map(
              (analyticsForDate) => analyticsForDate[platformLabel]?.[this.metricsByChartType.followers] ?? NaN
            ),
            fill: 'stack',
            spanGaps: true,
          };
        });
      case 'impressions':
        return Object.keys(this.platformsData).map((platformLabel) => {
          return {
            label: platformLabel,
            backgroundColor: PlatformColors[platformLabel.toUpperCase()],
            borderColor: '#fff',
            data: Object.values(this.socialAnalytics).map(
              (analyticsForDate) => analyticsForDate[platformLabel]?.[this.metricsByChartType.totalImpressions] ?? NaN
            ),
            fill: 'stack',
            spanGaps: true,
          };
        });
      case 'engagement':
        return Object.keys(this.platformsData).map((platformLabel) => {
          return {
            label: platformLabel,
            backgroundColor: PlatformColors[platformLabel.toUpperCase()],
            borderColor: '#fff',
            data: Object.values(this.socialAnalytics).map(
              (analyticsForDate) => analyticsForDate[platformLabel]?.[this.metricsByChartType.totalEngagement] ?? NaN
            ),
            fill: 'stack',
            spanGaps: true,
          };
        });
      default:
        return Object.keys(this.platformsData).map(() => {
          return {
            label: 'Unknown',
            backgroundColor: '#fff',
            borderColor: '#fff',
            data: [],
            fill: 'stack',
            spanGaps: true,
          };
        });
    }
  }

  get chartHasData() {
    if (this.lineChartType === 'daily') {
      return chartHasNonZeroData(this.chartDatasets as ChartDataset[]);
    }
    return lineChartHasDisplayableData(this.chartDatasets as ChartDataset[]);
  }

  get chartBackgroundColors(): string[] {
    return this.chartDatasets.map((dataset) => PlatformColors[dataset.label.toUpperCase()]);
  }

  get chartData(): ChartData {
    return {
      labels: this.chartLabels,
      datasets: this.chartDatasets.map((dataset, index) => {
        if (this.clickState === 'all' && this.hoverState === 'all') {
          dataset.backgroundColor = this.chartBackgroundColors[index];
        } else if (this.clickState !== 'all') {
          dataset.backgroundColor =
            dataset.label.toLowerCase() === this.clickState
              ? this.chartBackgroundColors[index]
              : this.hexToRgbA(this.chartBackgroundColors[index]);
        } else if (this.hoverState !== 'all') {
          dataset.backgroundColor =
            dataset.label.toLowerCase() === this.hoverState
              ? this.chartBackgroundColors[index]
              : this.hexToRgbA(this.chartBackgroundColors[index]);
        }
        return dataset;
      }),
    };
  }

  get chartTotalsByDate() {
    return this.chartLabels.reduce((acc, dateLabel, labelIndex) => {
      acc[dateLabel[0]] = 0;
      this.chartDatasets.forEach(({ data }) => {
        acc[dateLabel[0]] += data[labelIndex] || 0;
      });
      return acc;
    }, {});
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  getIconSrcByPlatform(platformLabel: string) {
    switch (platformLabel) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      default:
        return '';
    }
  }

  hexToRgbA(hex: string) {
    let c: string[];
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      const d = parseInt('0x' + c.join(''));
      return 'rgba(' + [(d >> 16) & 255, (d >> 8) & 255, d & 255].join(',') + ',0.4)';
    }
    throw new Error('Bad Hex');
  }

  mounted() {
    const iconURLs = Object.keys(this.platformsData).map((platformLabel) => this.getIconSrcByPlatform(platformLabel));
    const legend = document.getElementsByClassName('legend');
    for (let i = 0; i < legend.length; i++) {
      legend[i].innerHTML = iconURLs
        .map((iconURL, index) => {
          return `<div class="legend-item">
            <img class="icons" src="${iconURL}" alt="${this.chartDatasets[index].label}" />
          </div>`;
        })
        .join('');
    }
  }
}
