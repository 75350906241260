import { FeatureFlagService } from './launchDarkly';

const FeatureFlagPlugin = {
  install(Vue) {
    const featureFlagService = new FeatureFlagService();
    Vue.prototype.$featureFlags = featureFlagService;
  },
};

export default FeatureFlagPlugin;
