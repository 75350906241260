
import { Component, Vue, Prop } from 'vue-property-decorator';

import CloseButton from '@/createandpublish/components/CloseButton.vue';
import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';
import AddPlatformDropdown from '@/createandpublish/components/socialStories/AddPlatformDropdown.vue';

import YouTubePost from '@/createandpublish/components/socialStories/socialPostForms/YouTubePost.vue';
import TwitterPost from '@/createandpublish/components/socialStories/socialPostForms/TwitterPost.vue';
import TikTokPost from '@/createandpublish/components/socialStories/socialPostForms/TikTokPost.vue';
import LinkedInPost from '@/createandpublish/components/socialStories/socialPostForms/LinkedInPost.vue';
import PinterestPost from '@/createandpublish/components/socialStories/socialPostForms/PinterestPost.vue';
import InstagramPost from '@/createandpublish/components/socialStories/socialPostForms/InstagramPost.vue';
import FacebookPost from '@/createandpublish/components/socialStories/socialPostForms/FacebookPost.vue';

import { KEYBOARD_KEYS } from '@/utils';

import type { ActiveSocialAccount, AyrshareProfile } from '@/types/audience';
import type { Modify } from 'content-cloud-types/dist/types/helpers';
import { type SocialPlatform, SocialPlatforms } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { CreateSocialStoryInput as DraftSocialStory } from 'content-cloud-types/dist/types/createandpublish/PostingControllerInterfaces';

@Component({
  name: 'PlatformSelectorTabs',
  components: {
    CloseButton,
    YouTubeIcon,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    TikTokIcon,
    LinkedInIcon,
    PinterestIcon,
    AddPlatformDropdown,
    YouTubePost,
    TwitterPost,
    TikTokPost,
    LinkedInPost,
    PinterestPost,
  },
})
export default class PlatformSelectorTabs extends Vue {
  @Prop({ type: Object, required: true }) readonly profile!: AyrshareProfile | null;
  @Prop({ type: Array, required: true }) readonly activeSocialAccounts!: Modify<
    ActiveSocialAccount,
    {
      platform: SocialPlatform;
    }
  >[];
  @Prop({ required: false, default: null }) readonly draftSocialStory!: DraftSocialStory | null;
  @Prop({ type: Number, required: false, default: NaN }) readonly editingStoryId!: number;
  @Prop({ type: Boolean, required: false, default: false }) readonly isReadonly!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) readonly showsMediaSelector!: boolean;

  @Prop({ required: false, default: '' }) readonly currentSelectedTab!: SocialPlatform | '';

  currentFocusedTabIndex = 0;
  addedSocialAccountLabels: SocialPlatform[] = [];

  async onTabClick($event: MouseEvent) {
    const target = $event.target as HTMLElement;
    const buttonEl = target.closest('.tab-item__button') as HTMLButtonElement;
    const tabPlatform = buttonEl.dataset.tabPlatform as SocialPlatform;
    const tabPanelId = buttonEl.getAttribute('aria-controls') as string;
    // this.currentSelectedTab = tabPlatform;
    this.$emit('setCurrentSelectedTab', tabPlatform);

    const tabPanel = this.$el.querySelector(`#${tabPanelId}`) as HTMLDivElement;
    await new Promise((resolve) => requestAnimationFrame(resolve));
    tabPanel?.focus();
  }

  async onAddNewSocialPost(SocialPlatform: SocialPlatform) {
    this.addedSocialAccountLabels.push(SocialPlatform);
    const tabIndex = this.addedSocialAccountLabels.indexOf(SocialPlatform);
    await new Promise((resolve) => requestAnimationFrame(resolve));
    if (tabIndex !== -1) {
      this.focusTabByIndex(tabIndex);
      await new Promise((resolve) => setTimeout(resolve, 25));
      this.$refs.tabButtons[tabIndex].click();
    }
  }

  toggleDeleteModal(socialPlatform: SocialPlatform) {
    this.$emit('toggleDeleteModal', socialPlatform);
  }

  async onRemoveNewSocialPost(socialPlatform: SocialPlatform) {
    if (this.isReadonly) return;
    // Clear post from story in store and zero out any errors associated with it
    this.$emit('post:remove', socialPlatform);
    this.onErrorCountUpdated({ platform: socialPlatform, count: 0 });

    const index = this.addedSocialAccountLabels.indexOf(socialPlatform);

    if (index !== -1) {
      this.addedSocialAccountLabels.splice(index, 1);
      if (this.addedSocialAccountLabels.length) {
        if (socialPlatform === this.currentSelectedTab) {
          // If the displayed tab was set to the one that was deleted, move focus to first tab content
          await new Promise((resolve) => requestAnimationFrame(resolve));
          this.$emit('setCurrentSelectedTab', this.$refs.tabButtons[0].dataset.tabPlatform as SocialPlatform);
          // this.currentSelectedTab = this.$refs.tabButtons[0].dataset.tabPlatform as SocialPlatform;
        }
        // Then refocus the first one tab
        await new Promise((resolve) => requestAnimationFrame(resolve));
        this.focusTabByIndex(0);
      }
    }
  }

  toggleUploadingMediaStatus() {
    this.$emit('toggleUploadingMediaStatus');
  }

  get showAddProfileDropdown() {
    return this.socialAccountOptions.length > 0;
  }

  get socialAccountOptions() {
    return this.activeSocialAccounts.filter(({ platform }) => !this.addedSocialAccountLabels.includes(platform));
  }

  get addedSocialAccounts() {
    return this.activeSocialAccounts.filter(({ platform }) => this.addedSocialAccountLabels.includes(platform));
  }

  get firstTabIndex() {
    return 0;
  }
  get lastTabIndex() {
    const numTabs = this.addedSocialAccounts.length;
    if (numTabs - 1 <= 0) {
      return 0;
    }
    return numTabs - 1;
  }
  get prevTabIndex() {
    if (this.currentFocusedTabIndex === 0) {
      return this.firstTabIndex;
    }
    return this.currentFocusedTabIndex - 1;
  }
  get nextTabIndex() {
    const numTabs = this.addedSocialAccounts.length;
    if (numTabs === 0) {
      return 0;
    }
    if (this.currentFocusedTabIndex === numTabs - 1) {
      return this.lastTabIndex;
    }
    return this.currentFocusedTabIndex + 1;
  }

  onKeyDownHandler(e: KeyboardEvent) {
    switch (e.key) {
      case KEYBOARD_KEYS.LEFT:
        e.preventDefault();
        this.focusTabByIndex(this.prevTabIndex);
        break;
      case KEYBOARD_KEYS.RIGHT:
        e.preventDefault();
        this.focusTabByIndex(this.nextTabIndex);
        break;
      case KEYBOARD_KEYS.HOME:
        e.preventDefault();
        this.focusTabByIndex(this.firstTabIndex);
        break;
      case KEYBOARD_KEYS.END:
        e.preventDefault();
        this.focusTabByIndex(this.lastTabIndex);
        break;
      case KEYBOARD_KEYS.ENTER:
      case KEYBOARD_KEYS.DOWN:
        e.preventDefault();
        // Per accessibility standards, they Down key should place
        // the user at the top of the selected tab's content. Bundling
        // these cases into a click event to provide a simpler DX.
        this.$refs.tabButtons[this.currentFocusedTabIndex].click();
        break;
      default:
        break;
    }
  }

  focusTabByIndex(tabIndex: number) {
    const tab = this.$refs.tabButtons[tabIndex];
    this.currentFocusedTabIndex = tabIndex;
    tab.focus();
  }

  getPlatformIconByName(SocialPlatform: SocialPlatform) {
    switch (SocialPlatform) {
      case SocialPlatforms.youtube:
        return YouTubeIcon;
      case SocialPlatforms.instagram:
        return InstagramIcon;
      case SocialPlatforms.facebook:
        return FacebookIcon;
      case SocialPlatforms.twitter:
        return TwitterIcon;
      case SocialPlatforms.tiktok:
        return TikTokIcon;
      case SocialPlatforms.linkedin:
        return LinkedInIcon;
      case SocialPlatforms.pinterest:
        return PinterestIcon;
      default:
        return null;
    }
  }

  getComponentForPostContent(SocialPlatform: SocialPlatform) {
    switch (SocialPlatform) {
      case SocialPlatforms.youtube:
        return YouTubePost;
      case SocialPlatforms.instagram:
        return InstagramPost;
      case SocialPlatforms.facebook:
        return FacebookPost;
      case SocialPlatforms.twitter:
        return TwitterPost;
      case SocialPlatforms.tiktok:
        return TikTokPost;
      case SocialPlatforms.linkedin:
        return LinkedInPost;
      case SocialPlatforms.pinterest:
        return PinterestPost;
      default:
        return null;
    }
  }

  errorCounts: { [key in SocialPlatform]: number } = {
    [SocialPlatforms.facebook]: 0,
    [SocialPlatforms.instagram]: 0,
    [SocialPlatforms.linkedin]: 0,
    [SocialPlatforms.pinterest]: 0,
    [SocialPlatforms.tiktok]: 0,
    [SocialPlatforms.twitter]: 0,
    [SocialPlatforms.youtube]: 0,
  };

  onErrorCountUpdated({ platform, count }: { platform: SocialPlatform; count: number }) {
    this.$set(this.errorCounts, platform, count);
    this.onErrorCountChange();
  }

  onErrorCountChange() {
    const totalErrorCount = Object.values(this.errorCounts).reduce((acc, count) => {
      acc += count;
      return acc;
    }, 0);
    this.$emit('errorCount:update', totalErrorCount);
  }

  async mounted() {
    if (!isNaN(this.editingStoryId)) {
      const platformPostLabels =
        this.draftSocialStory?.socialPlatformPosts?.map(
          (socialPlatformPost) => socialPlatformPost.mainRequest.platforms[0]
        ) ?? [];
      this.addedSocialAccountLabels.push(...platformPostLabels);
      await new Promise((resolve) => requestAnimationFrame(resolve));
      // this.currentSelectedTab = platformPostLabels[0];
      this.$emit('setCurrentSelectedTab', platformPostLabels[0]);
    }
  }

  $refs!: {
    tabList: HTMLUListElement;
    tabListItems: HTMLLIElement[];
    tabButtons: HTMLButtonElement[];
  };
}
