import { formatFileSize } from '@/createandpublish/core/util';
import type { ObjValues } from '@/types/helpers';

// Global constants

type Bytes = number;

const ONE_KB: Bytes = Math.pow(1024, 1);
const ONE_MB: Bytes = Math.pow(ONE_KB, 2);
const ONE_GB: Bytes = Math.pow(ONE_KB, 3);

type Seconds = number;

const ONE_SECOND: Seconds = 1;
const ONE_MINUTE: Seconds = ONE_SECOND * 60;
const ONE_HOUR: Seconds = ONE_MINUTE * 60;

// YouTube constants

export const YOUTUBE_TITLE_MAX_LENGTH = 100;
export const YOUTUBE_DESCRIPTION_MAX_LENGTH = 5000;
export const YOUTUBE_SHORT_MAX_DURATION_SECONDS = ONE_MINUTE;

export const YOUTUBE_MAX_TAGS = 15;
export const YOUTUBE_TAG_CHARACTER_MIN_LENGTH = 2;
export const YOUTUBE_TAGS_TOTAL_CHARACTER_MAX_LENGTH = 400;

export const YOUTUBE_VIDEO_ACCEPT_FILE_TYPES = 'video/mp4,video/mov,video/quicktime';

export const YOUTUBE_IMAGE_ACCEPT_FILE_TYPES = 'image/png,image/jpeg';
export const YOUTUBE_MAX_THUMBNAIL_FILESIZE = ONE_MB * 2;

export const YouTubeVisibilityValues = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  UNLISTED: 'unlisted',
} as const;

export type YouTubeVisibility = ObjValues<typeof YouTubeVisibilityValues>;

// YouTube custom validators

export const youTubeTagsMaxTotalCharLen = (tags: string[]) =>
  tags.join().length <= YOUTUBE_TAGS_TOTAL_CHARACTER_MAX_LENGTH;

export const youTubeInvalidCharacters = (s: string) => !new RegExp(/[<>]/, 'm').test(s);

// TODO: Replace these two in YouTubePost component with vuelidate built-in maxValue validator
export const youTubeThumbnailFilesize = (filesize: number) => !filesize || filesize <= YOUTUBE_MAX_THUMBNAIL_FILESIZE;
export const youTubeShortMaxDuration = (seconds: number) => !seconds || seconds <= YOUTUBE_SHORT_MAX_DURATION_SECONDS;

// YouTube error messages

export const YouTubeErrorMessage = {
  title: {
    required: 'Title is required',
    maxLength: `Title must not exceed ${YOUTUBE_TITLE_MAX_LENGTH} characters`,
    youTubeInvalidCharacters: 'Title may not include "<" or ">" characters',
  },
  post: {
    required: 'Description is required',
    maxLength: `Description must not exceed ${YOUTUBE_DESCRIPTION_MAX_LENGTH} characters`,
    youTubeInvalidCharacters: 'Description may not include "<" or ">" characters',
  },
  thumbnail: {
    filesizeExceeded: `Thumbnail filesize must not exceed ${formatFileSize(YOUTUBE_MAX_THUMBNAIL_FILESIZE)}`,
  },
  tags: {
    tagMinLCharacterLength: `Each tag must have ${YOUTUBE_TAG_CHARACTER_MIN_LENGTH} or more characters (excluding the '#')`,
    youTubeTagsMaxTotalCharLen: `Combined tags may not exceed ${YOUTUBE_TAGS_TOTAL_CHARACTER_MAX_LENGTH} characters`,
    maxTagsLength: `The number of tags may not exceed ${YOUTUBE_MAX_TAGS}`,
  },
  shorts: {
    maxLength: `Video duration may not exceed ${YOUTUBE_SHORT_MAX_DURATION_SECONDS} seconds`,
  },
  mediaUrl: {
    required: 'Video file is required',
  },
} as const;

// Facebook constants

export const FACEBOOK_POST_MAX_LENGTH = 63206;

export const FACEBOOK_VIDEO_MAX_DURATION = ONE_HOUR * 4;
export const FACEBOOK_VIDEO_MAX_FILESIZE = ONE_GB * 10;
export const FACEBOOK_VIDEO_ACCEPT_FILE_TYPES = 'video/avi,video/mp4,video/mov,video/quicktime';
export const FACEBOOK_CAPTION_MAX_LENGTH = 125;

export const FACEBOOK_MAX_IMAGE_COUNT = 10;
export const FACEBOOK_MAX_IMAGE_FILESIZE = ONE_MB * 30;
export const FACEBOOK_IMAGE_MIN_WIDTH = 600;
export const FACEBOOK_IMAGE_MIN_HEIGHT = 600;
export const FACEBOOK_IMAGE_ACCEPT_FILE_TYPES = 'image/png,image/jpeg';

export const FACEBOOK_GIF_ACCEPT_FILE_TYPES = 'image/gif';

export const FacebookErrorMessage = {
  post: {
    required: 'Post is required',
    maxLength: `Post content must not exceed ${FACEBOOK_POST_MAX_LENGTH} characters`,
  },
  imageUrls: {
    maxFilesize: `Image filesize must not exceed ${formatFileSize(FACEBOOK_MAX_IMAGE_FILESIZE)}`,
    minWidth: `Image width must be at least ${FACEBOOK_IMAGE_MIN_WIDTH}px`,
    minHeight: `Image height must be at least ${FACEBOOK_IMAGE_MIN_HEIGHT}px`,
  },
  videoUrl: {
    maxLength: `Video duration must not exceed ${FACEBOOK_VIDEO_MAX_DURATION / 60} minutes`,
    maxFilesize: `Video filesize must not exceed ${formatFileSize(FACEBOOK_VIDEO_MAX_FILESIZE)}`,
  },
  videoTitle: {
    maxLength: `Video title must not exceed ${FACEBOOK_CAPTION_MAX_LENGTH} characters`,
  },
} as const;

// LinkedIn constants

export const LINKEDIN_POST_MAX_LENGTH = 3000;

export const LINKEDIN_MAX_IMAGE_COUNT = 9;
export const LINKEDIN_MAX_IMAGE_FILESIZE = ONE_MB * 5;
export const LINKEDIN_IMAGE_ACCEPT_FILE_TYPES = 'image/png,image/jpeg,image/gif';

export const LINKEDIN_MAX_VIDEO_FILESIZE = ONE_GB * 5;
export const LINKEDIN_MIN_VIDEO_FILESIZE = ONE_KB * 75;
export const LINKEDIN_MAX_VIDEO_DURATION = ONE_MINUTE * 10;
export const LINKEDIN_MIN_VIDEO_DURATION = ONE_SECOND * 3;
export const LINKEDIN_VIDEO_MIN_ASPECT_RATIO = 0.416;
export const LINKEDIN_VIDEO_MAX_ASPECT_RATIO = 2.4;
export const LINKEDIN_VIDEO_ACCEPT_FILE_TYPES = 'video/mp4';

export const LINKEDIN_CAPTION_MAX_LENGTH = 140;

export const LinkedInErrorMessage = {
  post: {
    required: 'Post is required',
    maxLength: `Post content must not exceed ${LINKEDIN_POST_MAX_LENGTH} characters`,
  },
  imageUrls: {
    maxFilesize: `Image filesize must not exceed ${formatFileSize(LINKEDIN_MAX_IMAGE_FILESIZE)}`,
  },
  videoUrl: {
    minLength: `Video duration must be at least ${LINKEDIN_MIN_VIDEO_DURATION} seconds`,
    maxLength: `Video duration must not exceed ${LINKEDIN_MAX_VIDEO_DURATION / 60} minutes`,
    minFilesize: `Video filesize must be at least ${formatFileSize(LINKEDIN_MIN_VIDEO_FILESIZE)}`,
    maxFilesize: `Video filesize must not exceed ${formatFileSize(LINKEDIN_MAX_VIDEO_FILESIZE)}`,
    minAspectRatio: `Video aspect ratio must be at least ${LINKEDIN_VIDEO_MIN_ASPECT_RATIO}`,
    maxAspectRatio: `Video aspect ratio must not exceed ${LINKEDIN_VIDEO_MAX_ASPECT_RATIO}`,
  },
  videoCaption: {
    maxLength: `Captions must not exceed ${LINKEDIN_CAPTION_MAX_LENGTH} characters`,
  },
} as const;

//Twitter constants
export const TWITTER_POST_MAX_LENGTH = 280;

export const TWITTER_MAX_IMAGE_COUNT = 4;
export const TWITTER_MAX_IMAGE_FILESIZE = ONE_MB * 5;
export const TWITTER_IMAGE_MIN_WIDTH = 4;
export const TWITTER_IMAGE_MIN_HEIGHT = 4;
export const TWITTER_IMAGE_MAX_WIDTH = 8192;
export const TWITTER_IMAGE_MAX_HEIGHT = 8192;
export const TWITTER_IMAGE_ACCEPT_FILE_TYPES = 'image/png,image/jpeg,image/gif';

export const TWITTER_MAX_VIDEO_FILESIZE = ONE_MB * 512;
export const TWITTER_MAX_VIDEO_DURATION = ONE_SECOND * 140;
export const TWITTER_MIN_VIDEO_DURATION = ONE_SECOND * 1;
export const TWITTER_VIDEO_MIN_ASPECT_RATIO = 0.3334;
export const TWITTER_VIDEO_MAX_ASPECT_RATIO = 3;

export const TWITTER_LONG_VIDEO_MAX_DURATION = ONE_MINUTE * 10;

export const TWITTER_VIDEO_ACCEPT_FILE_TYPES = 'video/mp4,video/mov,video/quicktime';

export const TWITTER_ALT_TEXT_MAX_LENGTH = 1000;

export const TwitterErrorMessage = {
  post: {
    required: 'Post is required',
    maxLength: `Post content must not exceed ${TWITTER_POST_MAX_LENGTH} characters`,
  },
  imageUrls: {
    maxImageNum: `Total amount of images must not exceed 4`,
    maxFilesize: `Image filesize must not exceed ${formatFileSize(TWITTER_MAX_IMAGE_FILESIZE)}`,
    minWidth: `Image width must be at least ${TWITTER_IMAGE_MIN_WIDTH}px`,
    minHeight: `Image height must be at least ${TWITTER_IMAGE_MIN_HEIGHT}px`,
    maxWidth: `Image width must not exceed ${TWITTER_IMAGE_MAX_WIDTH}px`,
    maxHeight: `Image height must not exceed ${TWITTER_IMAGE_MAX_HEIGHT}px`,
  },
  videoUrl: {
    minLength: `Video duration must be at least ${TWITTER_MIN_VIDEO_DURATION} seconds`,
    maxLength: `Video duration must not exceed ${TWITTER_LONG_VIDEO_MAX_DURATION} seconds`,
    maxFilesize: `Video filesize must not exceed ${formatFileSize(TWITTER_MAX_VIDEO_FILESIZE)}`,
    minAspectRatio: `Video aspect ratio must be at least ${TWITTER_VIDEO_MIN_ASPECT_RATIO}`,
    maxAspectRatio: `Video aspect ratio must not exceed ${TWITTER_VIDEO_MAX_ASPECT_RATIO}`,
  },
  altText: {
    maxLength: `Alternative text must not exceed ${TWITTER_ALT_TEXT_MAX_LENGTH} characters`,
  },
} as const;

//TikTok Constants

export const TIKTOK_MAX_DURATION_SECONDS = ONE_SECOND * 60;
export const TIKTOK_MIN_DURATION_SECONDS = ONE_SECOND * 3;
export const TIKTOK_VIDEO_ACCEPT_FILE_TYPES = 'video/mp4,video/webm';
export const TIKTOK_MAX_VIDEO_FILESIZE = ONE_MB * 50;
export const TIKTOK_POST_MAX_LENGTH = 300;

export const TikTokErrorMessage = {
  post: {
    required: 'Post is required',
    maxLength: `Post content must not exceed ${TIKTOK_POST_MAX_LENGTH} characters`,
  },
  videoUrl: {
    required: 'A video is required',
    minLength: `Video duration must be at least ${TIKTOK_MIN_DURATION_SECONDS} seconds`,
    maxLength: `Video duration must not exceed ${TIKTOK_MAX_DURATION_SECONDS / 60} minutes`,
    maxFilesize: `Video filesize must not exceed ${formatFileSize(TIKTOK_MAX_VIDEO_FILESIZE)}`,
  },
} as const;

//Pinterest Constants
export const PINTEREST_POST_MAX_LENGTH = 500;

export const PINTEREST_MAX_IMAGE_COUNT = 1;
export const PINTEREST_MAX_IMAGE_FILESIZE = ONE_MB * 20;
export const PINTEREST_IMAGE_ACCEPT_FILE_TYPES = 'image/png,image/jpeg,image/gif';

export const PINTEREST_TITLE_MAX_LENGTH = 100;
export const PINTEREST_ALT_TEXT_MAX_LENGTH = 500;

export const PinterestErrorMessage = {
  post: {
    required: 'Post is required',
    maxLength: `Post content must not exceed ${PINTEREST_POST_MAX_LENGTH} characters`,
  },
  imageUrls: {
    required: 'An image is required',
    maxFilesize: `Image filesize must not exceed ${formatFileSize(PINTEREST_MAX_IMAGE_FILESIZE)}`,
  },
  altText: {
    maxLength: `Alternative text must not exceed ${PINTEREST_ALT_TEXT_MAX_LENGTH} characters`,
  },
  title: {
    maxLength: `Title must not exceed ${PINTEREST_TITLE_MAX_LENGTH} characters`,
  },
  link: {
    url: 'Link must be valid URL',
  },
} as const;

//Instagram Constants
export const INSTAGRAM_POST_MAX_LENGTH = 2200;

export const INSTAGRAM_MAX_IMAGE_COUNT = 10;
export const INSTAGRAM_MAX_IMAGE_FILESIZE = ONE_MB * 8;
export const INSTAGRAM_IMAGE_ACCEPT_FILE_TYPES = 'image/png,image/jpeg,image/gif';

export const INSTAGRAM_MAX_VIDEO_FILESIZE = ONE_MB * 100;
export const INSTAGRAM_MAX_VIDEO_DURATION = ONE_SECOND * 60;
export const INSTAGRAM_MIN_VIDEO_DURATION = ONE_SECOND * 3;
export const INSTAGRAM_VIDEO_ACCEPT_FILE_TYPES = 'video/mp4,video/mov,video/quicktime';

export const INSTAGRAM_IMAGE_MIN_WIDTH = 320;
export const INSTAGRAM_IMAGE_MAX_WIDTH = 1440;

export const INSTAGRAM_IMAGE_MIN_ASPECT_RATIO = 0.8;
export const INSTAGRAM_IMAGE_MAX_ASPECT_RATIO = 1.91;

export const INSTAGRAM_VIDEO_MIN_ASPECT_RATIO = 0.8;
export const INSTAGRAM_VIDEO_MAX_ASPECT_RATIO = 1.77;

export const instagramWidthRange = (width: number) =>
  !width || (width >= INSTAGRAM_IMAGE_MIN_WIDTH && width <= INSTAGRAM_IMAGE_MAX_WIDTH);

export const InstagramErrorMessage = {
  post: {
    required: 'Post is required',
    maxLength: `Post content must not exceed ${INSTAGRAM_POST_MAX_LENGTH} characters`,
  },
  imageUrls: {
    maxFilesize: `Image filesize must not exceed ${formatFileSize(INSTAGRAM_MAX_IMAGE_FILESIZE)}`,
    minImageWidth: `Image width must be at least ${INSTAGRAM_IMAGE_MIN_WIDTH} pixels`,
    maxImageWidth: `Image width must not exceed ${INSTAGRAM_IMAGE_MAX_WIDTH} pixels`,
    minAspectRatio: `Image aspect ratio must be at least ${INSTAGRAM_IMAGE_MIN_ASPECT_RATIO}`,
    maxAspectRatio: `Image aspect ratio must not exceed ${INSTAGRAM_IMAGE_MAX_ASPECT_RATIO}`,
  },
  videoUrl: {
    minLength: `Video duration must be at least ${INSTAGRAM_MIN_VIDEO_DURATION} seconds`,
    maxLength: `Video duration must not exceed ${INSTAGRAM_MAX_VIDEO_DURATION} seconds`,
    maxFilesize: `Video filesize must not exceed ${formatFileSize(INSTAGRAM_MAX_VIDEO_FILESIZE)}`,
    minAspectRatio: `Video aspect ratio must be at least ${INSTAGRAM_VIDEO_MIN_ASPECT_RATIO}`,
    maxAspectRatio: `Video aspect ratio must not exceed ${INSTAGRAM_VIDEO_MAX_ASPECT_RATIO}`,
  },
} as const;
