import SocialStoryService from '@/services/CreateAndPublishSocialStoriesService';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import { getErrorsFromResponse } from '@/utils';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';

import type { SocialStoryDetails as SocialStory } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';
import type { SocialStoryRequest } from 'content-cloud-types/dist/types/createandpublish/PostingControllerInterfaces';
import { type SocialPlatform, SocialPlatforms } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { PlatformPostStatus } from 'content-cloud-types/dist/types/createandpublish/SocialPosting';
import type { ISO8601DateTime } from '@/types/Common';
import type { PaginatedResponse, SocialStorySearchParams } from '@/types/createandpublish/socialStory';
import type { Campaign } from '@/types/Campaign';
import type { DraftSocialStory, SocialPostBody } from '@/types/createandpublish/socialStory';
import { PlatformOptions } from 'content-cloud-types/dist/types/requests/createandpublish/AyrsharePostRequest';
import { Story } from '@/types/discover/Story';
import { RSSDrawerStoryData } from '@/types/discover/DiscoverRSSFeed';
import DiscoverIdeaStarterById from '@/models/DiscoverIdeaStarterByIdModel';
import { ImageAsset, VideoAsset } from '@/types/createandpublish';

const StoryService = new SocialStoryService();

/**
 * Map of PlatformOptions keys by platform name
 * See: node_modules/content-cloud-types/dist/types/requests/createandpublish/AyrsharePostRequest
 */
const platformOptionsKeyMap = {
  [SocialPlatforms.facebook]: 'facebookOptions',
  [SocialPlatforms.instagram]: 'instagramOptions',
  [SocialPlatforms.linkedin]: 'linkedInOptions',
  [SocialPlatforms.pinterest]: 'pinterestOptions',
  [SocialPlatforms.pinterest]: 'pinterestOptions',
  [SocialPlatforms.tiktok]: 'tikTokOptions',
  [SocialPlatforms.twitter]: 'twitterOptions',
  [SocialPlatforms.youtube]: 'youTubeOptions',
} as const;

/**
 * Helper to remove mediaUrls, as it, depending on the platform, causes an Ayrshare error if an empty list is sent.
 */
function sanitizePostBody(postUpdates: SocialPostBody): SocialPostBody {
  const {
    mainRequest: { mediaUrls },
  } = postUpdates;
  if (mediaUrls && !mediaUrls.length) {
    postUpdates.mainRequest.mediaUrls = undefined;
  }
  return postUpdates;
}

function parsePostOptions<TType extends SocialStory | SocialStory[]>(val: TType): TType {
  const parseStory = (story: SocialStory) => {
    const isPostOptionsStringified = !!story?.socialPosts?.find(
      ({ platformPostOptions }) => typeof platformPostOptions === 'string'
    );
    if (isPostOptionsStringified) {
      story.socialPosts.forEach((socialPost) => {
        if (!socialPost.platformPostOptions) return;

        try {
          const json = JSON.parse(socialPost.platformPostOptions);
          socialPost.platformPostOptions = json;
        } catch {
          // invalid JSON
        }
      });
    }
    return story;
  };

  if (Array.isArray(val)) {
    return val.map((story) => parseStory(story)) as TType;
  } else {
    return parseStory(val) as TType;
  }
}

const socialStoryModule: Module<SocialStoriesState, RootState> = {
  namespaced: true,

  state: {
    stories: [],
    storySearchParams: {
      page: 1,
      size: 10,
      sort: 'updatedAt',
      order: 'DESC',
    },
    storyPagination: null,
    draftSocialStory: null,
    editingStoryId: NaN,
    socialStoryDrawerReadonly: false,
    sharableForSocialStory: {
      data: null,
      label: 'none',
    },
  },

  getters: {
    stories(state) {
      return state.stories ?? [];
    },
    storySearchParams(state) {
      return state.storySearchParams;
    },
    storyPagination(state) {
      return state.storyPagination;
    },
    socialStoryDrawerStatus(state) {
      return state.socialStoryDrawerReadonly;
    },
    storyPaginationQuery(state) {
      const paginationData = state.storySearchParams as unknown as Record<string, string>;
      const searchParams = new URLSearchParams(paginationData);
      return `?${searchParams.toString()}`;
    },
    draftSocialStory(state) {
      return state.draftSocialStory;
    },
    editingStoryId(state) {
      return state.editingStoryId;
    },
    sharableForSocialStory(state) {
      return state.sharableForSocialStory;
    },
  },

  mutations: {
    SET_SOCIAL_STORY_DRAWER_READ_ONLY_STATUS(state, isReadonly = false) {
      state.socialStoryDrawerReadonly = isReadonly;
    },

    SET_STORIES(state, resp: PaginatedResponse<SocialStory[]>) {
      state.stories = resp.data;
      state.storyPagination = resp.pagination;
    },

    ADD_SOCIAL_STORY(state, story: SocialStory) {
      state.stories.push(story);
    },

    CLEAR_STORIES(state) {
      state.stories = [];
    },

    SET_SOCIAL_STORY_SEARCH_PARAMS(state, storySearchParams: SocialStorySearchParams) {
      state.storySearchParams = storySearchParams;
    },

    SET_SOCIAL_STORY_PAGINATION(state, storyPagination: PaginatedResponse<SocialStory[]>['pagination']) {
      state.storyPagination = storyPagination;
    },

    SET_NEW_DRAFT_SOCIAL_STORY(state) {
      state.draftSocialStory = {
        socialStoryRequest: {
          storyTitle: '',
          campaignIds: [],
        },
        socialPlatformPosts: [],
      };
    },

    SET_DRAFT_SOCIAL_STORY_FROM_EXISTING_STORY(state, story: SocialStory) {
      const { socialPosts, title, plannedDate, campaigns } = story;
      const campaignIds = (campaigns ?? []).map(({ id }) => id) as Campaign['id'][];

      const socialStoryRequest = {
        storyTitle: title,
        plannedDate: plannedDate ?? undefined, // prevent null value
        campaignIds,
      };

      const socialPlatformPosts: SocialPostBody[] = socialPosts.map((post) => ({
        socialPostId: post.id,
        mainRequest: {
          mediaUrls: post.socialPostMedia.map((media) => media.mediaUrl),
          isVideo: !!post.socialPostMedia.find(({ mediaType }) => mediaType === 'video'),
          platforms: [post.platform],
          post: post.post,
          scheduleDate: post.scheduleDate ?? undefined,
        },
        platformOptions: (post.platformPostOptions as PlatformOptions) ?? {
          [platformOptionsKeyMap[post.platform]]: {},
        },
        status: post.status,
      }));

      state.draftSocialStory = {
        socialStoryRequest,
        socialPlatformPosts,
      };
    },

    SET_EDITING_STORY_ID(state, id: SocialStory['id']) {
      state.editingStoryId = id;
    },

    CLEAR_EDITING_STORY_ID(state) {
      state.editingStoryId = NaN;
    },

    UPDATE_DRAFT_SOCIAL_STORY(state, socialStoryUpdates: SocialStoryRequest) {
      if (!state.draftSocialStory) return;

      const existingData = state.draftSocialStory.socialStoryRequest;
      state.draftSocialStory.socialStoryRequest = {
        ...existingData,
        ...socialStoryUpdates,
      };
    },

    UPDATE_DRAFT_SOCIAL_STORY_POST(
      state,
      { platformType, postUpdates }: { platformType: SocialPlatform; postUpdates: SocialPostBody }
    ) {
      if (!state.draftSocialStory) return;

      postUpdates = sanitizePostBody(postUpdates);

      const index = state.draftSocialStory.socialPlatformPosts.findIndex(({ mainRequest }) =>
        mainRequest.platforms.includes(platformType)
      );
      if (index !== -1) {
        const existingData = state.draftSocialStory.socialPlatformPosts[index];
        state.draftSocialStory.socialPlatformPosts[index] = {
          ...existingData,
          ...postUpdates,
        };
        return;
      }
      state.draftSocialStory.socialPlatformPosts.push(postUpdates);
    },

    REMOVE_DRAFT_SOCIAL_STORY_POST(state, platformType: SocialPlatform) {
      if (!state.draftSocialStory) return;

      const index = state.draftSocialStory.socialPlatformPosts.findIndex(({ mainRequest }) =>
        mainRequest.platforms.includes(platformType)
      );
      if (index !== -1) {
        state.draftSocialStory.socialPlatformPosts.splice(index, 1);
      }
    },

    UPDATE_STORY_POST_STATUS(
      state,
      { platformType, status }: { platformType: SocialPlatform; status: PlatformPostStatus | undefined }
    ) {
      if (!state.draftSocialStory) return;

      const platformPost = state.draftSocialStory.socialPlatformPosts.find(({ mainRequest }) =>
        mainRequest.platforms.includes(platformType)
      );
      if (platformPost) {
        platformPost.status = status;
      }
    },

    UPDATE_STORY_POST_SCHEDULE_DATE(
      state,
      { platformType, scheduleDate }: { platformType: SocialPlatform; scheduleDate: ISO8601DateTime | undefined }
    ) {
      if (!state.draftSocialStory) return;

      const platformPost = state.draftSocialStory.socialPlatformPosts.find(({ mainRequest }) =>
        mainRequest.platforms.includes(platformType)
      );
      if (platformPost) {
        platformPost.mainRequest.scheduleDate = scheduleDate;
      }
    },

    CLEAR_DRAFT_SOCIAL_STORY(state) {
      state.draftSocialStory = null;
    },

    SET_SHARABLE_FOR_SOCIAL_STORY(state, sharable: SharableForSocialStory) {
      state.sharableForSocialStory = sharable;
    },
  },

  actions: {
    getAllStories({ dispatch, commit }) {
      return handleAjax({
        request: StoryService.getAllStories(),
        dispatch,
        commit,
        mutation: 'SET_STORIES',
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },

    getStories({ dispatch, commit, getters }) {
      return handleAjax({
        request: StoryService.getStories(getters.storyPaginationQuery),
        dispatch,
        commit,
        mutation: 'SET_STORIES',
        errmsg: 'Server error: Unable to fetch stories',
        modify(respData: Awaited<ReturnType<typeof StoryService.getStories>>) {
          const { data: stories } = respData;
          respData.data = parsePostOptions(stories);
          return respData;
        },
      });
    },

    getStoryById({ dispatch, commit }, id: SocialStory['id']) {
      return handleAjax({
        request: StoryService.getStoryById(id),
        dispatch,
        commit,
        modify(respData: Awaited<ReturnType<typeof StoryService.getStoryById>>) {
          return parsePostOptions(respData);
        },
        callback(err) {
          if (err) {
            const errors = getErrorsFromResponse(err);
            if (errors) {
              throw errors;
            }
            throw ['Unknown server error'];
          }
        },
      });
    },

    async ensureDraftSocialStory({ dispatch, commit, getters, state }) {
      const { editingStoryId } = state;
      if (editingStoryId) {
        let story = state.stories.find(({ id }) => id === editingStoryId);
        if (!story) {
          try {
            story = await dispatch('getStoryById', editingStoryId);
          } catch (err) {
            throw ['Unable to setup story for editing'];
          }
        }
        commit('SET_DRAFT_SOCIAL_STORY_FROM_EXISTING_STORY', story);
        return;
      }
      if (!getters.draftSocialStory) {
        commit('SET_NEW_DRAFT_SOCIAL_STORY');
      }
    },

    saveSocialStory({ dispatch, commit, getters }) {
      return handleAjax({
        request: StoryService.saveStory(getters.draftSocialStory),
        dispatch,
        commit,
        callback: (err) => {
          if (err) {
            const errors = getErrorsFromResponse(err);
            if (errors) {
              throw errors;
            }
            throw ['Unknown server error'];
          }
          dispatch('getStories');
        },
      });
    },

    updateSocialStory({ dispatch, commit, getters }) {
      const { draftSocialStory, editingStoryId: id } = getters;
      return handleAjax({
        request: StoryService.updateStory(id, draftSocialStory),
        dispatch,
        commit,
        callback(err) {
          if (err) {
            const errors = getErrorsFromResponse(err);
            if (errors) {
              throw errors;
            }
          }
        },
      });
    },

    deleteSocialStory({ dispatch, commit }, id: SocialStory['id']) {
      return handleAjax({
        request: StoryService.deleteStory(id),
        dispatch,
        commit,
        callback(err) {
          if (err) {
            const errors = getErrorsFromResponse(err);
            if (errors) {
              throw errors;
            }
            throw ['Unknown server error'];
          }
          dispatch('getStories');
        },
      });
    },
  },
};

export default socialStoryModule;

export type SharableData = Story | RSSDrawerStoryData | DiscoverIdeaStarterById | VideoAsset | ImageAsset | null;
export type SharableLabel =
  | 'IdeaStarter'
  | 'TrendingStory'
  | 'RSSStory'
  | 'VideoAsset'
  | 'ImageAsset'
  | 'GifAsset'
  | 'none';

export interface SharableForSocialStory {
  data: SharableData;
  label: SharableLabel;
}

export interface SocialStoriesState {
  stories: SocialStory[];
  storySearchParams: SocialStorySearchParams;
  storyPagination: PaginatedResponse<SocialStory[]>['pagination'] | null;
  draftSocialStory: DraftSocialStory | null;
  editingStoryId: SocialStory['id'];
  socialStoryDrawerReadonly: boolean;
  sharableForSocialStory: SharableForSocialStory;
}
