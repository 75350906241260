
import { Component, Vue, Prop } from 'vue-property-decorator';

import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';

import { KEYBOARD_KEYS } from '@/utils';
import { SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialRanking';

import type { ActiveSocialAccount } from '@/types/audience';

const CLOSE_EVENTS = ['focusin', 'click'] as const;

@Component({
  name: 'AddPlatformDropdown',
  components: {
    YouTubeIcon,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    TikTokIcon,
    LinkedInIcon,
    PinterestIcon,
  },
})
export default class AddPlatformDropdown extends Vue {
  @Prop({ type: Array, required: true }) socialAccountOptions!: ActiveSocialAccount[];

  isOpen = false;

  async toggleMenu() {
    if (this.isOpen) {
      this.focusCombobox();
      this.removeCloseEvents();
      this.isOpen = false;
      return;
    }
    this.isOpen = true;
    await this.$nextTick();
    this.addCloseEvents();
    this.updateSelectedIndex(0, true);
    this.selectedIndex = 0;
  }

  focusCombobox() {
    this.$refs.combobox.focus();
  }

  onComboboxKeyDownEvent(e: KeyboardEvent) {
    const scrollToOption = true;
    switch (e.key) {
      case KEYBOARD_KEYS.ENTER:
        if (!this.isOpen) {
          this.toggleMenu();
          return;
        }
        this.addPlatformAccount();
        break;
      case KEYBOARD_KEYS.SPACE:
        e.preventDefault();
        this.toggleMenu();
        break;
      case KEYBOARD_KEYS.ESCAPE:
        e.stopPropagation();
        if (this.isOpen) this.toggleMenu();
        break;
      case KEYBOARD_KEYS.HOME:
        e.preventDefault();
        this.updateSelectedIndex(this.firstOptionIndex, scrollToOption);
        break;
      case KEYBOARD_KEYS.END:
        e.preventDefault();
        this.updateSelectedIndex(this.lastOptionIndex, scrollToOption);
        break;
      case KEYBOARD_KEYS.UP:
      case KEYBOARD_KEYS.LEFT:
        e.preventDefault();
        this.updateSelectedIndex(this.prevOptionIndex, scrollToOption);
        break;
      case KEYBOARD_KEYS.DOWN:
      case KEYBOARD_KEYS.RIGHT:
        e.preventDefault();
        this.updateSelectedIndex(this.nextOptionIndex, scrollToOption);
        break;
    }
  }

  selectedIndex = 0;

  updateSelectedIndex(selectionIndex: number, scroll = false) {
    this.selectedIndex = selectionIndex;
    const listItemEl = this.$refs.menuOptions[this.selectedIndex];
    scroll &&
      listItemEl.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }

  get firstOptionIndex() {
    return 0;
  }
  get lastOptionIndex() {
    const numOptions = this.socialAccountOptions.length;
    if (numOptions === 0) {
      return 0;
    }
    return numOptions - 1;
  }
  get prevOptionIndex() {
    if (this.selectedIndex === 0) {
      return this.firstOptionIndex;
    }
    return this.selectedIndex - 1;
  }
  get nextOptionIndex() {
    if (this.selectedIndex === this.lastOptionIndex) {
      return this.lastOptionIndex;
    }
    return this.selectedIndex + 1;
  }

  onHoverEvent(e: MouseEvent) {
    const listEl = e.target as HTMLLIElement;
    const selectionIndex = Number(listEl.dataset.selectionIndex);
    this.updateSelectedIndex(selectionIndex);
  }

  onListOptionClickEvent(e: MouseEvent) {
    const target = e.target as HTMLElement;
    const listEl = target.closest('.combobox-list__item') as HTMLLIElement;
    const selectionIndex = Number(listEl.dataset.selectionIndex);
    this.updateSelectedIndex(selectionIndex);
    this.addPlatformAccount();
  }

  addPlatformAccount() {
    const platformLabel = this.socialAccountOptions[this.selectedIndex].platform;
    this.$emit('onAddNewSocialPost', platformLabel);
    this.toggleMenu();
    this.$refs.combobox.focus();
  }

  getPlatformIconByName(platformLabel: SocialPlatform) {
    switch (platformLabel) {
      case SocialPlatform.youtube:
        return YouTubeIcon;
      case SocialPlatform.instagram:
        return InstagramIcon;
      case SocialPlatform.facebook:
        return FacebookIcon;
      case SocialPlatform.twitter:
        return TwitterIcon;
      case SocialPlatform.tiktok:
        return TikTokIcon;
      case SocialPlatform.linkedin:
        return LinkedInIcon;
      case SocialPlatform.pinterest:
        return PinterestIcon;
      default:
        return null;
    }
  }

  addCloseEvents() {
    CLOSE_EVENTS.forEach((event) => {
      window.addEventListener(event, this.closeEventHandler);
    });
  }

  removeCloseEvents() {
    CLOSE_EVENTS.forEach((event) => {
      window.removeEventListener(event, this.closeEventHandler);
    });
  }

  closeEventHandler(e: MouseEvent | FocusEvent) {
    const path = e.composedPath();
    const comboboxEl = this.$el as HTMLDivElement;
    if (!path.includes(comboboxEl)) {
      this.isOpen && this.toggleMenu();
    }
  }

  beforeDestroy() {
    this.removeCloseEvents();
  }

  $refs!: {
    menuOptions: HTMLLIElement[];
    combobox: HTMLDivElement;
  };
}
