import DiscoverShortcutModel from '@/models/DiscoverShortcutModel';
import DiscoverStorySearch from '@/models/DiscoverStorySearchModel';
import store from '@/store';
import { logDebugFunc } from '@/utils/logging';
import { isNotNullOrUndefined } from '@/utils/typeGuards';
import EventEmitter from 'eventemitter2';
import DiscoverStoryManager, { DiscoverStoryFeedType, extractDataFromSearchable } from './DiscoverStoryManager';

const logDebug = logDebugFunc();

const getStories = async (shortcutId: number) => {
  if (!shortcutId || isNaN(shortcutId)) {
    return [];
  }

  const rawStories = DiscoverStoryManager.filterStories(DiscoverStoryFeedType.trending, {
    shortcutIds: [shortcutId],
  });
  const stories = extractDataFromSearchable(rawStories);

  return stories;
};

const fetchStories = async () => {
  // logDebug(`DiscoverStoryFetch: fetchStories: >>> fetching stories for ${shortcutId}`);

  // if (!shortcutId || isNaN(shortcutId)) {
  //   console.warn(`DiscoverStoryFetch: fetchStories: invalid shortcutId`);
  //   return undefined;
  // }

  // const brandId = store.getters.selectedBrand?.id;
  // if (!brandId) {
  //   console.warn(`DiscoverStoryFetch: fetchStories: missing brand id`);
  //   return;
  // }

  // const response = await new DiscoverStoryService().getStories(shortcutId, brandId);

  // const stories = Array.isArray(response) ? response : [];

  // // Load stories even if empty, this will allow story manager to track empty categories

  // const primaryShortcut = store.getters.shortcuts?.find((s) => s.id == shortcutId);
  // const shortcuts = primaryShortcut ? [primaryShortcut] : undefined;

  // logDebug(`DiscoverStoryFetch: loading ${stories?.length ?? 0} stories for shortcut ${shortcutId}`);

  // await DiscoverStoryManager.loadStories(stories, DiscoverStoryFeedType.trending, {
  //   shortcuts,
  //   primaryShortcut,
  // });

  return [];
};

export type LoadedStoriesEvent = {
  shortcutId: number;
  stories: DiscoverStorySearch[];
  isUpdate?: boolean;
};

type FetchPromise = Promise<DiscoverStorySearch[] | undefined>;
type PromiseMap = Map<number, FetchPromise>;

class DiscoverStoryFetchClass extends EventEmitter {
  private readonly fetching: PromiseMap = new Map();

  public async fetchCategory(shortcutId: number, isUpdate = false, returnPending = false) {
    // If we already have the data and we are not requesting an update, return it
    const hasResults = DiscoverStoryManager.hasShortcutId(shortcutId);
    if (hasResults && !isUpdate) {
      logDebug(`DiscoverStoryFetch: has stories for ${shortcutId}, not fetching.`);

      const stories = await getStories(shortcutId);

      if (returnPending) {
        return Promise.resolve(stories);
      } else {
        this.emit('loadedStories', { shortcutId, stories, isUpdate });
        this.emit(`loadedStories:${shortcutId}`, { shortcutId, stories, isUpdate });
      }
    }

    // Look for an existing (pending) promise
    const existingPromise = this.fetching.get(shortcutId);
    if (existingPromise) {
      if (returnPending) {
        return existingPromise;
      } else {
        existingPromise.then((stories) => {
          logDebug(`DiscoverStoryFetch: loaded data from existing promise, emitting event for ${shortcutId}.`);
          this.emit('loadedStories', { shortcutId, stories, isUpdate });
          this.emit(`loadedStories:${shortcutId}`, { shortcutId, stories, isUpdate });
        });
        return;
      }
    }

    const stories = await fetchStories();

    this.fetching.delete(shortcutId);

    if (isUpdate) {
      this.emit('loadedStoriesUpdate', { shortcutId, stories, isUpdate });
      this.emit(`loadedStoriesUpdate:${shortcutId}`, { shortcutId, stories, isUpdate });
    } else {
      this.emit('loadedStories', { shortcutId, stories, isUpdate });
      this.emit(`loadedStories:${shortcutId}`, { shortcutId, stories, isUpdate });
    }
  }

  public async fetchAllCategories(isUpdate = false) {
    let shortcutIds: number[] = store.getters.shortcuts
      .map((s: DiscoverShortcutModel) => s.id)
      .filter(isNotNullOrUndefined);

    if (!isUpdate) {
      const needsShortcut = (shortcutId: number) =>
        !this.fetching.has(shortcutId) && !DiscoverStoryManager.hasShortcutId(shortcutId);
      shortcutIds = shortcutIds.filter(needsShortcut).filter(isNotNullOrUndefined);
    }

    const promises = Array.from(shortcutIds).map((shortcutId) => this.fetchCategory(shortcutId, isUpdate));
    if (!promises.length) {
      return;
    }

    await Promise.all(promises);
  }

  public async clearAndPrefetchSelectedCategories() {
    // Clear all previously cached categories

    await store.dispatch('clearShortcuts');

    await store.dispatch('fetchShortcuts');

    await this.prefetchSelectedCategories();
  }

  public async prefetchSelectedCategories() {
    const shortcutIds: number[] = store.getters.shortcuts
      .map((s: DiscoverShortcutModel) => s.id)
      .filter(isNotNullOrUndefined);
    logDebug(`DiscoverStoryFetch: prefetching ${shortcutIds.length} shortcuts`);

    const makePromise = (shortcutId: number) => {
      const promise = (async () => {
        logDebug(`DiscoverStoryFetch:    in (prefetch) promise: fetching ${shortcutId} stories`);
        const stories = [];
        // const stories = await fetchStories(shortcutId);
        logDebug(
          `DiscoverStoryFetch:    in (prefetch) promise: got ${stories?.length ?? '<undef>'} stories for ${shortcutId}.`
        );

        logDebug(`DiscoverStoryFetch:    in (prefetch) promise: deleting ${shortcutId} promise from map`);
        this.fetching.delete(shortcutId);

        return stories;
      })();

      return promise;
    };

    for (const shortcutId of shortcutIds) {
      const promise = makePromise(shortcutId);
      this.fetching.set(shortcutId, promise);
    }
  }
}

const DiscoverStoryFetch = new DiscoverStoryFetchClass();
export default DiscoverStoryFetch;
