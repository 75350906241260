var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"platform-post-preview"},[(_vm.tiktokPostDetails)?_c('section',{staticClass:"tiktok-preview"},[_c('div',{staticClass:"media-preview",style:({ backgroundImage: 'url(' + _vm.postImage + ')' })},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"main-section"},[_c('div',{staticClass:"profile-info"},[_c('div',{staticClass:"main-info"},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.userName))])]),_c('div',{staticClass:"post-preview"},[_c('p',{staticClass:"post-text"},[_vm._v(_vm._s(_vm.tiktokPostDetails.mainRequest.post))])])]),_c('div',{staticClass:"side-navigate"},[_c('div',{staticClass:"profile-image"},[_c('img',{attrs:{"src":_vm.profileImage,"alt":"profile image"}})]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_vm._m(6)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-bar"},[_c('div',{staticClass:"left-top"},[_c('span',[_vm._v("9:45")])]),_c('div',{staticClass:"right-top"},[_c('i',{staticClass:"material-icons"},[_vm._v("signal_cellular_alt")]),_c('i',{staticClass:"material-icons"},[_vm._v("wifi")]),_c('i',{staticClass:"material-icons battery"},[_vm._v("battery_full")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"navigate-bar"},[_c('div',{staticClass:"nav-icon-one"},[_c('i',{staticClass:"material-icons"},[_vm._v("stream")])]),_c('div',{staticClass:"nav-btns"},[_c('span',[_vm._v("Explore")]),_c('span',[_vm._v("Following")]),_c('span',[_vm._v("For You")])]),_c('div',{staticClass:"nav-icon-two"},[_c('i',{staticClass:"material-icons"},[_vm._v("search")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"side-icon-div"},[_c('i',{staticClass:"material-icons side-icon"},[_vm._v("favorite")]),_c('span',[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"side-icon-div"},[_c('i',{staticClass:"material-icons side-icon"},[_vm._v("chat_bubble")]),_c('span',[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"side-icon-div"},[_c('i',{staticClass:"material-icons side-icon"},[_vm._v("bookmark")]),_c('span',[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"side-icon-div"},[_c('i',{staticClass:"material-icons side-icon share"},[_vm._v("reply")]),_c('span',[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bottom-bar"},[_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("house")]),_c('span',[_vm._v("Home")])]),_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("people")]),_c('span',[_vm._v("Friends")])]),_c('div',{staticClass:"icon-group"},[_c('div',{staticClass:"tiktok-add"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("add")])])]),_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("inbox")]),_c('span',[_vm._v("Inbox")])]),_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("person")]),_c('span',[_vm._v("Profile")])])])
}]

export { render, staticRenderFns }