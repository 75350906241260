
import { getMaxValueFromChartDatasets } from '@/audience/core/utils/chart';
import { PlatformColors, PlatformLabels, convetToPlatformLabel } from '@/audience/data/socialPlatformChartConfig';
import type { ChartConfig } from '@/types/audience';
import { TimeRangeMode } from '@/types/audience/TimeRangeMode';
import { Snapshot } from '@/types/audience/per-post-metrics/Metrics';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import { ChartData, ChartOptions } from 'chart.js';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseLineChart from '../../base-charts/BaseLineChart.vue';
import NoMetricsBox from './NoMetricsBox.vue';
import {
  HighlightedMetric,
  chartOptions,
  chartScalesOptions,
  getChartLabels,
  getSnapshotMetricDataset,
  heighlightedMetricsByPlatform,
} from './metrics-logic';
import ShareMetricsModal from './share-metrics-modal/ShareMetricsModal.vue';
import Loading from '@/components/common/Loading.vue';

@Component({ components: { BaseLineChart, NoMetricsBox, ShareMetricsModal, Loading } })
export default class PlatformPostMetrics extends Vue {
  @Prop({ type: String, required: true }) platform!: PlatformLabels;
  @Prop({ type: String, required: true }) context!: 'impressions' | 'engagement' | 'audience';
  @Prop({ type: Array, required: true }) snapshots!: Snapshot[];
  @Prop({ type: String, required: true }) timeZone!: string;
  @Prop({ type: String, required: true }) timeRangeMode!: TimeRangeMode;
  @Prop({ type: Object }) selectedPost!: AudiencePost | undefined;

  selectedMetricsFields: string[] = [];

  showShareModel = false;
  generatingShareFile = false;
  metricsFile: File | undefined = undefined;

  get isEmpty() {
    return (
      this.snapshots.length <= 1 ||
      this.finalMetricsValues.some((metric) => !Number.isInteger(metric)) ||
      this.visibleMetricsFields.length === 0
    );
  }

  get metricsContext() {
    if (this.context === 'audience') {
      return 'impressions';
    }
    return this.context;
  }

  get platformColor() {
    return PlatformColors[this.platform.toUpperCase()];
  }

  get platformName() {
    return convetToPlatformLabel(this.platform);
  }

  get finalMetricsValues(): number[] {
    if (this.snapshots.length > 0) {
      const finalSnapshot = this.snapshots[this.snapshots.length - 1];

      return this.metricsFields.map((field) => finalSnapshot[field.key]);
    }

    return [];
  }

  get metricsFields() {
    return heighlightedMetricsByPlatform[convetToPlatformLabel(this.platform)] || [];
  }

  get visibleMetricsFields() {
    return this.metricsFields.filter((metric) => this.selectedMetricsFields.includes(metric.key));
  }

  get chartConfig(): ChartConfig {
    return {
      chartId: `${this.platform}-post-line-chart`,
      datasetIdKey: `${this.platform}-post-line-chart`,
      plugins: [],
      cssClasses: '',
      styles: {},
    };
  }

  get chartOptions(): ChartOptions {
    const maxCeil = getMaxValueFromChartDatasets(this.chartData.datasets);

    return {
      ...chartOptions,
      ...chartScalesOptions(maxCeil),
    };
  }

  get datasets() {
    return this.visibleMetricsFields.map((field) => ({
      label: field.displayText,
      data: getSnapshotMetricDataset(this.snapshots, field.key),
      borderColor: field.color,
      pointBackgroundColor: field.color,
    }));
  }

  get timeLabels() {
    return getChartLabels(this.snapshots, this.timeZone, this.timeRangeMode);
  }

  get chartData(): ChartData {
    return {
      labels: this.timeLabels,
      datasets: this.datasets,
    };
  }

  getRecentMetricValue(key: string) {
    if (this.snapshots.length > 0) {
      const mostRecentSnapshot = this.snapshots[this.snapshots.length - 1];
      return mostRecentSnapshot[key] || 0;
    }

    return 0;
  }

  handleSelectMetricField(field: string) {
    if (this.selectedMetricsFields.includes(field)) {
      this.selectedMetricsFields = this.selectedMetricsFields.filter((selectedField) => selectedField !== field);
    } else {
      this.selectedMetricsFields.push(field);
    }
  }

  isMetricSelected(field: string) {
    return this.selectedMetricsFields.includes(field);
  }

  async sharePostMetrics() {
    // await new Promise((res) => setTimeout(res, 700));
    const contentElement = document.getElementById('per-post-metrics-section');

    if (contentElement) {
      const clonedContentElement = contentElement?.cloneNode(true);

      // Clone the canvas with the context
      const originalGraphEl = contentElement.getElementsByTagName('canvas')[0];
      const clonedGraphEl = (clonedContentElement as HTMLElement).getElementsByTagName('canvas')[0];
      const context = clonedGraphEl.getContext('2d');
      if (context) {
        context.drawImage(originalGraphEl, 0, 0);
      }

      // Create and add the post data (name and date)
      const postData = document.createElement('div');

      const postName = document.createElement('h3');
      postName.innerText = this.selectedPost?.post || 'Post overview';

      const postDate = document.createElement('p');
      postDate.innerText = 'Posted at ' + moment(this.selectedPost?.dateCreated).format('MM/DD/YYYY HH:MM');

      postData.append(postName);
      postData.append(postDate);

      clonedContentElement.insertBefore(postData, clonedContentElement.firstChild);

      // Generate PDF from the metrics element
      try {
        const fileName = `post-metrics(${this.selectedPost?.postId}).pdf`;
        const exportOptions = {
          margin: 2,
          filename: fileName,
        };
        const blob = await html2pdf().set(exportOptions).from(clonedContentElement).outputPdf('blob');

        const metricsFile = new File([blob], fileName, { type: 'application/pdf' });
        this.metricsFile = metricsFile;

        this.showShareModel = true;
      } catch (err) {
        // console.log(err);
      } finally {
        this.generatingShareFile = false;
      }
    }
  }

  @Watch('metricsFields', { immediate: true })
  onMetricsFieldChange(newMetricsFields: HighlightedMetric[]) {
    this.selectedMetricsFields = newMetricsFields.map((metric) => metric.key);
  }
}
