
import { mapGetters } from 'vuex';
import moment, { MomentInput } from 'moment';

import '../styles.scss';

export default {
  name: 'trial-active-user',

  computed: {
    ...mapGetters(['getAdminEmail', 'currentPeriodEndsAt']),

    daysRemaining(): number {
      const expirationDate = moment(this.currentPeriodEndsAt as MomentInput);
      const now = moment();
      return expirationDate.diff(now, 'days');
    },
  },
};
