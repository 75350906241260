
import { ActiveSocialAccount, AyrshareProfile } from '@/types/audience';
import { Component, Vue, Prop } from 'vue-property-decorator';
import NoImagePlaceholder from '@/assets/no-image.png';
import { SocialPostBody } from '@/types/createandpublish/socialStory';
import { busEvents, eventBus } from '@/createandpublish/core/eventBus/socialPostEventBus';
import moment from 'moment';

@Component({
  name: 'YouTubePreview',
  components: {},
})
export default class YouTubePreview extends Vue {
  @Prop({ type: Object, required: false }) platformProfileData!: ActiveSocialAccount | null;
  @Prop({ type: Object, required: false }) audienceProfileData!: AyrshareProfile | null;
  @Prop({ type: Object, required: true }) currentPlatformPostDetails!: SocialPostBody;

  currentPostDetails: SocialPostBody | null = null;

  get currentDateTimeString() {
    return moment(new Date()).format('MMM DD, YYYY');
  }

  get youtubePostDetails() {
    return this.currentPostDetails ? (this.currentPostDetails as SocialPostBody) : null;
  }

  get youTubePostText() {
    return this.youtubePostDetails?.mainRequest?.post;
  }

  updateDraftPostDetails(postContent: SocialPostBody | undefined) {
    if (postContent?.mainRequest?.platforms?.includes('youtube')) {
      this.currentPostDetails = postContent;
    }
  }

  get profileImage() {
    return this.audienceProfileData?.image;
  }

  get profileName() {
    return this.platformProfileData?.platformUsername
      ? this.platformProfileData?.platformUsername
      : this.platformProfileData?.platformDisplayName;
  }

  get postImage() {
    if (!this.currentPostDetails?.mainRequest?.mediaUrls?.length) {
      return null;
    } else if (this.currentPostDetails?.platformOptions?.youTubeOptions?.thumbNail) {
      return this.currentPostDetails?.platformOptions?.youTubeOptions?.thumbNail;
    } else {
      return this.currentPostDetails?.mainRequest?.videoThumbnailUrl ?? NoImagePlaceholder;
    }
  }

  get videoIcon() {
    if (this.postImage) {
      return true;
    }
    return false;
  }

  get videoVisibility() {
    return this.currentPostDetails?.platformOptions?.youTubeOptions?.visibility;
  }

  get videoTitle() {
    return this.currentPostDetails?.platformOptions?.youTubeOptions?.title;
  }

  get platformPostOptions() {
    return this.currentPostDetails?.platformOptions?.youTubeOptions;
  }

  get youtubeTags() {
    const tags = this.platformPostOptions?.tags;
    return tags ? tags.map((tag) => '#' + `${tag.split(' ').join('')}`) : [];
  }

  mounted() {
    this.updateDraftPostDetails(this.currentPlatformPostDetails);
    eventBus.$on(busEvents.UPDATE_DRAFT_POST_DETAILS, this.updateDraftPostDetails);
  }
}
