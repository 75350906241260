
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'LinkIcon',
})
export default class LinkIcon extends Vue {
  @Prop({ type: String, required: false, default: '#1877F2' }) fill!: string;
  @Prop({ type: Number, required: false, default: 20 }) height!: number;
  @Prop({ type: Number, required: false, default: 20 }) width!: number;
}
