import AudienceNavTitles from '@/audience/data/leftNavTitles';
import type { RouteConfig } from 'vue-router';

import Dashboard from '@/audience/views/Dashboard.vue';
import ConnectSocial from '@/audience/views/ConnectSocial.vue';
const YouTube = () => import('@/audience/views/YouTube.vue');
const Instagram = () => import('@/audience/views/Instagram.vue');
const Facebook = () => import('@/audience/views/Facebook.vue');
const Twitter = () => import('@/audience/views/Twitter.vue');
const TikTok = () => import('@/audience/views/TikTok.vue');
const LinkedIn = () => import('@/audience/views/LinkedIn.vue');
const Pinterest = () => import('@/audience/views/Pinterest.vue');
const Leaderboard = () => import('@/audience/views/Leaderboard.vue');
const CreatorCommunity = () => import('@/audience/views/CreatorCommunity.vue');

export default [
  {
    path: '',
    name: 'Audience.Dashboard',
    component: Dashboard,
    meta: {
      sidebar_active_item: AudienceNavTitles.dashboard,
      title: 'Audience Analytics',
    },
  },
  {
    path: 'connect-social',
    name: 'Audience.ConnectSocial',
    component: ConnectSocial,
    meta: {
      sidebar_active_item: AudienceNavTitles.dashboard,
      title: 'Audience Analytics | Connect Social',
    },
  },
  {
    path: 'youtube',
    name: 'Audience.YouTube',
    component: YouTube,
    meta: {
      sidebar_active_item: AudienceNavTitles.youTube,
      title: 'Audience Analytics | YouTube',
    },
  },
  {
    path: 'instagram',
    name: 'Audience.Instagram',
    component: Instagram,
    meta: {
      sidebar_active_item: AudienceNavTitles.instagram,
      title: 'Audience Analytics | Instagram',
    },
  },
  {
    path: 'facebook',
    name: 'Audience.Facebook',
    component: Facebook,
    meta: {
      sidebar_active_item: AudienceNavTitles.facebook,
      title: 'Audience Analytics | Facebook',
    },
  },
  {
    path: 'twitter',
    name: 'Audience.Twitter',
    component: Twitter,
    meta: {
      sidebar_active_item: AudienceNavTitles.twitter,
      title: 'Audience Analytics | Twitter',
    },
  },
  {
    path: 'tiktok',
    name: 'Audience.TikTok',
    component: TikTok,
    meta: {
      sidebar_active_item: AudienceNavTitles.tikTok,
      title: 'Audience Analytics | TikTok',
    },
  },
  {
    path: 'linkedin',
    name: 'Audience.LinkedIn',
    component: LinkedIn,
    meta: {
      sidebar_active_item: AudienceNavTitles.linkedIn,
      title: 'Audience Analytics | LinkedIn',
    },
  },
  {
    path: 'pinterest',
    name: 'Audience.Pinterest',
    component: Pinterest,
    meta: {
      sidebar_active_item: AudienceNavTitles.pinterest,
      title: 'Audience Analytics | Pinterest',
    },
  },
  // {
  //   path: 'web',
  //   name: 'Audience.WebTraffic',
  //   component: null,
  //   meta: {
  //     sidebar_active_item: AudienceNavTitles.webTraffic,
  //     title: 'Audience Analytics | Web traffic',
  //   },
  // },
  {
    path: 'leaderboard',
    name: 'Audience.Leaderboard',
    component: Leaderboard,
    meta: {
      sidebar_active_item: AudienceNavTitles.leaderboard,
      title: 'Audience Analytics | Leaderboard',
    },
  },
  {
    path: 'community',
    name: 'Audience.CreatorCommunity',
    component: CreatorCommunity,
    meta: {
      sidebar_active_item: AudienceNavTitles.creatorCommunity,
      title: 'Audience Analytics | Creator Community',
    },
  },
  // {
  //   path: 'settings',
  //   name: 'Audience.Settings',
  //   component: null,
  //   meta: {
  //     sidebar_active_item: AudienceNavTitles.settings,
  //     title: 'Audience Analytics | Settings',
  //   },
  // },
] as RouteConfig[];
