import store from '@//store';
import { removeDisabledProducts } from '@/utils/products/removeDisabledProducts';

import type { SSE } from '@/types/SSE';
import type {
  // AudienceNotificationSocialAnalyticsRefreshedData as SocialAnalyticsData,
  AudienceNotificationSocialChannelConnectedData as ConnectedChannelData,
  AudienceNotificationSocialChannelDisconnectedData as DisconnectedChannelData,
  AudienceNotificationSocialChannelRefreshedData as ChannelRefreshedData,
} from 'content-cloud-types/dist/types/audience/Notification';
import type { ChargifySSEPayload, ChargifyWebhookEvent } from '@/types/ecomm';

export const socialAnalyticsRefreshed = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_analytics_refreshed', (_data) => {
    const socialPlatform = _data?.platform;
    if (socialPlatform) {
      store.dispatch('AudienceAnalyticsStore/fetchPlatformPosts', socialPlatform);
    }
    store.dispatch('AudienceAnalyticsStore/getSocialAnalytics');
  });
};

export const socialChannelConnected = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_channel_connected', (data: ConnectedChannelData) => {
    store.dispatch('AudienceAnalyticsStore/getProfile', true);
  });
};

export const socialChannelDisconnected = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_channel_disconnected', (data: DisconnectedChannelData) => {
    store.dispatch('AudienceAnalyticsStore/getProfile', true);
  });
};

export const socialChannelRefreshed = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_channel_refreshed', (data: ChannelRefreshedData) => {
    store.dispatch('AudienceAnalyticsStore/getProfile', true);
  });
};

export const subscriptionUpdate = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const chargifyEvents: ChargifyWebhookEvent[] = [
    'component_allocation_change',
    'signup_success',
    'subscription_product_change',
    'subscription_state_change',
    'renewal_failure',
  ];
  // These get sent from the backend with the same event names Chargify uses to classify their own webhook events
  chargifyEvents.forEach((eventName) => {
    msgServer.subscribe(eventName, (data: ChargifySSEPayload) => {
      const accountData = data.payload;
      const updatedAccountData = {
        ...accountData,
        products: removeDisabledProducts(accountData.products, store.getters.features),
      };
      store.commit('setUserProducts', updatedAccountData.products);
      store.commit('setData', updatedAccountData);
    });
  });
};
