
import { ActiveSocialAccount, AyrshareProfile } from '@/types/audience';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SocialPostBody } from '@/types/createandpublish/socialStory';
import { busEvents, eventBus } from '@/createandpublish/core/eventBus/socialPostEventBus';

@Component({
  name: 'InstagramPreview',
  components: {},
})
export default class InstagramPreview extends Vue {
  @Prop({ type: Object, required: false }) platformProfileData!: ActiveSocialAccount | null;
  @Prop({ type: Object, required: false }) audienceProfileData!: AyrshareProfile | null;
  @Prop({ type: Object, required: true }) currentPlatformPostDetails!: SocialPostBody;

  currentPostDetails: SocialPostBody | null = null;
  currentCarouselIndex = 0;

  onCarouselIndexChange() {
    const carouselTrain = this.$el.querySelector('.carousel-train') as HTMLElement;
    if (carouselTrain) {
      carouselTrain.style.left = `-${this.currentCarouselIndex * 100}%`;
    }
  }

  setNewCarouselIndex(dir: string) {
    this.currentCarouselIndex = dir === 'next' ? this.currentCarouselIndex + 1 : this.currentCarouselIndex - 1;
    this.onCarouselIndexChange();
  }

  get instagramPostDetails() {
    return this.currentPostDetails ? (this.currentPostDetails as SocialPostBody) : null;
  }

  updateDraftPostDetails(postContent: SocialPostBody | undefined) {
    if (postContent?.mainRequest?.platforms?.includes('instagram')) {
      this.currentPostDetails = postContent;
    }
  }

  get profileImage() {
    return this.audienceProfileData?.image;
  }

  get profileName() {
    return this.platformProfileData?.platformUsername
      ? this.platformProfileData?.platformUsername
      : this.platformProfileData?.platformDisplayName;
  }

  get postImages() {
    if (!this.currentPostDetails?.mainRequest?.mediaUrls?.length) {
      return null;
    }
    if (this.currentPostDetails?.mainRequest?.isVideo) {
      return [this.currentPostDetails?.mainRequest?.videoThumbnailUrl];
    }
    return this.currentPostDetails?.mainRequest.mediaUrls;
  }

  get videoIcon() {
    if (this.postImages?.length === 1 && this.currentPostDetails?.mainRequest?.isVideo) {
      return true;
    }
    return false;
  }

  // TODO - this is not currently in use, but will be important
  get platformPostOptions() {
    return this.currentPostDetails?.platformOptions?.instagramOptions;
  }

  mounted() {
    this.updateDraftPostDetails(this.currentPlatformPostDetails);
    eventBus.$on(busEvents.UPDATE_DRAFT_POST_DETAILS, this.updateDraftPostDetails);
  }
}
