
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Product } from '@/types/Product';
import { getProductByIdByAttribute, getProductUrlById } from '@/utils/products';
import { AuthenticatedUser } from '@/models/UserModel';
import { Brand } from '@/types/Brand';

@Component({
  name: 'ProfileDropdownProduct',
})
export default class ProfileDropDownProduct extends Vue {
  @Prop({ type: Number, default: 0 }) id!: Product['id'];
  @Prop({ type: String, default: '' }) name!: Product['name'];

  @Getter getAdminEmail!: string;
  @Getter authenticatedUser!: AuthenticatedUser;
  @Getter selectedBrand!: Brand;
  @Getter userProducts;
  @Getter isAdmin!: boolean;
  @Getter userBrands!: Brand[];

  get mailAdminHref() {
    return `mailto: ${this.getAdminEmail}`;
  }

  get noAccessCopy() {
    return 'No Access to ' + this.name;
  }

  get logo() {
    return getProductByIdByAttribute(this.id, 'logoHeader');
  }

  /**
   * Drives `locked` product icon. `true` if product is enabled and user has at least 1 brand available.
   * Name has `product` but no `brand` in it because this is driving product items.
   */
  get isProductActive() {
    let hasProductAccess = false;
    let hasBrandAccess = false;
    // If user has access to the specific product
    this.userProducts?.forEach((product) => {
      if (product.id === this.id) {
        hasProductAccess = true;
      }
    });
    // If user has access to at least 1 brand
    if (this.userBrands?.length > 0) {
      hasBrandAccess = true;
    }
    return hasProductAccess && hasBrandAccess;
  }

  get getProductRoute() {
    return `${getProductUrlById(this.id)}/${this.selectedBrand.id}`;
  }
}
