
import { Component, Vue, Prop } from 'vue-property-decorator';

import DoughnutChartPlaceholder from '@/assets/audience/dashboard-doughnut-chart/doughnut-chart-placeholder.png';
import LineChartPlaceholder from '@/assets/audience/dashboard-doughnut-chart/stacked-line-chart-placeholder.png';
import DailyChartPlaceholder from '@/assets/audience/dashboard-doughnut-chart/daily-chart-placeholder.png';

@Component({
  name: 'NewUserNoPlatformsPlaceholderDesktop',
})
export default class NewUserNoPlatformsPlaceholderDesktop extends Vue {
  @Prop({ type: String, required: true, default: 'doughnut' }) chartType!: 'doughnut' | 'line' | 'daily';

  get placeholderImgSrc(): string {
    if (this.chartType === 'doughnut') {
      return DoughnutChartPlaceholder;
    } else if (this.chartType === 'line') {
      return LineChartPlaceholder;
    } else if (this.chartType === 'daily') {
      return DailyChartPlaceholder;
    } else {
      return LineChartPlaceholder;
    }
  }

  get imageClasses() {
    return ['chart-image', { doughnut: this.chartType === 'doughnut' }];
  }
}
