export const timeZoneOptions = [
  { label: 'US/Alaska (AKST AKDT)', value: 'America/Anchorage' },
  { label: 'US/Aleutian (HST HDT)', value: 'America/Adak' },
  { label: 'America/Phoenix (MST)', value: 'America/Phoenix' },
  { label: 'US/Central (CST CDT)', value: 'America/Chicago' },
  { label: 'US/Eastern (EST EDT)', value: 'America/New_York' },
  { label: 'US/Hawaii (HST)', value: 'Pacific/Honolulu' },
  { label: 'US/Mountain (MST MDT)', value: 'America/Denver' },
  { label: 'US/Pacific (PST PDT)', value: 'America/Los_Angeles' },
  { label: 'US/Samoa (SST)', value: 'Pacific/Pago_Pago' },
] as const;

export const maybeGetLocalTimeZone = () => {
  if (!window.Intl) return undefined;

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZoneOptions.find(({ value }) => value === localTimeZone)?.value;
};
