
import { Component, Vue, Prop } from 'vue-property-decorator';

import MetricListItem from '@/audience/components/MetricListItem.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';

import type { Metric } from '@/types/audience';

@Component({
  name: 'MetricList',
  components: {
    MetricListItem,
    NoChartDataBanner,
  },
})
export default class MetricList extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Array, required: true }) metrics!: MetricItem[];
  @Prop({ type: String, required: true }) context!: 'audience' | 'impressions' | 'engagement';

  get showNoDataBanner() {
    return this.metrics.every(({ value }) => isNaN(value) || value === 0);
  }
}

interface MetricItem extends Metric {
  percentChange: number;
  numericChange: number;
}
