/**
 * @overview For use with the SocialStoryDrawer related views and their children, found in
 * @/src/createandpublish/components/socialStories/SocialStoryDrawer.vue
 * @author Brian Pennington
 */

import Vue from 'vue';

/**
 * Event bus for the SocialStoryDrawer.
 *
 * Usage:
 * @property {Function} $on register event listener; takes event type, handler params.
 * @property {Function} $off unregister event listener; takes event type, handler params.
 * @property {Function} $emit emit an event; takes event type, payload object params.
 */
export const eventBus = new Vue();

/**
 * @enum busEvents is an enum for all event types that may be published to this event bus.
 *
 * @property {String} VALIDATE_POST Tells Platform Post components to validate and report their error state
 * @property {String} ADD_PREFILL_CONTENT Tells Platform Post components to add prefill content to valid fields in the editor and should receive a ({ data, label }) parameter.
 * @property {String} ADD_MEDIA_FROM_LIBRARY Tells Platform Post components to add media from the library to the editor and should receive a (ImageAsset | VideoAsset) parameter.
 */
export const busEvents = {
  // Error Events
  VALIDATE_POST: 'validatePost',
  UPDATE_DRAFT_POST_DETAILS: 'updateDraftPostDetails',
  // Prefill Events
  ADD_PREFILL_CONTENT: 'addPrefillContent',
  ADD_MEDIA_FROM_LIBRARY: 'addMediaFromLibrary',
} as const;
