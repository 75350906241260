
import { Prop, Vue, Component } from 'vue-property-decorator';
import type { AlertMessage } from '@/types/AlertMessage';

@Component({
  name: 'AlertDisplay',
})
export default class AlertDisplay extends Vue {
  @Prop() alertMessage!: AlertMessage;
  @Prop() drawer!: boolean;
  // Probably need a close alert prop function

  alertType = {
    critical: {
      icon: 'exclamation-circle-fill',
      style: {
        backgroundColor: '#CB2610',
      },
      linkStyle: {
        color: '#CB2610',
      },
      iconColor: {
        color: '#CB2610',
      },
    },
    warning: {
      icon: 'exclamation-triangle-fill',
      style: {
        backgroundColor: '#F58120',
      },
      linkStyle: {
        color: '#0174B7',
        fontWeight: '500',
      },
      iconColor: {
        color: '#F58120',
      },
    },
    success: {
      icon: 'check2',
      style: {
        backgroundColor: 'green',
      },
      linkStyle: {
        color: 'green',
      },
      iconColor: {
        color: 'green',
      },
    },
    info: {
      icon: 'info-circle',
      style: {
        backgroundColor: '#42bfc0',
      },
      linkStyle: {
        color: '#0174b7',
      },
      iconColor: {
        color: '#42bfc0',
      },
    },
    ecommInfo: {
      icon: 'info-circle',
      style: {
        backgroundColor: '#41C0C0',
      },
      linkStyle: {
        color: '#0174b7',
      },
      iconColor: {
        color: '#41C0C0',
      },
    },
  };

  onLinkClick(navigate: (e: Event) => void, $event: Event, eventName?: string) {
    if (eventName) {
      $event.preventDefault();
      this.$emit(eventName);
      return;
    }
    navigate($event);
  }
}
