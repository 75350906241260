import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import type { Moment } from 'moment-timezone';
import type { ChartDataset } from 'chart.js';
import type {
  ChartDataByPlatform,
  SocialAnalytics,
  Dataset,
  ActiveSocialAccount,
  PlatformAnalyticsContext,
} from '@/types/audience';
import type { SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialRanking';
import { calculatePercentChange } from '@/audience/data/chart-plugins/util';

/**
 * Transforms social platform analytics data into an easy-to-consume format for use in the various charts
 * @param {String} label the name of one of the audience module platforms
 * @param socialAnalytics "extendedSocialAnalytics" getter from the audience module
 * @returns social analytics data transformed for chart
 */
export function getChartDataByPlatform(label: string, socialAnalytics: SocialAnalytics): ChartDataByPlatform {
  const socialAnalyticsValues = Object.values(socialAnalytics);

  const audienceValues = socialAnalyticsValues.map((dataByDate) => {
    const platform = dataByDate[label];
    if (!platform) return NaN;
    return platform.followers;
  });
  const audienceData = [{ label: 'Followers', data: audienceValues }];

  const audienceDailyValues = socialAnalyticsValues.map((dataByDate) => {
    const platform = dataByDate[label];
    if (!platform) return NaN;
    return platform.dailyFollowers;
  });
  const audienceDailyData = [{ label: 'Daily Followers', data: audienceDailyValues }];

  const breakdownsByType: Dataset[][] | null = [
    'impressionsBreakdown',
    'engagementBreakdown',
    'dailyEngagementBreakdown',
    'dailyImpressionsBreakdown',
  ].map((metricType) => {
    // Find an object that has the breakdown data we need so we can construct a model
    const dateDataWithBreakdown = socialAnalyticsValues.find((dataByDate) => {
      const platform = dataByDate[label];
      if (!platform) return false;
      return Object.prototype.hasOwnProperty.call(platform, metricType);
    });
    if (!dateDataWithBreakdown) return null;

    // Model the data
    const metricsByBreakdownType =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dateDataWithBreakdown[label]![metricType].map((metric) => ({ label: metric.label, data: [] })) || [];

    // Fill the model with data
    metricsByBreakdownType.forEach((_, i, self) => {
      self[i].data = socialAnalyticsValues.map((dataByDate) => {
        const value = dataByDate[label]?.[metricType][i]?.value;
        return value !== undefined ? value : NaN;
      });
    });
    return metricsByBreakdownType;
  });

  return {
    audience: audienceData,
    impressions: breakdownsByType[0],
    engagement: breakdownsByType[1],
    audienceDaily: audienceDailyData,
    engagementDaily: breakdownsByType[2],
    impressionsDaily: breakdownsByType[3],
  };
}

/**
 * Provides the summed value of all metric data for a given context and date.
 * @param platformData as produced by the chart util getChartDataByPlatform
 * @param context metric context
 * @param dataIndex index in a chart dataset representing the date you want data for
 * @returns summed value of all metrics for a given context and dataIndex
 */
export function getMetricTotalsByContextAndDataIndex(
  platformData: ChartDataByPlatform,
  context: PlatformAnalyticsContext,
  dataIndex: number
): number {
  const hasNoValidData = platformData[context]?.every((metric) => isNaN(metric.data[dataIndex]));
  if (hasNoValidData) {
    // Every value in all datasets are NaN.
    return NaN;
  }

  return (
    platformData[context]?.reduce((acc: number, metric) => {
      const { data } = metric;
      const dataIndexValue = data[dataIndex];
      acc += isNaN(dataIndexValue) ? 0 : dataIndexValue;
      return acc;
    }, 0) ?? 0
  );
}

export function metricGroupKeyByContext(context: PlatformAnalyticsContext): string {
  let metricGroupKey = '';
  switch (context) {
    case 'audience':
      metricGroupKey = 'followers';
      break;
    case 'impressions':
      metricGroupKey = 'impressionsBreakdown';
      break;
    case 'engagement':
      metricGroupKey = 'engagementBreakdown';
      break;
  }
  return metricGroupKey;
}

/**
 * Takes API data and creates objects for every date in the selected date range.
 * Also remaps the platform labels to proper casing.
 */
export function extendSocialAnalytics(
  startDate: Moment,
  endDate: Moment,
  socialAnalytics: SocialAnalytics,
  activeSocialAccounts: ActiveSocialAccount[]
): SocialAnalytics {
  const numDays = endDate.clone().startOf('day').diff(startDate.clone().startOf('day'), 'days') + 1;

  const dateLabels = new Array(numDays).fill(undefined).map((_, i) => {
    return startDate.clone().startOf('day').add(i, 'day').format('YYYY-MM-DD');
  });

  const platformKeyMapping: Record<string, PlatformLabels> = Object.values(PlatformLabels).reduce((acc, value) => {
    acc[value.toLowerCase()] = value; // Backend returns lower-case
    return acc;
  }, {});

  const activeSocialAccountsLabels: SocialPlatform[] = activeSocialAccounts.map(({ platform }) => platform) || [];

  const getPlatformDataByDateLabel = (dateLabel: string) => {
    const socialAnalyticsByDate = socialAnalytics[dateLabel];

    if (!socialAnalyticsByDate) {
      return activeSocialAccountsLabels.reduce((acc, label) => {
        acc[platformKeyMapping[label]] = null;
        return acc;
      }, {});
    }

    return activeSocialAccountsLabels.reduce((acc, label) => {
      const platformData = socialAnalyticsByDate[label];
      acc[platformKeyMapping[label]] = platformData ?? null;
      return acc;
    }, {});
  };

  return dateLabels.reduce((acc, dateLabel) => {
    acc[dateLabel] = getPlatformDataByDateLabel(dateLabel);
    return acc;
  }, {});
}

/**
 * Determines whether or not a group of datasets' data properties contain
 * any values other than 0 or NaN.
 * @param datasets An array of Chart.js datasets
 * @returns true if datasets data has any valid non-zero values
 */
export function chartHasNonZeroData(datasets: ChartDataset[]) {
  return datasets.some(({ data }) => {
    const result = data.reduce((acc: number, value) => {
      if (typeof value !== 'number') return acc;
      acc += isNaN(value) ? 0 : value;
      return acc;
    }, 0);
    return result > 0;
  });
}

/**
 * Determines whether or not data will be displayed on a line chart
 * @param datasets An array of Chart.js datasets
 * @returns true if at least one dataset has two or more valid, non-zero values
 */
export function lineChartHasDisplayableData(datasets: ChartDataset[]) {
  return datasets.some(({ data }) => {
    const filteredData = data.filter((dataPoint) => {
      return typeof dataPoint === 'number' && !isNaN(dataPoint) && dataPoint > 0;
    });
    return filteredData.length >= 2;
  });
}

export function getContextTotals(
  platformLabel: PlatformLabels,
  context: PlatformAnalyticsContext,
  platformData: ChartDataByPlatform
) {
  const title = `${platformLabel} ${context}`;
  const len = platformData[context]?.[0]?.data.length ?? 0;
  const initialValue = getMetricTotalsByContextAndDataIndex(platformData, context, 0);
  const finalValue = getMetricTotalsByContextAndDataIndex(platformData, context, len - 1);
  const percentChange = calculatePercentChange(initialValue, finalValue);
  const numericChange = finalValue - initialValue;
  return {
    title,
    value: finalValue,
    percentChange,
    numericChange,
  };
}

/**
 * Converts a date string into a Date object
 * @param {String} dateStr a string in the format YYYY-MM-DD
 * @returns {Date} Date object
 */
export const makeDate = (dateStr: string): Date => {
  const [year, month, day] = dateStr.split('-');
  return new Date(`${year}-${month}-${day}T00:00:00`);
};

/**
 * Get the maximum value from an array of chart datasets
 * @param datasets Chart dataset array
 * @returns max value
 */
export const getMaxValueFromChartDatasets = (datasets: ChartDataset[]) => {
  // get array of "stacked" total values
  const finalArray = datasets.reduce((acc: number[], dataset, index) => {
    const data = dataset.data as number[];
    const cleanData = data.map((n) => {
      return isNaN(n) ? 0 : n;
    });
    if (index === 0) {
      return cleanData;
    }
    for (let i = 0; i < cleanData.length; i++) {
      acc[i] = acc[i] + cleanData[i];
    }
    return acc;
  }, []);
  // get highest "stacked" value from array of totals
  const maxValue = Math.max(...finalArray);
  // find the floored log10 value (aka - how many times can we divide by 10 and still have a non-zero digit in the ones place...)
  const log10MaxRes = Math.floor(Math.log10(maxValue));
  // use log10 value to find the interval size (i.e. max of 6345 --> interval 1000)
  const interval = Math.pow(10, log10MaxRes);
  // use interval size to get the rounded min (ex: 6345 --> 6000)
  const roundedMin = Math.floor(maxValue / interval) * interval;
  // take rounded min and add one interval to get maxCeil (max value for y axis)
  const maxCeil = roundedMin + interval;

  return maxCeil;
};
