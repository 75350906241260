import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import type { Module } from 'vuex';
import type { RootState } from '@/types/store';
import type {
  GetMediaAssetsQueryParams,
  ImageAsset,
  PaginatedAssetsResponse,
  PaginationMetadata,
} from '@/types/createandpublish/mediaLibraries';

const MediaService = new CreateAndPublishMediaService();
const defaultPaginationValue: PaginationMetadata = {
  current_page: 1,
  limit: 10,
  total_pages: 0,
  total_count: 0,
};

export default {
  namespaced: true,

  state: {
    imageQuery: '',
    imageAssets: [],
    imagePagination: defaultPaginationValue,
  },

  getters: {
    imageAssets(state) {
      return state.imageAssets;
    },

    imagePagination(state) {
      return state.imagePagination;
    },
  },

  mutations: {
    SET_IMAGE_ASSETS(state, paginatedImageAssets: PaginatedAssetsResponse<ImageAsset>) {
      state.imagePagination = paginatedImageAssets.pagination;
      state.imageAssets = paginatedImageAssets.data;
    },

    CLEAR_IMAGE_ASSETS(state) {
      state.imageAssets = [];
      state.imagePagination = defaultPaginationValue;
    },

    UPDATE_IMAGE_ASSET(state, updatedAssetData: ImageAsset) {
      const index = state.imageAssets.findIndex((asset) => asset.id === updatedAssetData.id);
      if (index !== -1) {
        const updatedAsset = {
          ...state.imageAssets[index],
          ...updatedAssetData,
        };
        state.imageAssets.splice(index, 1, updatedAsset);
      }
    },

    DELETE_IMAGE_ASSET(state, assetId: number) {
      const index = state.imageAssets.findIndex((asset) => asset.id === assetId);
      if (index !== -1) {
        state.imageAssets.splice(index, 1);
      }
    },
  },

  actions: {
    async getImageAssets({ dispatch, commit }, params: GetMediaAssetsQueryParams) {
      const mediaType = 'image';
      await handleAjax({
        request: MediaService.getMediaLibraryItems(mediaType, params),
        dispatch,
        commit,
        mutation: 'SET_IMAGE_ASSETS',
      });
    },

    async updateImageAsset({ dispatch, commit }, imageAsset: ImageAsset) {
      const mediaType = 'image';
      await handleAjax({
        request: MediaService.updateMediaLibraryItem(mediaType, imageAsset),
        dispatch,
        commit,
        mutation: 'UPDATE_IMAGE_ASSET',
      });
    },

    async deleteImageAsset({ dispatch, commit }, assetId: number) {
      const mediaType = 'image';
      await handleAjax({
        request: MediaService.deleteMediaLibraryItem(mediaType, assetId),
        dispatch,
        commit,
        mutation: 'DELETE_IMAGE_ASSET',
        modify() {
          return assetId;
        },
      });
    },
  },
} as Module<ImageLibraryState, RootState>;

/**
 * Module state
 */
export interface ImageLibraryState {
  imageAssets: ImageAsset[];
  imagePagination: PaginationMetadata;
  imageQuery: string;
}
