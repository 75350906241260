import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import AudienceServiceModule from '@/services/AudienceService';
import { extendSocialAnalytics } from './utils/chart';
import { setupSSE } from '@/audience/eventsource/index';
import moment from 'moment-timezone';

import { SocialRankingInterval } from 'content-cloud-types/dist/types/audience/SocialRanking';
import { SocialRankingContext } from '@/types/audience';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';
import type { SSE } from '@/types/SSE';
import type {
  AudienceLeaderboardContent,
  AudienceLeaderboardCalculationRecord,
} from 'content-cloud-types/dist/types/audience/AudienceLeaderboardTypes';
import type {
  AyrshareProfile,
  DateRange,
  GrowthTip,
  SocialAnalytics,
  LeaderboardsByInterval,
  ActiveSocialAccount,
  LeaderboardRankData,
} from '@/types/audience';
import { maybeGetLocalTimeZone } from '@/constants/store/timeZones';
import NativePlatformsService from '@/services/NativePlatformsService';
import { AxiosResponse } from 'axios';
import { AudiencePost } from '@/types/audience/per-post-metrics/Post';
import NativePostsService from '@/services/per-post/NativePostsService';
import { PlatformLabels } from '../data/socialPlatformChartConfig';

const AudienceService = new AudienceServiceModule();

const timeZone = maybeGetLocalTimeZone() ?? 'America/New_York';

const DISMISSED_TIP_STORE_LABEL = '__ccDismissedGrowthTips';

const ensureDismissedTipStorage = () => {
  let storage = window.localStorage.getItem(DISMISSED_TIP_STORE_LABEL);
  if (!storage) {
    storage = JSON.stringify([]);
    window.localStorage.setItem(DISMISSED_TIP_STORE_LABEL, storage);
  }
};

export const initialState: AudienceAnalyticsState = {
  dateRange: {
    start: moment().subtract(30, 'day').tz(timeZone).format('YYYY-MM-DD'),
    end: moment().tz(timeZone).format('YYYY-MM-DD'),
  },
  profile: null,
  socialAnalytics: {},
  growthTips: [],
  leaderboards: null,
  leaderboardSelfAnalytics: {},
  msgServer: null,
  connectedPlatforms: [],
  platformPosts: [],
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    timeZone(_s, _g, _rs, rootGetters) {
      return rootGetters.selectedBrandTimeZone ?? timeZone;
    },

    dateRange(state) {
      return state.dateRange;
    },

    profile(state) {
      return state.profile;
    },

    isProfileOptedIntoLeaderboard(state) {
      return state.profile?.leaderboardOptIn ?? false;
    },

    // for ayrshare
    activeSocialAccounts(state) {
      return state.profile?.activeSocialAccounts ?? [];
    },

    // for native integration
    connectedPlatforms(state) {
      return state.connectedPlatforms ?? [];
    },

    growthTips(state) {
      ensureDismissedTipStorage();
      // const dismissedTips = JSON.parse(window.localStorage.getItem(DISMISSED_TIP_STORE_LABEL) as string);
      // return state.growthTips.filter((tip) => !dismissedTips.includes(tip.id));
      return state.growthTips;
    },

    leaderboards(state) {
      return state.leaderboards;
    },

    leaderboardSelfAnalytics(state) {
      return state.leaderboardSelfAnalytics;
    },

    leaderboardRankings(state): LeaderboardsByInterval {
      if (!state.leaderboards) {
        return Object.values(SocialRankingInterval).reduce((acc, rankingInterval) => {
          acc[rankingInterval] = {
            [SocialRankingContext.total_reach]: [],
            [SocialRankingContext.growth_rate]: [],
          };
          return acc;
        }, {});
      }

      const { rankings, profiles } = state.leaderboards;
      const rankingIntervals = Object.keys(rankings) as SocialRankingInterval[];

      return rankingIntervals.reduce((acc, rankingInterval) => {
        const totalReachProfiles =
          rankings[rankingInterval]?.brands
            ?.map((rankData: AudienceLeaderboardCalculationRecord) => {
              return {
                value: {
                  current: rankData.followers_cur,
                  previous: rankData.followers_prev,
                  percentChange: rankData.followers_growth_cur,
                },
                rank: {
                  current: rankData.followers_rank_cur,
                  previous: rankData.followers_rank_prev,
                  rankDifference: rankData.followers_rank_diff,
                },
                profile: profiles?.[rankData.brand_id],
              };
            })
            .sort((a, b) => (a.rank.current ?? NaN) - (b.rank.current ?? NaN)) ?? ([] as LeaderboardRankData[]);

        const growthRateProfiles =
          rankings[rankingInterval]?.brands
            ?.map((rankData: AudienceLeaderboardCalculationRecord) => {
              return {
                value: {
                  current: rankData.followers_growth_cur,
                  previous: rankData.followers_growth_prev,
                },
                rank: {
                  current: rankData.followers_growth_rank_cur,
                  previous: rankData.followers_growth_rank_prev,
                  rankDifference: rankData.followers_growth_rank_diff,
                },
                profile: profiles?.[rankData.brand_id],
              };
            })
            .sort((a, b) => (a.rank.current ?? NaN) - (b.rank.current ?? NaN)) ?? ([] as LeaderboardRankData[]);

        acc[rankingInterval] = {
          [SocialRankingContext.total_reach]: totalReachProfiles,
          [SocialRankingContext.growth_rate]: growthRateProfiles,
        };
        return acc;
      }, {});
    },

    socialAnalytics(state) {
      return state.socialAnalytics;
    },

    platformPosts(state) {
      return state.platformPosts;
    },

    extendedSocialAnalytics(_state, getters): SocialAnalytics {
      const startDate = moment.tz(getters.dateRange.start, getters.timeZone);
      const endDate = moment.tz(getters.dateRange.end, getters.timeZone);
      return extendSocialAnalytics(
        startDate,
        endDate,
        getters.socialAnalytics,
        getters.profile?.activeSocialAccounts ?? []
      );
    },

    socialAnalyticsBasePayload(state, getters) {
      const timeZone = getters.timeZone;
      const start = moment.tz(state.dateRange.start, timeZone).unix();
      const end = moment.tz(state.dateRange.end, timeZone).unix();
      return {
        start,
        end,
        platforms: 'all',
      };
    },

    msgServer(state) {
      return state.msgServer;
    },
  },

  mutations: {
    SET_DATE_RANGE(state, dateRange: DateRange) {
      state.dateRange = dateRange;
    },

    SET_PROFILE(state, profile: AyrshareProfile) {
      state.profile = profile;
    },

    SET_CONNECTED_PLATFORMS(state, connectedPlatforms: ActiveSocialAccount[]) {
      if (state.profile) {
        state.profile.activeSocialAccounts = connectedPlatforms;
      }
      state.connectedPlatforms = connectedPlatforms;
    },

    ADD_OR_UPDATE_ACTIVE_PROFILE(state, activeAccount: ActiveSocialAccount) {
      const activeSocialAccounts = state.profile?.activeSocialAccounts;
      if (!activeSocialAccounts) return;
      const accountIndex = activeSocialAccounts.findIndex((account) => account.platform === activeAccount.platform);
      if (accountIndex === -1) {
        activeSocialAccounts.push(activeAccount);
      } else {
        activeSocialAccounts.splice(accountIndex, 1, activeAccount);
      }
    },

    REMOVE_ACTIVE_PROFILE(state, deletedAccount: ActiveSocialAccount) {
      const activeSocialAccounts = state.profile?.activeSocialAccounts;
      if (!activeSocialAccounts) return;
      const accountIndex = activeSocialAccounts.findIndex((account) => account.platform === deletedAccount.platform);
      if (accountIndex !== -1) {
        activeSocialAccounts.splice(accountIndex, 1);
      }
    },

    CLEAR_PROFILE(state) {
      state.profile = null;
    },

    SET_SOCIAL_ANALYTICS(state, socialAnalytics: SocialAnalytics) {
      state.socialAnalytics = socialAnalytics;
    },

    SET_PLATFORM_POSTS(state, posts: AudiencePost[]) {
      state.platformPosts = posts;
    },

    CLEAR_SOCIAL_ANALYTICS(state) {
      state.socialAnalytics = {};
    },

    SET_GROWTH_TIPS(state, growthTips: GrowthTip[]) {
      state.growthTips = growthTips;
    },

    CLEAR_GROWTH_TIPS(state) {
      state.growthTips = [];
    },

    DISMISS_GROWTH_TIP() {
      ensureDismissedTipStorage();
      // const dismissedTips = JSON.parse(window.localStorage.getItem(DISMISSED_TIP_STORE_LABEL) as string);
      // dismissedTips.push(id);
      // window.localStorage.setItem(DISMISSED_TIP_STORE_LABEL, JSON.stringify(dismissedTips));
      // const tipIndex = state.growthTips.findIndex((tip) => tip.id === id);
      // if (tipIndex !== -1) {
      //   state.growthTips.splice(tipIndex, 1);
      // }
    },

    SET_LEADERBOARDS(state, leaderboards) {
      state.leaderboards = leaderboards;
    },

    CLEAR_LEADERBOARDS(state) {
      state.leaderboards = null;
    },

    SET_LEADERBOARD_SELF_ANALYTICS(state, leaderboardSelfAnalytics) {
      state.leaderboardSelfAnalytics = leaderboardSelfAnalytics;
    },

    CLEAR_LEADERBOARD_SELF_ANALYTICS(state) {
      state.leaderboardSelfAnalytics = {};
    },

    SET_OPT_IN_STATUS(state, leaderboardOptIn: boolean) {
      if (state.profile) {
        state.profile.leaderboardOptIn = leaderboardOptIn;
      }
    },

    SET_MESSAGE_SERVER(state, msgServer: SSE) {
      state.msgServer = msgServer;
    },

    CLEAR_MESSAGE_SERVER(state) {
      state.msgServer?.close();
      state.msgServer = null;
    },
  },

  actions: {
    getProfile({ dispatch, commit }, ignoreCached = false) {
      return handleAjax({
        request: AudienceService.getAyrShareProfile(ignoreCached),
        dispatch,
        commit,
        mutation: 'SET_PROFILE',
      });
    },

    getAyrShareConnectUrl({ dispatch, commit }) {
      return handleAjax({
        request: AudienceService.getAyrShareConnectUrl(),
        dispatch,
        commit,
      });
    },

    getSocialAnalytics({ dispatch, commit, getters }, options?: Record<string, unknown>) {
      const data = {
        ...getters.socialAnalyticsBasePayload,
        ...(options || {}),
      };
      const query = Object.keys(data).length ? '?' + new URLSearchParams(data).toString() : '';
      return handleAjax({
        request: AudienceService.getSocialAnalytics(query),
        dispatch,
        commit,
        mutation: 'SET_SOCIAL_ANALYTICS',
      });
    },

    setPlatformPosts({ commit }, posts: AudiencePost[]) {
      commit('SET_PLATFORM_POSTS', posts);
    },

    fetchPlatformPosts({ dispatch, commit }, platform: Lowercase<PlatformLabels>) {
      return handleAjax({
        request: new NativePostsService().getPosts(platform),
        dispatch,
        commit,
        mutation: 'SET_PLATFORM_POSTS',
      });
    },

    getConnectedPlatforms({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().getConnectedPlatforms(),
        dispatch,
        commit,
        mutation: 'SET_CONNECTED_PLATFORMS',
      });
    },

    processOAuthRedirection({ dispatch, commit }, payload: { platform: string; authCode: string }) {
      return handleAjax({
        request: new NativePlatformsService().processOAuthRedirection(payload.platform, payload.authCode),
        dispatch,
        commit,
        callback(response) {
          if (response) {
            const axiosResponse = response as AxiosResponse;
            const errors = axiosResponse?.data?.errors;
            if (errors) {
              throw errors[0];
            }
          }
        },
      });
    },

    connectIGFacebookPage({ dispatch, commit }, pageId: string) {
      return handleAjax({
        request: new NativePlatformsService().connectIGFacebookPage(pageId),
        dispatch,
        commit,
        callback(response) {
          if (response) {
            const axiosResponse = response as AxiosResponse;
            const errors = axiosResponse?.data?.errors;
            if (errors) {
              throw errors[0];
            }
          }
        },
      });
    },

    connectFacebookPage({ dispatch, commit }, pageId: string) {
      return handleAjax({
        request: new NativePlatformsService().connectFacebookPage(pageId),
        dispatch,
        commit,
        callback(response) {
          if (response) {
            const axiosResponse = response as AxiosResponse;
            const errors = axiosResponse?.data?.errors;
            if (errors) {
              throw errors[0];
            }
          }
        },
      });
    },

    unlinkYoutube({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkYoutube(),
        dispatch,
        commit,
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },

    unlinkFacebook({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkFacebook(),
        dispatch,
        commit,
      });
    },

    unlinkInstagram({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkInstagram(),
        dispatch,
        commit,
      });
    },

    unlinkTiktok({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkTiktok(),
        dispatch,
        commit,
      });
    },

    unlinkLinkedIn({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkLinkedIn(),
        dispatch,
        commit,
      });
    },

    unlinkTwitter({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkTwitter(),
        dispatch,
        commit,
      });
    },

    unlinkPinterest({ dispatch, commit }) {
      return handleAjax({
        request: new NativePlatformsService().unlinkPinterest(),
        dispatch,
        commit,
      });
    },

    getGrowthTips({ dispatch, commit }) {
      return handleAjax({
        request: AudienceService.getGrowthTips(),
        dispatch,
        commit,
        mutation: 'SET_GROWTH_TIPS',
      });
    },

    getLeaderboardRankings({ dispatch, commit }) {
      return handleAjax({
        request: AudienceService.getLeaderboardRankings(),
        dispatch,
        commit,
        mutation: 'SET_LEADERBOARDS',
      });
    },

    getLeaderboardSelfAnalytics({ dispatch, commit, getters }, options: Record<string, string>) {
      const query = Object.keys(options).length ? '?' + new URLSearchParams(options).toString() : '';
      handleAjax({
        request: AudienceService.getSocialAnalytics(query),
        dispatch,
        commit,
        mutation: 'SET_LEADERBOARD_SELF_ANALYTICS',
        modify(data: SocialAnalytics): SocialAnalytics {
          const startDate = moment.unix(Number(options.start));
          const endDate = moment.unix(Number(options.end));
          return extendSocialAnalytics(startDate, endDate, data, getters.profile.activeSocialAccounts ?? []);
        },
      });
    },

    toggleLeaderboardOptIn({ dispatch, commit, getters }) {
      return handleAjax({
        request: AudienceService.toggleLeaderboardOptIn(),
        dispatch,
        commit,
        mutation: 'SET_OPT_IN_STATUS',
        modify() {
          return !getters.profile.leaderboardOptIn;
        },
      });
    },

    async verifyAndUpdateStoreForCurrentBrand({ dispatch, commit, getters, rootGetters }) {
      const promises: Promise<void>[] = [];

      const ayrshareProfile: AyrshareProfile | null = getters.profile;
      const currentBrandId: number | undefined = rootGetters.selectedBrand?.id;
      const isBrandIdChanged = currentBrandId !== ayrshareProfile?.brandId;

      if (!ayrshareProfile || isBrandIdChanged) {
        promises.push(dispatch('getProfile', true));
      }

      const growthTips: GrowthTip[] = getters.growthTips;

      if (!growthTips.length) {
        promises.push(dispatch('getGrowthTips'));
      }

      try {
        await Promise.all(promises);
      } catch (e) {
        console.error(e);
      }

      const msgServer: SSE | null = getters.msgServer;

      if (msgServer && isBrandIdChanged) {
        commit('CLEAR_MESSAGE_SERVER');
      }

      if (!getters.msgServer) {
        const { notificationKey } = (getters.profile as AyrshareProfile | null) ?? {};
        const urlPrefix = process.env.VUE_APP_EVENT_URL;

        if (!urlPrefix || !notificationKey) {
          console.warn('Event server prefix or key not set. Unable to connect to SSE server.');
          return;
        }

        const sseUrl = new URL(urlPrefix);
        sseUrl.search = `stream=${notificationKey}`;
        const sseCfg = { format: 'json' };
        setupSSE(sseUrl, sseCfg)
          .then((msgServer) => {
            commit('SET_MESSAGE_SERVER', msgServer);
          })
          .catch((e) => {
            console.error('Unable to connect to SSE server; retrying every 20 seconds until established', e);
            const ms = 20000;
            const retry = () => {
              setupSSE(sseUrl, sseCfg)
                .then((msgServer) => commit('SET_MESSAGE_SERVER', msgServer))
                .catch(() => setTimeout(() => retry(), ms));
            };
            retry();
          });
      }
    },
  },
} as Module<AudienceAnalyticsState, RootState>;

export interface AudienceAnalyticsState {
  dateRange: DateRange;
  profile: AyrshareProfile | null;
  socialAnalytics: SocialAnalytics;
  growthTips: GrowthTip[];
  leaderboards: AudienceLeaderboardContent | null;
  leaderboardSelfAnalytics: SocialAnalytics;
  msgServer: SSE | null;
  connectedPlatforms: ActiveSocialAccount[];
  platformPosts: AudiencePost[];
}
