import HttpClient from '@/services/common/HttpClient';
import CreateAndPublishModel from '@/models/CreateAndPublishModel';
import { Show } from '@/types/Show';
import { EpisodesRequest } from '@/types/EpisodesRequest';

export default class CreateAndPublishService {
  private http: HttpClient = HttpClient.getInstance();

  private getBrand() {
    const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
    const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
    return brand.id;
  }

  private endpoint = `${process.env.VUE_APP_API_URL}/createandpublish/${this.getBrand()}`;

  /**
   * Gets all shows
   *
   * @return {array} collection of shows
   */
  public getAll(): Promise<CreateAndPublishModel> {
    return this.http.get(`${this.endpoint}/shows`);
  }

  /**
   * Gets one show
   *
   * @param {number} id the show id
   * @return {object} single show
   */
  public get(id: number): Promise<CreateAndPublishModel> {
    return this.http.get(`${this.endpoint}/show/${id}`);
  }

  /**
   * Creates a show
   *
   * @param {object} show the show payload
   * @returns {void}
   */
  public create(show: Partial<CreateAndPublishModel>) {
    return this.http.post<CreateAndPublishModel>(`${this.endpoint}/createshow`, show);
  }

  /**
   * Edits a show
   *
   * @param {object} show the show payload
   * @returns {void}
   */
  public edit(show: Partial<Show>) {
    return this.http.put<Show>(`${this.endpoint}/show/${show.id}`, show);
  }

  public uploadImage(file: FormData, imageType = 'notUsed'): Promise<{ location: string }> {
    return this.http.post(`${this.endpoint}/upload/image?field=${imageType}`, file);
  }

  /**
   * Gets all episodes
   *
   * @param {object} request the episode request
   * @return {array} collection of episodes
   */
  public getEpisodes(request: Partial<EpisodesRequest>): Promise<CreateAndPublishModel> {
    return this.http.post<CreateAndPublishModel>(`${this.endpoint}/episodes`, request);
  }

  /**
   * Deletes a show
   *
   * @param {object} show the show payload
   * @returns {void}
   */
  public delete(showId: Partial<Show>) {
    return this.http.delete<Show>(`${this.endpoint}/show/${showId}`);
  }
}
