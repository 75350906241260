
import { Component, Vue, Prop } from 'vue-property-decorator';
import StatIncreaseIcon from '@/assets/audience/arrow-up-icon.svg';
import StatDecreaseIcon from '@/assets/audience/arrow-down-icon.svg';
import { formatNumber, formatPercentChange } from '@/audience/filters';
import type { Metric } from '@/types/audience';

@Component({
  name: 'MetricListItem',
  filters: {
    formatNumber,
    formatPercentChange,
  },
})
export default class MetricListItem extends Vue {
  @Prop({ type: Object, required: true }) metric!: MetricItem;
  @Prop({ type: String, required: true }) context!: 'audience' | 'impressions' | 'engagement';

  get isAudienceMetric() {
    return this.context === 'audience';
  }

  get isPercentChangePositive() {
    return Math.sign(this.metric.percentChange) >= 0;
  }

  get statChangeIcon() {
    return this.isPercentChangePositive ? StatIncreaseIcon : StatDecreaseIcon;
  }

  get statChangeIconAltText() {
    const changeDirText = this.isPercentChangePositive ? 'an increase' : 'a decrease';
    return `An arrow indicating ${changeDirText} of the ${this.metric.label} metric.`;
  }

  get isValidValue() {
    return !isNaN(this.metric.value);
  }

  get showPercentChange() {
    return !isNaN(this.metric.percentChange) && this.metric.value > 0;
  }
}

interface MetricItem extends Metric {
  percentChange: number;
  numericChange: number;
}
