<template>
  <div v-if="showLoadingBar" class="loading-bar">
    <div class="loading-bar-progress" :style="progressWidth"></div>
    <p class="loading-text">Loading {{ progress }}%</p>
  </div>
</template>
<script>
export default {
  name: 'LoadingBar',
  props: {
    showLoadingBar: {
      type: Boolean,
      default: false,
    },
    mediaUploadProgress: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    progressWidth() {
      if (this.mediaUploadProgress['upload_progress'] < 10) {
        return {
          width: '10%',
          'transition-duration': '0.5s',
        };
      }
      return {
        width: `${this.mediaUploadProgress['upload_progress']}%`,
        'transition-duration': '0.5s',
      };
    },
    progress() {
      if (this.mediaUploadProgress['upload_progress'] === 0) {
        return 0;
      }
      return this.mediaUploadProgress['upload_progress'] - 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-bar {
  background-color: var(--neutral-grey-2-lighter-text);
  text-align: center;
  border-radius: 30px;
  position: relative;

  .loading-text {
    color: var(--neutral-white);
    padding: 5px;
    position: inherit;
    z-index: 5;
  }

  .loading-bar-progress {
    background-color: var(--accent-links);
    border-radius: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
