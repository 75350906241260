/* eslint-disable  @typescript-eslint/no-explicit-any */
// Partially converted this file to TypeScript - BDP
// TODO: Remove the above lines once the store is properly typed.

import * as unconvertedActions from '@/createandpublish/core/unconvertedActions';
import actions from '@/createandpublish/core/actions';
import mutations from '@/createandpublish/core/mutations';
import getters from '@/createandpublish/core/getters';

import analytics from '@/createandpublish/modules/analytics';

import showSetup from '@/createandpublish/modules/showSetup';

import getPlaylists from '@/createandpublish/modules/getPlaylists';
import draftEpisodes from '@/createandpublish/modules/draftEpisodes';

import stationEvents from '@/createandpublish/modules/stationEvents';
import audioEditor from '@/createandpublish/modules/audioEditor';
import mediaUploads from '@/createandpublish/modules/mediaUploads';

import audioWizard from '@/createandpublish/modules/audioWizard';
import imageLibrary from '@/createandpublish/modules/imageLibrary';
import videoLibrary from '@/createandpublish/modules/videoLibrary';

import socialStories from '@/createandpublish/modules/socialStories';
import socialStoriesAnalytics from '@/createandpublish/modules/socialStoriesAnalytics';
import socialPosts from '@/createandpublish/modules/socialPosts';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';
import type {
  AdvancedVideo,
  BestPracticesManifest,
  CategoryGroup,
  MessageBarData,
  PlaylistCategory,
  PlaylistPreview,
  PostAudioEvent,
  PostVersionInfo,
  Settings,
  Show,
  ShowPreview,
  SocialMediaConnection,
  Station,
  StationOption,
} from '@/types/createandpublish';
import type { SSE } from '@/types/SSE';
import type { AlertDisplay } from '@/types/AlertMessage';

export const initialState: CreateAndPublishStoreState = {
  baseUri: `${location.protocol}//${location.host}`,
  baseMediaUri: `${location.protocol}//media.${location.host}`,
  baseProgressUri: `${location.protocol}//progress.${location.host}`,
  baseEventsUri: `${location.protocol}//events.${location.host}`,
  endpoint: '',

  station: null,
  station_name: '',
  selectedStationOption: null,
  stationBrandId: NaN,
  stationList: [],

  // Bulk download jobs
  bulkDownloadJobs: [],

  // No show can be set as activeShow.id = 0
  // Shows "edit show" & "new episode" buttons
  activeShow: {} as Show,
  activeShowId: 0,
  activeShowEpisodes: [],

  buildAudioData: {
    uncompiledList: {
      objects: {},
      events: [],
    },
  },

  previewPlayer: null,
  twModal: null,
  showScheduleModal: null,

  advancedVideo: {
    playlist_id: undefined,
    url: undefined,
    isCreated: false,
    isFailed: false,
  },

  // Used to hold events for logger
  loggerEvents: [],

  // Used to hold group settings as configured in POST categories page
  groups: [],
  colorMap: null,
  categoryMap: null,

  message: null,

  // The logged in user's station settings
  settings: {} as Settings,

  // The logged in user's apikey auth header
  apikeyauth: '',
  // query param to pass creds to nav button hrefs
  navQLinks: '',

  shows: [],
  showsWithTitles: [],

  socialMediaConnections: [],

  // Playlist categories available (playlist categories are "tags")
  playlistCategories: [],

  loadedRSSImage: '',

  existing_images: [],

  isPodcastOnlyUser: null,
  isAdmin: false,
  isLoggerOnlyUser: null,
  isAvess: null,
  versionInfo: null,

  playlistsAwaitingDownload: new Map(),

  bestPracticesManifest: {} as BestPracticesManifest,

  stationSessionId: undefined,
  // SSE message server object (https://github.com/tserkov/vue-sse)
  msgServer: null,

  // Alert message under Podcasts.vue
  shouldShowAlert: false,
  alertMessage: {},
  // CNP Welcome Banner
  welcomeBannerActive: true,
};

const createAndPublishModule = {
  namespaced: true,
  modules: {
    draftEpisodes,
    stationEvents,
    analytics,
    showSetup,
    audioEditor,
    mediaUploads,
    getPlaylists,
    audioWizard,
    imageLibrary,
    videoLibrary,
    socialStories,
    socialStoriesAnalytics,
    socialPosts,
  },

  actions: {
    ...actions,
    ...unconvertedActions,
  },

  mutations,

  getters,

  state: initialState,
} as unknown as Module<CreateAndPublishStoreState, RootState>;

export default createAndPublishModule;

export interface CreateAndPublishStoreState {
  baseUri: string;
  baseMediaUri: string;
  baseProgressUri: string;
  baseEventsUri: string;
  endpoint: string; // To hit CC API

  // TODO: station/station_name are duplicates. Remove station_name.
  station: string | null;
  station_name: string;
  // TODO: selectedStationOption is unnecessary. Remove.
  selectedStationOption: StationOption | null;
  stationList: Station[];
  stationBrandId: number;

  bulkDownloadJobs: any[];

  activeShow: Show;
  activeShowId: number;
  activeShowEpisodes: PlaylistPreview[];

  buildAudioData: {
    uncompiledList: {
      objects: Record<string, any>;
      events: any[];
    };
  };

  previewPlayer: any;
  twModal: any;
  showScheduleModal: any;

  advancedVideo: AdvancedVideo;

  loggerEvents: PostAudioEvent[];

  groups: CategoryGroup[];
  colorMap: Record<string, string> | null;
  categoryMap: Record<string, string> | null;

  message: MessageBarData | null;

  // TODO: settings.stationOps is appended to settings by SET_STATION_SETTINGS. Duplicate of stationList in store. Remove.
  settings: Settings;

  apikeyauth: string;
  navQLinks: string;

  shows: ShowPreview[];
  showsWithTitles: ShowPreview[];

  socialMediaConnections: SocialMediaConnection[];

  playlistCategories: PlaylistCategory[];

  loadedRSSImage: string;

  existing_images: string[]; // URL paths w/o hostname.

  isPodcastOnlyUser: boolean | null;
  isAdmin: boolean;
  isLoggerOnlyUser: boolean | null;
  isAvess: boolean | null;
  versionInfo: PostVersionInfo | null;

  playlistsAwaitingDownload: Map<any, any>;

  bestPracticesManifest: BestPracticesManifest;

  stationSessionId: string | undefined;
  msgServer: SSE | null;

  shouldShowAlert: boolean;
  alertMessage: AlertDisplay;
  welcomeBannerActive: boolean;
}
