import { render, staticRenderFns } from "./LoadingBar.vue?vue&type=template&id=19058d01&scoped=true&"
import script from "./LoadingBar.vue?vue&type=script&lang=js&"
export * from "./LoadingBar.vue?vue&type=script&lang=js&"
import style0 from "./LoadingBar.vue?vue&type=style&index=0&id=19058d01&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19058d01",
  null
  
)

export default component.exports