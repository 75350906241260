// see https://stackoverflow.com/questions/48826622/split-string-into-pairs-triplets-quadruplets-and-on-ngrams#comment84658524_48827190
export const ngrams = (a: string[], n: number) => a.slice(0, 1 - n).map((_, i) => a.slice(i, i + n));

// see https://stackoverflow.com/a/37511463
export const normalizeString = (s: string) =>
  s
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 -]/g, '');

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

/**
 * Takes an array of words and parses them into a single comma separated string
 *
 * @example
 * const arr = ['chicken', 'beef', 'pork'];
 * arrayToStringList(arr); // -> "chicken, beef, and pork"
 *
 * @param v array of words to format
 * @returns formatted string
 */
export const arrayToStringList = (v: string[]) =>
  v.length === 2
    ? v.join(' and ')
    : v.length > 2
    ? v
        .slice(0, v.length - 1)
        .concat(`and ${v.slice(-1)}`)
        .join(', ')
    : v.join(', ');
