
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import CloseButton from '@/createandpublish/components/CloseButton.vue';
import Loading from '@/components/common/Loading.vue';

import type {
  ImageAsset,
  VideoAsset,
  MediaUploadType,
  PaginationMetadata,
  GetMediaAssetsQueryParams,
  MediaAsset,
} from '@/types/createandpublish';

type MediaAssetType = Exclude<MediaUploadType, 'audio' | undefined>;

const ImageLibrary = namespace('CreateAndPublishStore/imageLibrary');
const VideoLibrary = namespace('CreateAndPublishStore/videoLibrary');

@Component({
  name: 'MediaLibraryAssetSelector',
  components: {
    CloseButton,
    Loading,
  },
})
export default class MediaLibraryAssetSelector extends Vue {
  @Prop({ type: String, default: 'image' }) mediaType!: MediaAssetType;

  @ImageLibrary.Getter imageAssets!: ImageAsset[];
  @ImageLibrary.Getter imagePagination!: PaginationMetadata;
  @ImageLibrary.Mutation('CLEAR_IMAGE_ASSETS') clearImageAssets!: () => void;
  @ImageLibrary.Action getImageAssets!: (params: GetMediaAssetsQueryParams) => Promise<void>;

  @VideoLibrary.Getter videoAssets!: VideoAsset[];
  @VideoLibrary.Getter videoPagination!: PaginationMetadata;
  @VideoLibrary.Mutation('CLEAR_VIDEO_ASSETS') clearVideoAssets!: () => void;
  @VideoLibrary.Action getVideoAssets!: (params: GetMediaAssetsQueryParams) => Promise<void>;

  isLoading = false;
  selectedAssetId = NaN;

  // eslint-disable-next-line getter-return
  get mediaAssets(): Extract<MediaAsset, ImageAsset | VideoAsset>[] {
    switch (this.mediaType) {
      case 'image':
        return this.imageAssets;
      case 'video':
        return this.videoAssets;
    }
  }

  async getMediaAssets() {
    this.isLoading = true;
    const { paginationData } = this;
    switch (this.mediaType) {
      case 'image':
        await this.getImageAssets(paginationData);
        break;
      case 'video':
        await this.getVideoAssets(paginationData);
        break;
    }
    this.isLoading = false;
  }

  // eslint-disable-next-line getter-return
  get paginationData(): PaginationMetadata {
    switch (this.mediaType) {
      case 'image':
        return this.imagePagination;
      case 'video':
        return this.videoPagination;
    }
  }

  clearMediaAssets() {
    switch (this.mediaType) {
      case 'image':
        this.clearImageAssets();
        break;
      case 'video':
        this.clearVideoAssets();
        break;
    }
  }

  // eslint-disable-next-line getter-return
  get mediaAssetUrlKey() {
    switch (this.mediaType) {
      case 'image':
        return 'source_url';
      case 'video':
        return 'thumbnail_url';
    }
  }

  onAssetClick(assetId: (ImageAsset | VideoAsset)['id']) {
    this.selectedAssetId = assetId;
  }

  onSubmit() {
    this.$emit(
      'mediaAsset:selected',
      this.mediaAssets.find(({ id }) => id === this.selectedAssetId)
    );
    this.close();
  }

  close() {
    this.$emit('close');
  }

  created() {
    this.clearMediaAssets();
    this.getMediaAssets();
  }
}
