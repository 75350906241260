import { setupBaseSSE } from './base';

import {
  advancedVideoEditUrl,
  advancedVideoStatusUpdate,
  analyticsCsvGenerationComplete,
  audioWizardStatus,
  basicVideoStatusUpdate,
  bulkDownloadStacks,
  bulkDownloadStacksError,
  downloadPlaylist,
  eventUploadProgress,
  iHeartIngestionStatus,
  playlistComplete,
  uploadProgressChange,
} from './handlers';

export const setupSSE = async (url, cfg) => {
  const msgServer = await setupBaseSSE(url, cfg);

  advancedVideoEditUrl(msgServer);
  advancedVideoStatusUpdate(msgServer);
  analyticsCsvGenerationComplete(msgServer);
  audioWizardStatus(msgServer);
  basicVideoStatusUpdate(msgServer);
  bulkDownloadStacks(msgServer);
  bulkDownloadStacksError(msgServer);
  downloadPlaylist(msgServer);
  eventUploadProgress(msgServer);
  iHeartIngestionStatus(msgServer);
  playlistComplete(msgServer);
  uploadProgressChange(msgServer);

  return msgServer;
};

export default setupSSE;
