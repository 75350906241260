import HttpClient from '@/services/common/HttpClient';
import { AccountData } from '@/types/account/AccountData';
import { AuthURL } from '@/types/account/AuthURL';

export default class AccountService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = '/account';

  // Get account data
  public getAccount(): Promise<AccountData> {
    return this.http.get(this.endpoint);
  }
  //[Usman]: for testing puproses
  public getEnvVariables(): Promise<AccountData> {
    return this.http.get('/audience/327/profile/analytics/getEnvVariables');
  }
  //[Usman]: for testing puproses
  public linkToFacebook(): Promise<AuthURL> {
    return this.http.get('/facebook/connect');
  }
  public getTiktokOAuthURL(): Promise<AuthURL> {
    return this.http.get('/tiktok/connect');
  }
  //[Usman]: for testing puproses
  public fetchAnalytics(): Promise<AccountData> {
    return this.http.post('/createandpublish/327/analytics/posts', {});
  }
}
