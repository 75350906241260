import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import Page from '@/services/common/Page';

/**
 * Wrapper around HTTP calls using axios
 */
export default class HttpClient {
  private static instance?: HttpClient;
  private readonly axiosInstance: AxiosInstance;
  private readonly isDebug: boolean;
  private sessionId: string;

  private constructor() {
    const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:5000/api/v1/';
    this.sessionId = '';

    // Create instance with config
    this.axiosInstance = axios.create({
      baseURL,
    });

    // Set the AUTH token for any request
    this.axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      await this.ensureCurrentEventSessionId();
      const _store = localStorage.getItem('vuex');
      let authToken = null;
      if (_store) {
        authToken = JSON.parse(_store)?.authToken;
      }
      config.headers.setAuthorization(authToken ? `Bearer ${authToken}` : '', true);
      config.headers.set('X-EventSessionId', this.sessionId, true);
      config.withCredentials = true;
      return config;
    });

    this._initializeResponseInterceptor();

    this.isDebug = process.env.VUE_APP_DEBUG ? process.env.VUE_APP_NODE_ENV === 'development' : false;
  }

  public static getInstance(): HttpClient {
    if (!this.instance) {
      this.instance = new HttpClient();
    }

    return this.instance;
  }

  private async ensureCurrentEventSessionId() {
    const module = await import('@/store');
    const _sessionId = module.default.state.CreateAndPublishStore.stationSessionId ?? '';
    this.sessionId = _sessionId;
  }

  public getPage<T>(url: string): Promise<Page> {
    return this.axiosInstance.get<T>(url).then((res) => {
      return new Page(res.data, parseInt(res.headers['x-total-count']));
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get<T, U extends Record<string, any> = Record<string, any>>(
    url: string,
    params?: U,
    headers = {}
  ): Promise<T> {
    return this.axiosInstance.get<T>(url, { params, headers }).then((res) => {
      return res.data;
    });
  }

  public post<T>(url: string, data): Promise<T> {
    return this.axiosInstance.post<T>(url, data).then((res) => {
      return res.data;
    });
  }

  public put<T>(url: string, data): Promise<T> {
    return this.axiosInstance.put<T>(url, data).then((res) => {
      return res.data;
    });
  }

  public delete<T>(url: string): Promise<T> {
    return this.axiosInstance.delete<T>(url).then((res) => {
      return res.data;
    });
  }

  public patch<T>(url: string, data): Promise<T> {
    return this.axiosInstance.patch<T>(url, data).then((res) => {
      return res.data;
    });
  }

  //return full response to enable upload progress events
  public upload(url: string, data) {
    return this.axiosInstance.post(url, data).then((res) => {
      return res;
    });
  }

  private _initializeResponseInterceptor = () => {
    this.axiosInstance.interceptors.response.use(
      (res) => this._handleResponse(res),
      (err) => this._handleError(err)
    );
  };

  private _handleResponse = (res: AxiosResponse) => {
    if (this.isDebug) {
      // eslint-disable-next-line no-console
      console.log(`${res.status} | ${res.data.length}`);
    }
    return res;
  };

  private _handleError = async (error: AxiosError) => {
    const _axiosError = {
      ...error,
      response: { ...error.response },
    };

    const module = await import('@/store');
    const store = module.default;
    // redirect to login on unauthorized errors
    if (error?.response?.status === 401) {
      store.commit('CLEAR_STORE', undefined, { root: true });
      if (
        !['/login'].includes(window.location.pathname) &&
        !['/checkout/login'].includes(window.location.pathname) &&
        !['/audience/login'].includes(window.location.pathname)
      ) {
        window.location.href = '/login';
        throw new Error('Unauthorized: Redirecting to login');
      }
    }

    let data: Record<string, unknown> = {};

    if (typeof error.response?.data === 'string') {
      data.server_error_message = error.response.data;
    } else if (typeof error?.response?.data === 'object') {
      data = error.response.data as Record<string, unknown>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: Record<string, any> = (error.response as unknown as Record<string, unknown>) ?? {};
    payload.data = {
      ...data,
      _axiosError,
    };
    return payload;
  };
}
