
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Line as LineChart } from 'vue-chartjs/legacy';
import type { ChartData, ChartOptions } from 'chart.js';
import type { ChartConfig } from '@/types/audience';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler);

@Component({
  name: 'BaseLineChart',
  components: {
    LineChart,
  },
})
export default class BaseLineChart extends Vue {
  @Prop({ type: Object, required: true }) chartConfig!: ChartConfig;
  @Prop({ type: Object, required: true }) chartOptions!: ChartOptions;
  @Prop({ type: Object, required: true }) chartData!: ChartData;
}
