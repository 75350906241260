
import { Component, Vue, Prop } from 'vue-property-decorator';
import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import LinkIcon from '@/audience/components/social/LinkIcon.vue';
import { SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialRanking';
import { AudienceViewMode } from '@/types/audience/ViewMode';
import { TimeRangeMode } from '@/types/audience/TimeRangeMode';
import AudienceDateRangeSelector from './DateRangeSelector.vue';

@Component({
  name: 'ViewHeader',
  components: {
    LinkIcon,
    AudienceDateRangeSelector,
  },
})
export default class ViewHeader extends Vue {
  @Prop({ type: Object, required: true }) config!: HeaderConfig;
  @Prop({ type: Array, required: true }) contextOptions!: { label: string; value: string }[];
  @Prop({ type: String, required: true }) context!: string;
  @Prop({ type: Number, required: false }) viewMode!: AudienceViewMode;
  @Prop({ type: Boolean, required: false, default: false }) enableChangeViewMode!: boolean;
  @Prop({ type: String, required: false }) timeRangeMode!: TimeRangeMode;

  viewModeOptions = AudienceViewMode;
  timeRangeModeOptions = TimeRangeMode;

  onContextChange($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.$emit('onContextChange', value);
  }

  onViewModeChange(newViewMode) {
    this.$emit('onViewModeChange', newViewMode);
  }

  onTimeRangeModeChange(newTimeMode: TimeRangeMode) {
    this.$emit('onTimeRangeModeChange', newTimeMode);
  }

  get iconComponent() {
    switch (this.config.platformLabel) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      default:
        return null;
    }
  }
}

interface HeaderConfig {
  heading: string;
  showSubHeading?: boolean;
  subHeading?: string;
  platformLabel?: PlatformLabels;
  platform?: SocialPlatform;
}
