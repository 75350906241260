import Pageable from '@/services/common/Pageable';
import HttpClient from '@/services/common/HttpClient';
import UserModel from '@/models/UserModel';
import Page from '@/services/common/Page';
import { ForgotPasswordConfirmRequest } from '@/types/users/ForgotPasswordConfirmRequest';

export type SendResetPasswordLinkRequest = {
  email: UserModel['email'];
};

export type ResendUserActivationRequest = {
  email: UserModel['email'];
  firstName: UserModel['firstName'];
};

export default class UserService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = process.env.VUE_APP_PRODUCT_ENDPOINT || '/users';
  private authEndpoint = process.env.VUE_APP_PRODUCT_ENDPOINT || '/auth';

  /**
   * Gets all paged Users
   *
   * @param pageable
   */
  public getAll(pageable?: Pageable): Promise<Page> {
    const paramString = pageable === undefined ? '' : pageable.toUrlParamString();

    return this.http.getPage<UserModel[]>(this.endpoint + paramString);
  }

  /**
   * Gets One User by ID
   *
   * @param id : ID of the user
   */
  public getOne(id: string): Promise<UserModel> {
    return this.http.get(`${this.endpoint}/${id}`);
  }

  // Create user
  public create(user: Partial<UserModel>) {
    return this.http.post<UserModel>(`${this.endpoint}`, user);
  }

  // Edit user
  public edit(user: Partial<UserModel>) {
    return this.http.put<UserModel>(`${this.endpoint}/${user.id}`, user);
  }

  public async sendResetPasswordLink(payload: SendResetPasswordLinkRequest) {
    return await this.http.post(`${this.authEndpoint}/password-reset`, payload);
  }
  // Forgot password -- request password reset
  public async forgotPasswordReset(email: string) {
    return await this.http.post<UserModel>(`${this.authEndpoint}/password-reset`, { email });
  }

  // Forgot password -- password confirmation
  public async forgotPasswordConfirm(payload: ForgotPasswordConfirmRequest) {
    return await this.http.post<UserModel>(`${this.authEndpoint}/password-reset-confirmation`, payload);
  }

  // User activation -- password confirmation
  public async userActivationConfirm(payload: ForgotPasswordConfirmRequest) {
    return await this.http.post<UserModel>(`${this.authEndpoint}/confirmation`, payload);
  }

  // Resend user invitation email
  public async resendInvitationEmail(payload: ResendUserActivationRequest) {
    return await this.http.post<UserModel>(`${this.authEndpoint}/resend-confirmation-code`, payload);
  }
}
