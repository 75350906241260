
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import moment, { MomentInput } from 'moment';

import '../styles.scss';
import Button from '@/components/common/buttons/Button.vue';
import type { Feature } from '@/types/features/FEFeature';

@Component({
  name: 'TrialActiveAdmin',
  components: {
    Button,
  },
})
export default class TrialActiveAdmin extends Vue {
  @Getter currentPeriodEndsAt!: string;
  @Getter features!: Feature[];

  get daysRemaining(): number {
    const expirationDate = moment(this.currentPeriodEndsAt as MomentInput);
    const now = moment();
    return expirationDate.diff(now, 'days');
  }
  onUpgrade(): void {
    const ecommFeatureIsEnabled = this.features.includes('ecomm');
    if (ecommFeatureIsEnabled) {
      this.$router.push('/subscriptions');
    } else {
      window.open('https://support.contentcloud.app/support/home', '_blank');
    }
  }
}
