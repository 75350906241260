
import { Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';

import PortalHeader from '@/components/PortalHeader.vue';
import PortalSubHeader from '@/components/PortalSubHeader.vue';
import PortalLeftNav from '@/components/PortalLeftNav.vue';
import PortalFooter from '@/components/PortalFooter.vue';

import type { Brand } from '@/types/Brand';
import type { Route } from 'vue-router';
import SocialStoryDrawer from './createandpublish/components/socialStories/SocialStoryDrawer.vue';
import { Product } from './types/Product';
import { ProductHandle } from './types/ecomm/ChargifyEnums';
import { SubscriptionState } from './types/ecomm/SubscriptionState';

@Component({
  components: {
    PortalHeader,
    PortalSubHeader,
    PortalLeftNav,
    PortalFooter,
    SocialStoryDrawer,
  },
  metaInfo: {
    title: 'Idea Engine',
    meta: [{ name: 'viewport', content: `width=${window.innerWidth < 1024 ? 1024 : window.innerWidth}` }],
  },
})
export default class App extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter showSidebar!: boolean;
  @Getter collapseSidebar!: boolean;
  @Getter selectedBrand!: Brand;
  @Getter('userBrands') brands!: Brand[];
  @Getter suiteAccountProduct!: Product;
  @Getter rawSubscription;
  @Getter subscriptionStatus;

  isSubscriptionTrialEnded = false;
  daysLeftInTrial = 0;

  mounted() {
    this.updateSubscriptionTrialStatus();
  }

  @Watch('rawSubscription', { immediate: true, deep: true })
  onRawSubscriptionChanged() {
    this.updateSubscriptionTrialStatus();
  }

  updateSubscriptionTrialStatus() {
    if (this.rawSubscription?.state === SubscriptionState.Trialing && this.rawSubscription?.trialEndedAt) {
      const currentDateTime = new Date();
      const estOptions = { timeZone: 'America/New_York' };
      const currentDateTimeinEDT = new Date(currentDateTime.toLocaleString('en-US', estOptions));
      const trialExpiryDateTime = new Date(this.rawSubscription?.trialEndedAt);
      const timeDifference = trialExpiryDateTime.getTime() - currentDateTimeinEDT.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.daysLeftInTrial = daysDifference;
      if (currentDateTimeinEDT > trialExpiryDateTime) {
        this.isSubscriptionTrialEnded = true;
      } else {
        this.isSubscriptionTrialEnded = false;
      }
    } else {
      this.isSubscriptionTrialEnded = true;
    }
  }

  routeToPaymentPage() {
    this.$router.push(`/subscriptions/add-payment?plan=${ProductHandle.CYBER_MONDAY_PRODUCT}`);
  }

  get showSubHeader(): boolean {
    const isInViableModule = ['create', 'discover', 'audience'].includes(this.$route.path.split('/')[1]);
    const hasMoreThanOneBrand = this.brands.length > 1;
    const needsAudienceDatePicker =
      this.isAudienceRoute && !this.$route.path.includes('leaderboard') && !this.$route.path.includes('community');

    if (needsAudienceDatePicker) {
      return true;
    }
    return isInViableModule && hasMoreThanOneBrand;
  }

  get subHeaderClasses() {
    return this.collapseSidebar ? 'main collapsed' : 'main';
  }

  get viewClasses() {
    return [
      'view',
      {
        'view--overflowed': this.showLeftNav,
        'collapse-subheader': true,
      },
    ];
  }

  get isAudienceRoute(): boolean {
    return this.$route.path.includes('audience');
  }

  get selectedBrandId() {
    return this.selectedBrand?.id || null;
  }

  get showLeftNav() {
    return ['create', 'audience'].includes(this.$route.path.split('/')[1]);
  }

  get renderSocialStoryDrawer() {
    return !!this.suiteAccountProduct?.active;
  }

  routerViewKey = 0;

  @Watch('$route')
  onRouteChange(newRoute: Route, oldRoute: Route) {
    if (oldRoute.params.brandId != newRoute.params.brandId) {
      // Loose equality because sometimes it's a string and sometimes a number. Too lazy to find out what's doing that.
      this.routerViewKey++;
    }
  }
}
