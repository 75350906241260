
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ProfileDropdownLinkItem',
})
export default class ProfileDropdownLinkItem extends Vue {
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) icon!: string;
  @Prop({ type: String }) route!: string;
  @Prop({ type: Boolean, default: false }) external?: boolean;

  goToExternalLink() {
    window.open(this.route, '_blank');
  }

  emitRedirect() {
    this.$emit('redirect');
  }
}
