import Vue from 'vue';

import VueRouter, { RouteConfig } from 'vue-router';

import { productMap } from '@/constants/seeded/products';
import AudienceRouter from '@/router/audience';
import CreateAndPublishRouter from '@/router/createandpublish';
import DiscoverRouter from '@/router/discover';
import ErrorRouter from '@/router/error';
import UserPortalRouter from '@/router/userportal';
import DiscoverStoryFetch from '@/services/DiscoverStoryFetch';
import { isActiveUser, isCCAdmin, isMultiBrand, isValidSubscription, userHasProductAccess } from '@/utils';
import setupFullStory from '@/utils/fullStory';
import { logDebugFunc } from '@/utils/logging';
import {
  addBrandIdToRoute,
  getUnauthorizedRedirectQueryType,
  isFeatureAvailable,
  matchRouteBrandWithUserBrands,
  UnauthorizedRedirectQueryParams,
} from '@/utils/routerHelpers';
import runSupportSetup from '@/utils/thirdPartySupport';
import NotFound from '@/views/NotFound.vue';

import store from '../store';

const logDebug = logDebugFunc();

const routes: Array<RouteConfig> = [
  ...UserPortalRouter,
  ...AudienceRouter,
  ...CreateAndPublishRouter,
  ...DiscoverRouter,

  ...ErrorRouter,
  { path: '*', component: NotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((_to, _from, next) => {
  const topViewEl = document.querySelector('#router-view__top')?.firstElementChild;
  topViewEl &&
    topViewEl.scrollIntoView({
      inline: 'nearest',
      block: 'start',
    });

  next();
});

router.beforeEach(async (to, { name: fromName }, next) => {
  const {
    getters: { isLoggedIn, authenticatedUser, selectedBrand },
  } = store;

  const skipDiscoverPreload = process.env.VUE_APP_DISABLE_DISCOVER_PREFETCH === '1';
  if (!skipDiscoverPreload && isLoggedIn && authenticatedUser?.authentication?.userHasDiscoverAccess) {
    const promise = async () => {
      // Use new value of shortcuts, it might have changed before we are invoked
      if (!store.getters.shortcuts?.length) {
        // await store.dispatch('fetchShortcuts');
      }
      if (!store.getters.discoverPrefetchInitialized) {
        store.commit('setPrefetchInitialized', true);
        logDebug(`router: >>> fetching all stories`);
        await DiscoverStoryFetch.prefetchSelectedCategories();
      }
    };

    // Do not wait for result
    promise().catch((err) => {
      console.warn('warning: error fetching stories in router (ignoring): ', err);
    });
  }

  if (isLoggedIn) {
    await store.dispatch('AudienceAnalyticsStore/verifyAndUpdateStoreForCurrentBrand');
  }

  if (isLoggedIn && authenticatedUser) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.rudderanalytics.identify(
      'USERS ID',
      {
        users_name: authenticatedUser?.firstName + ' ' + authenticatedUser?.lastName + '',
        emailAddress: authenticatedUser?.email || '',
        // audienceProfileName: audienceProfileName
      },
      {
        page: {
          path: to.path,
          referrer: document.referrer,
          title: document.title,
          url: location.href,
        },
      }
    );
  }

  /**
   * Redirects a user to a given route name
   *
   * @param {string} routeName the route name to push to
   * @return {void}
   */
  const redirectTo = (routeName: string) => {
    if (fromName !== routeName) {
      router.push({ name: routeName });
    }
  };

  /**
   *
   * @param routeName
   * @param queryParams
   */
  const redirectToUnauthorized = (routeName: string, queryParams?: UnauthorizedRedirectQueryParams) => {
    const unauthorizedRouteName = '403 Unauthorized';
    // Set initial params
    const _params = { page: routeName, redirectType: '403' };
    // Set initial query params
    const _query = { type: getUnauthorizedRedirectQueryType(routeName, queryParams) };
    // If additional query params are found, add them
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        _query[key] = queryParams[key];
      });
    }
    router.push({
      name: unauthorizedRouteName,
      params: _params,
      query: _query, // This is to handle 'back' functionality
    });
  };

  // const redirectToLoginPrompt = (routeName) => {
  //   const unauthorizedRouteName = '401 Unauthorized';
  //   if (fromName !== unauthorizedRouteName) {
  //     router.push({ name: unauthorizedRouteName, params: { page: routeName, redirectType: '401' } });
  //   }
  // };

  if (!window['__CC_SUPPORT_INIT'] && isLoggedIn) {
    window['__CC_SUPPORT_INIT'] = true;
    runSupportSetup();
  }

  if (!window['__CC_Anoynmous_User'] && !isLoggedIn) {
    window['__CC_Anoynmous_User'] = true;
    if (process.env.NODE_ENV !== 'development') {
      setupFullStory();
    }
  }

  // If navigating to a route without brandId param that requires brand access, assign the selected brand
  const productRoutes: string[] = productMap.map((product) => product.url);
  const navigatingToBrandAccessRouteWithoutBrand =
    productRoutes.some((e) => to.fullPath.indexOf(e) > -1) &&
    !to.params?.brandId &&
    to.matched.some((m) => m.meta.RequiresBrandAccess);

  if (navigatingToBrandAccessRouteWithoutBrand) {
    return router.replace({
      name: to.name || 'Discover',
      // save the location we were at to come back later
      params: {
        ...to.params,
        brandId: store.getters.selectedBrand?.id || '',
      },
    });
  }

  // If unauthenticated user is accessing the app, redirect to login. Else, to 401
  if (!to.meta?.AllowAnonymous && !isLoggedIn) {
    next('/login?redirect=' + to.fullPath);
    return;
  } else if (to.meta?.PreventAuthenticated && isLoggedIn) {
    return redirectTo('Discover');
  }

  // If a route has a `feature` meta field, check if it's enabled and redirect to `404` if not
  if (to.meta?.feature) {
    if (!isFeatureAvailable(to.meta?.feature)) {
      return redirectTo('404 Page Not Found');
    }
  }

  // Requires product access
  if (to.matched.some((m) => m.meta.RequiresProductAccess)) {
    if (!userHasProductAccess(to.path)) {
      return redirectToUnauthorized(to.path);
    }
  }

  // Requires access to brand in route params
  if (to.matched.some((m) => m.meta.RequiresBrandAccess)) {
    // Get brand ID in route
    const brandIdInRoute = to.params?.brandId;
    // If brand ID exists in route
    if (brandIdInRoute) {
      const userHasAccessToBrandIdInRoute = matchRouteBrandWithUserBrands(authenticatedUser?.brands, brandIdInRoute);

      const brandIsNumber = Number.isInteger(Number(brandIdInRoute));

      if (brandIsNumber) {
        // If user has no access to brand ID in route
        if (!userHasAccessToBrandIdInRoute) {
          redirectToUnauthorized(to.path, { code: '2' });
          return;
        } else {
          if (brandIdInRoute != selectedBrand?.id) {
            store.dispatch('changeSelectedBrand', Number(brandIdInRoute));
          }
        }
      } else {
        router.replace({
          path: addBrandIdToRoute(to.fullPath, store.getters.selectedBrand?.id),
        });
      }
    }
  }

  // Admin-only
  if (to.meta?.AdminOnly) {
    if (!isCCAdmin()) {
      redirectToUnauthorized(to.path);
      return;
    }
  }
  // If user is disabled, can't access page
  if (to.meta?.PreventDisabled) {
    if (!isActiveUser()) {
      // only allow active users
      redirectToUnauthorized(to.path);
      return;
    }
  }

  // If user doesn't have valid subscription
  if (to.meta?.RequiresValidSubscription) {
    if (!isValidSubscription()) {
      redirectToUnauthorized(to.path);
      return;
    }
  }

  // If a route isn't available for MultiBrand account
  if (to.meta?.RequiresMultiBrandAccount) {
    if (!isMultiBrand()) {
      redirectToUnauthorized(to.path);
      return;
    }
  }

  // If a route isn't available for SingleBrand account
  if (to.meta?.RequiresSingleBrandAccount) {
    if (isMultiBrand()) {
      redirectToUnauthorized(to.path);
      return;
    }
  }

  // If no redirects
  next();
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta?.title ? `${to.meta.title} | Idea Engine` : 'Idea Engine';
  });
});

export default router;
