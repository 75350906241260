import store from '@/store';
import { roleIdMap, statusIdMap } from '@/utils/thirdPartySupport';
import type { AuthenticatedUser } from '@/models/UserModel';

const run = () => {
  const loggedInUser = store.getters['authenticatedUser'] as AuthenticatedUser;
  const { id, statusId, roleId, firstName, lastName, email, brands } = loggedInUser;
  const status = statusIdMap[statusId];
  const role = roleIdMap[roleId];

  window['beamer_config'] = {
    product_id: 'KQdTWHbp18304',
    selector: 'beamer-notification-button',
    user_id: id,
    user_email: email,
    user_firstname: firstName,
    user_lastname: lastName,
    user_status: status,
    user_role: role,
    default_brand_id: brands?.[0]?.id ?? NaN,
    default_brand_name: brands?.[0]?.name ?? '',
  };

  function beamerOnLoad() {
    if ('Beamer' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.Beamer as any).initializationLogged = true;
    }
  }

  const script = document.createElement('script');
  script.defer = true;
  script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  script.onload = beamerOnLoad;
  document.body.appendChild(script);
};

export default run;
