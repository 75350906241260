/* eslint-disable @typescript-eslint/no-explicit-any */
import { isValidErrorFormat } from '@/utils/typeGuards';
import type { Dispatch, Commit } from 'vuex';
import type { HttpResponse } from 'vue-resource/types/vue_resource';
import type { AxiosError, AxiosResponse } from 'axios';
import { Modify } from 'content-cloud-types/dist/types/helpers';

export const handleError = ({ error, errmsg, commit, callback }: HandleErrorParams) => {
  if (error instanceof Error) {
    if (error.message === 'Unauthorized: Redirecting to login') return;
    // Unhandled JavaScript error
    throw error;
  }

  // redirect to login on unauthorized errors
  if (error?.status === 401) {
    commit('CLEAR_STORE', undefined, { root: true });
    if (
      !['/login'].includes(window.location.pathname) &&
      !['/checkout/login'].includes(window.location.pathname) &&
      !['/audience/login'].includes(window.location.pathname)
    ) {
      window.location.href = '/login';
    }
    return;
  }

  let message = '';
  let isValidServerError = false;

  if (error?.data?.errors && error?.status) {
    // Check for expected error type first
    const errors: unknown = error.data.errors;
    const isExpectedType = isValidErrorFormat(errors);
    isValidServerError = isExpectedType && !!errors.length;
    message = isValidServerError ? (errors as string[])[0] : 'Unknown error';
  }

  if (errmsg) {
    const name = isValidServerError ? '' : error?.statusText || 'Server error';
    const details = isValidServerError ? message : errmsg;
    commit(
      'CreateAndPublishStore/SET_MESSAGE',
      {
        name,
        details,
        type: 'error',
      },
      { root: true }
    );
  }

  if (callback) {
    callback(error);
  }
};

export const handleAjax = async ({
  request,
  dispatch,
  commit,
  mutation,
  errmsg,
  modify,
  callback,
  root,
}: HandleAjaxParams) => {
  return request
    .then(async (resp) => {
      let data: unknown = null;
      // The following checks are shaky as all hell, but because developers have
      // used two different http libraries, which are configured to behave in
      // different manners, we have to try to figure out which is which until
      // we can remove all calls using the vue-resource library, in favor
      // of the axios library.
      if (isAxiosError(resp)) throw resp._axiosError.response;

      if (isVueResourceResponse(resp)) {
        data = resp.data;
      } else {
        // AxiosResponse.data
        data = resp;
      }

      if (mutation) {
        commit(mutation, modify ? modify(data) : data, root ? { root: true } : undefined);
      }

      if (callback) {
        await callback(undefined, data, resp, { dispatch, commit });
      }
      return data as any;
    })
    .catch((error: HttpResponse | AxiosResponse | Error) => {
      handleError({ error, errmsg, commit, callback });
    });
};

function isAxiosError(resp: unknown): resp is ModifiedAxiosError {
  // /src/services/common/HttpClient.ts doesn't throw, and instead returns resp.data
  if (resp && typeof resp === 'object' && '_axiosError' in resp) {
    return true;
  }
  return false;
}

function isVueResourceResponse(resp: unknown): resp is HttpResponse {
  if (
    Object.prototype.hasOwnProperty.call(resp, 'headers') &&
    Object.prototype.hasOwnProperty.call(resp, 'status') &&
    Object.prototype.hasOwnProperty.call(resp, 'data')
  ) {
    // resp is probably from vue-resource http library, which returns a response object.
    return true;
  }
  return false;
}

interface HandleErrorParams {
  error: HttpResponse | Error | AxiosResponse;
  errmsg?: string;
  commit: Commit;
  callback?: handleErrorCallback;
}

interface HandleAjaxParams {
  request: Promise<HttpResponse | AxiosResponse['data'] | ModifiedAxiosError>; // vue-resource returns the response object, axios service helper returns the response body, hence the any.
  dispatch: Dispatch;
  commit: Commit;
  mutation?: string; // Mutation name
  errmsg?: string; // Message used to display on error
  modify?: (apiRespData: Awaited<HandleAjaxParams['request']>) => any; // Modify resp.json before passing it to a mutation
  callback?: handleAjaxCallback;
  root?: boolean; // Use root store for mutation
}

type handleAjaxCallback = (
  err?: HttpResponse | AxiosResponse | Error,
  respData?: any,
  apiResponse?: HttpResponse | AxiosResponse['data'],
  vuexCtx?: CallbackVuexCtx
) => void;

type handleErrorCallback = (err: HttpResponse | AxiosResponse) => void;

interface CallbackVuexCtx {
  dispatch: Dispatch;
  commit: Commit;
}

type ModifiedAxiosError = Modify<
  AxiosError,
  {
    _axiosError: {
      response: AxiosResponse;
    };
  }
>;
