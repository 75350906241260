import UserModel from '@/models/UserModel';
import UserService from '@/services/UserService';
import { Module } from 'vuex';
import { RootState } from '@/types/store';
/**
 * Switch to using services
 * Commented out functionality that we'll need to change but will need later
 * Errors are returned in the actions and aren't stored in the store
 */

export const initialState: UserStoreState = {
  selectedUsers: [],
  totalCount: 0,
  users: [],
};

export interface UserStoreState {
  selectedUsers: UserModel[];
  totalCount: number;
  users: UserModel[];
}

const state = initialState;

const getters = {
  allUsersSelected: (state) => state.selectedUsers.length === state.users.length && state.users.length !== 0,
  getUserById: (state) => (userId) => state.users.find((x) => x.id === userId),
  allUsers: (state) => state.users,
  selectedUsers: (state) => state.selectedUsers,
  primaryAccountUser: (state) => state.users.find(({ isPrimaryForAccount }) => isPrimaryForAccount),
};

const actions = {
  async fetchUsers({ commit }) {
    const response = await new UserService().getAll();

    commit('setUsers', response.content);
    commit('setTotalCount', response.totalElements);
  },

  toggleSelectedUser({ commit }, user: UserModel) {
    commit('toggleSelectedUser', user);
  },

  toggleAllSelectedUsers({ commit }) {
    commit('toggleAllSelectedUsers');
  },

  removeSelectedUser({ commit }, user: UserModel) {
    commit('removeSelectedUser', user);
  },
};

const mutations = {
  setUsers: (state, users: UserModel) => (state.users = users),
  setTotalCount: (state, totalCount: number) => (state.totalCount = totalCount),
  toggleSelectedUser: (state, selectedUser: UserModel) => {
    const u = state.selectedUsers.filter((u: UserModel) => u.id === selectedUser.id);

    if (u.length > 0) {
      // Its already selected
      state.selectedUsers.splice(u);
    } else {
      state.selectedUsers.push(selectedUser);
    }
  },
  removeSelectedUser: (state, selectedUser: UserModel) =>
    (state.selectedUsers = state.selectedUsers.filter((u: UserModel) => u.id !== selectedUser.id)),
  toggleAllSelectedUsers: (state) => (state.selectedUsers = state.selectedUsers.length === 0 ? state.users : []),
};

const module: Module<UserStoreState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

export default module;
