
import { formatDate } from '@/createandpublish/core/filters';
import { Component, Prop, Vue } from 'vue-property-decorator';

// icons
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';

// images
import FacebookLogo from '@/assets/social-platforms/facebook.png';
import InstagramLogo from '@/assets/social-platforms/instagram.png';
import TikTokLogo from '@/assets/social-platforms/tiktok.png';
import YouTubeLogo from '@/assets/social-platforms/youtube.png';

//types
import { PlatformLabels, convetToPlatformLabel } from '@/audience/data/socialPlatformChartConfig';
import { checkImage } from '@/utils/vanilla/image';

@Component({
  components: {
    YouTubeIcon,
  },
  filters: {
    formatDate,
  },
})
export default class PostItem extends Vue {
  @Prop({ type: String }) type!: PlatformLabels;
  @Prop({ type: String }) url!: string;
  @Prop({ type: String }) thumbnail!: string;
  @Prop({ type: String }) caption!: string;
  @Prop({ type: Date }) postedAt!: Date;
  @Prop({ type: Number }) views!: number;

  @Prop({ type: Boolean, required: false, default: false }) highlighted!: boolean;

  isThumbnailValid = true;

  get platformName() {
    return convetToPlatformLabel(this.type);
  }

  get platformIcon() {
    switch (this.platformName) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      default:
        return '';
    }
  }

  get thumbnailSrc() {
    if (!this.isThumbnailValid) {
      if (this.platformName === PlatformLabels.INSTAGRAM) {
        return InstagramLogo;
      }
      if (this.platformName === PlatformLabels.TIKTOK) {
        return TikTokLogo;
      }
      if (this.platformName === PlatformLabels.YOUTUBE) {
        return YouTubeLogo;
      }
    }
    if (this.platformName === PlatformLabels.FACEBOOK) {
      return FacebookLogo;
    }
    return this.thumbnail;
  }

  goToPost() {
    window.open(this.url, '_blank');
  }

  onPostClick() {
    this.$emit('post-click');
  }

  async mounted() {
    this.isThumbnailValid = await checkImage(this.thumbnail);
  }
}
