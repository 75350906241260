import { AccountData, Subscription } from '@/types/account/AccountData';
import { AccountState } from '@/types/account/AccountState';
import AccountService from '@/services/AccountService';
import type { Product } from '@/types/Product';
import type { ProductSeats } from '@/types/account/AccountData';
import { ProductHandle, ProductKey } from '@/types/ecomm';
import { ProductIds } from '@/types/account/AccountData';
import type { Module, GetterTree } from 'vuex';
import { getPrioritySortedProducts } from '@/utils/products';
import { removeDisabledProducts } from '@/utils/products/removeDisabledProducts';
import { SubscriptionData } from '@/utils/ecomm';
import type { RootState } from '@/types/store';

import moment from 'moment';

export const initialState: AccountState = {
  data: null,
  error: null,
  isLoading: false,
};

const mutationNames = {
  setData: 'setData',
  setIsLoading: 'setIsLoading',
  setError: 'setError',
  setDefaultPaymentProfile: 'setDefaultPaymentProfile',
};

const mutations = {
  [mutationNames.setError]: (state: AccountState, error: string | null) => (state.error = error),
  [mutationNames.setIsLoading]: (state: AccountState, isLoading: boolean) => (state.isLoading = isLoading),
  [mutationNames.setData]: (state: AccountState, data: AccountData | null) => {
    state.data = data;
  },
  [mutationNames.setDefaultPaymentProfile]: (state: AccountState, { paymentProfileId }) => {
    if (!state.data) return;
    state.data.subscription.defaultPaymentProfileId = paymentProfileId;
  },
};

const isMultiBrand = (maxBrands: number): boolean => {
  return maxBrands !== 1;
};

const actions = {
  async fetchAccount({ commit, rootGetters }) {
    commit(mutationNames.setIsLoading, true);
    commit(mutationNames.setError, null);
    try {
      const res = await new AccountService().getAccount();

      commit(mutationNames.setIsLoading, false);

      const enabledProducts = removeDisabledProducts(res.products, rootGetters.features);
      commit(mutationNames.setData, {
        ...res,
        products: enabledProducts,
      });
    } catch (e) {
      commit(mutationNames.setIsLoading, false);
      if (e instanceof Error) {
        commit(mutationNames.setError, e.toString());
      }
    }
  },
};

const getters = {
  hasAccountData: (state) => !!state.data && !(state.isLoading || state.error),

  // Subscription related
  isTrial: ({ data }) => ['trialing', 'trial_ended'].includes(data?.subscriptionStatus || ''),
  subscriptionStatus: ({ data }) => data?.subscriptionStatus,
  trialEndedAt: ({ data }) => data?.trialEndedAt,
  trialHasExpired: ({ data }) => ['trial_ended'].includes(data?.subscriptionStatus || ''),
  trialStartedAt: ({ data }) => data?.trialStartedAt,
  currentPeriodEndsAt: ({ data }) => data?.currentPeriodEndsAt,
  subscriptionBillingInterval: ({ data }) => data?.subscription?.intervalUnit as 'day' | 'month' | 'year',
  maxBrands: ({ data }) => data?.maxBrands ?? 1,
  rawSubscription: ({ data }) => data?.subscription,
  subscriptionId: ({ data }) => data?.subscription.subscriptionId,
  subscriptionProductHandle: ({ data }) => data?.subscription.productHandle ?? ProductHandle.AUDIENCE,
  subscriptionProductKey: (_, getters): ProductKey => {
    switch (getters.subscriptionProductHandle) {
      case ProductHandle.DISCOVER_MONTHLY:
        return ProductKey.DISCOVER;
      case ProductHandle.CREATE_MONTHLY:
        return ProductKey.CREATE_AND_PUBLISH;
      case ProductHandle.SUITE_MONTHLY:
        return ProductKey.SUITE;
      case ProductHandle.DAILY_TEST_PRODUCT:
        return ProductKey.TEST_SUITE;
      case ProductHandle.FULL_SUITE_FREE_TRIAL:
        return ProductKey.SUITE_FREE_TRIAL;
      case ProductHandle.CYBER_MONDAY_PRODUCT:
        return ProductKey.SUITE;
      case ProductHandle.AUDIENCE:
      default:
        return ProductKey.AUDIENCE;
    }
  },
  currentSubscriptionData: (state, getters): SubscriptionData | null => {
    if (!state.data) return null;
    const audienceSeatQuantity = (getters.productSeats[ProductIds.AUDIENCE] as ProductSeats)?.max ?? 0;
    const suiteSeatQuantity = (getters.productSeats[ProductIds.SUITE] as ProductSeats)?.max ?? 0;

    const brandsManagedCount = getters.maxBrands as number;

    const { subscriptionProductHandle: productHandle, subscriptionProductKey: productKey } = getters;

    return {
      productKey: productKey,
      productHandle: productHandle,
      audienceSeatQuantity: audienceSeatQuantity ?? 1,
      suiteSeatQuantity: suiteSeatQuantity ?? 0,
      audienceBrandQuantity: audienceSeatQuantity ? brandsManagedCount : 1,
      suiteBrandQuantity: suiteSeatQuantity ? brandsManagedCount : 0,
    };
  },
  // End Subscription related
  error: ({ error }) => error,
  getAccount: (state) => state,
  // Account products
  accountProducts: ({ data }) => data?.products || [],

  // Account products sorted by priority, excluding Suite
  sortedPriorityAccountProducts: ({ data }) => {
    const products = getPrioritySortedProducts(data?.products || []);
    return products.filter((p) => p.id !== ProductIds.SUITE);
  },

  packageAccountProducts: ({ data }, getters) => {
    const products = data?.products;
    if (!products?.length) {
      return undefined;
    }

    const suiteProduct = products.find((p) => p.id == ProductIds.SUITE);
    const audienceProduct = products.find((p) => p.id == ProductIds.AUDIENCE);

    const currentSubscriptionData = getters.currentSubscriptionData;

    const suiteSeatQuantity = currentSubscriptionData?.suiteSeatQuantity;
    const audienceSeatQuantity = currentSubscriptionData?.audienceSeatQuantity;

    return [
      { ...suiteProduct, maxSeats: suiteSeatQuantity },
      { ...audienceProduct, maxSeats: audienceSeatQuantity },
    ];
  },

  primaryAccountProduct: (_, getters) => {
    const packageProducts = getters.packageAccountProducts;

    const suiteProduct = packageProducts.find((p) => p.id == ProductIds.SUITE);
    const audienceProduct = packageProducts.find((p) => p.id == ProductIds.AUDIENCE);

    if (suiteProduct) {
      return suiteProduct;
    }
    return audienceProduct;
  },

  audienceAccountProduct: (_, getters) => getters.packageAccountProducts.find((p) => p.id == ProductIds.AUDIENCE),
  suiteAccountProduct: (_, getters) => getters.packageAccountProducts.find((p) => p.id == ProductIds.SUITE),

  renewalOrTrialShortText: (_, getters) => {
    const { state, currentPeriodEndsAt, trialEndedAt, expiresAt } = getters.rawSubscription as Subscription;

    if (state == 'trialing') {
      const trialEnded = moment(trialEndedAt);
      const nextDue = trialEnded.format('MMMM Do');

      return `Your next payment will be due ${nextDue}, following the end of your trial period.`;
    } else if (expiresAt) {
      const expires = moment(expiresAt).format('MMMM Do, YYYY');

      return `Your plan will expire on ${expires}.`;
    } else if (currentPeriodEndsAt) {
      const renews = moment(currentPeriodEndsAt).format('MMMM Do, YYYY');

      return `Your plan will automatically renew on ${renews}.`;
    }
  },

  nextPaymentDueShortText: (_, getters) => {
    const { state, currentPeriodEndsAt, trialEndedAt } = getters.rawSubscription as Subscription;

    if (state == 'trialing') {
      const trialEnded = moment(trialEndedAt);
      const nextDue = trialEnded.format('MMMM Do');

      return `Your next payment will be due ${nextDue}, following the end of your trial period.`;
    } else {
      const nextDue = moment(currentPeriodEndsAt).format('MMMM Do');

      return `Your next payment will be due ${nextDue}.`;
    }
  },

  nextPaymentDueText: (_, getters) => {
    const { state, currentPeriodEndsAt, trialStartedAt, trialEndedAt } = getters.rawSubscription as Subscription;

    if (state == 'trialing') {
      const now = moment();
      const trialStarted = moment(trialStartedAt);
      const trialEnded = moment(trialEndedAt);

      const trialDays = Math.floor(moment.duration(now.diff(trialStarted)).asDays());
      const trialLength = Math.floor(moment.duration(trialEnded.diff(trialStarted)).asDays());

      const trialDaysString = trialDays == 0 ? 'the first day' : `day ${trialDays + 1}`;

      const nextDue = trialEnded.format('MMMM Do, YYYY');
      const dueDay = trialEnded.format('Do');

      return `You are on ${trialDaysString} of your ${trialLength}-day trial. Your first payment will be due on ${nextDue} after the trial ends. You will then be charged on the ${dueDay} of each month.`;
    } else {
      const currentPeriodEnds = moment(currentPeriodEndsAt);
      const nextDue = currentPeriodEnds.format('MMMM Do, YYYY');
      const dueDay = currentPeriodEnds.format('Do');

      return `Your next payment is due on ${nextDue}. You are charged on the ${dueDay} of each month.`;
    }
  },

  sortedPriorityAccountProductsAll: ({ data }) =>
    getPrioritySortedProducts(data?.products || [], data?.allProducts || []),
  subscriptionProducts: (_, getters) => {
    // NOTE: A Subscription Product is distinct from a Product, in the sense that a Product represents a CC module (i.e. Discover)
    // A Subscription Product is a product in the Chargify sense.
    const subscriptionProductHandle = getters.subscriptionProductHandle as ProductHandle;
    const audienceProduct = getters.accountProducts.find(({ id }) => ProductIds.AUDIENCE === id) as Product;

    const suiteProduct: Product = {
      active: false,
      currentSeats: 0,
      description:
        'Measure audience growth, discover your next piece of creative genius, and easily schedule, publish and track your content across multiple platforms.',
      id: ProductIds.SUITE,
      maxSeats: 0,
      name: 'Idea Engine Creator Package',
    };

    if (
      [ProductHandle.FULL_SUITE_FREE_TRIAL, ProductHandle.SUITE_MONTHLY, ProductHandle.DAILY_TEST_PRODUCT].includes(
        subscriptionProductHandle
      )
    ) {
      const { state } = getters.rawSubscription as Subscription;
      const suiteAccountProduct = getters.accountProducts.find(({ id }) => ProductIds.SUITE === id);
      if (suiteAccountProduct) {
        const { maxSeats, currentSeats } = suiteAccountProduct;

        suiteProduct.active = ['trialing', 'active'].includes(state);
        suiteProduct.currentSeats = currentSeats;
        suiteProduct.maxSeats = maxSeats;
      }
      // return [suiteProduct];
    }

    return [audienceProduct, suiteProduct];
  },
  isLoading: ({ isLoading }) => isLoading,
  isUserMultiBrand: ({ data }) => (data ? isMultiBrand(data.maxBrands) : undefined),
  userProductName: (state) => (id: Product['id']) => {
    const products = state.data?.products ?? [];
    if (products?.length > 0) {
      return products.find((product) => product.id === id)?.name || 'NA';
    }
    return 'NA';
  },
  productSeats: (state): Record<Product['id'], ProductSeats> => {
    const entries =
      state.data?.products?.map((product: Product) => {
        return [
          product.id,
          {
            available: product.maxSeats - product.currentSeats,
            filled: product.currentSeats,
            max: product.maxSeats,
          },
        ];
      }) ?? [];

    return Object.fromEntries(entries);
  },
  userProductSeats: (state) => (id: Product['id']) => {
    const products = state.data?.products ?? [];
    if (products?.length > 0) {
      const currentProduct = products.find((product) => product.id === id);
      if (currentProduct?.id) {
        return {
          available: currentProduct.maxSeats - currentProduct.currentSeats,
          filled: currentProduct.currentSeats,
          max: currentProduct.maxSeats,
        };
      }
    }
    // If product not found
    return {
      available: 'NA',
      filled: 'NA',
      max: 'NA',
    };
  },
} as GetterTree<AccountState, RootState>;

const {
  isLoading: { name: ACCOUNT_IS_LOADING },
  getAccount: { name: ACCOUNT_DATA },
  isUserMultiBrand: { name: ACCOUNT_IS_MULTI_BRAND },
  error: { name: ACCOUNT_ERROR },
} = getters;

const getterNames = { ACCOUNT_IS_LOADING, ACCOUNT_DATA, ACCOUNT_ERROR, ACCOUNT_IS_MULTI_BRAND };

const {
  fetchAccount: { name: ACCOUNT_FETCH },
} = actions;
const actionNames = { ACCOUNT_FETCH };

const module: Module<AccountState, RootState> = {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

export interface R {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default module;

export { actionNames, getterNames };
