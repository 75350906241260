import { escapeRegExp, getTalkBreakTitle } from '../core/util';
import HttpClient from '@/services/common/HttpClient';
import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state: {
    ad_stack: [],
    playlistEditing: null,
    twToken: null,
  },
  getters: {
    adStack: (state) => state.ad_stack,
    playlistEditing: (state) => state.playlistEditing,
    twToken: (state) => state.twToken,
  },
  mutations: {
    TOGGLE_AD_STATUS(state, index) {
      state.ad_stack[index].isPlaceholder = !state.ad_stack[index].isPlaceholder;
    },

    REMOVE_AD(state, index) {
      state.ad_stack.splice(index, 1);
    },

    ADD_AD(state) {
      state.ad_stack.push({ id: uuidv4(), rawDuration: 0, isPlaceholder: true });
    },

    CLEAR_ADS(state) {
      state.ad_stack = [];
    },

    INSERT_AD_AT_INDEX(state, index) {
      state.ad_stack.splice(index, 0, { id: uuidv4(), rawDuration: 0, isPlaceholder: true });
    },

    SET_AD_STACK(state, adStack) {
      state.ad_stack = adStack;
    },

    SET_TW_TOKEN: (state, token) => {
      state.twToken = token;
    },

    SET_PLAYLIST_SEGMENT_LIST: (state, segmentList) => {
      state.playlistEditing.tw_segment_list = segmentList;
    },

    SET_PLAYLIST_SEGMENT_POSITIONS: (state, segmentPositions) => {
      state.playlistEditing.tw_segment_positions = segmentPositions;
    },

    SET_PLAYLIST_SAMPLE_RATE: (state, sampleRate) => {
      state.playlistEditing.tw_sample_rate = sampleRate;
    },

    SET_PLAYLIST_SELECTION: (state, selectionIndex) => {
      state.playlistEditing.selectionIndex = selectionIndex;
    },

    SET_EDITOR_SELECTION: (state, selectionRange) => {
      state.playlistEditing.selectionRange = selectionRange;
    },

    SET_PLAYLIST_EVENTS: (state, events) => {
      state.playlistEditing.events = events;
    },
    SET_EDIT_PLAYLIST: (state, playlist) => {
      state.playlistEditing = playlist;
    },
  },
  actions: {
    addAd({ commit }) {
      commit('ADD_AD');
    },

    insertAddAtIndex({ commit }, index) {
      commit('INSERT_AD_AT_INDEX', index);
    },

    removeAd({ commit }, index) {
      commit('REMOVE_AD', index);
    },

    toggleAdStatus({ commit }, index) {
      commit('TOGGLE_AD_STATUS', index);
    },

    clearAds({ commit }) {
      commit('CLEAR_ADS');
    },

    setAdStack({ commit }, adStack) {
      commit('SET_AD_STACK', adStack);
    },
    async editPlaylist({ commit, rootGetters, rootState }, playlistId) {
      try {
        const http = HttpClient.getInstance();
        // Call to the backend to smush the playlist together and send it to twisted wave
        const apiResponse = await http.get(`${rootState.CreateAndPublishStore.endpoint}/playlist/edit/${playlistId}`);

        // Call to the backend to get the details for the playlist so we can map things
        const playlistResponse = await http.get(`${rootState.CreateAndPublishStore.endpoint}/playlist/${playlistId}`);
        const eventsList = [];
        let eventsBegin = 0;
        // this initial sample count is just so we're setting a sensible default to begin and end
        // this will get overridden as soon as the user does anything with the segments
        // this is mostly to protect in case they get to this point and do nothing to trigger the segment observer
        const sampleRate = 44100;

        const interpolateTalkBreakTitle = (event) => {
          const categoryName = (event.cart && event.cart.category && event.cart.category.name) || '';
          return getTalkBreakTitle(event, rootGetters.categoryMap[categoryName], rootGetters.allShows);
        };

        playlistResponse.events.forEach((event) => {
          const dur = event.duration * sampleRate;
          eventsList.push({
            id: event.id,
            title: interpolateTalkBreakTitle(event),
            duration: event.duration,
            cart: event.cart,
            begin_sample: eventsBegin,
            end_sample: eventsBegin + dur,
            deleted: false,
          });
          eventsBegin += dur;
        });

        const playlistMetadata = {
          id: playlistResponse.body.id,
          events: eventsList,
          duration: playlistResponse.duration,
          document_id: apiResponse.document_id,
          tw_segment_list: [],
          tw_segment_positions: [],
          tw_sample_rate: 0,
          selectionIndex: null,
          selectionRange: null,
        };
        // Set the editor token
        commit('SET_TW_TOKEN', apiResponse.token);
        // Set the playlist we're editing
        commit('SET_EDIT_PLAYLIST', playlistMetadata);
        return apiResponse;
      } catch (e) {
        // throw error into console but don't do anything
        console.error(e);
      }
    },

    async sendPlaylistOutputs({ rootState }, playlist) {
      const http = HttpClient.getInstance();
      const resp = http.post(
        `${rootState.CreateAndPublishStore.endpoint}/playlist/submit_output/${playlist.id}`,
        playlist
      );
      return resp;
    },

    async getStackEventsFromStrings({ rootState }, events) {
      const http = HttpClient.getInstance();
      const promiseArray = [];
      events.forEach((event) => {
        promiseArray.push(http.get(`${rootState.CreateAndPublishStore.baseUri}${event}`));
      });
      const resp = await Promise.all(promiseArray);
      return resp.map((x) => x);
    },

    // Sync the events with the segments we're getting back from the segment listener
    syncSegmentsAndEvents({ getters, commit }) {
      // shallow copies to avoid mutation
      const oldEvents = [...getters.playlistEditing.events];
      const newEvents = [];
      const sampleRate = getters.playlistEditing.tw_sample_rate;
      let samplePosition = 0;
      getters.playlistEditing.tw_segment_list.forEach((seg, index) => {
        const numSamplesInSeg = seg.end - seg.begin;
        const newBegin = samplePosition;
        const newDuration = Number((numSamplesInSeg / sampleRate).toFixed(2));
        const newEnd = samplePosition + numSamplesInSeg;
        samplePosition += numSamplesInSeg;
        // We have enumerated duplicate segments which will normally mess up the matching so this match fixes that
        const titleRegex = new RegExp(`(${escapeRegExp(seg.name.replace(/\s\([A-Z]+\)$/, ''))})(\\s\\([A-Z]+\\))?$`);
        newEvents[index] = {
          ...oldEvents.find((event) => event.title.match(titleRegex)),
          begin_sample: newBegin,
          end_sample: newEnd,
          duration: newDuration,
          deleted: false,
          title: seg.name,
        };
      });

      // We want to keep metadata around for events that we aren't displaying in case someone hits undo
      // This is the best way I could figure to do it
      oldEvents.forEach((e, index) => {
        if (!newEvents.find((event) => event.title === e.title)) {
          // shallow copy to prevent mutation
          const tempEvent = { ...e };
          tempEvent.deleted = true;
          commit('REMOVE_AD', index);
          newEvents.push(tempEvent);
        }
      });

      // Sync the ad stack as best we can.
      // This isn't perfect, some of the ads get a little jumbled but this is the best I could come up with for now
      const oldAds = getters.adStack;
      const newAds = [];
      for (let i = 0; i < newEvents.filter((e) => !e.deleted).length - 1; i++) {
        if (oldAds.length > i) {
          newAds[i] = { ...oldAds[i] };
        } else {
          newAds[i] = { id: uuidv4(), rawDuration: 0, isPlaceholder: true };
        }
      }
      commit('SET_AD_STACK', newAds);
      commit('SET_PLAYLIST_EVENTS', newEvents);
    },

    setSegmentList({ commit }, segmentList) {
      commit('SET_PLAYLIST_SEGMENT_LIST', segmentList);
    },

    setSegmentPositions({ commit }, segmentPositions) {
      commit('SET_PLAYLIST_SEGMENT_POSITIONS', segmentPositions);
    },

    setPlaylistSampleRate({ commit }, sampleRate) {
      commit('SET_PLAYLIST_SAMPLE_RATE', sampleRate);
    },

    setPlaylistSelection({ commit }, selectionIndex) {
      commit('SET_PLAYLIST_SELECTION', selectionIndex);
    },

    setEditorSelection({ commit }, selectionRange) {
      commit('SET_EDITOR_SELECTION', selectionRange);
    },
  },
};
