export function parseCommentary(commentary: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(commentary, 'text/html');

  let listItems: NodeListOf<HTMLLIElement | HTMLParagraphElement> = doc.querySelectorAll('li');

  if (!listItems.length) {
    listItems = doc.querySelectorAll('p');
  }

  const listItemsArray = Array.from(listItems);
  const listItemsText = listItemsArray.map((item) => {
    let currentContent = item.textContent?.trim();
    if (currentContent && currentContent[0] === '"') {
      currentContent = currentContent.slice(1, currentContent.length - 1);
    }
    if (currentContent && currentContent[0] === currentContent[0].toLocaleLowerCase()) {
      currentContent = currentContent[0].toLocaleUpperCase() + currentContent.slice(1);
    }
    if (currentContent && currentContent[currentContent.length - 1] !== '.') {
      currentContent += '.';
    }
    return currentContent;
  });
  return listItemsText;
}
