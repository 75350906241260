
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { type SocialPlatform } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import { DraftSocialStory } from '@/types/createandpublish/socialStory';
import { namespace } from 'vuex-class';
import { AyrshareProfile } from '@/types/audience';

import InstagramPreview from './socialPostPreviews/InstagramPreview.vue';
import FacebookPreview from './socialPostPreviews/FacebookPreview.vue';
import YouTubePreview from './socialPostPreviews/YouTubePreview.vue';
import TikTokPreview from './socialPostPreviews/TikTokPreview.vue';
import TwitterPreview from './socialPostPreviews/TwitterPreview.vue';
import LinkedInPreview from './socialPostPreviews/LinkedInPreview.vue';
import PinterestPreview from './socialPostPreviews/PinterestPreview.vue';
import { CreateSocialPostBody } from 'content-cloud-types/dist/types/createandpublish/PostingControllerInterfaces';

const audienceData = namespace('AudienceAnalyticsStore');

@Component({
  name: 'SocialPostPreview',
  components: {
    InstagramPreview,
    FacebookPreview,
    YouTubePreview,
    TikTokPreview,
    TwitterPreview,
    LinkedInPreview,
    PinterestPreview,
  },
})
export default class SocialPostPreview extends Vue {
  @Prop({ required: false, default: '' }) readonly currentSelectedTab!: SocialPlatform | '';
  @Prop({ required: false, default: null }) readonly draftSocialStory!: DraftSocialStory | null;

  @audienceData.Getter profile!: AyrshareProfile;

  platformPostDetails: CreateSocialPostBody | null = null;

  get audienceProfileData() {
    if (this.currentSelectedTab !== '') {
      return this.profile;
    }
    return null;
  }

  get platformProfileData() {
    if (this.currentSelectedTab !== '') {
      return this.profile.activeSocialAccounts.find(
        (platformData) => platformData.platform === this.currentSelectedTab
      );
    }
    return null;
  }

  @Watch('currentSelectedTab')
  getCurrentPlatformPostDetails() {
    let temp: CreateSocialPostBody | null | undefined = null;
    if (this.draftSocialStory?.socialPlatformPosts?.length) {
      temp = this.draftSocialStory.socialPlatformPosts.find(
        (post) => post.mainRequest.platforms[0] === this.currentSelectedTab
      );
      if (temp) {
        this.platformPostDetails = temp;
      }
    } else {
      this.platformPostDetails = temp ? temp : null;
    }
  }

  get currentPlatformPostDetails() {
    this.getCurrentPlatformPostDetails();
    return this.platformPostDetails;
  }

  mounted() {
    if (this.currentSelectedTab !== '') {
      this.platformPostDetails = null;
    }
  }
}
