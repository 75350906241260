import store from '@/store';
import type { AuthenticatedUser } from '@/models/UserModel';
import type { Brand } from '@/types/Brand';
import { roleIdMap, statusIdMap } from '@/utils/thirdPartySupport';
import { v4 as uuid } from 'uuid';

export interface FullStoryUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string | undefined;
  brandId?: number | undefined;
  role?: string;
  status?: string;
}

const run = () => {
  const authenticatedUser = store.getters['authenticatedUser'] as AuthenticatedUser;

  const id = uuid();
  let fullStoryUser: FullStoryUser = {};
  let uid = `CC:${id}`;

  if (authenticatedUser) {
    // if user is logged in

    const { id: brandId } = store.getters['selectedBrand'] as Brand;
    uid = `CC:${authenticatedUser.id}:${authenticatedUser.email}`;

    fullStoryUser = {
      firstName: authenticatedUser.firstName,
      lastName: authenticatedUser.lastName,
      email: authenticatedUser.email,
      phone: authenticatedUser.phone,
      brandId: brandId,
      role: roleIdMap[authenticatedUser.roleId],
      status: statusIdMap[authenticatedUser.statusId],
    };
  }

  window.FS.identify(uid, fullStoryUser);
};

export default run;
