import { render, staticRenderFns } from "./PlatformPostMetrics.vue?vue&type=template&id=3fc4adf1&scoped=true&"
import script from "./PlatformPostMetrics.vue?vue&type=script&lang=ts&"
export * from "./PlatformPostMetrics.vue?vue&type=script&lang=ts&"
import style0 from "./PlatformPostMetrics.vue?vue&type=style&index=0&id=3fc4adf1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc4adf1",
  null
  
)

export default component.exports