import MurmurHash3 from 'imurmurhash';

/**
 * Returns a string hashed with non-cryptographic 16 bit hash (as hex)
 * */
export const nonCryptoHash = (s: string): string => new MurmurHash3(s).result().toString(16);

/**
 * Returns the given string suffixed with a non-crpytographic hash of both strings
 * or uses the first string if only one string is provided
 * */
export const withNonCryptoHashSuffix = (s: string, s2?: string): string =>
  `${s}-${nonCryptoHash(s2 ? `${s}-${s2}` : s)}`;
