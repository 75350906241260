/* eslint-disable no-console */
import MiniSearch, { AsPlainObject } from 'minisearch';
import uniqBy from 'lodash.uniqby';

import DiscoverStorySearch from '@/models/DiscoverStorySearchModel';

import DiscoverShortcutModel from '@/models/DiscoverShortcutModel';

import { stopListEn } from '@/utils/stoplists/words';
import { stopPhrasesEnRegex } from '@/utils/stoplists/phrases';
import { isNotNullOrUndefined } from '@/utils/typeGuards';
import { normalizeString } from '@/utils/string';
import { nonCryptoHash } from '@/utils/hashing';
import { logDebugFunc } from '@/utils/logging';

const logDebug = logDebugFunc();

export type SavedIndex = { [k in string]: unknown };

export enum DiscoverStoryFeedType {
  idea_starters = 'idea_starters',
  trending = 'trending',
}

// Just title only for now, adding shortcutName gives mixed results (but more of them)
const fields = ['title'];

export type DiscoverStorySearchable = {
  id: string;
  feedType: DiscoverStoryFeedType;

  // Fields to be searched
  title: string;
  shortcutName?: string;

  shortcutId?: number;

  shortcuts?: DiscoverShortcutData[];
  primaryShortcut?: DiscoverShortcutData;

  data: DiscoverStorySearch;
};

export type DiscoverStorySearchableMeta = Pick<DiscoverStorySearchable, 'shortcutId' | 'shortcuts' | 'primaryShortcut'>;

export type DiscoverStorySearchableFilters = DiscoverStorySearchableMeta & {
  shortcutIds?: number[];
};

// This is to allow adding fields later without changing other users
// such as story counts, updated at, etc.
export type DiscoverShortcutData = DiscoverShortcutModel;

export const createSearchableStory = (
  data: DiscoverStorySearch,
  feedType: DiscoverStoryFeedType,
  meta?: DiscoverStorySearchableMeta
): DiscoverStorySearchable => {
  const { storyId, title } = data;

  const shortcuts = meta?.shortcuts ?? [];
  const primaryShortcut = meta?.primaryShortcut ?? shortcuts?.[0];

  const shortcutId = primaryShortcut?.id;
  const shortcutName = primaryShortcut?.name;

  // Create a unique, searchable id
  const id = `${storyId}-${nonCryptoHash(`${title}-${shortcutId}`)}`;

  const searchable: DiscoverStorySearchable = {
    id,
    feedType,

    title,
    shortcutName,

    ...meta,
    shortcuts,
    primaryShortcut,

    data,
  };

  return searchable;
};

export const extractDataFromSearchable = (
  searchableIter: IterableIterator<DiscoverStorySearchable> | Iterable<DiscoverStorySearchable>
): IterableIterator<DiscoverStorySearch> => {
  function* gen() {
    for (const { data } of searchableIter) {
      yield data;
    }
  }

  return gen();
};

export const filterSearchableStory =
  (feedType: DiscoverStoryFeedType, filters?: DiscoverStorySearchableFilters) => (story: DiscoverStorySearchable) => {
    // console.info(`filterSearchableStory: filters: `, filters);

    if (story.feedType !== feedType) {
      return false;
    }

    if (filters) {
      const filterShortcutIds: Set<number> = new Set(
        filters.shortcuts?.map(({ id }) => Number(id)) ??
          filters.shortcutIds ??
          (filters.shortcutId ? [Number(filters.shortcutId)] : [])
      );

      if (filterShortcutIds?.size) {
        const storyShortcutIds = story.shortcuts?.map(({ id }) => Number(id));

        const valid = storyShortcutIds?.some((id) => filterShortcutIds.has(id));
        if (!valid) {
          return false;
        }
      }
    }

    return true;
  };

function newSearchEngine<D = unknown>() {
  const minisearch = new MiniSearch<D>({
    fields,
    processTerm: (term) => (stopListEn.has(term) ? null : term.toLowerCase()), // index term processing

    tokenize: (text: string) => {
      let s = normalizeString(text.toLocaleLowerCase());

      s = s.replace(stopPhrasesEnRegex, ' ');

      const res = s.split(/ /g);

      return res;
    },

    searchOptions: {
      weights: {
        fuzzy: 0.1,
        prefix: 0.8,
      },
      prefix: true,
      fuzzy: true,
      boost: {
        title: 2,
      },
      combineWith: 'AND',
    },
  });

  return minisearch;
}

type DiscoverStoryMap = Map<string, DiscoverStorySearchable>;

type DiscoverShortcutMap = Map<number, DiscoverShortcutData>;

class DiscoverStoryManagerClass {
  searchEngine?: MiniSearch;
  searchIndex?: SavedIndex;

  storyMap?: DiscoverStoryMap;
  shortcutMap?: DiscoverShortcutMap;

  get isReady() {
    return !!this.searchEngine && !!this.storyMap;
  }

  private async prepareSearchEngine() {
    if (!this.searchEngine) {
      if (this.searchIndex) {
        this.searchEngine = MiniSearch.loadJS(this.searchIndex as AsPlainObject, { fields });
      } else {
        this.searchEngine = newSearchEngine();
      }
    }
  }

  private async mapStories(
    stories: DiscoverStorySearch[],
    feedType: DiscoverStoryFeedType,
    meta?: DiscoverStorySearchableMeta
  ) {
    const mappedStories: [string, DiscoverStorySearchable][] = stories.map((story) => {
      const searchable = createSearchableStory(story, feedType, meta);
      const id = searchable.id;

      return [id, searchable];
    });

    return mappedStories;
  }

  // FUTURE: make this support feedType, but for now, we delete everything
  // ideaStarters are not currently cached in StoryManager
  async clearAll() {
    this.storyMap = undefined;
    this.shortcutMap = undefined;
    if (this.searchEngine) {
      this.searchEngine.removeAll();
    }
  }

  async loadStories(
    stories: DiscoverStorySearch[],
    feedType: DiscoverStoryFeedType,
    meta: DiscoverStorySearchableMeta
  ) {
    const addedStories = stories.length ? await this.addStoriesToSearch(stories, feedType, meta) : 0;

    // Always add shortcuts, this will allow tracking empty categories

    const shortcutIds = await this.addShortcuts(meta);

    logDebug(
      `DiscoverStoryManager: loadStories: adding ${addedStories} documents to index (shortcut ids: ${shortcutIds.join(
        ', '
      )})`
    );
    logDebug(
      `DiscoverStoryManager: loadStories: shortcut map now has ${this.shortcutMap?.size ?? 0} entries: `,
      Array.from((this.shortcutMap ?? new Set()).keys()).join(', ')
    );
  }

  private async addStoriesToSearch(
    stories: DiscoverStorySearch[],
    feedType: DiscoverStoryFeedType,
    meta: DiscoverStorySearchableMeta
  ) {
    if (!this.searchEngine) {
      await this.prepareSearchEngine();
    }
    const mappedStories = await this.mapStories(stories, feedType, meta);

    // Hack: filter ids already in index
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const indexedIds = new Set((this.searchEngine as any)._documentIds.values());

    // FUTURE: replace with lodash version, already used elsewhere
    const dedupedStories = uniqBy(mappedStories, (s) => s[1].id);

    const unindexedStories = dedupedStories.filter((s) => !indexedIds.has(s[1].id));

    if (process.env.VUE_APP_DEBUG_LOG == '1') {
      console.debug(`DiscoverStoryManager: loadStories: mappedStories: `, mappedStories);
      console.debug(`DiscoverStoryManager: loadStories: dedupedStories: `, dedupedStories);
      console.debug(`DiscoverStoryManager: loadStories: unindexedStories: `, unindexedStories);
    }

    // Load stories into story map
    // FUTURE: replace this with an lru implementation
    if (!this.storyMap) {
      this.storyMap = new Map<string, DiscoverStorySearchable>(unindexedStories);
    } else {
      for (const [id, searchable] of unindexedStories) {
        this.storyMap.set(id, searchable);
      }
    }

    // Add to search engine
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.searchEngine!.addAllAsync(unindexedStories.map((s) => s[1]));

    return unindexedStories?.length ?? 0;
  }

  async addShortcuts(meta: DiscoverStorySearchableMeta) {
    const shortcuts = Array.from(
      new Set([meta?.primaryShortcut, ...(meta?.shortcuts ?? [])].filter(isNotNullOrUndefined))
    );
    const shortcutIds = shortcuts.map((s) => s.id);

    if (!this.shortcutMap) {
      this.shortcutMap = new Map();
    }

    for (const shortcut of shortcuts) {
      this.shortcutMap.set(shortcut.id, shortcut);
    }

    return shortcutIds;
  }

  async searchStories(query: string): Promise<DiscoverStorySearchable[]> {
    if (!this.searchEngine || !this.storyMap) {
      return [];
    }

    const searchResults = this.searchEngine.search(query);
    if (process.env.VUE_APP_DEBUG_LOG == '1') {
      console.info(`DiscoverStoryManager: searchResults: `, searchResults);
    }

    const mappedResults = searchResults.map((sr) => this.storyMap?.get(sr.id)).filter(isNotNullOrUndefined);

    return mappedResults;
  }

  async autoSuggest(query: string) {
    if (!this.searchEngine || !this.storyMap) {
      return [];
    }

    const suggestions = this.searchEngine.autoSuggest(query);
    if (process.env.VUE_APP_DEBUG_LOG == '1') {
      console.debug(`DiscoverStoryManager: autoSuggest: `, suggestions);
    }

    return suggestions;
  }

  filterStories(
    feedType: DiscoverStoryFeedType,
    filters?: DiscoverStorySearchableFilters
  ): Array<DiscoverStorySearchable> {
    if (!this.searchEngine || !this.storyMap) {
      return [];
    }

    const stories = Array.from(this.storyMap.values());
    logDebug(`DiscoverStoryManager: filterStories: stories: `, stories);
    const filtered = stories.filter(filterSearchableStory(feedType, filters));

    return filtered;
  }

  hasShortcutId(shortcutId: number) {
    const res = this.shortcutMap?.has(shortcutId) ?? false;
    logDebug(`DiscoverStoryManager: hasShortcutId: checking for shortcut ${shortcutId}: `, res);
    return res;
  }
}

const DiscoverStoryManager = new DiscoverStoryManagerClass();
export default DiscoverStoryManager;
