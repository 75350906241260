import { requiresBrandAccess, requiresProductAccess } from '@/constants/router/permissions';
import type { RouteConfig } from 'vue-router';

const Discover = () => import('@/views/Discover.vue');
const Following = () => import('@/components/discover/Following.vue');
const DiscoverSettings = () => import('@/components/discover/Settings.vue');
const CreatorCommunity = () => import('@/audience/views/CreatorCommunity.vue');

export default [
  {
    path: '/discover/:brandId?',
    name: 'Discover',
    component: Discover,
    meta: {
      ...requiresBrandAccess,
      sidebar_active_item: 'Trending',
      ...requiresProductAccess,
      title: 'Trending | Discover',
      feature: 'discover',
    },
  },
  {
    path: '/discover/:brandId/following',
    name: 'Discover Following',
    component: Following,
    meta: {
      ...requiresBrandAccess,
      sidebar_active_item: 'Following',
      ...requiresProductAccess,
      title: 'Following | Discover',
      feature: 'discover',
    },
  },
  {
    path: '/discover/:brandId/settings',
    name: 'Discover Settings',
    component: DiscoverSettings,
    meta: {
      ...requiresBrandAccess,
      sidebar_active_item: 'Settings',
      ...requiresProductAccess,
      title: 'Settings | Discover',
      feature: 'discover',
    },
  },
  {
    path: '/discover/:brandId/community',
    name: 'Discover.CreatorCommunity',
    component: CreatorCommunity,
    props: {
      addPadding: true,
    },
    meta: {
      sidebar_active_item: 'Creator Community',
      title: 'Creator Community | Discover',
    },
  },
] as RouteConfig[];
