import { v4 } from 'uuid';
import { currentStation } from '../core/util';
import Vue from 'vue';
import HttpClient from '@/services/common/HttpClient';

const createId = (showId) => `${currentStation()}_${showId}_${v4()}`;

/**
 * Takes data returned from the API and normalizes it for this application
 */
const normalizeEpisodeData = (episode, activeShow) => {
  const categoryList = [];
  episode.categories.forEach((category) => categoryList.push(category.resource_uri));

  return {
    advanced_video_status: episode.advanced_video_status || 0,
    ad_insert_events: episode.ad_insert_events || JSON.stringify([]),
    attribution: episode.attribution || [],
    categories: categoryList,
    chapter_markers: episode.chapter_markers || [],
    description: episode.description || '',
    duration: episode.duration || 0,
    events: episode.events || [],
    goto_live: episode.goto_live || false,
    image: episode.image || '',
    rss_image: episode.rss_image || '',
    image_croppings: episode.image_croppings || JSON.stringify({ left: 0, top: 0, right: 0, bottom: 0 }),
    rss_image_croppings: episode.rss_image_croppings || JSON.stringify({ left: 0, top: 0, right: 0, bottom: 0 }),
    name: episode.name || 'Untitled Episode',
    onceUseTags: episode.onceUseTags || [],
    restricted: episode.restricted || false,
    show: episode.show.resource_uri || activeShow.resource_uri,
    post_locations: episode.post_locations || [],
    tags: episode.tags || [],
    explicit: episode.explicit,
    draft: episode.draft,
    unpublished: episode.unpublished,
    permanent: episode.permanent,
    episode_type: episode.episode_type || 'full',
    season_number: episode.season_number || null,
    episode_number: episode.episode_number || null,
    social_share_text: episode.social_share_text || '',
    maxStepCompleted: 0,
  };
};

export default {
  namespaced: true,

  state: {
    currentStep: 1,
    currentDraftEpisodeId: null,
    currentDraftEpisodeTimestamp: NaN,
    draftEpisodes: {},
    currentDraftEvents: [],
    editingEpisode: null,
    ad_stack: [],
  },

  getters: {
    currentDraftEpisodeId: (state) => state.currentDraftEpisodeId,
    editingEpisode: (state) => state.editingEpisode,
    currentDraftEpisode: (state) => {
      const id = state.currentDraftEpisodeId;
      // ts is just here to force this getter to abandon old cache
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const ts = state.currentDraftEpisodeTimestamp; // eslint-disable-line no-unused-vars
      return state.draftEpisodes[id] || null;
    },
    currentDraftEpisodeAdMarkersForEvents: (_state, getters) => {
      if (getters.currentDraftEpisode && getters.currentDraftEpisode.ad_insert_events) {
        const adEvents = JSON.parse(getters.currentDraftEpisode.ad_insert_events);
        return adEvents.filter((adEvent) => {
          // refers an event_id, or is "END"
          // e.g. ["1575885869.681390","END"]
          return isNaN(adEvent) || Number(adEvent) > 100000;
        });
      }
      return [];
    },
    currentDraftEpisodeAdMarkersForWavesurfer: (_state, getters) => {
      if (getters.currentDraftEpisode && getters.currentDraftEpisode.ad_insert_events) {
        const adEvents = JSON.parse(getters.currentDraftEpisode.ad_insert_events);
        return adEvents.filter((adMarker) => {
          // refers the number of seconds into the full audio
          // e.g. ["53.147979", "75.44994"]
          return !isNaN(adMarker) && Number(adMarker) < 100000;
        });
      }
      return [];
    },
    currentDraftEvents: (state) => state.currentDraftEvents,
    getBrand: () => {
      const vuex = localStorage.getItem('vuex');
      try {
        const _store = vuex ? JSON.parse(JSON.stringify(vuex)) : null;
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand?.id;
      } catch {
        console.warn('Could not retrieve brand id');
      }
    },
  },

  mutations: {
    /**
     * Set step process in drafting episode.
     * @param state
     * @param stepNum
     * @constructor
     */
    SET_STEP(state, stepNum) {
      state.currentStep = stepNum;
    },

    SET_MAX_STEP_COMPLETED(state, step) {
      state.maxStepCompleted = step;
    },

    /**
     * Set ID for current drafted episode.
     * @param state
     * @param id
     * @constructor
     */
    SET_CURRENT_DRAFT_EPISODE_ID(state, id) {
      state.currentDraftEpisodeId = id;
    },

    /**
     * Assign new ID to drafted episode.
     * @param state
     * @param id
     * @param newId
     * @constructor
     */
    SET_NEW_EPISODE_ID(state, { id, newId }) {
      const existing = state.draftEpisodes[id] || { id };
      delete state.draftEpisodes[id];

      // Now set new ID to the old episode object
      state.draftEpisodes[newId] = existing;
      state.currentDraftEpisodeId = newId;
    },

    /**
     * Update draft episode based on ID.
     * @param state
     * @param id
     * @param data
     * @constructor
     */
    UPDATE_DRAFT_EPISODE(state, { id, data }) {
      const existing = state.draftEpisodes[id] || { id };
      Vue.set(state.draftEpisodes, id, {
        ...existing,
        ...data,
      });

      // id must be removed when updating a playlist
      delete state.draftEpisodes[id].id;
      state.currentDraftEpisodeTimestamp = new Date().getTime();
    },

    /**
     * Update current drafted events. Prevents duplicate events, which are not supported
     * @param state
     * @param events
     * @constructor
     */
    UPDATE_DRAFT_EVENTS(state, events) {
      const uniqueEvents = [];
      events.forEach((event) => {
        if (!uniqueEvents.includes(event)) {
          uniqueEvents.push(event);
        }
      });
      state.currentDraftEvents = uniqueEvents;
    },

    /**
     * Update current drafted events with separated ad events.
     * @param state
     * @param events
     * @param ad_insert_events
     * @constructor
     */
    UPDATE_DRAFT_EVENTS_WITH_ADS(state, { events, ad_insert_events }) {
      // Iterate ad events into events list
      const newList = [];
      events.forEach((event, index) => {
        // Does an ad slot need to go in?
        if (ad_insert_events.includes(event.id)) {
          newList.push({
            isAd: true,
            id: index,
          });
        }
        newList.push(event);
      });
      if (ad_insert_events.includes('END')) {
        newList.push({
          isAd: true,
          id: events.length,
        });
      }

      // Set draft events to new list
      state.currentDraftEvents = newList;
    },

    /**
     * Replace event in drafted events with a new event.
     * @param state
     * @param event
     * @param newEvent
     * @constructor
     */
    REPLACE_EVENT_IN_DRAFTS(state, { event, newEvent }) {
      const index = state.currentDraftEvents.findIndex((dEvent) => dEvent.id === event.id);
      Vue.set(state.currentDraftEvents, index, newEvent);
    },

    SET_EDITING_EPISODE_DATA(state, { episode }) {
      state.editingEpisode = episode;
    },
  },

  actions: {
    /**
     * Start a new draft episode and switch to it.
     */
    startNewDraftEpisode({ commit, rootState }) {
      const id = createId(rootState.CreateAndPublishStore.activeShowId);
      const data = {
        _draftId: id,
      };

      commit('UPDATE_DRAFT_EPISODE', { id, data });
      commit('SET_CURRENT_DRAFT_EPISODE_ID', id);
    },

    /**
     * Update current drafted episode.
     * @param rootState
     * @param state
     * @param commit
     * @param data
     */
    updateCurrentDraftEpisode({ rootState, state, commit }, data) {
      let id = state.currentDraftEpisodeId;
      if (!id) {
        id = createId(rootState.CreateAndPublishStore.activeShowId);
        commit('SET_CURRENT_DRAFT_EPISODE_ID', id);
      }
      commit('UPDATE_DRAFT_EPISODE', { id, data });
    },

    clearDraftEpisodes({ dispatch, commit }) {
      commit('SET_STEP', 1);
      commit('SET_CURRENT_DRAFT_EPISODE_ID', null);
      dispatch('clearDraftEvents');
    },

    /**
     * Update drafted events for drafted episode.
     * @param commit
     * @param state
     * @param events
     */
    updateDraftEvents({ commit }, { events }) {
      commit('UPDATE_DRAFT_EVENTS', events);
    },

    /**
     * Append new draft event resource.
     */
    appendDraftEvent({ commit, state }, event) {
      commit('UPDATE_DRAFT_EVENTS', [...state.currentDraftEvents, ...[event]]);
    },

    /**
     * Remove all drafted events from current drafted episode.
     * @param commit
     * @param state
     */
    clearDraftEvents({ commit }) {
      commit('UPDATE_DRAFT_EVENTS', []);
    },

    /**
     * Remove draft event by index.
     * @param commit
     * @param state
     * @param index
     */
    removeDraftEventByIndex({ commit, state }, index) {
      const events = [...state.currentDraftEvents];

      events.splice(index, 1);
      commit('UPDATE_DRAFT_EVENTS', events);
    },

    /**
     * Update temp episode with drafted events.
     * @param dispatch
     * @param commit
     * @param state
     * @param rootGetters
     * @param events Array
     * @returns {Promise<void>}
     */
    async saveDraftEvents({ commit, getters }, events) {
      commit('UPDATE_DRAFT_EPISODE', {
        id: getters.currentDraftEpisodeId,
        data: { events: events },
      });
    },

    /**
     * Turn a drafted episode into an episode backend resource.
     * @param dispatch
     * @param commit
     * @param getters
     * @param state
     * @param rootGetters
     * @param rootState
     * @param data episode data
     * @returns {Promise<string>}
     */
    async createCurrentDraftEpisode({ dispatch, commit, getters, state, rootState }, data) {
      try {
        const draftEpisode = {
          ...getters.currentDraftEpisode,
          ...{
            name: data.name || rootState.CreateAndPublishStore.activeShow.title,
            image: data.image || '',
            selectedShutterstockImage: data.selectedShutterstockImage || {}, // { id: String, url: String, attribution? } - url is the preview thumbnail used on the image page; id is what gets passed to server
            image_croppings: data.image_croppings || JSON.stringify({ left: 0, top: 0, right: 0, bottom: 0 }), // static for now
            rss_image: data.rss_image || '',
            rss_image_croppings: data.rss_image_croppings || JSON.stringify({ left: 0, top: 0, right: 0, bottom: 0 }),
            episode_number: data.episode_number || null,
            season_number: data.season_number || null,
            episode_type: data.episode_type || 'full',
            attribution: data.attribution || '',
            description: data.description || rootState.CreateAndPublishStore.activeShow.description,
            permanent: 0,
            goto_live: 0,
            restricted: 0,
            tags: data.tags || [],
            events: data.events || [],
            categories: data.categories || [],
            onceUseTags: [],
            show: rootState.CreateAndPublishStore.activeShow.resource_uri,
            post_locations: data.post_locations || [],
            ad_insert_events: data.ad_insert_events || JSON.stringify([]),
            draft: true,
            explicit: data.explicit || false,
          },
        };

        // Remove temporary ID's before creating
        delete draftEpisode.id;
        delete draftEpisode._draftId;
        const http = HttpClient.getInstance();
        const endpoint = rootState.CreateAndPublishStore.endpoint;
        const response = await http.post(`${endpoint}/playlist/`, draftEpisode);
        const playlistUri = response.location.replace(/\/$/, ''); // Remove last optional /
        const playlistId = playlistUri.substr(playlistUri.lastIndexOf('/') + 1);
        draftEpisode['id'] = playlistId; // Add permanent ID to object

        // Update draft event with it's new generated id and properties
        await commit('UPDATE_DRAFT_EPISODE', {
          id: state.currentDraftEpisodeId,
          data: draftEpisode,
        });
        await commit('SET_NEW_EPISODE_ID', {
          id: state.currentDraftEpisodeId,
          newId: playlistId,
        });

        // Now return just the playlist ID
        return playlistId; // Return ID of playlist from uri
      } catch (err) {
        console.error('err', err);
        // Handle error
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not create episode.',
          },
          { root: true }
        );
      }
    },

    /**
     * Get episode by id.
     * @param dispatch
     * @param state
     * @param commit
     * @param rootGetters
     * @param rootState
     * @param id
     * @returns {Promise<*|*|undefined>}
     */
    async getEpisodeById({ dispatch, rootState }, id) {
      try {
        const http = HttpClient.getInstance();
        const endpoint = rootState.CreateAndPublishStore.endpoint;
        const response = await http.get(`${endpoint}/playlist/${id}/`);

        return response;
      } catch (err) {
        console.error('err', err);
        // Handle error
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not retrieve episode.',
          },
          { root: true }
        );
      }
    },

    /**
     * Update episode by id.
     * @param dispatch
     * @param rootGetters
     * @param rootState
     * @param id
     * @param data
     * @returns {Promise<void>}
     */
    async updateEpisode({ dispatch, getters, rootState }, { id, data }) {
      try {
        const payload = {
          ...getters.currentDraftEpisode,
          ...data,
        };
        delete payload.id;
        delete payload._draftId;
        const http = HttpClient.getInstance();
        const endpoint = rootState.CreateAndPublishStore.endpoint;
        const response = await http.patch(`${endpoint}/playlist/${id}/`, payload);
        return response;
      } catch (err) {
        console.error('err', err);
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not update playlist.',
          },
          { root: true }
        );
      }
    },

    /**
     * Delete episode by id.
     * @param dispatch
     * @param rootState
     * @param id
     * @returns {Promise<*|undefined>}
     */
    async deleteEpisode({ dispatch, rootState }, id) {
      try {
        const http = HttpClient.getInstance();
        const endpoint = rootState.CreateAndPublishStore.endpoint;
        await http.delete(`${endpoint}/playlist/${id}/`);
      } catch (err) {
        return dispatch(
          'handleServerError',
          {
            error: err,
            errmsg: 'Could not delete playlist.',
          },
          { root: true }
        );
      }
    },

    /**
     * Check to see if event id is in drafted events list.
     * @param state
     * @param id
     * @returns {number}
     */
    isEventInDrafts({ state }, id) {
      return state.currentDraftEvents.findIndex((event) => event.id === id) !== -1;
    },

    /**
     * Replace event in drafted events with a new one.
     * @param state
     * @param commit
     * @param event
     * @param newEvent
     * @param rootState
     */
    replaceEventInDrafts({ commit }, { event, newEvent }) {
      commit('REPLACE_EVENT_IN_DRAFTS', { event, newEvent });
    },

    ensureDraftEpisode({ rootGetters, state, commit }, { showId = null }) {
      const actualShowId = showId || rootGetters.currentShowId;
      let id = state.currentDraftEpisodeId;
      if (!id) {
        id = createId(actualShowId);
        const data = { id, _draftId: id };
        commit('UPDATE_DRAFT_EPISODE', { id, data });
        commit('SET_CURRENT_DRAFT_EPISODE_ID', id);
      }
    },

    ensureDraftEpisodePromise({ rootGetters, state, commit }, { showId = null }) {
      const actualShowId = showId || rootGetters.currentShowId;
      return new Promise((resolve) => {
        let id = state.currentDraftEpisodeId;
        if (!id) {
          id = createId(actualShowId);
          const data = { id, _draftId: id };
          commit('UPDATE_DRAFT_EPISODE', { id, data });
          commit('SET_CURRENT_DRAFT_EPISODE_ID', id);
        }
        resolve();
      });
    },

    setStep({ commit }, stepNum) {
      commit('SET_STEP', stepNum);
    },

    publishEpisode({ rootState, getters }, { data }) {
      const completeData = {
        ...getters.currentDraftEpisode,
        ...data,
      };
      delete completeData._draftId;
      delete completeData.id;

      try {
        const http = HttpClient.getInstance();
        const endpoint = rootState.CreateAndPublishStore.endpoint;
        http.post(`${endpoint}/playlist/`, completeData);
      } catch (err) {
        console.error(err);
      }
    },

    async getEpisodeByIdAndMakeCurrentDraft({ dispatch, commit }, id) {
      let episode = await dispatch('getEpisodeById', id);
      episode = normalizeEpisodeData(episode);
      commit('SET_CURRENT_DRAFT_EPISODE_ID', id);
      dispatch('updateCurrentDraftEpisode', episode);
      dispatch('updateDraftEvents', episode);
      commit('UPDATE_DRAFT_EVENTS_WITH_ADS', {
        events: episode.events,
        ad_insert_events: episode.ad_insert_events,
      });
    },

    async duplicateEpisode({ dispatch, commit, rootGetters }, id) {
      try {
        // get playlist data for episode to duplicate
        let copyOfEpisode = await dispatch('getEpisodeById', id);
        copyOfEpisode = { ...copyOfEpisode, name: `Copy of.... ${copyOfEpisode.name}` };

        // prep data for duplicate
        const data = normalizeEpisodeData(copyOfEpisode, rootGetters.activeShow);

        // create new temp playlist and get temp playlist id
        const newPlaylistId = await dispatch('createCurrentDraftEpisode', data);
        dispatch('updateDraftEvents', copyOfEpisode);

        // set currentDraftEpisodeId to new playlist id
        commit('SET_CURRENT_DRAFT_EPISODE_ID', newPlaylistId);
      } catch (e) {
        console.error(e);
      }
    },

    async getPlaylistSchedule({ rootGetters }, { playlist_id }) {
      try {
        const query = `?station=${rootGetters['CreateAndPublishStore/station']}&playlist_id=${playlist_id}`;
        const http = HttpClient.getInstance();
        const resp = await http.get(
          `${rootGetters['CreateAndPublishStore/stationUrl']}api/v1/playlist/schedule_pub.json${query}`
        );
        // const resp = await http.get(`${rootState.CreateAndPublishStore.endpoint}/playlist/schedule_pub`, query);
        return resp;
      } catch (err) {
        // Intentionally throw error for component to handle
        throw err.body.error;
      }
    },

    async deletePlaylistSchedule({ rootState }, { playlist_id }) {
      const http = HttpClient.getInstance();
      await http.delete(`${rootState.CreateAndPublishStore.endpoint}/playlist/schedule_pub/${playlist_id}`);
    },

    async updatePlaylistSchedule({ rootGetters }, { playlist_id, status, execute_at, social_media_connections }) {
      try {
        const payload = {
          station: rootGetters['CreateAndPublishStore/station'],
          playlist_id,
          status,
          execute_at,
          social_media_connections,
        };
        const http = HttpClient.getInstance();
        http.patch(`${rootGetters['CreateAndPublishStore/stationUrl']}api/v1/playlist/schedule_pub.json`, payload);
        // await http.patch(`${rootState.CreateAndPublishStore.endpoint}/playlist/schedule_pub`, payload);
      } catch (err) {
        // intentionally throw error for component to handle
        throw err.body.error;
      }
    },

    async createChapterMarker({ rootState }, marker) {
      try {
        const http = HttpClient.getInstance();
        //http.post(`${rootGetters.stationUrl}api/v1/chaptermarker/`, marker);
        const resp = await http.post(`${rootState.CreateAndPublish.endpoint}/chaptermarker/`, marker);
        return resp.headers.map.location[0];
      } catch (e) {
        return Promise.reject(new Error(`Unable to talk to server to add chapter marker: ${marker.chapter_name}`));
      }
    },

    async updateChapterMarker({ rootState }, marker) {
      // const http = HttpClient.getInstance();
      // http.patch(`${rootGetters.stationUrl}api/v1/chaptermarker/${marker.id}/`, marker);
      try {
        return await Vue.http.patch(`${rootState.CreateAndPublish.endpoint}/chaptermarker/${marker.id}/`, marker);
      } catch (e) {
        return Promise.reject(new Error(`Unable to talk to server to update chapter marker: ${marker.chapter_name}`));
      }
    },

    getDraftEpisodeById({ state }, id) {
      return state.draftEpisodes[id] || null;
    },
  },
};
