
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import MediaUploadAndPreview from '@/createandpublish/components/socialStories/MediaUploadAndPreview.vue';
import TagInput from '@/createandpublish/components/common/TagsInput.vue';

import { eventBus, busEvents } from '@/createandpublish/core/eventBus/socialPostEventBus';
import { required, maxLength, maxValue, url } from 'vuelidate/lib/validators';
import {
  PinterestErrorMessage,
  PINTEREST_POST_MAX_LENGTH,
  PINTEREST_MAX_IMAGE_FILESIZE,
  PINTEREST_ALT_TEXT_MAX_LENGTH,
  PINTEREST_TITLE_MAX_LENGTH,
  PINTEREST_IMAGE_ACCEPT_FILE_TYPES,
} from '@/createandpublish/core/utils/socialStory';
import { SocialPlatform, SocialPlatforms } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { MediaElProperties } from '@/types/createandpublish';
import type { PinterestPostOptions } from 'content-cloud-types/dist/types/requests/createandpublish/PlatformPostOptions';
import type { PlatformPostStatus } from 'content-cloud-types/dist/types/createandpublish/SocialPosting';
import type { DraftSocialStory, SocialPostBody } from '@/types/createandpublish/socialStory';

const SocialStoryStore = namespace('CreateAndPublishStore/socialStories');

Component.registerHooks(['beforeDestroy', 'validations']);

@Component({
  name: 'PinterestPost',
  components: {
    MediaUploadAndPreview,
    TagInput,
  },
})
export default class PinterestPost extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly isReadonly!: boolean;
  @Prop({ type: String, required: true }) readonly currentSelectedTab!: SocialPlatform;

  @SocialStoryStore.Getter readonly draftSocialStory?: DraftSocialStory;
  @SocialStoryStore.Getter readonly editingStoryId!: number;
  @SocialStoryStore.Mutation('UPDATE_DRAFT_SOCIAL_STORY_POST') updatePost!: ({
    platformType,
    postUpdates,
  }: {
    platformType: SocialPlatform;
    postUpdates: SocialPostBody;
  }) => void;

  title = '';
  post = '';
  mediaUrl = '';
  link = '';
  altText = '';

  editingPostStatus: PlatformPostStatus | '' = '';

  get postContent(): SocialPostBody {
    const { title, post, mediaUrl, altText, link } = this;
    const mediaUrls = mediaUrl ? [mediaUrl] : [];

    const platforms: SocialPlatform[] = [SocialPlatforms.pinterest];
    const mainRequest: SocialPostBody['mainRequest'] = {
      post,
      platforms,
      mediaUrls,
      isVideo: false,
    };
    const pinterestOptions: PinterestPostOptions = {
      title,
      link,
      altText,
    };
    return {
      mainRequest,
      platformOptions: {
        pinterestOptions,
      },
    };
  }

  @Watch('postContent')
  onPostContentUpdate(newVal: PinterestPost['postContent']) {
    this.updatePost({
      platformType: SocialPlatforms.pinterest,
      postUpdates: newVal,
    });
    eventBus.$emit(busEvents.UPDATE_DRAFT_POST_DETAILS, newVal);
  }

  validations() {
    return {
      title: {
        required,
        maxLength: maxLength(PINTEREST_TITLE_MAX_LENGTH),
      },
      post: {
        required,
        maxLength: maxLength(PINTEREST_POST_MAX_LENGTH),
      },
      mediaUrl: {
        required,
      },
      mediaUrlProperties: {
        filesize: {
          maxValue: maxValue(PINTEREST_MAX_IMAGE_FILESIZE),
        },
      },
      altText: {
        maxLength: maxLength(PINTEREST_ALT_TEXT_MAX_LENGTH),
      },
      link: {
        url,
      },
    };
  }

  get isErrorFree() {
    return !this.$v.$invalid;
  }

  get errorCount() {
    const { titleError, postError, mediaUrlError, altTextError } = this;

    let count = 0;
    titleError && count++;
    postError && count++;
    mediaUrlError && count++;
    altTextError && count++;

    return count;
  }

  @Watch('errorCount')
  onErrorCountChange() {
    this.$emit('errorCount:update', { platform: SocialPlatforms.pinterest, count: this.errorCount });
  }

  get altTextError() {
    const field = this.$v.altText;
    const { maxLength } = PinterestErrorMessage.altText;
    if (field.$error) {
      return maxLength;
    }
    return '';
  }
  get titleError() {
    const field = this.$v.title;
    const { maxLength } = PinterestErrorMessage.title;

    if (field.$error) {
      return maxLength;
    }
    return '';
  }

  get linkError() {
    const field = this.$v.link;
    const { url } = PinterestErrorMessage.link;

    if (field.$error) {
      return url;
    }
    return '';
  }
  get postError() {
    const field = this.$v.post;
    const { required, maxLength } = PinterestErrorMessage.post;

    if (field.$error) {
      if (!field.required) {
        return required;
      }
      if (!field.maxLength) {
        return maxLength;
      }
    }
    return '';
  }

  get mediaUrlError() {
    const field = this.$v.mediaUrl;
    const filesizeField = field?.filesize;
    const { required, maxFilesize } = PinterestErrorMessage.imageUrls;

    if (field.$error) {
      if (!field.required) return required;
    }

    if (field.$invalid) {
      if (!filesizeField?.maxValue) return maxFilesize;
    }

    return '';
  }

  get descriptionLengthString() {
    return `${this.post.length}/${PINTEREST_POST_MAX_LENGTH}`;
  }

  mediaUrlProperties: MediaElProperties | null = null;
  onMediaUrlPropertiesChanged(properties: MediaElProperties) {
    this.mediaUrlProperties = properties;
  }

  get imageFileInputAcceptAttr() {
    return PINTEREST_IMAGE_ACCEPT_FILE_TYPES;
  }

  validateForm() {
    this.$v.$touch();
  }

  toggleUploadingMediaStatus() {
    this.$emit('toggleUploadingMediaStatus');
  }

  get isPostEditable() {
    const { editingPostStatus: status, editingStoryId: storyId } = this;
    if (isNaN(storyId)) return true;

    const allowedStatuses: PlatformPostStatus[] = ['draft', 'scheduled', 'error'];

    return !this.isReadonly && !isNaN(storyId) && allowedStatuses.includes(status as PlatformPostStatus);
  }

  setDataFromExistingEpisode() {
    const platformPost = this.draftSocialStory?.socialPlatformPosts.find(
      (socialPosts) => socialPosts.mainRequest.platforms[0] === SocialPlatforms.pinterest
    );
    if (!platformPost) return;

    this.editingPostStatus = platformPost.status ?? '';

    platformPost.mainRequest;
    const { post, mediaUrls } = platformPost.mainRequest;
    const { title, link, altText } = platformPost.platformOptions.pinterestOptions ?? {};

    this.post = post;
    this.mediaUrl = mediaUrls?.[0] ?? '';
    this.title = title ?? '';
    this.link = link ?? '';
    this.altText = altText ?? '';
  }

  setupBusEvents() {
    eventBus.$on(busEvents.VALIDATE_POST, this.validateForm);
    eventBus.$on(busEvents.ADD_PREFILL_CONTENT, this.addPrefillContent);
    eventBus.$on(busEvents.ADD_MEDIA_FROM_LIBRARY, this.addMediaFromLibrary);
  }

  addMediaFromLibrary(content: { value: string; prefix: 'video' | 'image' | 'gif' }) {
    const { prefix, value } = content;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.pinterest) {
      switch (prefix) {
        case 'image':
        case 'gif':
          this.mediaUrl = value;
          break;
        case 'video':
        default:
          break;
      }
    }
  }

  addPrefillContent(content: { value: string; prefix: string }) {
    const { prefix, value } = content;

    const currentPost = this.post;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.pinterest) {
      switch (prefix) {
        case 'to post':
          this.post = currentPost + value + ' ';
          break;
        case 'title':
          this.title = value;
          break;
        case 'link':
          this.link = value;
          break;
        case 'hashtag':
          this.post = currentPost + `#${value.split(' ').join('')}` + ' ';
          break;
        default:
          break;
      }
    }
  }

  destroyBusEvents() {
    eventBus.$off(busEvents.VALIDATE_POST, this.validateForm);
  }

  mounted() {
    this.setupBusEvents();
    if (!isNaN(this.editingStoryId)) {
      this.setDataFromExistingEpisode();
    }
  }

  beforeDestroy() {
    this.destroyBusEvents();
  }
}
