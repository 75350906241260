<template>
  <div class="NotFound">
    <div class="logo">
      <img src="@/assets/product-logos/logo_color_dark_txt_transparent.png" class="logo" />
    </div>
    <h1>404</h1>
    <h2>This isn't the page you're looking for</h2>
    <div class="divider"></div>
    <div class="learn-more" v-if="this.path">
      <Button
        v-if="
          this.path.includes('discover') || (this.name !== undefined && this.name.toLowerCase().includes('discover'))
        "
        buttonType="primary"
        onClickEventHandler="click"
        @click="$router.push('/discover')"
      >
        Return to Discover
      </Button>
      <Button
        v-else-if="
          this.path.includes('create') || (this.name !== undefined && this.name.toLowerCase().includes('create'))
        "
        buttonType="primary"
        onClickEventHandler="click"
        @click="$router.push('/create')"
      >
        Return to your dashboard
      </Button>
      <Button v-else buttonType="primary" onClickEventHandler="click" @click="$router.push('/account')">
        Return to Account Overview
      </Button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Button from '@/components/common/buttons/Button.vue';
export default defineComponent({
  components: {
    Button,
  },
  data() {
    return {
      name: '',
      path: '',
      parentProduct: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data.name = vm.$route.name;
      vm.$data.path = vm.$route.path;
    });
  },
});
</script>

<style lang="scss" scoped>
.NotFound {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  margin-top: 100px;
  text-align: center;

  h1 {
    font-size: 128px;
    font-weight: 700;
    line-height: 128px;
    margin-top: 64px;
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-top: 32px;
  }

  .divider {
    border-top: 1px solid var(--neutral-grey-3-borders);
    margin: 48px auto 55px;
    max-width: 580px;
    width: 50%;
  }

  .logo {
    height: 200px;
  }

  .learn-more {
    color: var(--accent-links);
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 48px;

    button {
      margin: 0 auto;
    }
  }
}
</style>
