import { Context } from '@/types/Context';
import { TimeRangeMode } from '@/types/audience/TimeRangeMode';
import { Snapshot } from '@/types/audience/per-post-metrics/Metrics';
import { ChartOptions } from 'chart.js';
import { PlatformLabel, PlatformLabels } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import moment from 'moment';

// Chart options

export const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    filler: {
      propagate: false,
    },
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.000001,
    },
    point: {
      hitRadius: 10,
      pointStyle: 'line',
    },
  },
};

export const chartScalesOptions = (maxCeil: number) => {
  return {
    scales: {
      y: {
        min: 0,
        max: maxCeil,
        // stacked: true,
        ticks: {
          callback: function (value) {
            if (+value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
  };
};

// Chart labels

export const getChartLabels = (snapshots: Snapshot[], timeZone: string, timeRangeMode: TimeRangeMode) => {
  return snapshots.map((snapshot) => {
    const date = moment.tz(snapshot.asOfDate, timeZone);

    const hourAddition = timeRangeMode === TimeRangeMode.First72 ? `${date.format('h:mm A')}, ` : '';
    return `${hourAddition}${date.format('MM-DD')}`;
  });
};

// Totals

export type HighlightedMetric = { key: string; displayText: string; context: Context; color: string };

enum MetricColor {
  Views = '#25F3ED',
  Comments = '#fa6800',
  Likes = '#1877F2',
  Reach = '#BB00AD',
  Reactions = '#1DA1F2',
  Share = '#FF5A44',
}

export const heighlightedMetricsByPlatform: Record<PlatformLabel, HighlightedMetric[]> = {
  [PlatformLabels.FACEBOOK]: [
    { key: 'reactionsCount', displayText: 'Reactions', context: 'engagement', color: MetricColor.Reactions },
    { key: 'impressionsUnique', displayText: 'Views', context: 'impressions', color: MetricColor.Views },
    { key: 'commentsCount', displayText: 'Comments', context: 'impressions', color: MetricColor.Comments },
  ],
  [PlatformLabels.INSTAGRAM]: [
    { key: 'likeCount', displayText: 'Likes', context: 'engagement', color: MetricColor.Likes },
    { key: 'videoViews', displayText: 'Video Views', context: 'impressions', color: MetricColor.Views },
    { key: 'commentsCount', displayText: 'Comments', context: 'engagement', color: MetricColor.Comments },
    { key: 'reach', displayText: 'Reach', context: 'impressions', color: MetricColor.Reach },
  ],
  [PlatformLabels.TIKTOK]: [
    { key: 'likeCount', displayText: 'Likes', context: 'engagement', color: MetricColor.Likes },
    { key: 'viewCount', displayText: 'Views', context: 'impressions', color: MetricColor.Views },
    { key: 'commentCount', displayText: 'Comments', context: 'engagement', color: MetricColor.Comments },
    { key: 'shareCount', displayText: 'Shares', context: 'engagement', color: MetricColor.Share },
  ],
  [PlatformLabels.YOUTUBE]: [
    { key: 'likes', displayText: 'Likes', context: 'engagement', color: MetricColor.Likes },
    { key: 'views', displayText: 'Views', context: 'impressions', color: MetricColor.Views },
    { key: 'comments', displayText: 'Comments', context: 'engagement', color: MetricColor.Comments },
  ],
  [PlatformLabels.TWITTER]: [],
  [PlatformLabels.LINKEDIN]: [],
  [PlatformLabels.PINTEREST]: [],
};

// Snapshots

export const getSnapshotMetricDataset = (snapshots: Snapshot[], metric: string) => {
  return snapshots.map((snapshot, index) => {
    if (index === 0) {
      return snapshot[metric];
    }

    const previousDataPoint = snapshots[index - 1];
    return Math.max(snapshot[metric] - previousDataPoint[metric], 0);
  });
};
