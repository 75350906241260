import store from '../../../store';
import AudioWizardRoutes from '@/createandpublish/core/routes/audioWizard';

const EpisodesRoute = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EpisodesRoute');
const EditEpisodeRoute = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EditEpisodeRoute');
const EditEpisodeReviewAudio = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EditEpisodeReviewAudio');
const EditEpisodeAudioV2 = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EditEpisodeAudioV2');
const EditEpisodeInformation = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EditEpisodeInformation');
const EditEpisodePublish = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EditEpisodePublish');
// const EditEpisodeHeadliner = () => import(/* webpackChunkName: "cp-episodes" */ '../../views/EditEpisodeHeadliner');

export default {
  path: 'episodes/:episodeId',
  component: EpisodesRoute,
  props: true,
  meta: {
    title: 'POST Shows',
    sidebar_active_item: 'Shows',
  },
  async beforeEnter(to, from, next) {
    const setErrorMsg = () => {
      store.commit('SET_MESSAGE', {
        name: 'Invalid Episode',
        details: "Couldn't find the episode you're attempting to access.",
        type: 'error',
      });
    };

    // Clear old store data before working on a new episode
    store.dispatch('CreateAndPublishStore/draftEpisodes/clearDraftEpisodes');
    store.commit('CreateAndPublishStore/stationEvents/SET_UPLOAD_UUID', { uuid: null });
    store.commit('CreateAndPublishStore/CLEAR_ADVANCED_VIDEO_DATA');

    if (to && to.params && to.params.episodeId === '0') {
      store.commit('CreateAndPublishStore/audioWizard/CLEAR_STACK_EVENTS');
      // Ensuring draft episode
      const showId = to.params.showId ? Number(to.params.showId) : null;
      store.dispatch('CreateAndPublishStore/draftEpisodes/ensureDraftEpisodePromise', { showId }).then(() => next());
    } else if (to && to.params && !isNaN(to.params.episodeId) && to.params.episodeId !== '0') {
      try {
        // Get playlist from API and set it as the current draft episode
        await store.dispatch(
          'CreateAndPublishStore/draftEpisodes/getEpisodeByIdAndMakeCurrentDraft',
          to.params.episodeId
        );
        // If editing an already existing episode, get the original episode too
        if (to.query.editingEpisode) {
          const originalEpisode = await store.dispatch(
            'CreateAndPublishStore/draftEpisodes/getEpisodeById',
            to.query.editingEpisode
          );
          store.commit('CreateAndPublishStore/draftEpisodes/SET_EDITING_EPISODE_DATA', { episode: originalEpisode });
        }
        next();
      } catch (err) {
        console.error(err);
        setErrorMsg();
        next({ path: `/create/${to.params.brandId}/podcasts/shows/${to.params.showId}/details` });
      }
    } else {
      // This will occur when to.params.episodeId is 'undefined' or otherwise invalid
      setErrorMsg();
      next({ path: `/create/${to.params.brandId}/podcasts/shows/${to.params.showId}/details` });
    }
  },
  children: [
    {
      path: 'edit',
      component: EditEpisodeRoute,
      meta: {
        title: `New Episode | Podcasts | Create&Publish | Idea Engine` || 'Idea Engine',
        sidebar_active_item: 'Shows',
      },
      props: true,
      children: [
        {
          path: 'audio',
          component: EditEpisodeAudioV2,
          name: 'audio',
          props: true,
          async beforeEnter(to, from, next) {
            await store.dispatch('CreateAndPublishStore/fetchAllShowsData');
            await store.dispatch('CreateAndPublishStore/fetchGroupSettings').then(() => {
              store.dispatch('CreateAndPublishStore/setGroupMaps');
            });
            next();
          },
          meta: {
            sidebar_active_item: 'Shows',
          },
        },
        AudioWizardRoutes,
        {
          name: 'edit-episode-information',
          path: 'info',
          component: EditEpisodeInformation,
          meta: {
            title: `New Episode | Podcasts | Create&Publish | Idea Engine` || 'Idea Engine',
            sidebar_active_item: 'Shows',
          },
          props: true,
          async beforeEnter(to, from, next) {
            await store.dispatch('CreateAndPublishStore/getPlaylistCategories');
            next();
          },
        },
        {
          name: 'edit-episode-review-audio',
          path: 'review',
          component: EditEpisodeReviewAudio,
          props: true,
          meta: {
            sidebar_active_item: 'Shows',
          },
        },
        {
          name: 'edit-episode-publish',
          path: 'publish',
          component: EditEpisodePublish,
          props: true,
          async beforeEnter(to, from, next) {
            if (!store.getters.allSocialMediaConnections || store.getters.allSocialMediaConnections.length === 0) {
              await store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
            }
            next();
          },
          meta: {
            sidebar_active_item: 'Shows',
          },
        },
        // {
        //   name: 'edit-episode-headliner',
        //   path: 'video',
        //   component: EditEpisodeHeadliner,
        //   props: true,
        // },
      ],
    },
  ],
};
