import BrandService from '@/services/BrandService';
import type { Brand } from '@/types/Brand';
import type { Module } from 'vuex';
import type { RootState } from '@/types/store';

export const initialState: BrandState = {
  brands: [],
  selectedBrand: null,
};

const state = initialState;

const getters = {
  allBrands: (state: BrandState) => state.brands,
  selectedBrand: (state: BrandState) => state.selectedBrand,
  selectedBrandCampaigns: (state: BrandState) => state.selectedBrand?.campaigns ?? [],
  selectedBrandTimeZone: (state: BrandState) =>
    state.selectedBrand?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const actions = {
  async fetchBrands({ commit }) {
    const response = await new BrandService().getAll();
    commit('setBrands', response);
  },
  changeSelectedBrand({ commit, getters }, brand) {
    if (brand) {
      // If argument is a brand object
      if (brand?.id) {
        commit('setSelectedBrand', brand);
      } else {
        // if argument is a brand ID
        if (typeof Number(brand) === 'number') {
          if (getters.userBrands?.length > 0) {
            const foundBrand = getters.userBrands.find((userBrand) => userBrand.id === Number(brand));
            if (foundBrand?.id) {
              commit('setSelectedBrand', foundBrand);
            }
          }
        }
      }
    }
  },
  setInitialSelectedBrand({ commit, getters }, routeBrandId) {
    const user = getters.authenticatedUser;
    if (user && user.id && user.brands?.length > 0) {
      const preferredBrand = routeBrandId || user?.defaultBrandId; // Route brand ID as priority, fallback to `defaultBrandId` otherwise
      const brand = preferredBrand ? user.brands.find((brand) => brand.id == preferredBrand) : user.brands[0];
      if (brand) {
        commit('setSelectedBrand', brand);
      }
    }
  },
};

const mutations = {
  setBrands: (state, brands) => (state.brands = brands),
  setSelectedBrand: (state, brand) => {
    if (brand) {
      state.selectedBrand = brand;
    }
  },
  setDefaultBrandId: (state, id) => (state.defaultBrandId = id),
};

const module: Module<BrandState, RootState> = { state, getters, actions, mutations };

export default module;
export interface BrandState {
  brands: Brand[];
  selectedBrand: Brand | null;
}
