
import { ChatbotMessage } from '@/types/discover/ChatbotMessage';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProfileImage from './ProfileImage.vue';
import TypingAnimation from './TypingAnimation.vue';

@Component({
  components: {
    ProfileImage,
    TypingAnimation,
  },
})
export default class Message extends Vue {
  @Prop({ type: Object, required: true }) message!: ChatbotMessage;
  @Getter authenticatedUser;

  get userInitials() {
    const currentUser = this.authenticatedUser;
    return currentUser ? currentUser.firstName[0] + currentUser.lastName[0] : '';
  }

  // // typing animation
  // index = 0;
  // typeTimeout = 0;

  // type() {
  //   const typewriter = document.getElementsByClassName('typewriter')[0];
  //   if (typewriter && this.message.content) {
  //     if (this.index < this.message.content.length) {
  //       typewriter.innerHTML = this.message.content.slice(0, this.index) + '<span class="blinking-cursor">|</span>';
  //       this.index = this.index + 1;
  //       this.typeTimeout = setTimeout(this.type, Math.random() * 100 + 30) as unknown as number;
  //     } else {
  //       typewriter.innerHTML = this.message.content.slice(0, this.index) + '<span class="blinking-cursor">|</span>';
  //     }
  //   }
  // }

  // @Watch('message', { immediate: true })
  // onMessageChange({ content: newContent }) {
  //   if (newContent !== '' && this.message.sender.type === 'CHATBOT') {
  //     clearTimeout(this.typeTimeout);
  //     this.type();
  //   }
  // }
}
