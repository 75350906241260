
import { ChatbotMessage } from '@/types/discover/ChatbotMessage';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Message from './Message.vue';
import { emptyStateConversation } from './empty-state';

@Component({
  components: {
    Message,
  },
})
export default class ChatbotConversation extends Vue {
  @Prop({ type: Array, required: true }) messages!: ChatbotMessage[];

  get messagesToDisplay() {
    return this.messages.length > 0 ? this.messages : emptyStateConversation;
  }

  @Watch('messages')
  onMessagesChange(newMessages: ChatbotMessage[], oldMessages: ChatbotMessage[]) {
    if (newMessages.length > oldMessages.length && this.$refs.messages) {
      const messagesContainerHeight = (this.$refs.messages as HTMLElement).scrollHeight + 300;
      this.$nextTick(() => {
        (this.$refs.messages as HTMLElement).scrollTo({ top: messagesContainerHeight, behavior: 'smooth' });
      });
    }
  }
}
