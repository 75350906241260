var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"platform-post-preview"},[(_vm.facebookPostDetails)?_c('section',{staticClass:"facebook-preview"},[_c('div',{staticClass:"top-bar"},[_c('div',{staticClass:"left-top"},[_c('div',{staticClass:"profile-image"},[_c('img',{attrs:{"src":_vm.profileImage,"alt":"profile image"}})]),_c('div',{staticClass:"profile-info"},[_c('div',{staticClass:"main-info"},[_c('span',{staticClass:"profile-name"},[_vm._v(_vm._s(_vm.profileName)+" ")])]),_c('div',{staticClass:"sub-info"},[_vm._v(" "+_vm._s(_vm.currentDateTimeString)+" "),_c('i',{staticClass:"material-icons dot"},[_vm._v("fiber_manual_record")]),_c('i',{staticClass:"material-icons globe"},[_vm._v("public")])])])]),_vm._m(0)]),_c('div',{staticClass:"post-preview"},[_c('span',{staticClass:"post-text"},[_vm._v(_vm._s(_vm.facebookPostDetails.mainRequest.post))])]),(_vm.postImages?.length)?_c('div',{staticClass:"media-preview",class:{
        'one-img': _vm.postImages?.length === 1,
        'two-img': _vm.postImages?.length === 2,
        'three-img': _vm.postImages?.length === 3,
        'four-img': _vm.postImages?.length === 4,
        'many-img': _vm.postImages?.length >= 5,
      }},[_vm._l((_vm.postImages),function(postImage){return _c('div',{key:postImage,staticClass:"media-container"},[_c('img',{attrs:{"src":postImage,"alt":"media preview"}})])}),(_vm.postImages?.length >= 6)?_c('div',{staticClass:"media-count"},[_c('div',[_vm._v("+"+_vm._s(_vm.postImages?.length - 5))])]):_vm._e(),(_vm.videoIcon)?_c('div',{staticClass:"video-icon"},[_vm._m(1)]):_vm._e()],2):_vm._e(),_vm._m(2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"right-top"},[_c('div',{staticClass:"dots"},[_c('span',[_vm._v(".")]),_c('span',[_vm._v(".")]),_c('span',[_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"video-icon-container"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("play_circle_filled")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bottom-bar"},[_c('div',{staticClass:"action-div"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("thumb_up_off_alt")]),_c('span',[_vm._v("Like")])]),_c('div',{staticClass:"action-div"},[_c('i',{staticClass:"material-icons social-icons"},[_vm._v("chat_bubble_outline")]),_c('span',[_vm._v("Comment")])]),_c('div',{staticClass:"action-div"},[_c('i',{staticClass:"material-icons social-icons share"},[_vm._v("reply")]),_c('span',[_vm._v("Share")])])])
}]

export { render, staticRenderFns }