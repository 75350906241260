
/**
 * This component is meant to be used like an input. It has been design
 * such that you can use the v-model directive from the parent and can expect
 * to have the reactive binding work like any other.
 */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';

Component.registerHooks(['mounted']);

@Component({
  name: 'CampaignInput',
  components: {
    CampaignCircle,
  },
})
export default class CampaignInput extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: number[];

  campaigns: number[] = [];

  @Watch('campaigns', { immediate: true })
  onCampaignUpdate() {
    this.$emit('input', this.campaigns);
  }
  @Watch('value', { immediate: true })
  onValueUpdate() {
    this.campaigns = this.value;
  }

  @Getter selectedBrand;

  get activeCampaigns(): Campaign[] {
    return (this.selectedBrand as Brand)?.campaigns?.filter((campaign) => campaign.active) || [];
  }

  onCampaignClick(campaignId: number): void {
    const index = this.campaigns.indexOf(campaignId);
    if (index === -1) {
      this.campaigns.push(campaignId);
    } else {
      this.campaigns.splice(index, 1);
    }
  }
}
