
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import MediaUploadAndPreview from '@/createandpublish/components/socialStories/MediaUploadAndPreview.vue';
import TagInput from '@/createandpublish/components/common/TagsInput.vue';

import { eventBus, busEvents } from '@/createandpublish/core/eventBus/socialPostEventBus';
import { required, maxLength, maxValue, minValue } from 'vuelidate/lib/validators';
import {
  TIKTOK_VIDEO_ACCEPT_FILE_TYPES,
  TIKTOK_MAX_DURATION_SECONDS,
  TIKTOK_MIN_DURATION_SECONDS,
  TikTokErrorMessage,
  TIKTOK_POST_MAX_LENGTH,
  TIKTOK_MAX_VIDEO_FILESIZE,
} from '@/createandpublish/core/utils/socialStory';
import { SocialPlatform, SocialPlatforms } from 'content-cloud-types/dist/types/audience/SocialPlatforms';
import type { MediaElProperties } from '@/types/createandpublish';
import type { PlatformPostStatus } from 'content-cloud-types/dist/types/createandpublish/SocialPosting';
import type { DraftSocialStory, SocialPostBody } from '@/types/createandpublish/socialStory';
import EmojiButton from '@/components/common/buttons/EmojiButton.vue';

const SocialStoryStore = namespace('CreateAndPublishStore/socialStories');

Component.registerHooks(['beforeDestroy', 'validations']);

@Component({
  name: 'TikTokPost',
  components: {
    MediaUploadAndPreview,
    TagInput,
    EmojiButton,
  },
})
export default class TikTokPost extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly isReadonly!: boolean;
  @Prop({ type: String, required: true }) readonly currentSelectedTab!: SocialPlatform;

  @SocialStoryStore.Getter readonly draftSocialStory?: DraftSocialStory;
  @SocialStoryStore.Getter readonly editingStoryId!: number;
  @SocialStoryStore.Mutation('UPDATE_DRAFT_SOCIAL_STORY_POST') updatePost!: ({
    platformType,
    postUpdates,
  }: {
    platformType: SocialPlatform;
    postUpdates: SocialPostBody;
  }) => void;

  post = ' ';
  mediaUrl = '';
  videoThumbnailUrl = '';

  editingPostStatus: PlatformPostStatus | '' = '';

  updateVideoThumbnailUrl(value: string) {
    this.videoThumbnailUrl = value;
  }

  toggleUploadingMediaStatus() {
    this.$emit('toggleUploadingMediaStatus');
  }

  get postContent(): SocialPostBody {
    const { post, mediaUrl, videoThumbnailUrl } = this;

    const videoThumbnailUrlOption = videoThumbnailUrl ? { videoThumbnailUrl: videoThumbnailUrl } : {};
    const mediaUrlsOption = mediaUrl.length
      ? { mediaUrls: [mediaUrl] }
      : { mediaUrls: undefined as unknown as string[] };
    const platforms: SocialPlatform[] = [SocialPlatforms.tiktok];

    const mainRequest: SocialPostBody['mainRequest'] = {
      post,
      platforms,
      ...videoThumbnailUrlOption,
      ...mediaUrlsOption,
      isVideo: true,
    };
    return {
      mainRequest,
      platformOptions: {},
    };
  }

  onInputChanged(input) {
    this.post = input;
    this.$v.post.$model = input;
  }

  @Watch('postContent')
  onPostContentUpdate(newVal: TikTokPost['postContent']) {
    this.updatePost({
      platformType: SocialPlatforms.tiktok,
      postUpdates: newVal,
    });
    eventBus.$emit(busEvents.UPDATE_DRAFT_POST_DETAILS, newVal);
  }

  validations() {
    return {
      post: {
        required,
        maxLength: maxLength(TIKTOK_POST_MAX_LENGTH),
      },
      mediaUrlProperties: {
        required,
        duration: {
          minValue: minValue(TIKTOK_MIN_DURATION_SECONDS),
          maxValue: maxValue(TIKTOK_MAX_DURATION_SECONDS),
        },
        filesize: {
          maxValue: maxValue(TIKTOK_MAX_VIDEO_FILESIZE),
        },
      },
    };
  }

  get isErrorFree() {
    return !this.$v.$invalid;
  }

  get errorCount() {
    const { postError, mediaUrlError } = this;

    let count = 0;
    postError && count++;
    mediaUrlError && count++;

    return count;
  }

  @Watch('errorCount')
  onErrorCountChange() {
    this.$emit('errorCount:update', { platform: SocialPlatforms.tiktok, count: this.errorCount });
  }

  get postError() {
    const field = this.$v.post;
    const { required, maxLength } = TikTokErrorMessage.post;

    if (field.$error) {
      if (!field.required) {
        return required;
      }
      if (!field.maxLength) {
        return maxLength;
      }
    }
    return '';
  }

  get mediaUrlError() {
    const field = this.$v.mediaUrlProperties;
    const durationField = field?.duration;
    const filesizeField = field?.filesize;
    const { minLength, maxLength, maxFilesize, required } = TikTokErrorMessage.videoUrl;

    if (field.$error) {
      if (!field.required) return required;
    }

    if (field.$invalid) {
      if (!durationField?.minValue) return minLength;
      if (!durationField?.maxValue) return maxLength;
      if (!filesizeField?.maxValue) return maxFilesize;
    }

    return '';
  }

  mediaUrlProperties: MediaElProperties | null = null;

  onMediaUrlPropertiesChanged(properties: MediaElProperties) {
    this.mediaUrlProperties = properties;
  }

  get videoFileInputAcceptAttr() {
    return TIKTOK_VIDEO_ACCEPT_FILE_TYPES;
  }

  validateForm() {
    this.$v.$touch();
  }

  get isPostEditable() {
    const { editingPostStatus: status, editingStoryId: storyId } = this;
    if (isNaN(storyId)) return true;

    const allowedStatuses: PlatformPostStatus[] = ['draft', 'scheduled', 'error'];

    return !this.isReadonly && !isNaN(storyId) && allowedStatuses.includes(status as PlatformPostStatus);
  }

  setDataFromExistingEpisode() {
    const platformPost = this.draftSocialStory?.socialPlatformPosts.find(
      (socialPosts) => socialPosts.mainRequest.platforms[0] === SocialPlatforms.tiktok
    );
    if (!platformPost) return;

    this.editingPostStatus = platformPost.status ?? '';

    platformPost.mainRequest;
    const { post, mediaUrls, videoThumbnailUrl } = platformPost.mainRequest;

    this.post = post;
    this.mediaUrl = mediaUrls?.[0] ?? '';
    this.videoThumbnailUrl = videoThumbnailUrl ?? '';
  }

  setupBusEvents() {
    eventBus.$on(busEvents.VALIDATE_POST, this.validateForm);
    eventBus.$on(busEvents.ADD_PREFILL_CONTENT, this.addPrefillContent);
    eventBus.$on(busEvents.ADD_MEDIA_FROM_LIBRARY, this.addMediaFromLibrary);
  }

  addMediaFromLibrary(content: { value: string; prefix: 'video' | 'image' | 'gif' }) {
    const { prefix, value } = content;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.tiktok) {
      switch (prefix) {
        case 'video':
          this.mediaUrl = value;
          break;
        case 'gif':
        case 'image':
        default:
          break;
      }
    }
  }

  addPrefillContent(content: { value: string; prefix: string }) {
    const { prefix, value } = content;

    const currentPost = this.post;

    if (prefix && value && this.currentSelectedTab === SocialPlatforms.tiktok) {
      switch (prefix) {
        case 'to post':
        case 'title':
        case 'link':
          this.post = currentPost + value + ' ';
          break;
        case 'hashtag':
          this.post = currentPost + `#${value.split(' ').join('')}` + ' ';
          break;
        default:
          break;
      }
    }
  }

  destroyBusEvents() {
    eventBus.$off(busEvents.VALIDATE_POST, this.validateForm);
  }

  mounted() {
    this.setupBusEvents();
    if (!isNaN(this.editingStoryId)) {
      this.setDataFromExistingEpisode();
    }
  }

  beforeDestroy() {
    this.destroyBusEvents();
  }
}
