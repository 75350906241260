import DiscoverCategoriesModel from '@/models/DiscoverCategoriesModel';
import DiscoveryCollapsibleSidebar from '@/models/DiscoverCollapsibleSidebarModel';
import { DiscoverState } from '../../types/discover/DiscoverState';
import { DiscoverData } from '@/types/discover/DiscoverData';
import type { ActionContext, ActionTree, Module } from 'vuex';
import type { RootState } from '@/types/store';

import DiscoverStoryManager, { DiscoverStorySearchable } from '@/services/DiscoverStoryManager';

import type DiscoverShortcutModel from '@/models/DiscoverShortcutModel';
import DiscoverCategoryService from '@/services/DiscoverCategoryService';

import { logDebugFunc } from '@/utils/logging';
import { handleAjax } from '@/createandpublish/core/util';
import DiscoverSettingsServiceModule from '@/services/DiscoverSettingsService';

const DiscoverSettingsService = new DiscoverSettingsServiceModule();

const logDebug = logDebugFunc();

export const initialState = {
  data: undefined,
  welcomeBannerActive: true,
  categories: undefined,
  category: undefined,
  shortcuts: [],
  prefetchInitialized: false,
  isTwitterAuthorized: false,
};

const state: DiscoverState = initialState;

const getters = {
  allCategories: (state: DiscoverState) => state.categories,
  isTwitterAuthorized: (state: DiscoverState) => state.isTwitterAuthorized,
  isWelcomeBannerActive: (state: DiscoverState) => state.welcomeBannerActive,
  shortcuts: (state: DiscoverState) => state.shortcuts,
  category: (state: DiscoverState) => state.category,
  discoverPrefetchInitialized: (state: DiscoverState) => state.prefetchInitialized,

  isCategoryLoading: () => (shortcutId: number) => !DiscoverStoryManager.hasShortcutId(shortcutId),

  storyManagerSearchReady: () => () => {
    return DiscoverStoryManager.isReady;
  },

  storyManagerHasShortcutId: () => (shortcutId: number) => {
    const res = DiscoverStoryManager.hasShortcutId(shortcutId);
    return res;
  },

  // TODO: implement autoSuggest the same way, if we gain an autocomplete dropdown

  storyManagerSearch:
    () =>
    async (query: string): Promise<DiscoverStorySearchable[]> => {
      if (!DiscoverStoryManager.isReady) {
        logDebug(`DiscoverStore: storyManagerSearch: not ready`);
        return [];
      }

      const results = await DiscoverStoryManager.searchStories(query);
      logDebug(`DiscoverStore: storyManagerSearch: results: `, results);

      return results;
    },

  storyManagerAutoSuggest: () => async (query: string) => {
    if (!DiscoverStoryManager.isReady) {
      return [];
    }

    const results = await DiscoverStoryManager.autoSuggest(query);

    return results;
  },
};

const actions = {
  setCategory({ commit }, id) {
    commit('setCategory', id);
  },

  async clearShortcuts({ commit }: ActionContext<DiscoverState, RootState>) {
    commit('setShortcuts', []);
  },

  async fetchShortcuts({ commit, rootState }: ActionContext<DiscoverState, RootState>) {
    const brandId = rootState.BrandStore.selectedBrand?.id;
    if (!brandId || isNaN(brandId)) {
      console.warn(`DiscoverStore: brandId is not valid, cannot fetch shortcuts`);
      return;
    }

    const response = await new DiscoverCategoryService().getShortcuts(brandId);
    commit('setShortcuts', response);
    if (!state.category) {
      commit('setCategory', response[0]?.id);
    }
  },

  getTwitterAuthStatus({ dispatch, commit, rootState }) {
    const brandId = rootState.BrandStore.selectedBrand?.id as number;
    return handleAjax({
      request: DiscoverSettingsService.getTwitterAuth(brandId),
      dispatch,
      commit,
      mutation: 'setTwitterAuthStatus',
      modify(resp: Awaited<ReturnType<typeof DiscoverSettingsService.getTwitterAuth>>) {
        return resp.ok && resp.val.userAuth;
      },
    });
  },

  //don't know the mechanism for determining when a user will no longer get
  //the welcome banner, so I'm setting this to true by default for now
  toggleWelcomeBanner({ commit }, activeState) {
    commit('setWelcomeBannerActive', activeState);
  },
  toggleTrendingActive({ commit }, isActive) {
    commit('setTrendingActive', isActive);
  },
  toggleFollowingActive({ commit }, isActive) {
    commit('setFollowingActive', isActive);
  },
  toggleSettingsActive({ commit }, isActive) {
    commit('setSettingsActive', isActive);
  },
  toggleSidebarStatus({ commit }, sidebarData) {
    commit('setSidebarStatus', sidebarData);
  },
} as ActionTree<DiscoverState, RootState>;

const mutations = {
  setCategories: (state, categories: DiscoverCategoriesModel) => (state.categories = categories),
  setCategory: (state, category: number) => (state.category = category),
  setSidebarStatus: (state, sidebarClosedProp: DiscoveryCollapsibleSidebar) =>
    (state.sidebarClosedProp = sidebarClosedProp),
  setTrendingActive: (state, trendingActive: DiscoverData) => (state.trendingActive = trendingActive),
  setFollowingActive: (state, followingActive: DiscoverData) => (state.followingActive = followingActive),
  setSettingsActive: (state, settingsActive: DiscoverData) => (state.settingsActive = settingsActive),
  setShortcuts: (state, shortcuts: DiscoverShortcutModel[]) => (state.shortcuts = shortcuts),
  setWelcomeBannerActive: (state, welcomeBannerActive: DiscoverData) =>
    (state.welcomeBannerActive = welcomeBannerActive),
  setPrefetchInitialized: (state, prefetchInitialized: boolean) => {
    state.prefetchInitialized = prefetchInitialized;
  },
  setTwitterAuthStatus(state, isTwitterAuthorized: boolean) {
    state.isTwitterAuthorized = isTwitterAuthorized;
  },
};

const module: Module<DiscoverState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

const moduleName = 'discover';

export default module;

export { moduleName };
