import HttpClient from '@/services/common/HttpClient';
import store from '@/store';
import { ISO8601DateTime } from '@/types/Common';
import type { SocialPostResponse as SocialPost } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';

export default class CreateAndPublishSocialPostsService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store.getters.selectedBrand?.id as number | undefined;
    if (!brandId) {
      // Fallback to localStorage
      try {
        const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand.id;
      } catch {
        console.warn('[CreateAndPublishSocialStoriesService] Unable to get brandId from localStorage');
      }
    }
    return brandId;
  }

  private get postsPrefix() {
    return `/createandpublish/${this.brandId}/posts`;
  }

  public getPosts(start: ISO8601DateTime, end: ISO8601DateTime): Promise<SocialPost[]> {
    const params = new URLSearchParams({
      start,
      end,
    });

    return this.http.get(`${this.postsPrefix}?${params.toString()}`);
  }

  public deletePost(postId: number): Promise<SocialPost> {
    return this.http.delete(`${this.postsPrefix}/${postId}`);
  }
}
