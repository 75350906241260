import store from '@/store';

const PodcastAnalyticsRoute = () => import('@/createandpublish/views/analytics/podcasts/AnalyticsRoute');
const PodcastAnalyticsShows = () => import('@/createandpublish/views/analytics/podcasts/AnalyticsShows');
const PodcastAnalyticsEpisodes = () => import('@/createandpublish/views/analytics/podcasts/AnalyticsEpisodes');
const PodcastAnalyticsCountries = () => import('@/createandpublish/views/analytics/podcasts/AnalyticsCountries');
const PodcastAnalyticsPlatforms = () => import('@/createandpublish/views/analytics/podcasts/AnalyticsPlatforms');
const PodcastAnalyticsDate = () => import('@/createandpublish/views/analytics/podcasts/AnalyticsDate');

export default {
  path: 'podcasts',
  name: 'Create.Analytics.Podcasts',
  component: PodcastAnalyticsRoute,
  redirect: 'podcasts/date',
  beforeEnter(_to, _from, next) {
    store.commit('CreateAndPublishStore/analytics/CLEAR_ALL_ANALYTICS_DATA');
    next();
  },
  children: [
    {
      path: 'date',
      name: 'Create.Analytics.Podcasts.Date',
      component: PodcastAnalyticsDate,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'shows',
      name: 'Create.Analytics.Podcasts.Shows',
      component: PodcastAnalyticsShows,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'episodes',
      name: 'Create.Analytics.Podcasts.Episodes',
      component: PodcastAnalyticsEpisodes,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'countries',
      name: 'Create.Analytics.Podcasts.Countries',
      component: PodcastAnalyticsCountries,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'platforms',
      name: 'Create.Analytics.Podcasts.Platforms',
      component: PodcastAnalyticsPlatforms,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
  ],
};
