import store from '@/store';

const AudioWizardRoute = () =>
  import(
    /* webpackChunkName: "cp-audio-library" */ '@/createandpublish/views/audio-library/audio-wizard/AudioWizardRoute.vue'
  );
const AudioWizardDescription = () =>
  import(
    /* webpackChunkName: "cp-audio-library" */ '@/createandpublish/views/audio-library/audio-wizard/AudioWizardDescription.vue'
  );
const AudioWizardLibrary = () =>
  import(
    /* webpackChunkName: "cp-audio-library" */ '@/createandpublish/views/audio-library/audio-wizard/AudioWizardLibrary.vue'
  );
const AudioWizardEditor = () =>
  import(
    /* webpackChunkName: "cp-audio-library" */ '@/createandpublish/views/audio-library/audio-wizard/AudioWizardEditor.vue'
  );

// Don't add name properties to these route objects. This routing table is imported im multiple locations
// within the app, which will cause a vue-router warning about duplicate names.
export default {
  path: 'audio-wizard',
  component: AudioWizardRoute,
  props: (route) => ({
    wizardMode: route.params.episodeId
      ? store.getters['CreateAndPublishStore/audioWizard/wizardModes'].EPISODE_WIZARD
      : store.getters['CreateAndPublishStore/audioWizard/wizardModes'].TRACK_WIZARD,
  }),
  redirect: (route) => (route.params.episodeId ? 'audio-wizard/library' : 'audio-wizard/description'),
  meta: {
    sidebar_active_item: 'Audio',
  },
  children: [
    {
      path: 'description',
      component: AudioWizardDescription,
      meta: {
        sidebar_active_item: 'Audio',
      },
    },
    {
      path: 'library',
      component: AudioWizardLibrary,
      meta: {
        sidebar_active_item: 'Audio',
      },
      beforeEnter(_to, _from, next) {
        store.commit('CreateAndPublishStore/audioWizard/CLEAR_AUDIO_EVENTS');
        next();
      },
    },
    {
      path: 'editor',
      component: AudioWizardEditor,
      meta: {
        sidebar_active_item: 'Audio',
      },
    },
  ],
};
