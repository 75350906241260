
import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import ProfileDropdownProduct from './components/ProfileDropdownProduct.vue';
import ProfileDropdownLinkItem from './components/ProfileDropdownLinkItem.vue';

import { isCCAdmin } from '../../utils';
import { isFeatureAvailable } from '@/utils/routerHelpers';
import { HIRE_CREATOR_URL } from '@/utils/jobs/jobs';

import type { AuthenticatedUser } from '@/models/UserModel';
import type { Brand } from '@/types/Brand';
import { ProductIds } from '../../types/account/AccountData';

@Component({
  name: 'ProfileDropdown',
  components: {
    ProfileDropdownProduct,
    ProfileDropdownLinkItem,
  },
})
export default class ProfileDropdown extends Vue {
  @Getter authenticatedUser!: AuthenticatedUser;
  @Getter isUserMultiBrand!: boolean;
  @Getter selectedBrand!: Brand;
  @Getter sortedPriorityAccountProducts;

  products: {
    id: number;
    name: string;
  }[] = [];

  @Action logOut!: () => void;

  hideProfileMenu = true;

  get getAdminEmail() {
    return this.authenticatedUser?.adminEmail ? `mailto:${this.authenticatedUser?.adminEmail}` : '';
  }
  get isCCAdmin() {
    return isCCAdmin();
  }
  get manageBrandsTitle() {
    return isFeatureAvailable('campaigns') ? 'Manage brands & campaigns' : 'Manage brands';
  }

  get hireCreatorUrl() {
    return HIRE_CREATOR_URL;
  }

  signOut() {
    this.logOut();
    this.$router.replace({ path: '/login' });
  }
  closeProfileMenu() {
    if (this.$el.classList.contains('show') && !this.$el.classList.contains('collapsing')) {
      this.$root.$emit('bv::toggle::collapse', 'profile-menu');
    }
  }
  appLink(path) {
    return `/${path}/${this.selectedBrand?.id}`;
  }

  async created() {
    const isCreateAndPublishEnabled = await this.$featureFlags.getCreateAndPublishFlag();
    this.products = this.sortedPriorityAccountProducts;
    if (!isCreateAndPublishEnabled) {
      this.products = this.sortedPriorityAccountProducts.filter(
        (product) => product.id !== ProductIds.CREATE_AND_PUBLISH
      );
    }
  }

  mounted() {
    this.$root.$on('closeProfileFromIframe', () => {
      if (this.$el.classList.contains('show')) {
        this.$root.$emit('bv::toggle::collapse', 'profile-menu');
      }
    });
  }
}
