import HttpClient from '@/services/common/HttpClient';
import { AuthURL } from '@/types/account/AuthURL';
import { ActiveSocialAccount } from '@/types/audience';
import { FacebookPage } from '@/types/nativeIntegration/FacebookPage';
export default class NativePlatformsService {
  private http: HttpClient = HttpClient.getInstance();

  public getFacebookOAuthURL(): Promise<AuthURL> {
    return this.http.get('/facebook/connect');
  }
  public getInstagramOAuthURL(): Promise<AuthURL> {
    return this.http.get('/instagram/connect');
  }
  public getTiktokOAuthURL(): Promise<AuthURL> {
    return this.http.get('/tiktok/connect');
  }
  public getYoutubeOAuthURL(): Promise<AuthURL> {
    return this.http.get('/youtube/connect');
  }
  public getTwitterOAuthURL(): Promise<AuthURL> {
    return this.http.get('/twitter/connect');
  }
  public getLinkedInOAuthURL(): Promise<AuthURL> {
    return this.http.get('/linkedIn/connect');
  }
  public getPinterestOAuthURL(): Promise<AuthURL> {
    return this.http.get('/pinterest/connect');
  }
  public getConnectedPlatforms(): Promise<ActiveSocialAccount[]> {
    return this.http.get('/native/platforms');
  }
  public unlinkYoutube(): Promise<void> {
    return this.http.get('/youtube/disconnect');
  }
  public unlinkFacebook(): Promise<void> {
    return this.http.get('/facebook/disconnect');
  }
  public unlinkInstagram(): Promise<void> {
    return this.http.get('/instagram/disconnect');
  }
  public unlinkTiktok(): Promise<void> {
    return this.http.get('/tiktok/disconnect');
  }
  public unlinkTwitter(): Promise<void> {
    return this.http.get('/twitter/disconnect');
  }
  public unlinkLinkedIn(): Promise<void> {
    return this.http.get('/linkedin/disconnect');
  }
  public unlinkPinterest(): Promise<void> {
    return this.http.get('/pinterest/disconnect');
  }
  public connectFacebookPage(pageId: string): Promise<void> {
    return this.http.post(`/facebook/pages`, { pageId });
  }
  public connectIGFacebookPage(pageId: string): Promise<void> {
    return this.http.post(`/instagram/pages`, { pageId });
  }
  public processOAuthRedirection(platform: string, authCode?: string): Promise<void | FacebookPage[]> {
    return this.http.get(`/${platform}/callback?code=${authCode}`);
  }
}
