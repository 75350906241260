import * as LDClient from 'launchdarkly-js-client-sdk';
import type { AuthenticatedUser } from '@/models/UserModel';
import store from '@/store';

interface FlagChange {
  current: boolean;
  previous: boolean;
}

export class FeatureFlagService {
  private ldClient;
  private isReady;

  constructor() {
    this.isReady = false;
    const context = this.getContext();
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LD_CLIENT_ID as string, context);
    this.setupFlagChangeListeners();
    this.ldClient.waitUntilReady().then(() => {
      this.isReady = true;
    });
  }

  setupFlagChangeListeners() {
    this.ldClient.on('change', async (changes) => {
      for (const [flagKey, flagDetails] of Object.entries(changes)) {
        switch (flagKey) {
          case 'NATIVE_INTEGRATION':
            await this.handleNativeIntegrationFlagChange(flagDetails as FlagChange);
            break;
          default:
            break;
        }
      }
    });
  }

  getContext() {
    const authenticatedUser: AuthenticatedUser = store.getters['authenticatedUser'];
    const currentDate = new Date();
    const estOptions = { timeZone: 'America/New_York' };
    const date = currentDate.toLocaleDateString('en-US', estOptions);
    const context = {
      kind: 'multi',
      user: {
        key: process.env.VUE_APP_LD_CONTEXT_KEY,
        email: authenticatedUser?.email || '',
      },
      // product: {
      //   key: 'product',
      //   name: 'Creator Package Monthly',
      // },
      date: {
        key: 'date',
        value: date,
      },
    };
    return context;
  }

  async getCreateAndPublishFlag() {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    const context = this.getContext();
    await this.ldClient.identify(context);
    const flagValue = this.ldClient.variation('CREATE_AND_PUBLISH', false);
    return flagValue;
  }

  async getTiktokOAuthFlag() {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    const context = this.getContext();
    await this.ldClient.identify(context);
    const flagValue = this.ldClient.variation('TIKTOK_OAUTH', false);
    return flagValue;
  }

  async getYoutubeOAuthFlag() {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    const context = this.getContext();
    await this.ldClient.identify(context);
    const flagValue = this.ldClient.variation('YOUTUBE_OAUTH', false);
    return flagValue;
  }

  async getCheckoutBillingFlag() {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    const context = this.getContext();
    await this.ldClient.identify(context);
    const flagValue = this.ldClient.variation('CHECKOUT_BILLING', false);
    return flagValue;
  }

  async getCyberMondayOfferFlag() {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    const context = this.getContext();
    await this.ldClient.identify(context);
    const flagValue = this.ldClient.variation('CYBER_MONDAY_OFFER', false);
    return flagValue;
  }

  async getNativeIntegrationFlag() {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    const context = this.getContext();
    await this.ldClient.identify(context);
    const flagValue = this.ldClient.variation('NATIVE_INTEGRATION', false);
    return flagValue;
  }

  async handleNativeIntegrationFlagChange(flagChange: FlagChange) {
    if (!this.isReady) {
      await this.ldClient.waitUntilReady();
    }
    switch (flagChange.current) {
      case true:
        await store.dispatch('AudienceAnalyticsStore/getConnectedPlatforms');
        store.commit('setNativeIntegrationFlag', true);
        break;
      case false:
        await store.dispatch('AudienceAnalyticsStore/getProfile');
        store.commit('setNativeIntegrationFlag', false);
        break;
      default:
        break;
    }
  }
}
