import store from '@/store';
import type { AuthenticatedUser } from '@/models/UserModel';
import type { Brand } from '@/types/Brand';
import { roleIdMap, statusIdMap } from '@/utils/thirdPartySupport';

/**
 * FreshChat widget docs. https://support.freshchat.com/support/solutions/articles/233349-pre-chat-form
 */

const run = () => {
  const scriptEl = document.createElement('script');
  scriptEl.defer = true;
  scriptEl.src = 'https://wchat.freshchat.com/js/widget.js';
  scriptEl.onload = initFc;
  document.head.appendChild(scriptEl);
};

const initFc = () => {
  const { id, firstName, lastName, email } = store.getters['authenticatedUser'] as AuthenticatedUser;

  window.fcWidget.init({
    token: '4856173b-0f88-4ef7-abfe-b57e784771cd',
    host: 'https://futurimedia.freshchat.com',
    widgetUuid: '9e23a0a2-ab0e-499e-a3ff-7f03077de09f',
    tags: ['Idea Engine'],
    siteId: 'Idea Engine',
    firstName,
    lastName,
    email,
    externalId: `cc_${id}`,
    config: {
      headerProperty: {
        backgroundColor: '#29323D',
        foregroundColor: '#FFF',
        hideChatButton: true,
      },
      cssNames: {
        widget: 'custom_fc_frame', // mandatory (is set to default)
        expanded: 'custom_fc_expanded', // mandatory (is set to default)
      },
      content: {
        headers: {
          chat: 'Chat With VIP Support',
        },
      },
    },
  });

  window.fcWidget.on('user:created', () => {
    setFcUserProperties();
  });
};

const setFcUserProperties = () => {
  const {
    id: userId,
    firstName,
    lastName,
    email,
    phone,
    roleId,
    statusId,
  } = store.getters['authenticatedUser'] as AuthenticatedUser;
  const { id: brandId } = store.getters['selectedBrand'] as Brand;

  window.fcWidget.user
    .setProperties({
      firstName,
      lastName,
      email,
      phone,
      brandId,
      userId,
      role: roleIdMap[roleId],
      status: statusIdMap[statusId],
    })
    .catch(() => {
      /* eat it */
    });
};

export const openFreshChat = () => {
  const widget = window.fcWidget;
  if (!widget) {
    // lazy load widget
    run();
    const interval = setInterval(() => {
      if (window.fcWidget?.isLoaded()) {
        clearInterval(interval);
        openFreshChat();
      }
    }, 100);
    return;
  }
  if (!widget.isOpen()) {
    widget.open();
  }
};

export const closeFreshChat = () => {
  const widget = window.fcWidget;
  if (!widget) return;
  if (widget.isOpen()) {
    widget.close();
  }
};

export const toggleFreshChat = () => {
  const widget = window.fcWidget;
  if (!widget) {
    // lazy load widget
    run();
    const interval = setInterval(() => {
      if (window.fcWidget?.isLoaded()) {
        clearInterval(interval);
        openFreshChat();
      }
    }, 300);
    return;
  }
  if (widget.isOpen()) {
    widget.close();
  } else {
    widget.open();
  }
};

export default run;
