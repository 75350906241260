
// Developer note: The b-sidebar component that Drawer.vue wraps is
// opened and closed in a very unconventional way. Instead of using b-sidebar's
// :visible prop, you need to use the following to toggle on its visibility:
// this.$root.emit('bv::toggle::collapse', 'drawerId');
// where drawerId is the id prop passed to Drawer. To close it, you call the
// hide() function passed to the submit.onClick callback and cancel event.

import { Component, Prop, Vue } from 'vue-property-decorator';
import AssistiveLink from '@/components/common/form/AssistiveLink.vue';
import DrawerTitle from './components/DrawerTitle.vue';
import Button from '@/components/common/buttons/Button.vue';
@Component({ components: { DrawerTitle, Button, AssistiveLink } })
export default class Drawer extends Vue {
  @Prop({ default: 'NA', required: true }) qa!: string; // Required to make sure we've added QA attribute
  @Prop({ default: 'drawer-id', required: true }) id!: string;
  @Prop({ default: 'drawer', required: false }) name!: string;
  @Prop({ default: '640px', required: false }) width?: string;
  @Prop({ default: false, required: false }) noFooter?: boolean;
  @Prop({ required: false }) title?: string;
  @Prop({ required: false }) noCloseIcon?: boolean;
  @Prop({ required: true }) submit!: {
    buttonText: string;
    onClick: (hide: () => void) => Promise<void>; // Change type when we handle errors
    qa: string;
  };
  @Prop({ required: false }) hideEventFunction!: () => void;
  @Prop({ required: false }) showEventFunction!: () => void;
  @Prop({ default: false, required: false }) loading!: boolean;
  @Prop({ default: false, required: false }) vh100!: boolean;

  onHide(hide) {
    this.$root.$emit('bv::toggle::collapse', this.$props.id);
    this.$emit('cancel', hide);
  }

  hideEvent() {
    if (this.$props.hideEventFunction) {
      return this.$emit(this.$props.hideEventFunction);
    }
  }

  showEvent() {
    if (this.$props.showEventFunction) {
      return this.$emit(this.$props.showEventFunction);
    }
  }

  async onSubmit(hide) {
    await this.submit.onClick(hide);
  }
}
