// From python package justext, English stopwords
export const stopListEn = new Set([
  'it',
  'in',
  'league',
  'help',
  'then',
  'six',
  'few',
  'from',
  'san',
  'designed',
  'great',
  'year',
  'died',
  'result',
  'life.',
  'king',
  'due',
  'much',
  'have',
  'so',
  'had',
  'married',
  'which',
  'last',
  'air',
  'working',
  'are',
  // 'west',
  // will can also be a legal term
  // 'will',
  'australian',
  'men',
  'land',
  'form',
  'started',
  'average',
  'opened',
  'able',
  'show',
  'father',
  'player',
  'back',
  'radio',
  'law',
  'to',
  'an',
  'no',
  'making',
  'control',
  'as',
  'point',
  'was',
  'period',
  'while',
  'close',
  'different',
  'end',
  'death',
  'right',
  'national',
  'do',
  'usually',
  // 'south',
  'business',
  'years',
  'its',
  'formed',
  'most',
  'since',
  'title',
  'taken',
  'him',
  'official',
  'years,',
  'she',
  'third',
  'later',
  'we',
  'not',
  'each',
  'of',
  'largest',
  'two',
  'george',
  'august',
  'across',
  '(born',
  'after',
  'professional',
  'per',
  'included',
  'world',
  'final',
  'time,',
  'he',
  'approximately',
  'city',
  'under',
  'announced',
  'recorded',
  'lost',
  'large',
  'former',
  'place',
  'service',
  'within',
  'born',
  'continued',
  'out',
  'top',
  'field',
  'miles',
  'featured',
  'side',
  'u.s.',
  'june',
  'features',
  'living',
  'support',
  'is',
  'remained',
  'times',
  'against',
  'run',
  'be',
  'total',
  'began',
  'river',
  'over',
  'where',
  'himself',
  'site',
  'following',
  'appeared',
  'development',
  'works',
  'such',
  'late',
  'music',
  'work',
  'school',
  'became',
  'students',
  'japanese',
  'days',
  'de',
  'december',
  'worked',
  'does',
  // 'state',
  'role',
  'down',
  'see',
  'signed',
  'some',
  'followed',
  'band',
  'community',
  'however,',
  'production',
  'rather',
  'took',
  'joined',
  'sent',
  'general',
  'game',
  'british',
  'together',
  'i',
  'by',
  // 'income',
  'available',
  'throughout',
  'developed',
  // 'council',
  'left',
  'you',
  'appointed',
  'single',
  'must',
  'near',
  'five',
  'used',
  'what',
  'many',
  'live',
  'according',
  'published',
  'never',
  'open',
  'army',
  'this',
  'similar',
  'upon',
  'wrote',
  'group',
  'use',
  'well',
  'came',
  'social',
  'march',
  'may',
  'european',
  'western',
  'james',
  'royal',
  'on',
  'including',
  'states',
  'september',
  // 'canadian',
  'church',
  'series',
  'said',
  'currently',
  'london',
  'established',
  'into',
  'song',
  'time',
  'species',
  'also',
  'become',
  'order',
  'day',
  'made',
  'high',
  'games',
  'although',
  'famous',
  'government',
  'served',
  'built',
  'april',
  'age',
  'district',
  'best',
  'around',
  'eventually',
  'include',
  'county',
  'moved',
  'early',
  'years.',
  // 'robert',
  'based',
  'number',
  'set',
  'sold',
  'member',
  'young',
  'november',
  'term',
  'station',
  'leading',
  'history.',
  'further',
  'political',
  'been',
  'without',
  'name',
  'can',
  'playing',
  // 'indian',
  'almost',
  'old',
  'along',
  'found',
  'released',
  'produced',
  'county,',
  'four',
  'played',
  'long',
  'play',
  'written',
  'main',
  'version',
  // 'president',
  'next',
  'considered',
  'good',
  'son',
  'when',
  'record',
  'elected',
  'because',
  'town',
  'who',
  'own',
  'small',
  'way',
  'now',
  'album',
  'even',
  'another',
  'given',
  // 'million',
  'just',
  'original',
  'party',
  'make',
  'includes',
  // 'village',
  // 'university',
  'english',
  'german',
  'performed',
  'did',
  'population',
  // 'east',
  'public',
  'seen',
  'more',
  'located',
  'special',
  '(the',
  'important',
  'should',
  'originally',
  'career',
  'at',
  'those',
  'founded',
  'story',
  'area',
  'again',
  'john',
  'february',
  // 'house',
  'went',
  'for',
  'how',
  'if',
  'william',
  'both',
  'century',
  'their',
  // 'building',
  'having',
  'would',
  'with',
  'short',
  'through',
  'could',
  'january',
  'international',
  'during',
  'than',
  'led',
  'program',
  'common',
  'they',
  'life',
  'french',
  'known',
  'team',
  'members',
  'described',
  'home',
  'york',
  'but',
  'all',
  'popular',
  'received',
  // 'family',
  'one',
  'called',
  // 'children',
  'her',
  'every',
  'book',
  'modern',
  'has',
  'and',
  'named',
  'still',
  'until',
  'very',
  'were',
  'returned',
  'water',
  // 'research',
  'gave',
  'though',
  'between',
  'full',
  'them',
  'history',
  'character',
  'before',
  'second',
  'first',
  'united',
  'people',
  'the',
  'position',
  'same',
  'us',
  // 'military',
  'there',
  'lead',
  'held',
  'get',
  'david',
  'major',
  'line',
  'often',
  'the',
  'that',
  'being',
  'club',
  'film',
  'once',
  'various',
  'won',
  'other',
  'off',
  'either',
  'system',
  'take',
  'up',
  'july',
  // 'north',
  'about',
  'his',
  'power',
  'head',
  'country',
  'season',
  'using',
  'these',
  'among',
  'st.',
  'created',
  'local',
  // 'war',
  'or',
  'company',
  // 'american',
  'new',
  // 'television',
  'a',
  'less',
  'several',
  'college',
  'only',
  'like',
  'october',
  // 'football',
  'any',
  'current',
  'part',
  'three',
]);
