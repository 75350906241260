
import { ActiveSocialAccount, AyrshareProfile } from '@/types/audience';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SocialPostBody } from '@/types/createandpublish/socialStory';
import { busEvents, eventBus } from '@/createandpublish/core/eventBus/socialPostEventBus';

@Component({
  name: 'TwitterPreview',
  components: {},
})
export default class TwitterPreview extends Vue {
  @Prop({ type: Object, required: false }) platformProfileData!: ActiveSocialAccount | null;
  @Prop({ type: Object, required: false }) audienceProfileData!: AyrshareProfile | null;
  @Prop({ type: Object, required: true }) currentPlatformPostDetails!: SocialPostBody;

  currentPostDetails: SocialPostBody | null = null;

  get twitterPostDetails() {
    return this.currentPostDetails ? (this.currentPostDetails as SocialPostBody) : null;
  }

  updateDraftPostDetails(postContent: SocialPostBody | undefined) {
    if (postContent?.mainRequest?.platforms?.includes('twitter')) {
      this.currentPostDetails = postContent;
    }
  }

  get profileImage() {
    return this.audienceProfileData?.image;
  }

  get profileName() {
    return this.platformProfileData?.platformDisplayName;
  }

  get userName() {
    return this.platformProfileData?.platformUsername;
  }

  get postImages() {
    if (!this.currentPostDetails?.mainRequest?.mediaUrls?.length) {
      return null;
    }
    if (this.currentPostDetails?.mainRequest?.isVideo) {
      return [this.currentPostDetails?.mainRequest?.videoThumbnailUrl];
    }
    return this.currentPostDetails?.mainRequest.mediaUrls;
  }

  get videoIcon() {
    if (this.postImages?.length === 1 && this.currentPostDetails?.mainRequest?.isVideo) {
      return true;
    }
    return false;
  }

  // TODO - this is not currently in use, but will be important
  get platformPostOptions() {
    return this.currentPostDetails?.platformOptions?.twitterOptions;
  }

  mounted() {
    this.updateDraftPostDetails(this.currentPlatformPostDetails);
    eventBus.$on(busEvents.UPDATE_DRAFT_POST_DETAILS, this.updateDraftPostDetails);
  }
}
