import moment from 'moment';
import { Moment } from 'moment-timezone';

export enum TimeRangeMode {
  First72 = 'First72',
  ThisWeek = 'ThisWeek',
  AllTime = 'AllTime',
}

export const getDateRangeByRangeMode = (
  postPostedAt: Moment,
  rangeMode: TimeRangeMode
): { startDate: string; endDate: string } | undefined => {
  if (rangeMode === TimeRangeMode.First72) {
    return {
      startDate: postPostedAt.format('YYYY-MM-DD'),
      endDate: postPostedAt.clone().add({ day: 3 }).format('YYYY-MM-DD'),
    };
  } else if (rangeMode === TimeRangeMode.ThisWeek) {
    return {
      startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    };
  } else if (rangeMode === TimeRangeMode.AllTime) {
    return undefined;
  }

  return undefined;
};
