
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Campaign } from '@/types/Campaign';

@Component({
  name: 'CampaignCircle',
})
export default class CampaignCircle extends Vue {
  @Prop({ required: true }) readonly campaign!: Campaign;
  @Prop({ required: false, default: '16px' }) readonly height!: string;
  @Prop({ required: false, default: '16px' }) readonly width!: string;

  get colorStyle() {
    return {
      color: this.campaign.color,
    };
  }
}
