
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'NoChartDataBanner',
})
export default class NoChartDataBanner extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) isLineChart!: boolean;
  @Prop({ type: Number, required: false, default: 0 }) offset?: number;
}
