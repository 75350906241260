
import { Component, Vue } from 'vue-property-decorator';

import ContextTotalCard from '@/audience/components/ContextTotalCard.vue';
import MetricList from '@/audience/components/MetricList.vue';

@Component({
  name: 'NewUserNoPlatformsPlaceholderMobile',
  components: {
    ContextTotalCard,
    MetricList,
  },
})
export default class NewUserNoPlatformsPlaceholderMobile extends Vue {
  get contextTotalCardConfig() {
    return [
      { title: 'Facebook', value: 53021, percentChange: 0.17 },
      { title: 'Twitter', value: 9048, percentChange: 0.07 },
      { title: 'YouTube', value: 34360, percentChange: 1.53 },
    ];
  }

  onGetStartedClick() {
    this.$emit('open-ayrshare-link');
  }
}
