// Application framework
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Futuri-ui-kit
import '@futuri/futuri-ui-kit/dist/';
import 'focus-visible';

// Bootstrap
import { BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

// Global app styles
import './assets/aec.scss';
import './assets/brands.scss';
import './assets/global.scss';
import './assets/portal.scss';

// vue-progress-path global stylesheet (loading spinner)
import 'vue-progress-path/dist/vue-progress-path.css';
// global date-picker styles
import '@/styles/date-range-picker.scss';

// Custom directives
import clickOutside from './directives/click-outside';
import feature from './directives/feature';
import modelWcDirective from './directives/modelWcDirective';

// Vue plugins
import { BootstrapVue } from 'bootstrap-vue';
import Maska from 'maska';
import VueMask, { VueMaskDirective } from 'v-mask';
import VTooltip from 'v-tooltip';
import Meta from 'vue-meta';
import VueProgress from 'vue-progress-path';
import VueRouter from 'vue-router';
import VueSSE from 'vue-sse';
import Vuelidate from 'vuelidate';
import Components from './plugins/Components.js';
import ErrorPrettified from './plugins/ErrorPrettified.js';
import ToProperCase from './plugins/ToProperCase.js';
import FeatureFlagPlugin from './utils/featureFlagPlugin';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import vuetify from '@/plugins/vuetify';

Vue.use(Vuelidate);
Vue.use(VueProgress);
Vue.use(VTooltip);
Vue.use(Components);
Vue.use(ErrorPrettified);
Vue.use(BootstrapVueIcons);
Vue.use(ToProperCase);
Vue.use(Meta);
Vue.use(VueMask);
Vue.use(Maska);
Vue.use(VueSSE);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(FeatureFlagPlugin);
Vue.use(Toast);

Vue.directive('model-wc', modelWcDirective);
Vue.directive('click-outside', clickOutside);
Vue.directive('feature', feature);
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^fm-/];

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
