/**
 * Type guard that verifies that errors are of the expected type
 * @param errors unknown variable
 * @returns true if param type = string[] and is of length > 0
 */
export const isValidErrorFormat = (errors: unknown): errors is string[] => {
  return Array.isArray(errors) && errors.every((error) => typeof error === 'string') && errors.length > 0;
};

/**
 * Type guard that returns true if `val` is not `null` or `undefined`.
 * @param val
 * @returns boolean
 */
export function isNotNullOrUndefined<T>(val: T | null | undefined): val is T {
  return typeof val !== 'undefined' && val !== null;
}

/**
 * Type guard that returns true if `val` is `null` or `undefined`.
 * @param val
 * @returns boolean
 */
export function isNullOrUndefined<T>(val: T | null | undefined): val is null | undefined {
  return typeof val === 'undefined' || val === null;
}
