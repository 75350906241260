
import { mapGetters } from 'vuex';

import './styles.scss';
import TrialActiveAdmin from './components/TrialActiveAdmin.vue';
import TrialActiveUser from './components/TrialActiveUser.vue';
import TrialExpiredAdmin from './components/TrialExpiredAdmin.vue';
import TrialExpiredUser from './components/TrialExpiredUser.vue';

export default {
  name: 'TrialExpirationAlert',

  components: {
    TrialActiveAdmin,
    TrialActiveUser,
    TrialExpiredAdmin,
    TrialExpiredUser,
  },

  computed: {
    ...mapGetters(['isAdmin', 'trialHasExpired']),
  },
};
