
import Loading from '@/components/common/Loading.vue';
import { ChatbotMessage } from '@/types/discover/ChatbotMessage';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChatbotConversation from './conversation/ChatbotConversation.vue';

@Component({
  components: { Loading, ChatbotConversation },
})
export default class ChatbotResults extends Vue {
  @Prop({ type: String, required: true }) query!: string;
  @Prop({ type: String, required: true }) result!: string | null;
  loading = false;

  get convertedMessages() {
    const messages: ChatbotMessage[] = [];

    messages.push({
      content: this.query,
      sender: {
        type: 'USER',
      },
    });

    messages.push({
      content: this.result,
      sender: {
        type: 'CHATBOT',
      },
    });

    return messages;
  }

  // @Watch('result', { immediate: true })
  // onResultStateChange(newState: string | undefined) {
  //   this.loading = isNull(newState);
  // }
}
