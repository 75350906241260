import HttpClient from '@/services/common/HttpClient';
import store from '@/store';
import type { PaginatedResponse } from '@/types/createandpublish/socialStory';
import type {
  CreateSocialStoryInput as NewSocialStoryPayload,
  UpdateSocialStoryInput,
} from 'content-cloud-types/dist/types/createandpublish/PostingControllerInterfaces';
import type { SocialStoryDetails as SocialStory } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';

export default class CreateAndPublishSocialStoriesService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store.getters.selectedBrand?.id as number | undefined;
    if (!brandId) {
      // Fallback to localStorage
      try {
        const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand.id;
      } catch {
        console.warn('[CreateAndPublishSocialStoriesService] Unable to get brandId from localStorage');
      }
    }
    return brandId;
  }

  // Endpoint prefix definitions.
  private get storiesPrefix() {
    return `/createandpublish/${this.brandId}/story`;
  }
  private get postingPrefix() {
    return `/createandpublish/${this.brandId}/posts`;
  }

  public getStories(params: string): Promise<PaginatedResponse<SocialStory[]>> {
    return this.http.get(`${this.storiesPrefix}${params.toString()}`);
  }

  public getStoryById(id: SocialStory['id']): Promise<SocialStory> {
    return this.http.get(`${this.storiesPrefix}/${id}`);
  }

  public getAllStories(): Promise<SocialStory[]> {
    return this.http.get(`${this.storiesPrefix}`);
  }

  public saveStory(story: NewSocialStoryPayload): Promise<SocialStory> {
    return this.http.post(`${this.storiesPrefix}`, story);
  }

  public updateStory(id: SocialStory['id'], story: UpdateSocialStoryInput): Promise<SocialStory> {
    return this.http.put(`${this.storiesPrefix}/${id}`, story);
  }

  public deleteStory(id: SocialStory['id']): Promise<SocialStory> {
    return this.http.delete(`${this.storiesPrefix}/${id}`);
  }
}
