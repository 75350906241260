
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import CropImageUpload from '@/createandpublish/components/CropImageUpload.vue';
import UploadMediaMixin from '@/createandpublish/mixins/UploadMediaMixin.vue';
import MediaLibraryAssetSelector from '@/createandpublish/components/socialStories/MediaLibraryAssetSelector.vue';
import LoadingBar from '@/components/LoadingBar.vue';

import { maybeGetContentLength } from '@/utils';

import type {
  MediaUploadType,
  MediaElProperties,
  ImageCroppings,
  ImageAsset,
  VideoAsset,
} from '@/types/createandpublish';

const CreateAndPublishStore = namespace('CreateAndPublishStore');
const MODULE_NAMESPACE = 'CreateAndPublishStore/mediaUploads';
const mediaUploadsModule = namespace(MODULE_NAMESPACE);

@Component({
  name: 'MediaUploadAndPreview',
  components: {
    CropImageUpload,
    MediaLibraryAssetSelector,
    LoadingBar,
  },
})
export default class MediaUploadAndPreview extends Mixins(UploadMediaMixin) {
  @Prop({ type: String, required: true }) readonly value!: string;
  @Prop({ type: String, required: false }) readonly videoThumbnailSrc!: string;
  @Prop({ type: String, required: false, default: '' }) readonly label!: string;
  @Prop({ type: String, required: false }) readonly assistiveText?: string;
  @Prop({ type: Object, required: false, default: () => ({ width: '100%', height: 'auto' }) }) readonly dimensions!: {
    width: string;
    height: string;
  };
  @Prop({ type: String, required: true }) readonly mediaType!: Exclude<MediaUploadType, 'audio' | undefined>;
  @Prop({ type: String, required: true }) readonly fileInputAcceptAttr!: string;
  @Prop({ type: Boolean, default: false }) readonly isReadonly!: boolean;
  @CreateAndPublishStore.Getter mediaUrl!: string;
  @CreateAndPublishStore.Action cropImage!: (data: {
    image: string;
    croppings: ImageCroppings;
  }) => Promise<{ status_code: number; location: string }>;

  @mediaUploadsModule.Getter('progress') mediaUploadProgress!: object;

  isLoading = false;
  cropImageModal = {
    isOpen: false,
  };
  mediaLibraryModal = {
    isOpen: false,
  };

  get mediaSrc() {
    return this.value;
  }
  set mediaSrc(value) {
    this.$emit('input', value);
  }

  showLoadingBar = false;

  @Watch('isLoading')
  toggleUploadingMediaStatus() {
    this.showLoadingBar = true;
    this.$emit('toggleUploadingMediaStatus');
  }

  handleDrop(e: DragEvent) {
    const dt = e.dataTransfer;
    if (!dt) return;
    if (!this.canDrop(dt.items)) return;
    const file = dt.files[0];
    this.uploadMediaFile(file);
  }

  inspectDragEvent(e: DragEvent) {
    const dt = e.dataTransfer;
    if (!dt) return;
    dt.dropEffect = this.canDrop(dt.items) ? 'copy' : 'none';
  }

  /**
   * Tests whether or not a user is dragging a single image over the drop-zone.
   * @param {DataTransferItemList} items list of items being dragged
   * @returns {Boolean} true if user is allowed to drop content
   */
  canDrop(items) {
    return (
      !this.isLoading && !!items && items.length === 1 && this.fileInputAcceptAttr.split(',').includes(items[0].type)
    );
  }

  onChooseImage() {
    if (this.isLoading) return;
    this.$refs.fileInput.click();
  }

  onFileInputChange(e: Event) {
    const files = (e.target as HTMLInputElement).files;
    if (files?.length) {
      this.uploadMediaFile(files[0]);
    }
  }

  clearMediaSrc() {
    this.$emit('clearMedia');
    this.mediaSrc = '';
    this.$emit('updateVideoThumbnailUrl', '');
    this.imageProperties = null;
    this.videoProperties = null;
  }

  async uploadMediaFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      this.$watch('mediaUploadProgress', () => {
        const progress = this.mediaUploadProgress['upload_progress'] - 1;
        if (progress <= 99 && progress >= 0) {
          this.showLoadingBar = true;
        } else {
          this.showLoadingBar = false;
        }
      });

      this.isLoading = true;
      this.initMediaUploadService(this.mediaType, this.onUploadComplete);
      this.startUploadWithFile(file);
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }

  get postUrlPrefix() {
    return this.mediaUrl.replace('media.', '');
  }

  onUploadComplete() {
    const uploadEvent = { ...this.allSuccessfulUploads?.[0] }; // Clone
    this.mediaSrc = uploadEvent?.source_url as string;
    this.$emit('updateVideoThumbnailUrl', uploadEvent?.thumbnail_url);
    this.isLoading = false;
  }

  get isCropAllowed() {
    return false;
    // return this.mediaType === 'image';
  }

  showCropModal() {
    this.cropImageModal.isOpen = true;
  }

  async handleImageCropped(data) {
    this.cropImageModal.isOpen = false;

    if (!data) return;
    alert('Not supported by API yet');
    return;

    // const { cropPercentages } = data;

    // this.isLoading = true;
    // // Crop doesn't currently work this line forces the url to have ".cropped." before it downloads
    // // Plus, crop forces a 700 x 366 aspect ratio on L1188
    // // https://git.streamon.fm/cloudlogger/meta_api/-/blob/unified/audiometa/views/upload.py#L1141
    // // Send crop data to server and get back location of new cropped image
    // const resp = await this.cropImage({
    //   image: this.mediaSrc.replace(this.postUrlPrefix, ''),
    //   croppings: cropPercentages,
    // });

    // if (resp && resp.status_code === 201 && resp.location) {
    //   const imageSrc = `${this.postUrlPrefix}${resp.location}`;
    //   this.mediaSrc = imageSrc.replace('.thumbnail.', '.original.');
    // }

    // this.isLoading = false;
  }

  openMediaLibraryModel() {
    this.mediaLibraryModal.isOpen = true;
  }

  closeMediaLibraryModal() {
    this.mediaLibraryModal.isOpen = false;
  }

  onMediaAssetSelected(mediaAsset: ImageAsset | VideoAsset) {
    this.mediaSrc = mediaAsset.source_url;
    this.$emit('updateVideoThumbnailUrl', mediaAsset?.thumbnail_url);
  }

  videoProperties: MediaElProperties | null = null;

  @Watch('videoProperties', { immediate: true })
  onVideoPropsChange(newVal) {
    this.$emit('videoProperties:changed', newVal);
  }

  async setVideoProperties(e: Event) {
    const videoEl = e.target as HTMLVideoElement;
    const { videoWidth, videoHeight, duration } = videoEl;
    const filesize = await maybeGetContentLength(this.value);

    this.videoProperties = {
      width: videoWidth,
      height: videoHeight,
      aspectRatio: videoWidth / videoHeight,
      duration: duration,
      filesize: filesize ? Number(filesize) : 0,
    };
  }

  imageProperties: MediaElProperties | null = null;

  @Watch('imageProperties', { immediate: true })
  onImagePropsChange(newVal) {
    this.$emit('imageProperties:changed', newVal);
  }

  async setImageProperties(e: Event) {
    const imageEl = e.target as HTMLImageElement;
    const { naturalWidth, naturalHeight } = imageEl;
    const filesize = await maybeGetContentLength(this.value);

    this.imageProperties = {
      width: naturalWidth,
      height: naturalHeight,
      aspectRatio: naturalWidth / naturalHeight,
      filesize: filesize ? Number(filesize) : 0,
    };
  }

  $refs!: {
    fileInput: HTMLInputElement;
  };
}
