
import ChatbotResults from '@/components/chatbot/results/ChatbotResults.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { ChatbotResults },
})
export default class ChatbotSearch extends Vue {
  defaultValue = 'What is currently trending in beauty on YouTube?';
  queryValue = '';
  result: string | null = null;

  isResultsSectionOpen = false;

  resetSearch() {
    this.isResultsSectionOpen = false;
    this.result = null;
  }

  handleInputChange(e) {
    if (this.isResultsSectionOpen) {
      this.resetSearch();
    }
    this.queryValue = e.target.value;
  }

  async getChatbotResult() {
    if (this.queryValue !== '') {
      this.isResultsSectionOpen = true;
      // try {
      //   const chatbotResult = await new ChatbotService().getChatbotResultsByQuery(this.queryValue);
      //   this.result = chatbotResult.toString();
      // } catch (err) {
      //   this.result = 'An error occured. Please try again';
      // }
    }
  }
}
