import SocialStoryAnalyticsService from '@/services/CreateAndPublishSocialStoriesAnalyticsService';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import type {
  TimeChunk,
  FinalStoryLeaderboardObject,
  SocialStoryAnalyticsByTimeStep,
  ParsedStoryDetailsAndData,
  SocialStoryListResponse,
} from 'content-cloud-types/dist/types/createandpublish/AnalyticsControllerInterfaces';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';

const StoryAnalyticsService = new SocialStoryAnalyticsService();

const socialStoryAnalyticsModule: Module<SocialStoriesAnalyticsState, RootState> = {
  namespaced: true,

  state: {
    stories: {
      allStories: [],
      storiesByEngagement: [],
      storiesByImpression: [],
    },
    timeChunk: 'allday',
    singleStoryId: 0,
    singleStoryAnalytics: {},
  },

  getters: {
    stories(state) {
      return state.stories;
    },
    timeChunk(state) {
      return state.timeChunk;
    },
    singleStoryAnalytics(state) {
      return state.singleStoryAnalytics;
    },
  },

  mutations: {
    SET_STORIES(state, stories: SocialStoryAnalytics) {
      state.stories = stories;
    },

    CLEAR_STORIES(state) {
      state.stories = {
        allStories: [],
        storiesByEngagement: [],
        storiesByImpression: [],
      };
    },

    SET_SINGLE_STORY(state, story: SocialStoryAnalyticsByTimeStep) {
      state.singleStoryAnalytics = story;
    },

    SET_STORY_ID(state, storyId: number) {
      state.singleStoryId = storyId;
    },
  },

  actions: {
    getStoriesAnalytics({ dispatch, commit, state }) {
      return handleAjax({
        request: StoryAnalyticsService.getTopStories(state.timeChunk),
        dispatch,
        commit,
        mutation: 'SET_STORIES',
        modify(respData: FinalStoryLeaderboardObject): SocialStoryAnalytics {
          const {
            storiesByImpression,
            storiesByEngagement,
            allStories: { data },
          } = respData;
          return {
            storiesByEngagement,
            storiesByImpression,
            allStories: data,
          };
        },
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },

    getStoryAnalyticsById({ dispatch, commit, state }) {
      return handleAjax({
        request: StoryAnalyticsService.getStoryById(state.singleStoryId, state.timeChunk),
        dispatch,
        commit,
        mutation: 'SET_SINGLE_STORY',
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },
  },
} as Module<SocialStoriesAnalyticsState, RootState>;

export default socialStoryAnalyticsModule;

export type SocialStoryAnalytics = {
  allStories: SocialStoryListResponse[];
  storiesByImpression: ParsedStoryDetailsAndData[];
  storiesByEngagement: ParsedStoryDetailsAndData[];
};

export interface SocialStoriesAnalyticsState {
  stories: SocialStoryAnalytics;
  timeChunk: TimeChunk;
  singleStoryId: number;
  singleStoryAnalytics: SocialStoryAnalyticsByTimeStep;
}
